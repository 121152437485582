import React from 'react';

import {View, StyleSheet} from 'react-native';

import {colors} from '../classes/Colors';

const ProgressBar = ({percentage}) => {
  // Determine the color based on percentage
  let progressColor;
  if (percentage >= 100) {
    progressColor = colors.Green500; // Green for completed
  } else if (percentage > 0) {
    progressColor = colors.Orange400; // Orange for in progress
  } else {
    progressColor = '#DCDDDF'; // Gray for empty
  }

  return (
    <View style={styles.container}>
      <View style={styles.progressBar}>
        <View
          style={[
            styles.progress,
            {width: `${percentage}%`, backgroundColor: progressColor},
          ]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  progressBar: {
    width: '100%',
    height: 10,
    backgroundColor: '#D3D3D3', // Gray for the empty bar background
    overflow: 'hidden',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  progress: {
    height: '100%',
  },
});

export default ProgressBar;
