export const getPromotionStrings = (promotion, promoCode, hasCollected) => {
  if (hasCollected) {
    return promotion.text.collected;
  }
  if (promoCode?.promoCode) {
    return promotion.text.completed;
  }
  return promotion.text.initial;
};

export const formatPromotionDate = date => {
  return date
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', ' -');
};

export const getPromotionDateStr = expiryDate => {
  const date = new Date(expiryDate);
  return expiryDate
    ? ` \u2022 Offer valid until ${formatPromotionDate(date)}`
    : '';
};

// info and qrCode are associated to the modal in handlePromoModalToShow
// double check these when changing them
// TODO: change this
export const analyticsEventNames = {
  claimed: 'promotion_claimed',
  info: 'promotion_more_info',
  takeQuiz: 'promotion_take_quiz',
  qrCode: 'promotion_qr_code_viewed',
};
