/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ImageBackground,
  FlatList,
  Modal,
  TextInput,
  Keyboard,
} from 'react-native';
import moment from 'moment';
import ScreenComponent from '../components/ScreenComponent';
import {colors} from '../classes/Colors';
import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';

const checkBoxActive = require('../assets/Button_Checkbox_Active.png');
const checkBoxInactive = require('../assets/Button_Checkbox_Inactive.png');

export default class VideoListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      filters: [], // 'seen', 'not seen'
      items: [],
      searchText: '',
      showSearchInput: false,
    };
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      filterButtonTapped: this.filterButtonTapped,
      searchButtonTapped: this.searchButtonTapped,
    });

    let filters = await DataController.getVideoFilters();
    if (!filters.length) filters = ['seen', 'not seen'];
    this.setState({filters: filters}, () => {
      this.filterItems();
    });
  }

  static navigationOptions = ({navigation}) => {
    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('filterButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Filter.png')}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: navigation.getParam('title', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  searchButtonTapped = () => {
    this.setState(prev => ({showSearchInput: !prev.showSearchInput}));
  };

  filterButtonTapped = () => {
    Keyboard.dismiss();
    this.setState({showFilterModal: true});
  };

  handleFilterOptionSelection(optionSelected) {
    let newFilters = [];
    if (this.state.filters.includes(optionSelected)) {
      newFilters = this.state.filters.filter(it => it !== optionSelected);
    } else {
      newFilters = [...this.state.filters, optionSelected];
    }

    this.setState({filters: newFilters}, () => {
      this.filterItems();
      DataController.saveVideoFilters(this.state.filters);
    });
  }

  filterItems() {
    let newItems = [];

    const allItems = [...this.props.screenProps.videos];
    const videoHistory = [...this.props.screenProps.videoHistory];

    // apply filters
    if (
      this.state.filters.includes('seen') &&
      this.state.filters.includes('not seen')
    ) {
      newItems = [...allItems];
    } else if (this.state.filters.includes('seen')) {
      newItems = allItems.filter(it => {
        const found = videoHistory.find(vid => vid.videoId === it.id);
        return found && found.secondsWatched >= 30;
      });
    } else if (this.state.filters.includes('not seen')) {
      newItems = allItems.filter(it => {
        const found = videoHistory.find(vid => vid.videoId === it.id);
        return !found || found.secondsWatched < 30;
      });
    } else {
      // filters are empty, show nothing
      this.setState({items: newItems});
    }

    // apply search
    const trimmedText = this.state.searchText.toLocaleLowerCase().trim();
    if (trimmedText === '') this.setState({items: newItems});
    newItems = newItems.filter(it =>
      it.Title.toLowerCase().includes(trimmedText),
    );
    this.setState({items: newItems});
  }

  sortByReleaseDate = arr =>
    arr
      .slice()
      .sort((a, b) => new Date(b.ReleaseDate) - new Date(a.ReleaseDate));

  render() {
    return (
      <ScreenComponent style={[styles.container]}>
        {this.state.showSearchInput && (
          <View style={styles.searchInputContainer}>
            <TextInput
              autoFocus
              onSubmitEditing={() => this.filterItems()}
              value={this.state.searchText}
              style={styles.searchInput}
              underlineColorAndroid="transparent"
              placeholder={'Search'}
              placeholderTextColor={'#BBB'}
              autoCapitalize={'none'}
              autoCorrect={false}
              inputMode="search"
              returnKeyType={'search'}
              onChangeText={text =>
                this.setState({searchText: text}, () => {
                  this.filterItems();
                })
              }
            />
          </View>
        )}
        <FlatList
          contentContainerStyle={{
            width: LayoutController.isCompact() ? '100%' : 700,
            marginHorizontal: 'auto',
          }}
          data={this.sortByReleaseDate(this.state.items)}
          renderItem={({item}) => {
            const currentVideoHistory =
              this.props.screenProps.videoHistory.find(
                vid => vid.videoId === item.id,
              );
            return (
              <TouchableOpacity
                style={styles.cardContainer}
                onPress={() =>
                  this.props.navigation.navigate('Video', {
                    video: item,
                    from: 'Video List',
                  })
                }>
                <View style={{flex: 1, paddingRight: 6}}>
                  <View style={styles.vidInfoContainer}>
                    <Text
                      style={{
                        color: colors.Grey700,
                        fontWeight: '500',
                        fontSize: 12,
                      }}>
                      {moment(item.ReleaseDate).format('DD MMM YYYY')}
                    </Text>
                    <View
                      style={{
                        backgroundColor: 'rgb(121, 139, 147)',
                        borderRadius: 1,
                        height: 2,
                        marginRight: 6,
                        marginLeft: 6,
                        width: 2,
                      }}
                    />
                    <View
                      style={{
                        justifyContent: 'center',
                        paddingHorizontal: 6,
                        height: 16,
                        borderRadius: 2,
                        backgroundColor: DataController.getColorForTrust(
                          item.Trust,
                        ),
                      }}>
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: '500',
                          letterSpacing: -0.1,
                          color: colors.white,
                        }}>
                        {DataController.getTrustDisplayName(item.Trust)}
                      </Text>
                    </View>
                    {currentVideoHistory &&
                      currentVideoHistory.secondsWatched >= 30 && (
                        <>
                          <View
                            style={{
                              backgroundColor: 'rgb(121, 139, 147)',
                              borderRadius: 1,
                              height: 2,
                              marginRight: 6,
                              marginLeft: 6,
                              width: 2,
                            }}
                          />
                          <Text
                            style={{color: colors.Grey700, fontWeight: '500'}}>
                            Seen
                          </Text>
                        </>
                      )}
                  </View>
                  <Text style={styles.title}>{item.Title}</Text>
                  <Text style={styles.description} numberOfLines={1}>
                    {item.ShortDescription}
                  </Text>
                </View>
                <ImageBackground
                  imageStyle={{
                    width: 70,
                    height: 70,
                  }}
                  style={{
                    width: 70,
                    height: 70,
                    backgroundColor: colors.Grey100,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  source={{
                    uri: item.Screenshot
                      ? `https://ppp-temp-files.s3.amazonaws.com/VideoPodcasts/${item.Screenshot[0].filename}`
                      : '',
                  }}>
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: 25,
                      width: 50,
                      height: 50,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Image
                      style={{
                        width: 15,
                        height: 20,
                      }}
                      source={require('../assets/Button_Play_White.png')}
                    />
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            );
          }}
          ItemSeparatorComponent={() => <View style={styles.divider} />}
        />
        <Modal
          visible={this.state.showFilterModal}
          onRequestClose={() => this.setState({showFilterModal: false})}
          transparent={true}
          animationType="fade">
          <View
            style={{
              flex: 1,
              backgroundColor: 'rgba(52, 52, 52, 0.4)',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <View style={{width: LayoutController.isCompact() ? '75%' : 350}}>
              <View style={styles.modalHeader}>
                <Text style={styles.modalHeaderText}>Filter</Text>
                <TouchableOpacity
                  style={styles.modalDoneBtn}
                  onPress={() => this.setState({showFilterModal: false})}>
                  <Text style={styles.modalHeaderText}>Done</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.modalOptionsContainer}>
                <TouchableOpacity
                  style={styles.modalRowBtn}
                  onPress={() => this.handleFilterOptionSelection('seen')}>
                  <Text style={styles.optionText}>Seen</Text>
                  <Image
                    style={{width: 26, height: 26}}
                    source={
                      this.state.filters.includes('seen')
                        ? checkBoxActive
                        : checkBoxInactive
                    }
                  />
                </TouchableOpacity>
                <View style={styles.divider} />
                <TouchableOpacity
                  style={styles.modalRowBtn}
                  onPress={() => this.handleFilterOptionSelection('not seen')}>
                  <Text style={styles.optionText}>Not Seen</Text>
                  <Image
                    style={{width: 26, height: 26}}
                    source={
                      this.state.filters.includes('not seen')
                        ? checkBoxActive
                        : checkBoxInactive
                    }
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  cardContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  vidInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginTop: 4,
    fontSize: 16,
    fontWeight: '600',
    color: colors.Grey900,
  },
  description: {
    marginTop: 4,
    fontSize: 14,
    color: colors.Grey600,
  },
  searchInputContainer: {
    backgroundColor: colors.NavBar,
    marginBottom: 10,
  },
  searchInput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    backgroundColor: colors.SearchBar,
    borderRadius: 5,
    color: colors.white,
  },
  divider: {
    height: 1,
    backgroundColor: colors.Grey100,
  },
  modalRowBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  modalOptionsContainer: {
    paddingHorizontal: 12,
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  modalHeader: {
    backgroundColor: colors.NavBar,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: 'center',
    padding: 10,
  },
  modalHeaderText: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  optionText: {
    color: colors.Grey900,
    fontWeight: 'bold',
  },
  modalDoneBtn: {
    right: 12,
    position: 'absolute',
    padding: 10,
  },
});
