import React, {memo} from 'react';

import {Platform, Image, TouchableOpacity} from 'react-native';
import RenderHTML from 'react-native-render-html';

function ignoreThis(node, parent) {
  const classToIgnore = Platform.OS === 'web' ? 'mobile' : 'web';
  return (
    node.type === 'tag' &&
    node.name === 'a' &&
    node.attribs.class === classToIgnore
  );
}

/**
 * Renders a string of HTML as a native React component tree.
 *
 * @param {Object} props - component props
 * @param {string} props.html - the HTML to render
 * @param {Object} [props.tagStyles] - styles to apply to rendered HTML tags
 * @param {number} [props.contentWidth=200] - the width of the rendered content
 */
const HTMLRenderer = ({
  html,
  tagStyles,
  contentWidth = 200,
  onPressImage,
  imageStyles,
}) => {
  const renderers = {
    img: ({tnode, style}) => {
      const {src} = tnode?.attributes || {};

      if (!src) {
        return <TDefaultRenderer domNode={domNode} />;
      }

      const imageComponent = (
        <Image
          source={{
            uri: src,
          }}
          style={[style, imageStyles]}
          resizeMode="cover"
        />
      );

      if (onPressImage) {
        return (
          <TouchableOpacity onPress={() => onPressImage(src)} style={style}>
            {imageComponent}
          </TouchableOpacity>
        );
      }

      return imageComponent;
    },
  };

  return (
    <RenderHTML
      tagsStyles={tagStyles}
      contentWidth={contentWidth}
      source={{html}}
      ignoreDomNode={ignoreThis}
      renderers={renderers}
    />
  );
};

export default memo(HTMLRenderer);
