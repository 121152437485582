import React from 'react';

import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
  Platform,
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

import {colors} from '../../classes/Colors';
import LayoutController from '../../classes/LayoutController';

import CustomButton from '../CustomButton';

const PromoCard = ({
  header,
  title,
  subtitle,
  imageUrl,
  icon,
  onPress,
  promoButtonOnPress,
  infoButtonOnPress,
  isButtonDisabled,
  promoCode,
  disabledButtonText,
  activeButtonText,
  buttonText,
  showLogo,
}) => {
  const isTablet = LayoutController.isTablet();
  const hasPromoCode = Boolean(promoCode?.promoCode);
  const showPromoCode = icon === 'Promotion' && hasPromoCode;
  const imageSource = {uri: imageUrl};

  const button = isButtonDisabled ? (
    <CustomButton
      title={disabledButtonText}
      buttonStyle={styles.collectedButton}
      titleStyle={styles.collectedButtonTitle}
      disabled={true}
    />
  ) : (
    <CustomButton
      title={activeButtonText}
      buttonStyle={styles.promoButton}
      titleStyle={styles.promoButtonTitle}
      iconComponent={
        <Image
          style={styles.icon}
          source={require('../../assets/promo/qr_code_white.png')}
        />
      }
      onPress={promoButtonOnPress}
    />
  );

  return (
    <View style={isTablet ? tabletStyles.container : mobileStyles.container}>
      <View style={styles.headerContainer}>
        <Image
          style={styles.icon}
          source={require('../../assets/promo/gift_white.png')}
        />
        <Text style={styles.header}>{header}</Text>
      </View>
      <View style={isTablet ? tabletStyles.layout : mobileStyles.layout}>
        <ImageBackground
          style={isTablet ? tabletStyles.image : mobileStyles.image}
          source={imageSource}
          resizeMode="cover">
          <LinearGradient
            colors={['rgba(6, 42, 68, 0.1)', 'rgba(6, 42, 68, 1)']}
            style={[
              styles.background,
              isTablet ? tabletStyles.image : mobileStyles.image,
            ]}
            start={isTablet ? {x: 0, y: 0} : {x: 0, y: 0}}
            end={isTablet ? {x: 1, y: 0} : {x: 0, y: 1}}
          />
        </ImageBackground>
        <View
          style={StyleSheet.flatten([
            styles.contentContainer,
            isTablet
              ? tabletStyles.contentContainer
              : mobileStyles.contentContainer,
          ])}>
          {showLogo && (
            <Image
              style={styles.logo}
              resizeMode="contain"
              source={require('../../assets/promo/greggs_logo_white.png')}
            />
          )}
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
          {showPromoCode ? (
            button
          ) : (
            <CustomButton
              title={buttonText}
              buttonStyle={styles.ctaButton}
              titleStyle={styles.ctaButtonTitle}
              onPress={onPress}
            />
          )}
          <CustomButton
            title={'More Information'}
            buttonStyle={styles.infoButton}
            titleStyle={styles.infoButtonTitle}
            onPress={infoButtonOnPress}
          />
        </View>
      </View>
    </View>
  );
};

const commonStyles = {
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'rgba(6, 42, 68, 1)',
  },
  icon: {
    width: 24,
    height: 24,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
  },
};

const tabletStyles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    marginBottom: 16,
    maxWidth: 1334,
  },
  layout: {
    flexDirection: 'row',
    flex: 1,
  },
  image: {
    alignSelf: 'stretch',
    flex: 2,
  },
  contentContainer: {
    marginLeft: -1,
    paddingHorizontal: '5%',
    paddingVertical: '8%',
    justifyContent: 'center',
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    marginBottom: 16,
  },
  layout: {
    flexDirection: 'column',
    flex: 1,
  },
  image: {
    alignSelf: 'stretch',
    height: 184,
  },
  contentContainer: {
    padding: 16,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
});

const styles = StyleSheet.create({
  ...commonStyles,
  headerContainer: {
    backgroundColor: colors.PromoBlueDarkest,
    padding: 9,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    color: colors.white,
    fontWeight: '700',
    fontSize: 11,
    marginLeft: 6,
    marginRight: 6,
  },
  logo: {
    marginVertical: 10,
    height: 15,
    width: 65,
  },
  title: {
    color: colors.white,
    marginBottom: 6,
    fontSize: 20,
    fontWeight: '700',
  },
  subtitle: {
    color: 'rgba(255, 255, 255, 0.8)',
    marginBottom: 20,
    fontSize: 13,
    fontWeight: '600',
  },
  ctaButton: {
    backgroundColor: colors.white,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  ctaButtonTitle: {
    color: colors.darkGrey,
    fontWeight: '700',
    fontSize: 17,
  },
  infoButton: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  infoButtonTitle: {
    color: colors.white,
    fontWeight: '700',
    textDecorationLine: 'underline',
    fontSize: 12,
  },
  promoButton: {
    borderColor: colors.white,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: 14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  promoButtonTitle: {
    color: colors.white,
    fontWeight: '700',
    fontSize: 17,
  },
  collectedButton: {
    backgroundColor: colors.PromoBlueCollected,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  collectedButtonTitle: {
    color: colors.PromoBluePale,
    fontWeight: '700',
    fontSize: 17,
  },
});

export default PromoCard;
