import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  ScrollView,
  StyleSheet,
} from 'react-native';

const AMImageViewer = ({visible, imageUrls, onClose}) => {
  const [zoomPercentage, setZoomPercentage] = useState(400);

  const zoomIn = () => {
    if (zoomPercentage < 2000) {
      setZoomPercentage(zoomPercentage + 50);
    }
  };

  const zoomOut = () => {
    if (zoomPercentage > 110) {
      setZoomPercentage(zoomPercentage - 50);
    }
  };

  const close = () => {
    onClose();
    setZoomPercentage(400);
  };

  if (!visible) {
    return <View />;
  }

  const imageUrl = imageUrls && imageUrls.length > 0 ? imageUrls[0].url : '';

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollContent}>
          <Image
            style={{
              width: zoomPercentage,
              height: zoomPercentage,
            }}
            source={{uri: imageUrl}}
            resizeMode="contain"
          />
        </ScrollView>
      </View>

      <TouchableOpacity style={styles.closeButton} onPress={close}>
        <Image
          style={styles.closeIcon}
          source={require('../assets/Button_Close.png')}
        />
      </TouchableOpacity>

      <TouchableOpacity style={styles.zoomButton} onPress={zoomIn}>
        <Text style={styles.zoomText}>+</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[styles.zoomButton, styles.zoomOutButton]}
        onPress={zoomOut}>
        <Text style={styles.zoomText}>-</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#374044',
  },
  imageContainer: {
    flex: 1,
    alignSelf: 'stretch',
  },
  scrollView: {
    flex: 1,
    alignSelf: 'stretch',
    overflow: 'scroll',
  },
  scrollContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 30,
    right: 10,
    width: 32,
    height: 32,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 16,
    justifyContent: 'center',
  },
  closeIcon: {
    height: 30,
    width: 30,
    alignSelf: 'center',
  },
  zoomButton: {
    position: 'absolute',
    top: 82,
    right: 10,
    width: 32,
    height: 32,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 16,
    justifyContent: 'center',
  },
  zoomOutButton: {
    top: 122,
  },
  zoomText: {
    alignSelf: 'center',
    paddingBottom: 7,
    fontSize: 30,
    color: '#FFF',
  },
});

export default AMImageViewer;
