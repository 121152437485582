/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import AMWebView from '../components/AMWebView';
import ScreenComponent from '../components/ScreenComponent';

import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';

import NWASBookStyles from '../ParaPassBooks/NWASBookStyles';

export default class BookSectionSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onMessage(m) {
    var message = m.nativeEvent.data;
    console.log(message);
    if (message.includes('bookImageTapped')) {
      var imageString = message;
      imageString = imageString.replace('bookImageTapped-', '');

      this.props.openImageModal(imageString);
    }
    if (message.includes('readButtonTapped')) {
      let messageComponenets = message.split('-');
      if (messageComponenets.length > 1) {
        let type = messageComponenets[1];
        this.props.readButtonTapped(type);

        if (this.webView) {
          setTimeout(() => {
            this.webView.injectJavaScript(`
              window.scroll({
                top: 1000000, 
                left: 0, 
                behavior: 'instant'
              })
            `);
          }, 200);
        }
      }
    }
    if (message.includes('paraFolioButtonTapped')) {
      let messageComponenets = message.split('-');
      if (messageComponenets.length > 1) {
        let type = messageComponenets[1];
        this.props.paraFolioButtonTapped(type);
      }
    }
    if (message.includes('quizButtonTapped')) {
      let messageComponents = message.split('-');
      if (messageComponents.length > 1) {
        const {quizset, onPressHandler} = this.props.quizDetails;
        onPressHandler(quizset);
      }
    }
  }

  render() {
    let shouldRenderSlide =
      this.props.index > this.props.activeIndex - 2 &&
      this.props.index < this.props.activeIndex + 2;

    if (shouldRenderSlide) {
      // slide is near active index so render it

      const script = `
        <script>
  
          document.addEventListener('DOMContentLoaded', function(){ 
            // your code goes here
            
            var highlightedElements = document.getElementsByClassName('searchHighlightCurrent');
            if (highlightedElements.length > 0) {
              var highlightedElement = highlightedElements[0];
              highlightedElement.scrollIntoView({block: "center"});
            }
  
            let imageElements = document.getElementsByClassName("bookImage");
            console.log('image elements: ' + imageElements.length);
            for(let i = 0; i < imageElements.length; i++) {
              let imageElement = imageElements[i];
              console.log('adding onclick');
              imageElement.onclick = function () {
                console.log(window.ReactNativeWebView);
                if (window.ReactNativeWebView != null && window.ReactNativeWebView.postMessage != null) {
                  window.ReactNativeWebView.postMessage("bookImageTapped-" + imageElement.src);
                }  
              }
            }
  
            let readButtonElements = document.getElementsByClassName("bookReadButton");
            for(let i = 0; i < readButtonElements.length; i++) {
              let readButtonElement = readButtonElements[i];
              console.log('adding onclick');
              readButtonElement.onclick = function () {
                console.log(window.ReactNativeWebView);
                if (window.ReactNativeWebView != null && window.ReactNativeWebView.postMessage != null) {
                  window.ReactNativeWebView.postMessage("readButtonTapped-" + readButtonElement.id);
                }  
              }
            }

            let quizButtonElements = document.getElementsByClassName("bookQuizButton");
            for(let i = 0; i < quizButtonElements.length; i++) {
              let quizButtonElement = quizButtonElements[i];
              console.log('adding onclick');
              quizButtonElement.onclick = function () {
                console.log(window.ReactNativeWebView);
                if (window.ReactNativeWebView != null && window.ReactNativeWebView.postMessage != null) {
                  window.ReactNativeWebView.postMessage("quizButtonTapped-" + quizButtonElement.id);
                }  
              }
            }
  
            let paraFolioButtonElements = document.getElementsByClassName("bookParaFolioButton");
            for(let i = 0; i < paraFolioButtonElements.length; i++) {
              let paraFolioButtonElement = paraFolioButtonElements[i];
              console.log('adding onclick');
              paraFolioButtonElement.onclick = function () {
                console.log(window.ReactNativeWebView);
                if (window.ReactNativeWebView != null && window.ReactNativeWebView.postMessage != null) {
                  window.ReactNativeWebView.postMessage("paraFolioButtonTapped-" + paraFolioButtonElement.id);
                }  
              }
            }
  
          }, false);
  
  
  
        </script>
      `;

      let styleCSS = `
          <style>
              .content {
                overflow-y: auto;
              }
              .standby-content-container {
                  box-sizing: border-box;
                  max-width: 784px;
                  font-family: InterUI-Regular, -apple-system, BlinkMacSystemFont,
                              "Segoe UI", "Roboto", "Oxygen",
                              "Ubuntu", "Cantarell", "Fira Sans",
                              "Droid Sans", "Helvetica Neue", sans-serif;
                  font-size: ${this.props.fontSize}px;
                  margin-top:1em;
                  margin-left:auto;
                  margin-right:auto;
                  padding-left: 16px;
                  padding-right: 16px;
                  padding-bottom: 16px;
                  -webkit-text-size-adjust:none;
                  /*    6.5 inch*/
  
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  -ms-word-break: break-all;
                  word-break: break-word;
              }
              .chapterTitle {
                font-size: 12px;
                color: #212529;
              }
              body, html, .height-calculator {
                margin: 0;
                padding: 0;
              }
              .height-calculator {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
              }
              .hiddenText {
                display: none;
              }
              img {
                max-width: 100%;
              }
              .searchHighlight {
                background-color: #E5F1FF;
                color: black;
                display: inline;
              }
              .searchHighlightCurrent {
                background-color: #007BFE;
                color: white;
                display: inline;
              }
              h3 {
                font-size: 22px !important;
                font-weight: bold !important;
                color: #212529 !important;
              }
              .bookReadTitleLabel {
                font-weight: 700;
                text-align: center;
                margin-bottom: 10px;
              }
              .bookReadButton {
                font-weight: 700;
                color: #1581ea;
                background-color: #fff;
                border: 1px solid #1581ea;
                padding-top: 13px;
                padding-bottom: 13px;
                text-align: center;
                max-width: 342px;
                margin: auto;
                margin-bottom: 20px;
                border-radius: 8px;
              }
              .bookQuizLabel {
                font-weight: 700;
                text-align: center;
                margin-bottom: 10px;
              }
              .bookQuizButton {
                font-weight: 700;
                color: #fff;
                background-color: #1581ea;
                border: 1px solid #1581ea;
                padding-top: 13px;
                padding-bottom: 13px;
                text-align: center;
                max-width: 342px;
                margin: auto;
                margin-bottom: 20px;
                border-radius: 8px;
              }
              .bookParaFolioButton {
                font-weight: 700;
                color: #FFF;
                background-color: #1581ea;
                padding-top: 13px;
                padding-bottom: 13px;
                text-align: center;
                max-width: 342px;
                margin: auto;
                margin-bottom: 20px;
                border-radius: 8px;
              }
              .bookButtonsContainer {
                margin-top: 15px;
              }
        `;

      styleCSS += NWASBookStyles.getCSSForBook(this.props.book);

      styleCSS += `
            </style>
        `;

      let contentHTML = this.props.sectionHTML;

      contentHTML = contentHTML.replace(/\r/g, '');
      contentHTML = contentHTML.replace(/\n/g, '');
      contentHTML = contentHTML.replace(
        /\.\.\/images\//g,
        'bookImages/' + this.props.book.ID + '-',
      );
      contentHTML = contentHTML.replace(
        /\.\.\/Images\//g,
        'bookImages/' + this.props.book.ID + '-',
      );
      contentHTML = contentHTML.replace(
        /images\//g,
        'bookImages/' + this.props.book.ID + '-',
      );

      contentHTML = contentHTML.replace(/<img /g, "<img class='bookImage' ");

      // replace page links
      contentHTML =
        Platform.OS === 'web'
          ? contentHTML.replace(/pp-link/g, 'button')
          : contentHTML.replace(/pp-link/g, 'a');

      contentHTML = this.props.addHighlights(contentHTML, this.props.isActive);

      let headerHTML =
        '<h1 class="chapterTitle">' + this.props.chapterName + '</h1><hr>';

      var readButtonHtml = ``;
      let quizButtonHtml = ``;

      if (Platform.OS !== 'web') {
        if (this.props?.quizDetails?.quizset) {
          quizButtonHtml = `
            <div class='bookButtonsContainer'>
              <div class='bookQuizLabel'>${this.props?.quizDetails?.quizset.title}</div>
              <div id='chapter' class='bookQuizButton'>Take quiz</div>
            </div>`;
        }
        if (this.props.isSectionRead(this.props.name)) {
          readButtonHtml = `
            <div class='bookButtonsContainer'>
              <div class='bookReadTitleLabel'>You've read this subchapter</div>
              <div id='section' class='bookReadButton bookReadButtonFinished'>Read</div>
            </div>`;
        } else {
          readButtonHtml = `
            <div class='bookButtonsContainer'>
              <div class='bookReadTitleLabel'>Finished reading this subchapter?</div>
              <div id='section' class='bookReadButton'>Mark as read</div>
            </div>`;
        }

        if (this.props.isLastSectionOfChapter) {
          if (this.props.isChapterRead(this.props.name)) {
            readButtonHtml += `
              <div class='bookButtonsContainer'>
                <div class='bookReadTitleLabel'>You've read this chapter</div>
                <div id='chapter' class='bookReadButton bookReadButtonFinished'>Read</div>
                <div id='chapter' class='bookParafolioButton'>Add to ParaFolio</div>
              </div>`;
          } else {
            readButtonHtml += `
              <div class='bookButtonsContainer'>
                <div class='bookReadTitleLabel'>Finished reading this chapter?</div>
                <div id='chapter' class='bookReadButton'>Mark as read</div>
              </div>`;
          }
        }
      }

      var rawHtml =
        "<html><head><meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>" +
        styleCSS +
        "</head><body><div class='standby-content-container'>" +
        headerHTML +
        contentHTML +
        quizButtonHtml +
        readButtonHtml +
        "<span class='hiddenText'>" +
        1 +
        '</span></div>' +
        script +
        '</body></html>';

      var scalesPageToFit = false;

      if (Platform.OS === 'android') {
        scalesPageToFit = true;
      }

      var baseUrl = 'web/';

      if (Platform.OS === 'android') {
        baseUrl = 'file:///android_asset/';
      }

      var webContentLayout = (
        <View style={{flex: 1}}>
          <AMWebView
            ref={ref => (this.webView = ref)}
            source={{html: rawHtml, baseUrl: baseUrl}}
            scalesPageToFit={scalesPageToFit}
            useWebKit={true}
            javaScriptEnabled={true}
            // onNavigationStateChange={this.onNavigationChange.bind(this)}
            onMessage={m => this.onMessage(m)}
            setSupportMultipleWindows={false}
            allowFileAccessFromFileURLs={true}
            originWhitelist={['*']}
            allowFileAccess={true}
            allowUniversalAccessFromFileURLs={true}
            onShouldStartLoadWithRequest={event => {
              console.log(event.url);

              let shouldIntercept = false;
              if (
                event.navigationType === 'click' ||
                Platform.OS === 'android'
              ) {
                shouldIntercept = true;
              }

              if (shouldIntercept) {
                let lastPathComponent = event.url.substring(
                  event.url.lastIndexOf('/') + 1,
                );

                if (
                  event.url.includes('http') ||
                  event.url.includes('www') ||
                  event.url.includes('mailto')
                ) {
                  PlatformController.openURL(event.url);
                  return false;
                } else if (event.url.includes('glid://')) {
                  let glid = event.url.replace('glid://', '');
                  // this.goToReference(glid);
                  return false;
                } else if (lastPathComponent.substr(0, 2) === 'CH') {
                  // book link
                  lastPathComponent = lastPathComponent.replace(/%20/g, '');
                  lastPathComponent = lastPathComponent.replace(/CH/g, '');
                  let lastPathComponentParts = lastPathComponent.split('-');
                  if (lastPathComponentParts.length === 2) {
                    let chapterString = lastPathComponentParts[0];
                    let sectionString = lastPathComponentParts[1];
                    let chapterIndex = parseInt(chapterString);
                    let sectionIndex = parseInt(sectionString);

                    this.props.goToSection(chapterIndex, sectionIndex);
                  }
                } else if (lastPathComponent.substr(0, 9) === 'caseStudy') {
                  // case study link
                  lastPathComponent = lastPathComponent.replace(/%20/g, '');
                  let lastPathComponentParts = lastPathComponent.split('-');
                  if (lastPathComponentParts.length === 2) {
                    let caseStudyName = lastPathComponentParts[1];
                    this.props.goToCaseStudy(caseStudyName);
                  }
                  return false;
                } else if (lastPathComponent.startsWith('#')) {
                  return true;
                }
                return false;
              }
              return true;
            }}
          />
        </View>
      );

      if (Platform.OS === 'web') {
        var readButtonLayout = [];
        const quizButtonLayout = [];

        if (this.props?.quizDetails?.quizset) {
          const {quizset, onPressHandler} = this.props.quizDetails;
          quizButtonLayout.push(
            <View>
              <Text style={styles.bookReadTitleLabel}>{quizset.title}</Text>
              <TouchableOpacity onPress={onPressHandler}>
                <Text style={[styles.quizButton]}>Take quiz</Text>
              </TouchableOpacity>
            </View>,
          );
        }

        if (this.props.isSectionRead(this.props.name)) {
          readButtonLayout.push(
            <View>
              <Text style={styles.bookReadTitleLabel}>
                You've read this subchapter
              </Text>
              <TouchableOpacity
                onPress={this.onMessage.bind(this, {
                  nativeEvent: {data: 'readButtonTapped-section'},
                })}>
                <Text style={[styles.bookReadButton]}>Mark as unread</Text>
              </TouchableOpacity>
            </View>,
          );
        } else {
          readButtonLayout.push(
            <View>
              <Text style={styles.bookReadTitleLabel}>
                Finished reading this subchapter?
              </Text>
              <TouchableOpacity
                onPress={this.onMessage.bind(this, {
                  nativeEvent: {data: 'readButtonTapped-section'},
                })}>
                <Text style={[styles.bookReadButton]}>Mark as read</Text>
              </TouchableOpacity>
            </View>,
          );
        }

        if (this.props.isLastSectionOfChapter) {
          if (this.props.isChapterRead(this.props.name)) {
            readButtonLayout.push(
              <View>
                <Text style={styles.bookReadTitleLabel}>
                  You've read this chapter
                </Text>
                <TouchableOpacity
                  onPress={this.onMessage.bind(this, {
                    nativeEvent: {data: 'readButtonTapped-chapter'},
                  })}>
                  <Text style={[styles.bookReadButton]}>Mark as unread</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={this.onMessage.bind(this, {
                    nativeEvent: {data: 'paraFolioButtonTapped-chapter'},
                  })}>
                  <Text style={[styles.bookParafolioButton]}>
                    Add to ParaFolio
                  </Text>
                </TouchableOpacity>
              </View>,
            );
          } else {
            readButtonLayout.push(
              <View>
                <Text style={styles.bookReadTitleLabel}>
                  Finished reading this chapter?
                </Text>
                <TouchableOpacity
                  onPress={this.onMessage.bind(this, {
                    nativeEvent: {data: 'readButtonTapped-chapter'},
                  })}>
                  <Text style={[styles.bookReadButton]}>Mark as read</Text>
                </TouchableOpacity>
              </View>,
            );
          }
        }

        webContentLayout = (
          <div className="content">
            <div dangerouslySetInnerHTML={{__html: rawHtml}} />
            {quizButtonLayout}
            {readButtonLayout}
          </div>
        );
      }

      return (
        <ScreenComponent key={this.props.index} style={{flex: 1}}>
          {webContentLayout}
        </ScreenComponent>
      );
    } else {
      // slide is not near active index so render dummy slide
      return <View />;
    }
  }
}

const {width} = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  child: {width: width},
  bookReadTitleLabel: {
    fontWeight: '700',
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 10,
  },
  bookReadButton: {
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 18,
    paddingTop: 13,
    paddingBottom: 13,
    textAlign: 'center',
    width: 342,
    marginBottom: 20,
    borderRadius: 8,
    color: colors.ButtonBlue,
    borderColor: colors.ButtonBlue,
    borderWidth: 1,
    backgroundColor: colors.white,
  },
  quizButton: {
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 18,
    paddingTop: 13,
    paddingBottom: 13,
    textAlign: 'center',
    width: 342,
    marginBottom: 20,
    borderRadius: 8,
    color: colors.white,
    borderColor: colors.ButtonBlue,
    borderWidth: 1,
    backgroundColor: colors.ButtonBlue,
  },
  bookParafolioButton: {
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 18,
    paddingTop: 13,
    paddingBottom: 13,
    textAlign: 'center',
    width: 342,
    marginBottom: 20,
    borderRadius: 8,
    color: colors.ButtonBlue,
    borderColor: colors.ButtonBlue,
    borderWidth: 1,
    backgroundColor: colors.white,
  },
});
