import React from 'react';

import {
  StyleSheet,
  Modal,
  View,
  Image,
  Pressable,
  Text,
  SafeAreaView,
} from 'react-native';

import {colors} from '../../classes/Colors';
import {getPromotionStrings} from '../../constants/promotions';
import {usePromotions} from '../../context/PromotionsContext';

import PromoInfoContent from './PromoInfoContent';
import PromoQrContent from './PromoQrContent';

const getModalContent = (
  modalToShow,
  promoCode,
  isPromoCollected,
  promotion,
  onPress,
) => {
  const {title, subtitle, button} = getPromotionStrings(
    promotion,
    promoCode,
    isPromoCollected,
  );

  switch (modalToShow) {
    case 'qrCode': {
      return (
        <PromoQrContent
          title={title}
          subtitle={subtitle}
          buttonTitle={button}
          promoCode={promoCode}
          onButtonPress={onPress}
          isButtonDisabled={isPromoCollected}
          expiryDate={promotion.finalDate}
        />
      );
    }
    case 'info': {
      return <PromoInfoContent content={promotion.terms} />;
    }
    default: {
      return null;
    }
  }
};

const PromoModal = ({title, isModalVisible, onClose, modalToShow, onPress}) => {
  const {promotion, isPromoCollected, promoCode, promoCollectedHandler} =
    usePromotions();

  if (!promotion) {
    return null;
  }

  const pressHandler = () => {
    promoCollectedHandler();
    onPress();
  };

  const modalContent = getModalContent(
    modalToShow,
    promoCode,
    isPromoCollected,
    promotion,
    pressHandler,
  );

  return (
    <Modal
      animationType="slide"
      visible={isModalVisible}
      presentationStyle="fullScreen">
      {/* Double safe area view for the navbar and the white background */}
      <SafeAreaView style={{flex: 0, backgroundColor: colors.NavBar}} />
      <SafeAreaView style={{flex: 1, backgroundColor: colors.white}}>
        <View style={styles.navbar}>
          <Pressable onPress={onPress}>
            <Image
              style={styles.closeButton}
              source={require('../../assets/Button_Close.png')}
            />
          </Pressable>
          {title && <Text style={styles.title}>{title}</Text>}
          <View style={{width: 40}}></View>
        </View>
        {modalContent}
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  navbar: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: colors.NavBar,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeButton: {
    marginLeft: 10,
    height: 30,
    width: 30,
  },
  title: {
    color: colors.white,
    fontSize: 17,
    fontWeight: '600',
  },
});

export default PromoModal;
