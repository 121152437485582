'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';

import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import DynamicText from '../components/DynamicText';

var moment = require('moment');

export default class QuizResultCell extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var quizSetName = '';
    var quizSetDate = '';
    var typeLayout = null;
    var questionCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var borderLeftColor = colors.white;
    var borderLeftWidth = 0;

    if (this.props.quiz != null) {
      quizSetName = this.props.quiz.quizMode;
      questionCount = this.props.quiz.questions.length;

      if (quizSetName === '10 Questions') {
        if (questionCount === 1) {
          quizSetName = '1 Question';
        } else if (questionCount < 10) {
          quizSetName = questionCount + ' Questions';
        }
      }
      if (this.props.quizSet && this.props.quizSet.pma) {
        quizSetName = 'PMA';
      }
    }

    if (this.props.results != null) {
      for (
        var resultIndex = 0;
        resultIndex < this.props.results?.length;
        resultIndex++
      ) {
        var result = this.props.results[resultIndex];

        if (result.correct) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    }

    unansweredCount = questionCount - correctCount - incorrectCount;

    var typeDotSeparatorLayout = null;

    if (typeLayout != null) {
      typeDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    var quizTimeLayout = null;
    var iconDotSeparatorLayout = null;
    var iconLayout = null;

    if (this.props.quiz != null) {
      var dateString = moment(this.props.quiz.time).format('DD MMM YYYY');
      var timeString = moment(this.props.quiz.time).format('HH:mm');

      quizTimeLayout = (
        <DynamicText
          style={{
            marginTop: 4,
            fontSize: 16,
            letterSpacing: -0.41,
            color: colors.Grey900,
          }}>
          Taken on{' '}
          <DynamicText style={{fontWeight: '500'}}>{dateString}</DynamicText> at{' '}
          <DynamicText style={{fontWeight: '500'}}>{timeString}</DynamicText>
        </DynamicText>
      );
    }

    iconDotSeparatorLayout = (
      <View
        style={{
          marginHorizontal: 6,
          width: 2,
          height: 2,
          backgroundColor: colors.Grey700,
          borderRadius: 1,
        }}
      />
    );

    if (this.props.quizSet != null && this.props.quizSet.type === 'standby') {
      iconLayout = (
        <Image
          style={{width: 24, height: 24}}
          source={require('../assets/Glyph_Small_Standby.png')}
        />
      );
    } else if (
      this.props.quizSet != null &&
      this.props.quizSet.type === 'caseStudy'
    ) {
      iconLayout = (
        <Image
          style={{width: 24, height: 24}}
          source={require('../assets/Glyph_Small_CaseStudy.png')}
        />
      );
    } else {
      iconLayout = (
        <Image
          style={{width: 24, height: 24}}
          source={require('../assets/Icon_Quizzes_Blue.png')}
        />
      );
    }

    var infoLayout = (
      <View>
        <DynamicText
          style={{
            marginTop: 4,
            fontSize: 19,
            fontWeight: '600',
            letterSpacing: -0.04,
            color: colors.Grey900,
          }}>
          {quizSetName}
        </DynamicText>
        {quizTimeLayout}
      </View>
    );

    var progressLayout = (
      <View>
        <View
          style={{marginTop: 7, flexDirection: 'row', alignItems: 'center'}}>
          <DynamicText
            style={{fontSize: 16, letterSpacing: -0.41, color: colors.Grey600}}>
            {correctCount}/{questionCount}
          </DynamicText>
          <ProgressView
            style={{flex: 1, marginLeft: 6, marginRight: 8, height: 4}}
            correctAnswers={correctCount}
            incorrectAnswers={incorrectCount}
            unansweredQuestions={unansweredCount}
          />
        </View>
      </View>
    );

    if (LayoutController.isCompact()) {
      return (
        <View
          style={{
            paddingLeft: 10,
            paddingRight: 6,
            paddingVertical: 15,
            borderBottomWidth: 1,
            borderColor: colors.Grey100,
            borderLeftWidth: borderLeftWidth,
            borderLeftColor: borderLeftColor,
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1}}>
              {infoLayout}

              {progressLayout}
            </View>

            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            paddingLeft: 10,
            paddingRight: 6,
            paddingVertical: 15,
            borderBottomWidth: 1,
            borderColor: colors.Grey100,
            borderLeftWidth: borderLeftWidth,
            borderLeftColor: borderLeftColor,
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1}}>{infoLayout}</View>

            <View style={{flex: 1}}>{progressLayout}</View>

            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </View>
      );
    }
  }
}
