import React from 'react';

import {TouchableOpacity, ScrollView, View} from 'react-native';

import {
  getBookStatus,
  getBookProgressData,
  doesBookHaveQuiz,
} from '../utils/books';

import ContentCard from './ContentCard';
import BookCard from './BookCard';

const orderBooksByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

/**
 * A content card component to render a horizontal scroll view of books for a user.
 * @param {string} stateName - name to reference for the card state
 * @param {Object} books - An array of book objects.
 * @param {Array<string>} booksProgress - An array of strings representing the progress of each book, where each string is in the format 'bookId-chapterNumber-sectionNumber'.
 * @param {Boolean} isCollapsed - Whether the content card is collapsed.
 * @param {Function} onCollapseButtonPress - A function to be called when the collapse button is pressed.
 * @param {Function} onSeeMoreButtonPress - A function to be called when the see more button is pressed.
 * @param {Function} onBookPress - A function to be called when a book is pressed.
 */
const BooksRail = ({
  stateName,
  books,
  booksProgress,
  isCollapsed,
  onCollapseButtonPress,
  onSeeMoreButtonPress,
  onBookPress,
  quizSets,
}) => {
  const booksWithProgress = books.map(book => {
    const bookStatus = getBookStatus(book, booksProgress);
    const progressData = getBookProgressData(book, booksProgress);
    return {
      book,
      bookStatus,
      progressData,
    };
  });
  const booksInProgress = booksWithProgress
    .filter(book => book.bookStatus === 'In Progress')
    .sort(orderBooksByName);
  const booksNotStarted = booksWithProgress
    .filter(book => book.bookStatus === null)
    .sort(orderBooksByName);
  const booksRead = booksWithProgress
    .filter(book => book.bookStatus === 'Read')
    .sort(orderBooksByName);
  const sortedBooks = [...booksInProgress, ...booksNotStarted, ...booksRead];
  return (
    <ContentCard
      key={stateName}
      name="Books"
      padContents={false}
      seeMoreButton={true}
      seeMoreButtonTapped={onSeeMoreButtonPress}
      collapsed={isCollapsed}
      collapsible={true}
      collapseButtonTapped={onCollapseButtonPress}>
      <ScrollView
        horizontal={true}
        style={{paddingHorizontal: 12, paddingBottom: 16}}>
        {sortedBooks?.map(({book, bookStatus, progressData}, index) => {
          const isLastItem = index === books.length - 1;
          const bookHasQuiz = doesBookHaveQuiz(book, quizSets);
          return (
            <View key={book.ID} style={[!isLastItem && {marginRight: 10}]}>
              <TouchableOpacity onPress={() => onBookPress(book)}>
                <BookCard
                  book={book}
                  bookStatus={bookStatus}
                  showProgressBar={true}
                  bookProgress={progressData}
                  bookHasQuiz={bookHasQuiz}
                  size="small"
                />
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
    </ContentCard>
  );
};

export default BooksRail;
