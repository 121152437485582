import React, {createContext, useContext, useState, useEffect} from 'react';

import RemoteDataController from '../classes/RemoteDataController';
import DataController from '../classes/DataController';

const AcknowledgementsContext = createContext(undefined);

const AcknowledgementsProvider = ({
  user,
  children,
  startedLoadingDataInterval,
}) => {
  const [acknowledgements, setAcknowledgements] = useState([]);
  const isAcknowledged = id =>
    acknowledgements?.some(({ContentID}) => ContentID === id);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    const getAcknowledgements = async () => {
      const localAcknowledgements = await DataController.getAcknowledgements();
      if (localAcknowledgements?.length > 0) {
        setAcknowledgements(localAcknowledgements);
      } else {
        const remoteAcknowledgements =
          await RemoteDataController.downloadAcknowledgements();
        setAcknowledgements(remoteAcknowledgements);
      }
    };
    getAcknowledgements();
  }, [user?.uid, startedLoadingDataInterval]);

  const updateAcknowledgementsHandler = async acknowledgement => {
    if (isAcknowledged(acknowledgement.ContentID)) {
      return;
    }

    const prevAcknowledgements = [...acknowledgements];

    // Optimistically update the UI
    setAcknowledgements(prevAcknowledgements => [
      ...prevAcknowledgements,
      acknowledgement,
    ]);

    try {
      const updatedAcknowledgements =
        await RemoteDataController.uploadAcknowledgements([acknowledgement]);
      setAcknowledgements(updatedAcknowledgements);
    } catch (error) {
      // reset the state if the upload fails
      setAcknowledgements(prevAcknowledgements);
      console.error('Error updating acknowledgements', error);
    }
  };

  return (
    <AcknowledgementsContext.Provider
      value={{
        acknowledgements,
        updateAcknowledgementsHandler,
        isAcknowledged,
      }}>
      {children}
    </AcknowledgementsContext.Provider>
  );
};

const useAcknowledgements = (type: string) => {
  const context = useContext(AcknowledgementsContext);
  if (!context) {
    throw new Error(
      'useAcknowledgements must be used within an AcknowledgementsProvider',
    );
  }

  const {acknowledgements, ...rest} = context;

  if (type && Array.isArray(acknowledgements)) {
    const filteredAcknowledgements = acknowledgements.filter(
      acknowledgement => acknowledgement.type === type,
    );
    return {...rest, acknowledgements: filteredAcknowledgements};
  }

  return context;
};

export {AcknowledgementsProvider, useAcknowledgements};
