import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';

import {colors} from '../../classes/Colors';

import {usePromotions} from '../../context/PromotionsContext';

const PromoQuizButton = ({onPress}) => {
  const {isPromoAvailable, getNewPromoCode, promoCode} = usePromotions();

  const quizCallback = () => {
    if (isPromoAvailable && !promoCode?.promoCode) {
      getNewPromoCode();
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={{flex: 1}}
      onPress={() => onPress(quizCallback)}>
      <View
        style={{
          flex: 1,
          backgroundColor: colors.ButtonBlue,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 8,
        }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: '700',
            letterSpacing: -0.39,
            color: colors.white,
          }}>
          Finish Quiz
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default PromoQuizButton;
