/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import LoadingBar from '../components/LoadingBar';
import BookCard from '../components/BookCard';

import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';
import LayoutController from '../classes/LayoutController';

import {containerWidths} from '../constants/containerWidths';
import {getBookStatus, doesBookHaveQuiz} from '../utils/books';

var _ = require('lodash');

type Props = {};

type State = {};

export default class BookDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: 'Books',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {};
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
        searchButtonTapped: that.searchButtonTapped.bind(that),
      });
    }, 100);
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  goToBook(book: Object) {
    this.props.navigation.navigate(
      'BookContents',
      {
        book: book,
      },
      this.state,
    );

    let bookName = book.name;
    if (
      this.props.screenProps.user &&
      this.props.screenProps.user.paraPassTrusts &&
      this.props.screenProps.user.paraPassTrusts.length > 0
    ) {
      let trust = this.props.screenProps.user.paraPassTrusts[0];
      bookName = bookName + ' - ' + trust;
    }

    try {
      firebase.analytics().logEvent('book_viewed', {bookViewedName: bookName});
    } catch (error) {
      console.log(error);
    }
  }

  searchButtonTapped() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Book',
      },
      this.state,
    );
  }

  render() {
    const isTablet = LayoutController.isTablet();
    const orderedBooks = this.props.screenProps.books.slice().sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    const bookCellsLayout = orderedBooks.map((book, index, arr) => {
      const bookStatus = getBookStatus(
        book,
        this.props.screenProps.bookReadingProgress,
      );
      const isSecondLastTabletItem = index === arr.length - 2 && isTablet;
      const isLastItem = index === arr.length - 1;
      const bookHasQuiz = doesBookHaveQuiz(
        book,
        this.props.screenProps.quizSets,
      );
      return (
        <View
          key={book.name}
          style={[
            isTablet ? styles.bookTabletContainer : styles.bookContainer,
            (isLastItem || isSecondLastTabletItem) && {marginBottom: 0},
          ]}>
          <TouchableOpacity onPress={this.goToBook.bind(this, book)}>
            <BookCard
              book={book}
              bookStatus={bookStatus}
              size="large"
              bookHasQuiz={bookHasQuiz}
            />
          </TouchableOpacity>
        </View>
      );
    });

    return (
      <ScreenComponent style={styles.container}>
        <View style={styles.titleContainer}>
          <View style={styles.iconContainer}>
            <Image
              style={styles.icon}
              source={require('../assets/Icon_Books_Banner.png')}
            />
          </View>
          <Text style={styles.title}>Books</Text>
        </View>
        <LoadingBar screenProps={this.props.screenProps} />
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <View
            style={
              isTablet ? styles.booksTabletContainer : styles.booksContainer
            }>
            {bookCellsLayout}
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
  titleContainer: {
    flexDirection: 'row',
    height: 60,
    backgroundColor: '#57B0AB',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 42,
    borderRadius: 21,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: '#F0FAF9',
  },
  title: {
    marginLeft: 13,
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.white,
  },
  scrollContainer: {
    padding: 16,
    maxWidth: containerWidths.BooksMaxWidth + 32,
    width: '100%',
    alignSelf: 'center',
  },
  booksContainer: {
    backgroundColor: colors.white,
    padding: 16,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.17,
    shadowRadius: 4,
    elevation: 4, // For Android
  },
  booksTabletContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 32,
    backgroundColor: colors.white,
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.17,
    shadowRadius: 4,
    elevation: 4, // For Android
  },
  bookTabletContainer: {
    marginBottom: 32,
  },
  bookContainer: {
    marginBottom: 12,
  },
});
