import React from 'react';

import {Text, View, StyleSheet, TouchableOpacity} from 'react-native';

import {colors} from '../classes/Colors';

/**
 * Renders a Text component that displays a given text and allows a user to
 * press a "Read More" button to read the full text. The text is truncated to
 * the given number of lines.
 *
 * @param {string} text
 * The text to be displayed.
 *
 * @param {function} onPress
 * Function to call when the "Read More" button is pressed.
 *
 * @param {number} numberOfLines
 * The number of lines to truncate the text to. Defaults to 3.
 *
 * @returns {React.Component}
 * A React component containing the truncated text and the "Read More" button.
 */
const ReadMoreText = ({text, onPress, numberOfLines = 2}) => {
  return (
    <View>
      <Text numberOfLines={numberOfLines} style={styles.text}>
        {text}
      </Text>
      <TouchableOpacity onPress={onPress} style={{alignSelf: 'flex-start'}}>
        <Text style={styles.readMore}>Read More</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    lineHeight: 18,
    color: 'black',
  },
  readMore: {
    fontSize: 15,
    lineHeight: 18,
    color: colors.ButtonBlue,
    fontWeight: 'bold',
  },
  button: {
    alignSelf: 'flex-start',
  },
});

export default ReadMoreText;
