import React from 'react';

import {StyleSheet, ScrollView} from 'react-native';

import CustomMarkdown from '../CustomMarkdown';

const PromoInfoContent = ({content}) => {
  return (
    // using contentContainerStyle instead of style to allow scrolling
    <ScrollView contentContainerStyle={styles.container}>
      <CustomMarkdown>{content}</CustomMarkdown>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 20,
    width: '100%',
    maxWidth: 500,
    alignSelf: 'center',
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    marginBottom: 12,
  },
  contentItem: {
    fontSize: 15,
  },
  line: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  bulletPoint: {
    marginRight: 4,
  },
});

export default PromoInfoContent;
