import React from 'react';

import {getPromotionStrings} from '../../constants/promotions';
import {usePromotions} from '../../context/PromotionsContext';
import LayoutController from '../../classes/LayoutController';

import PromoBanner from './PromoBanner';

const PromoResultsSection = ({screenProps}) => {
  const {promotion, isPromoAvailable, promoCode, isPromoCollected} =
    usePromotions();

  const showPromotion = isPromoAvailable && promoCode?.promoCode;

  if (!showPromotion) {
    return null;
  }

  const {title, subtitle, button} = getPromotionStrings(
    promotion,
    promoCode,
    isPromoCollected,
  );

  const isTablet = LayoutController.isTablet();
  const imageUrl = isTablet
    ? promotion.resultsImageLandscape.url
    : promotion.resultsImagePortrait.url;

  const analyticsParams = {
    content_type: 'promotion',
    content_id: promotion.id,
    content_name: promotion.name,
    trust: promotion.trust,
  };

  return (
    <PromoBanner
      title={title}
      subtitle={subtitle}
      onPress={() =>
        screenProps.handlePromoModalToShow('qrCode', analyticsParams)
      }
      isButtonDisabled={isPromoCollected}
      expiryDate={promotion.finalDate}
      imageUrl={imageUrl}
      showIcon={promotion.showIcon}
      disabledButtonText={button}
      activeButtonText={promoCode.promoCode}
    />
  );
};

export default PromoResultsSection;
