/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  Alert,
  Dimensions,
} from 'react-native';

import AMWebView from '../components/AMWebView';

import ScreenComponent from '../components/ScreenComponent';
import ProgressView from '../components/ProgressView';
import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';
import PlatformController from '../classes/PlatformController';
import GuidelinesController from '../classes/GuidelinesController';
import DynamicText from '../components/DynamicText';
import RNFS from '../classes/RNFS';
import {firebase} from '../classes/Firebase';

import {colors} from '../classes/Colors';

var _ = require('lodash');
var moment = require('moment');

type Props = {};

type State = {
  quizSet: [],
  caseStudy: Object,
  webviewHeight: number,
  caseStudyHtml: string,
};

export default class CaseStudyOverviewScreen extends Component {
  static navigationOptions = ({navigation}) => {
    const caseStudy = navigation.getParam('caseStudy', {});
    var rightButtonsLayout =
      caseStudy?.Questions?.length > 0 ? (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity
            onPress={navigation.getParam('takeQuizButtonTapped')}>
            <DynamicText
              style={{color: colors.white, marginRight: 10, fontSize: 17}}>
              Take Quiz
            </DynamicText>
          </TouchableOpacity>
        </View>
      ) : null;

    return {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        quizSet: this.props.navigation.getParam('quizSet', {}),
        caseStudy: this.props.navigation.getParam('caseStudy', {}),
        webviewHeight: 1,
      };

      if (this.state.caseStudy != null && this.state.caseStudy.Notes != null) {
        this.state.caseStudyHtml = this.state.caseStudy.Notes;
      } else {
        this.state.caseStudyHtml = '';
      }

      // this.state.caseStudyHtml =  "<a href='jrcalcplus://open?CLID=200296'>Link text</a>" + this.state.caseStudyHtml
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.state.caseStudy.CoverImage != null) {
        var imageURL = await DataController.getCaseStudyImagePath(
          this.state.caseStudy,
          this.state.caseStudy.CoverImage[0].filename,
        );
        this.setState({imageURL: imageURL});
      }

      if (Platform.OS === 'ios') {
        // get image urls as base64 - as can't load images regularly on iOS for some reason
        var imageMatches = this.state.caseStudyHtml.match(
          /src\s*=\s*"images\/(.+?)"/g,
        );

        var caseStudyHtml = this.state.caseStudyHtml;

        if (imageMatches) {
          for (
            let imageMatchIndex = 0;
            imageMatchIndex < imageMatches.length;
            imageMatchIndex++
          ) {
            var imageMatch = imageMatches[imageMatchIndex];

            var imageFileName = imageMatch
              .replace('src="images/', '')
              .replace('"', '');

            var imagePath = await DataController.getCaseStudyImagePath(
              this.state.caseStudy,
              imageFileName,
            );

            var base64String =
              await DataController.getBase64ImageStringForImagePath(imagePath);

            var imageType = 'jpeg';

            if (imageFileName.includes('png')) {
              imageType = 'png';
            }

            caseStudyHtml = caseStudyHtml.replace(
              imageMatch,
              'id="' +
                imagePath +
                '" src="data:image/' +
                imageType +
                ';base64, ' +
                base64String +
                '"',
            );

            var test = '';
          }
        }

        if (Platform.OS === 'web') {
          caseStudyHtml = caseStudyHtml.replace(
            /jrcalcplus:\/\/open?/g,
            'https://jrcalcplusweb.co.uk?',
          );
        }

        this.setState({caseStudyHtml: caseStudyHtml});

        try {
          firebase.analytics().logEvent('casestudy_viewed', {
            caseStudyViewed: this.state.caseStudy.Name,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        takeQuizButtonTapped: that.takeQuizButtonTapped.bind(that, 'navBar'),
      });
    }, 100);
  }

  takeQuizButtonTapped(from: string) {
    this.takeQuiz('All Questions');

    try {
      firebase.analytics().logEvent('casestudy_quiz_started', {
        caseStudyViewed: this.state.caseStudy.Name,
        from: from,
      });
    } catch (error) {
      console.log(error);
    }
  }

  takeQuiz(quizMode: string) {
    var that = this;

    // check if there's an inprogress quiz already underway and ask user what they want to do.
    var inProgressQuiz = null;

    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.inProgressQuizzes.length;
      quizIndex++
    ) {
      var anInProgressQuiz =
        this.props.screenProps.inProgressQuizzes[quizIndex];

      if (anInProgressQuiz.quizSet === this.state?.quizSet?.name) {
        inProgressQuiz = anInProgressQuiz;
        break;
      }
    }

    if (inProgressQuiz && Platform.OS !== 'web') {
      Alert.alert(
        'You already have a quiz in progress for this case study',
        'Would you like to continue where you left off or start a new quiz',
        [
          {
            text: 'Start new quiz',
            onPress: () => {
              that.startQuiz(quizMode, null);
            },
          },
          {
            text: 'Continue',
            onPress: () => {
              that.startQuiz(quizMode, inProgressQuiz);
            },
          },
        ],
        {cancelable: false},
      );
    } else {
      this.startQuiz(quizMode, null);
    }
  }

  startQuiz(quizMode: string, inProgressQuiz: Object) {
    var questionsForQuizSet = this.state.quizSet?.questions.slice();

    if (questionsForQuizSet.length > 0) {
      // sort so they are in sequential order
      questionsForQuizSet = _.sortBy(questionsForQuizSet, ['QuestionID']);

      if (inProgressQuiz) {
        var results =
          this.props.screenProps.resultsForQuizzes[inProgressQuiz.name];

        var questionsForQuiz = [];
        var currentQuestion = null;
        var currentQuestionNumber = 1;
        var questionNumbersAnswered = [];
        var questionNumbersViewed = [];
        var correctCount = 0;
        var incorrectCount = 0;

        for (
          var quizQuestionIndex = 0;
          quizQuestionIndex < inProgressQuiz.questions.length;
          quizQuestionIndex++
        ) {
          var quizQuestionName = inProgressQuiz.questions[quizQuestionIndex];
          var questionForQuiz = null;

          // add full question to quiz
          for (
            var questionIndex = 0,
              questionsLength = this.props.screenProps.questions.length;
            questionIndex < questionsLength;
            questionIndex++
          ) {
            var question = this.props.screenProps.questions[questionIndex];

            if (question.Name === quizQuestionName) {
              questionsForQuiz.push(question);
              questionForQuiz = question;
            }
          }

          var resultFound = false;

          for (
            let resultIndex = 0;
            resultIndex < results?.length;
            resultIndex++
          ) {
            var result = results[resultIndex];
            var resultQuestionName = result.packName + '-' + result.questionID;

            if (
              result.quizName === inProgressQuiz.name &&
              resultQuestionName === quizQuestionName
            ) {
              resultFound = true;

              if (result.correct) {
                correctCount++;
              } else {
                incorrectCount++;
              }

              questionNumbersAnswered.push(quizQuestionIndex + 1);
              questionNumbersViewed.push(quizQuestionIndex + 1);
            }
          }

          if (resultFound === false && currentQuestion == null) {
            currentQuestionNumber = quizQuestionIndex + 1;
            currentQuestion = questionForQuiz;
          }
        }

        this.props.navigation.navigate(
          'Quiz',
          {
            quiz: inProgressQuiz,
            quizSet: this.state.quizSet,
            questions: questionsForQuiz,
            currentQuestion: currentQuestion,
            currentQuestionNumber: currentQuestionNumber,
            questionNumbersAnswered: questionNumbersAnswered,
            questionNumbersViewed: questionNumbersViewed,
            correctCount: correctCount,
            incorrectCount: incorrectCount,
            comingFromOverviewScreen: false,
            from: 'Case Study',
          },
          this.state,
        );
      } else {
        var questionsForQuiz = questionsForQuizSet;

        var now = new Date();
        var nowTimestamp = now.getTime();

        var questionIDsForQuiz = questionsForQuiz.map(function (question) {
          return question.PackName + '-' + question.QuestionID;
        });

        var quiz = {
          name: this.state.quizSet.name + '-' + nowTimestamp,
          time: nowTimestamp,
          quizSet: this.state.quizSet.name,
          quizMode: quizMode,
          questions: questionIDsForQuiz,
        };

        this.props.navigation.navigate(
          'Quiz',
          {
            quiz: quiz,
            quizSet: this.state.quizSet,
            questions: questionsForQuiz,
            from: 'Case Study',
          },
          this.state,
        );
      }
    } else {
      // no questions so don't start quiz
    }
  }

  async goToReference(glid) {
    var referenceContent = await GuidelinesController.getGuidelineJSONForGLID(
      glid,
    );

    if (referenceContent != null && referenceContent !== 'Old Guideline') {
      this.props.navigation.navigate(
        'Reference',
        {
          guidelineContents: referenceContent,
          from: 'Case Study',
        },
        this.state,
      );
    } else {
      let guidelineName =
        await GuidelinesController.getGuidelineNameForGuideline(glid);
      if (guidelineName != null && guidelineName !== '') {
        // guideline is in list so it should be downloaded - but might not have finished yet
        Alert.alert(
          "The reference can't be displayed at the moment. It might still be downloading. Please try again shortly.",
        );
      }
    }
  }

  onNavigationChange(event) {
    if (event.title) {
      var htmlHeight = Number(event.title); //convert to number
      htmlHeight = htmlHeight;

      // if (this.state.currentQuestionNumber === 1) {
      //   htmlHeight = htmlHeight + 80;
      // }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    }
  }

  onMessage(m) {
    var message = m.nativeEvent.data;
    if (message.includes('scrollHeight')) {
      var htmlHeight = Number(message.split('-')[1]);

      if (Platform.isPad) {
        htmlHeight = htmlHeight - 50;
      }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    } else if (message.includes('imageTapped')) {
      var imageString = message;

      if (Platform.OS === 'ios') {
        imageString = imageString.replace('imageTapped-', '');
        imageString = imageString.substr(0, imageString.indexOf('|'));
      } else {
        var imageString = imageString.replace(/imageTapped%257C/g, '');
        imageString = imageString.replace(/imageTapped-/g, '');
        imageString = imageString.replace('|', '');
        var imageString = imageString.replace(/%253A/g, ':');
        var imageString = imageString.replace(/%252F/g, '/');
        var imageString = decodeURI(imageString);

        console.log(imageString);
      }

      this.openImageModal(imageString);
    }
  }

  render() {
    const hasQuestions =
      this.state.caseStudy?.Questions?.length > 0 &&
      this.state.quizSet?.questions?.length > 0;
    var headerImageLayout = null;
    var alignTitle = 'left';

    var imageSource = {uri: this.state.imageURL};
    var defaultSource = require('../assets/Image_Loading_Placeholder_Large.png');
    if (this.state.imageURL == null) {
      defaultSource = require('../assets/Image_No_Image_Placeholder_Large.png');
      imageSource = defaultSource;
    }

    let headerImageWidth = 664;

    if (LayoutController.isCompact()) {
      headerImageLayout = (
        <View style={{}}>
          <Image
            style={{maxWidth: headerImageWidth, height: 200}}
            source={imageSource}
            defaultSource={defaultSource}
          />
        </View>
      );
    } else {
      alignTitle = 'center';

      headerImageLayout = (
        <View style={{alignItems: 'center'}}>
          <Image
            style={{width: headerImageWidth, height: 300}}
            source={imageSource}
            defaultSource={defaultSource}
          />
        </View>
      );
    }

    // replace image with video if there is a videoID
    var videoID = this.state.caseStudy.VideoID;
    // var videoID = '629001140?h=3676dbff50';

    if (videoID != null) {
      let webCSS = '';

      if (Platform.OS === 'web') {
        webCSS = `
          .video-content {
            min-height: 340px;
          }
          .video-container {
            min-height: 340px;
          }
        `;
      }

      var videoHtml =
        `
      <html>
        <head>
          <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
          <style>
          body {
            margin: 0px;
            padding: 0px;
          }
          .video-content {
            height: 100%;
          }
          .video-container {
            width: 100%;
            position: relative;
            height: 100%;
          }
          .video-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          ${webCSS}

          </style>
        </head>
        <body>
          <div class='video-container'>
            <iframe src="https://player.vimeo.com/video/` +
        videoID +
        `${
          videoID.includes('?') ? '&' : '?'
        }?portrait=0&autoplay=0" class="video-frame" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </body>
      </html>`;

      let videoWidth = Dimensions.get('window').width;
      if (videoWidth > 700) {
        videoWidth = 700;
      }

      if (Platform.OS === 'web') {
        headerImageLayout = (
          <View
            style={{flex: 1, maxHeight: 340, minHeight: 340, marginBottom: 10}}>
            <div
              className="video-content"
              dangerouslySetInnerHTML={{__html: videoHtml}}></div>
          </View>
        );
      } else if (Platform.OS === 'ios') {
        headerImageLayout = (
          <View
            style={{width: videoWidth, aspectRatio: 16 / 9, marginBottom: 10}}>
            <AMWebView
              style={{flex: 1}}
              source={{html: videoHtml}}
              useWebKit={true}
              allowsFullscreenVideo={true}
            />
          </View>
        );
      } else if (Platform.OS === 'android') {
        headerImageLayout = (
          <View
            style={{
              alignSelf: 'stretch',
              width: videoWidth,
              aspectRatio: 16 / 9,
              marginBottom: 10,
            }}>
            <AMWebView
              style={{flex: 1}}
              source={{html: videoHtml}}
              useWebKit={true}
              allowsFullscreenVideo={true}
            />
          </View>
        );
      }
    }

    let audioHtml = '';
    let audioURL = this.state.caseStudy.AudioURL;
    if (audioURL != null && audioURL !== '') {
      audioHtml =
        "<audio title='ParaPass Case Study' preload='metadata' style='width: 100%;' src='" +
        audioURL +
        "' controls></audio>";
    }

    var authorHtml = '<h1>Authors</h1>';

    for (
      let authorIndex = 0;
      authorIndex < this.state.caseStudy.Author.length;
      authorIndex++
    ) {
      var author = this.state.caseStudy.Author[authorIndex];

      let matchingAuthor = _.find(this.props.screenProps.authors, {
        Name: author,
      });

      if (matchingAuthor != null) {
        authorHtml += '<h2>' + matchingAuthor.Name + '</h2>';

        if (matchingAuthor.Notes != null) {
          authorHtml += '<p>' + matchingAuthor.Notes + '</p>';
        } else {
          authorHtml += '<br/>';
        }
      } else {
        authorHtml += '<h2>' + author + '</h2>';
      }
    }

    var contentHTML = this.state.caseStudyHtml;

    if (Platform.OS === 'web') {
      // change so all images are loaded from s3 rather than locally
      var originalSrc = 'src="images/';
      var newSrc =
        'src="' +
        'https://ppp-temp-files.s3.amazonaws.com/' +
        this.state.caseStudy.Name +
        '/';
      var regExpSrc = new RegExp(originalSrc, 'g');
      contentHTML = contentHTML.replace(regExpSrc, newSrc);

      // change all links to open in new tab/window as opening in current tab and going back would lose navigation
      var originalAnchors = '<a href="http';
      var newAnchors = '<a target="_blank" href="http';
      var regExpAnchors = new RegExp(originalAnchors, 'g');
      contentHTML = contentHTML.replace(regExpAnchors, newAnchors);
    }

    const script = `
    <script>

      document.addEventListener('DOMContentLoaded', function(){ 
        // your code goes here
        window.location.hash = 1;
        
        setTimeout(() => {
          var calculator = document.createElement("div");
          calculator.className = "height-calculator";
          while (document.body.firstChild) {
              calculator.appendChild(document.body.firstChild);
          }
          document.body.appendChild(calculator);
          document.title = calculator.scrollHeight;
          window.ReactNativeWebView.postMessage("scrollHeight-" + calculator.scrollHeight);
        }, 100);

        console.log(calculator.scrollHeight);
        console.log('adding button click');
        document.querySelector("button").onclick = function() {
          console.log("Send post message");
          window.ReactNativeWebView.postMessage("Hello React", "*");
        }
      }, false);

    </script>
    `;

    var styleCSS =
      `
            <style>
              .standby-content-container
              {
                  font-family: InterUI-Regular, -apple-system, BlinkMacSystemFont,
                              "Segoe UI", "Roboto", "Oxygen",
                              "Ubuntu", "Cantarell", "Fira Sans",
                              "Droid Sans", "Helvetica Neue", sans-serif;
                  font-size: ` +
      this.props.screenProps.fontSize +
      `px;
                  margin-top:1em;
                  margin-left:auto;
                  margin-right:auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-bottom: 20px;
                  -webkit-text-size-adjust:none;
                  line-height:140%;
                  max-width:700px;
                  /*    6.5 inch*/

                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  -ms-word-break: break-all;
                  word-break: break-word;
              }
              h1.title {
                font-size: 21px;
                color: ` +
      colors.Grey900 +
      `;
              }
              p.volume
              {
                  text-align:right;
                  font-size:140%;
                  font-weight:bold;
                  margin-top:1.5em;
                  margin-bottom:-1.5em;
              }
              span.volume
              {
                  background-color:#3ca376;
                  color:#ffffff;
                  border-radius:10px;
                  padding:0.5em;
                  padding-left:1.5em;
                  padding-right:1.5em;
              }
              img{
                  max-height:100%;
                  max-width:100%;
              }
              img.inline
              {
                  vertical-align:middle;
              }
              p.image
              {
                  text-align:center;
              }
              p.openimage
              {
                  text-align:center;
                  padding-top:2em;
                  padding-bottom:5em;
                  border-radius:10px;
                  background-color:#fef56c;
              }
              p.author
              {
                  text-align:center;
                  font-size:140%;
                  font-weight:bold;
                  margin-top:-5em;
                  margin-bottom:1.5em;
              }
              span.author
              {
                  background-color:#3ca376;
                  color:#ffffff;
                  border-radius:10px;
                  padding:0.5em;
                  padding-left:5em;
                  padding-right:5em;
              }
              p.title
              {
                  text-align:center;
                  color:#0081ad;
                  font-size:180%;
                  font-weight:bold;
                  margin-bottom:2em;
              }
              div.box
              {
                  border:3px solid #0081ad;
                  padding:0.3em 1em 0.3em 1em;
              }
              p.floatr
              {
                  text-align:right;
                  float:right;
                  margin-top:-1em;
                  margin-right:-9px;
              }
              
              p.float
              {
                  text-align:right;
                  float:right;
                  margin-top:-1em;
                  padding-left:1em;
              }
              span.color
              {
                  color:#0081ad;
              }
              span.color1
              {
                  color:#0081ad;
                  font-size:140%;
              }
              
              h1
              {
                  font-size:180%;
                  color:#0081ad;
                  line-height:1em;
                  margin-top:1em;
              }
              h2
              {
                  font-size:140%;
                  margin-bottom:-.5em;
                  margin-top:1em;
              }
              p.fig-caption
              {
                  text-align:left;
                  font-size:120%;
                  color:#0081ad;
              }
              span.endhead
              {
                  color:#86cfd6;
                  font-size:140%;
                  text-shadow:2px 2px 2px #767d83;
              
              }
              p.attribution
              {
                  font-size:90%;
                  margin-top:-.8em;
                  margin-bottom:.8em;
                  color:#0081ad;
                  padding-top:.3em;
                  text-align:left;
              }
              div.figure
              {
                  margin-top:2em;
                  margin-bottom:2em;
              }
              
              p.table-caption
              {
                  text-align:left;
                  font-size:120%;
                  color:#0081ad;
              }
              table
              {
              /*    border:2px solid;*/
                  width:100%;
              }
              td.cell1
              {
                  border-bottom:2px solid;
                  padding-top:.5em;
                  padding-left:0.5em;
                  padding-bottom:.5em;
              }
              td.cell1a
              {
                  padding-top:.5em;
                  padding-left:0.5em;
                  padding-bottom:.5em;
              }
              p.sq
              {
                  text-indent:-1.35em;
                  margin-left:1.55em;
                  margin-top:-.8em;
                  text-align:left;
              }
              span.sq
              {
                  color:#0081ad;
              }
              div.list
              {
                  margin-top:1em;
                  margin-bottom:1em;
              }
              p.reftitle
              {
                  text-align:center;
                  color:#3ca376;
                  font-size:165%;
                  font-weight:bold;
                  margin-bottom:1em;
              }            

              body, html, .height-calculator {
                  margin: 0;
                  padding: 0;
              }
              .height-calculator {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
              }
              .hiddenText {
                display: none;
              }
              .assessment-button {
                background-color: ` +
      colors.ButtonBlue +
      `;
                border: none;
                color: white;
                padding: 15px 32px;
                width: 100%;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
              }
              sup {
                font-size: 0.6em;
              }
            </style>
            `;

    // set-up question webview
    var rawHtml =
      "<html><head><meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>" +
      styleCSS +
      "</head><body><div class='standby-content-container'>" +
      audioHtml +
      "<h1 class='title'>" +
      this.state.caseStudy.Title +
      '</h1>' +
      contentHTML +
      authorHtml +
      "<span class='hiddenText'>" +
      1 +
      '</span></div>' +
      script +
      '</body></html>';

    rawHtml = rawHtml.replace(/<br \/>/g, '<br>');

    var baseUrl = DataController.getCaseStudyPath(this.state.caseStudy); // image path without last component
    if (Platform.OS == 'android') {
      // console.log('file://' + RNFS.DocumentDirectoryPath + '/' + this.state.issue.Name + '/');
      baseUrl =
        'file://' +
        RNFS.DocumentDirectoryPath +
        '/' +
        this.state.caseStudy.Name +
        '/';
    }

    var scalesPageToFit = false;

    if (Platform.OS === 'android') {
      scalesPageToFit = true;
    }

    var webContentLayout = (
      <AMWebView
        style={{flex: 1, height: this.state.webviewHeight}}
        // ref="mainWebView"
        source={{html: rawHtml, baseUrl: baseUrl}}
        // style={{height:this.state.Height}}
        scalesPageToFit={scalesPageToFit}
        useWebKit={true}
        javaScriptEnabled={true}
        scrollEnabled={false}
        onNavigationStateChange={this.onNavigationChange.bind(this)}
        onMessage={m => this.onMessage(m)}
        allowFileAccessFromFileURLs={true}
        originWhitelist={['*']}
        allowFileAccess={true}
        allowUniversalAccessFromFileURLs={true}
        allowsFullscreenVideo={true}
        onShouldStartLoadWithRequest={event => {
          console.log(event.url);
          if (
            event.url.includes('http') ||
            event.url.includes('www') ||
            event.url.includes('mailto') ||
            event.url.includes('jrcalcplus://')
          ) {
            if (
              event.url.includes('https://parapassweb.co.uk') &&
              event.url.includes('quiz=')
            ) {
              try {
                let quizName = event.url.substr(event.url.indexOf('quiz=') + 5);
                this.props.screenProps.openQuizSetWithName(quizName, undefined);
              } catch (error) {
                console.log(error);
              }
              return false;
            }

            try {
              PlatformController.openURL(event.url);
            } catch (error) {
              console.log(error);
            }
            return false;
          } else if (event.url.includes('glid://')) {
            let glid = event.url.replace('glid://', '');
            this.goToReference(glid);
            return false;
          }
          return true;
        }}
      />
    );

    if (Platform.OS === 'web') {
      var buttonHtml =
        "<button class='assessment-button' onclick='" +
        this.takeQuizButtonTapped.bind(this, 'Bottom of Page') +
        "'>Test</button>";

      rawHtml = rawHtml.replace(
        "<button class='assessment-button'>Take assessment quiz</button>",
        '',
      );

      var tags = document.getElementsByTagName('a');

      for (var i = 0; i < tags.length; i++) {
        let anchorTag = tags[i];

        let href = anchorTag.getAttribute('href');
        if (href != null && href.includes('glid://')) {
          let glid = href.replace('glid://', '');
          anchorTag.setAttribute('href', '#');
          anchorTag.onclick = this.goToReference.bind(this, glid);
        }
      }

      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: rawHtml}}></div>
      );
    }

    var contentStretchMode = 'stretch';

    if (LayoutController.isCompact() === false) {
      contentStretchMode = 'center';
    }

    let trustLayout = null;
    if (this.state.caseStudy.Trust != null) {
      trustLayout = (
        <View
          style={{
            justifyContent: 'center',
            paddingHorizontal: 6,
            height: 16,
            borderRadius: 2,
            backgroundColor: DataController.getColorForTrust(
              this.state.caseStudy.Trust,
            ),
          }}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '500',
              letterSpacing: -0.1,
              color: colors.white,
            }}>
            {DataController.getTrustDisplayName(this.state.caseStudy.Trust)}
          </Text>
        </View>
      );
    }

    var trustDotSeparatorLayout = null;
    if (trustLayout != null) {
      trustDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          styles={{flex: 1}}
          contentContainerStyle={{backgroundColor: colors.white}}>
          <View
            style={{
              flexDirection: 'row',
              height: 60,
              backgroundColor: '#57B0AB',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 100,
            }}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 42,
                height: 42,
                borderRadius: 21,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: '#F0FAF9',
              }}>
              <Image
                style={{width: 34, height: 34}}
                source={require('../assets/Results_Glyph_CaseStudy.png')}
              />
            </View>
            <Text
              style={{
                marginLeft: 13,
                fontSize: 30,
                fontWeight: 'bold',
                color: colors.white,
              }}>
              Case Studies
            </Text>
          </View>

          <View style={{alignItems: contentStretchMode}}>
            <View style={{maxWidth: 700, alignItems: 'stretch'}}>
              <View style={{zIndex: 100}}>
                {headerImageLayout}

                <View style={{marginBottom: 10, padding: 10}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    {this.state.quizSet?.questions?.length && (
                      <>
                        <DynamicText
                          style={{
                            fontSize: 12,
                            fontWeight: '500',
                            color: colors.Grey700,
                          }}>
                          {this.state.quizSet?.questions?.length} Questions
                        </DynamicText>
                        <View
                          style={{
                            marginHorizontal: 6,
                            width: 2,
                            height: 2,
                            backgroundColor: colors.Grey700,
                            borderRadius: 1,
                          }}
                        />
                        {trustLayout}
                        {trustDotSeparatorLayout}
                      </>
                    )}
                    <DynamicText style={{fontSize: 12, color: colors.Grey700}}>
                      {moment(this.state.caseStudy.PublicDate).format(
                        'DD MMM YYYY',
                      )}
                    </DynamicText>
                  </View>
                </View>
              </View>

              {webContentLayout}

              {hasQuestions && (
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={this.takeQuizButtonTapped.bind(
                    this,
                    'Bottom of Page',
                  )}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 80,
                    }}>
                    <DynamicText
                      style={{
                        marginRight: 5,
                        marginTop: 10,
                        paddingHorizontal: 60,
                        paddingVertical: 14,
                        backgroundColor: colors.ButtonBlue,
                        fontSize: 17,
                        fontWeight: '700',
                        letterSpacing: -0.39,
                        color: colors.white,
                      }}>
                      Take assessment quiz
                    </DynamicText>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
