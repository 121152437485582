import React from 'react';

import {View, Text, StyleSheet} from 'react-native';

import ProgressBar from './ProgressBar';

import {getBookStatusColor} from '../utils/books';

const BookProgress = ({data, status, hideBorderTop}) => {
  return (
    <View
      style={[
        styles.progressContainer,
        hideBorderTop && {
          borderTopWidth: 0,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        },
      ]}>
      <View style={styles.progressTextContainer}>
        <View style={styles.progressChapterTextContainer}>
          <Text
            style={styles.progressChapter}>{`Chapter: ${data.number}`}</Text>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={styles.progressChapterTitle}>
            {data.name}
          </Text>
        </View>
        <View style={styles.progressPercentContainer}>
          <Text
            style={[
              styles.progressPercent,
              {color: getBookStatusColor(status)},
            ]}>{`${data.percentage} %`}</Text>
        </View>
      </View>
      <ProgressBar percentage={data.percentage} />
    </View>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    backgroundColor: '#F8F8F8',
    borderTopColor: '#DEE2E6',
    borderTopWidth: 1,
  },
  progressTextContainer: {
    flexDirection: 'row',
    padding: 15,
    justifyContent: 'space-between',
  },
  progressChapterTextContainer: {
    maxWidth: 231,
    justifyContent: 'center',
  },
  progressChapter: {
    fontWeight: '600',
    fontSize: 13,
    lineHeight: 16,
    color: '#404040CC',
  },
  progressChapterTitle: {
    fontWeight: '500',
    fontSize: 15,
    lineHeight: 18,
    color: '#343A40',
    maxWidth: 180,
  },
  progressPercent: {
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 18,
    alignSelf: 'center',
  },
  progressPercentContainer: {
    justifyContent: 'center',
  },
});

export default BookProgress;
