/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  Keyboard,
  Linking,
  TextInput,
  ScrollView,
  Image,
  Dimensions,
  KeyboardAvoidingView,
  SafeAreaView,
} from 'react-native';
import {
  HeaderButton,
  HeaderButtons,
  Item,
} from 'react-navigation-header-buttons';

import AuthenticationController from '../classes/AuthenticationController';
import LayoutController from '../classes/LayoutController';
import PlatformController from '../classes/PlatformController';
import DataController from '../classes/DataController';
import RemoteDataController from '../classes/RemoteDataController';
import {EventRegister} from 'react-native-event-listeners';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import {getInset} from 'react-native-safe-area-view';
import {Ionicons} from '@expo/vector-icons';
import SSOLogin from '../components/SSO/SSOLogin';

var _ = require('lodash');

type Props = {};

type State = {
  emailText: string,
  emailConfirmationText: string,
  passwordText: string,
  passwordConfirmationText: string,
  firstNameText: string,
  lastNameText: string,
  submitButtonEnabled: boolean,
  loginFailedErrorMessage: string,
  loginType: string,
  termsAndConditionsAccepted: boolean,
  hidePassword: boolean,
  hideConfirmationPassword: boolean,
  checkEmail: boolean,
  checkPass: boolean,
};

export default class LoginScreen extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      emailText: '',
      emailConfirmationText: '',
      passwordText: '',
      passwordConfirmationText: '',
      firstNameText: '',
      lastNameText: '',
      submitButtonEnabled: false,
      loginFailedErrorMessage: '',
      loginType: 'login',
      termsAndConditionsAccepted: false,
      hidePassword: true,
      hideConfirmationPassword: true,
      checkEmail: false,
      checkPass: false,
      ssoConfig: [],
    };
  }

  getSSOConfig = async () => {
    const ssoConfig = await RemoteDataController.downloadSSOConfig();
    this.setState({ssoConfig});
  };

  componentDidMount() {
    this.getSSOConfig();
  }

  async displayLastEnteredUserEmail() {}

  emailTextDidChange(text: string) {
    this.setState({emailText: text}, () => {
      this.validateEntry();
    });
  }

  emailConfirmationTextDidChange(text: string) {
    this.setState({emailConfirmationText: text}, () => {
      this.validateEntry();
    });
  }

  passwordTextDidChange(text: string) {
    this.setState({passwordText: text}, () => {
      this.validateEntry();
    });
  }

  passwordConfirmationTextDidChange(text: string) {
    this.setState({passwordConfirmationText: text}, () => {
      this.validateEntry();
    });
  }

  firstNameTextDidChange(text: string) {
    this.setState({firstNameText: text}, () => {
      this.validateEntry();
    });
  }

  lastNameTextDidChange(text: string) {
    this.setState({lastNameText: text}, () => {
      this.validateEntry();
    });
  }

  termsAndConditionsCheckboxTapped() {
    this.setState(
      {termsAndConditionsAccepted: !this.state.termsAndConditionsAccepted},
      () => {
        this.validateEntry();
      },
    );
  }

  validateEntry() {
    var inputIsOK = true;

    var validEmail = this.validateEmail(this.state.emailText);

    if (validEmail === false) {
      inputIsOK = false;
    }

    if (this.state.passwordText.length < 1) {
      inputIsOK = false;
    }

    if (this.state.termsAndConditionsAccepted === false) {
      inputIsOK = false;
    }

    if (this.state.loginType === 'register') {
      var validConfirmationEmail =
        this.state.emailConfirmationText === this.state.emailText;
      var validConfirmationPassword =
        this.state.passwordConfirmationText === this.state.passwordText;
      if (
        validConfirmationEmail === false ||
        validConfirmationPassword === false
      ) {
        inputIsOK = false;
      }

      if (this.state.firstNameText.length < 1) {
        inputIsOK = false;
      }

      if (this.state.lastNameText.length < 1) {
        inputIsOK = false;
      }
    }

    this.setState({submitButtonEnabled: inputIsOK});
  }

  validateEmail(email) {
    var tester =
      /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!email) return false;

    if (email.length > 254) return false;

    var valid = tester.test(email);
    if (!valid) return false;

    // Further checking of some things regex can't handle
    var parts = email.split('@');
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split('.');
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  errorHandler = async loginFailedErrorMessage => {
    this.setState({
      loginFailedErrorMessage,
    });
  };

  async submit() {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      Keyboard.dismiss();
    }

    if (this.state.submitButtonEnabled == true) {
      if (this.state.loginType === 'login') {
        this.props.showLoggingInIndicator('Logging in');

        this.login();
      } else if (this.state.loginType === 'register') {
        this.props.showLoggingInIndicator('Registering');
        this.signUp();
      }
    } else {
      this.setState({
        loginFailedErrorMessage:
          'Please fill in all the fields and make sure the email address is valid',
      });
    }
  }

  async login() {
    this.setState({loginFailedErrorMessage: ''});

    try {
      if (this.state.submitButtonEnabled == true) {
        try {
          var contactID = await AuthenticationController.logIn(
            this.state.emailText,
            this.state.passwordText,
          );

          try {
            await AuthenticationController.downloadUserInfo(contactID);
            await DataController.saveTrusts(contactID);
          } catch (error) {
            console.log(error);
          }

          EventRegister.emit('userUpdated', '');
        } catch (e) {
          console.log('failed logging in');
          console.log(e);
          console.log(Object.prototype.toString.call(e));
          console.log(JSON.stringify(e));
          this.props.hideLoggingInIndicator();

          if (typeof e === 'object') {
            this.setState({loginFailedErrorMessage: e.message});
          } else {
            if (e === '') {
              this.setState({loginFailedErrorMessage: 'Server error'}); // possibly invalid hmac
            } else {
              this.setState({loginFailedErrorMessage: e});
            }
          }
        }
      }
    } catch (e) {
      this.props.hideLoggingInIndicator();
      console.log(e);
    }
  }

  async signUp() {
    console.log('registering user');

    this.setState({loginFailedErrorMessage: ''});

    try {
      if (this.state.submitButtonEnabled == true) {
        try {
          var registeredSuccessfully =
            await AuthenticationController.registerUser(
              this.state.emailText,
              this.state.passwordText,
              this.state.firstNameText,
              this.state.lastNameText,
            );

          if (registeredSuccessfully) {
            this.login();
          }
        } catch (e) {
          console.log('failed registering');
          console.log(e);
          this.props.hideLoggingInIndicator();

          if (typeof e === 'object') {
            this.setState({loginFailedErrorMessage: e.message});
          } else {
            this.setState({loginFailedErrorMessage: e});
          }
        }
      }
    } catch (e) {
      this.props.hideLoggingInIndicator();
      console.log(e);
    }
  }

  switchToRegister() {
    this.setState(
      {loginType: 'register', loginFailedErrorMessage: ''},
      function () {
        this.validateEntry();
      },
    );
  }

  switchToLogin() {
    this.setState(
      {loginType: 'login', loginFailedErrorMessage: ''},
      function () {
        this.validateEmail();
      },
    );
  }

  switchToSSO() {
    this.setState({loginType: 'SSO', loginFailedErrorMessage: ''});
  }

  render() {
    var submitButtonEnabledStyle = {opacity: 1};

    if (this.state.submitButtonEnabled === false) {
      submitButtonEnabledStyle = {opacity: 0.5};
    }

    var loginFailedLayout = null;

    if (this.state.loginFailedErrorMessage !== '') {
      loginFailedLayout = (
        <View style={{flexDirection: 'column', marginTop: 10}}>
          <Text style={styles.errorText}>
            {this.state.loginFailedErrorMessage}
          </Text>
          <Text style={styles.errorText}>
            If you have forgotten your password, reset it below or contact your
            authentication provider
          </Text>
        </View>
      );
    }

    var contentLayout = null;

    let termsAndConditionsCheckboxImageSource = require('../assets/Button_Checkbox_Inactive.png');
    if (this.state.termsAndConditionsAccepted) {
      termsAndConditionsCheckboxImageSource = require('../assets/Button_Checkbox_Active.png');
    }

    const showEmailMismatchMessage =
      this.state.checkEmail &&
      this.state.emailText !== this.state.emailConfirmationText;

    const showPasswordMismatchMessage =
      this.state.checkPass &&
      this.state.passwordText !== this.state.passwordConfirmationText;

    if (this.state.loginType === 'login') {
      contentLayout = (
        <View
          style={{
            marginHorizontal: 'auto',
            minWidth: Platform.OS === 'web' ? 700 : '60%',
            padding: 20,
          }}>
          <View
            style={{
              backgroundColor: '#FFF',
              paddingHorizontal: 10,
              paddingTop: 10,
              paddingBottom: 20,
              marginTop: 10,
            }}>
            <Text style={styles.titleText}>Log in</Text>

            <Text style={styles.textInputLabel}>Email</Text>

            <View style={styles.textInputWrapper}>
              <TextInput
                ref={'usernamInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.emailTextDidChange(text)}
                value={this.state.emailText}
                keyboardType={'email-address'}
                placeholder={'Email address'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
                onSubmitEditing={event => this.submit()}
              />
            </View>

            <Text style={styles.textInputLabel}>Password</Text>

            <View style={[styles.textInputWrapper, {justifyContent: 'center'}]}>
              <TextInput
                ref={'passwordInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.passwordTextDidChange(text)}
                value={this.state.passwordText}
                secureTextEntry={this.state.hidePassword}
                placeholder={'Password'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
                onSubmitEditing={event => this.submit()}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState({hidePassword: !this.state.hidePassword})
                }
                style={{position: 'absolute', right: 8}}>
                <Text style={{color: '#4a5056'}}>
                  {this.state.hidePassword ? 'Show' : 'Hide'}
                </Text>
              </TouchableOpacity>
            </View>

            {loginFailedLayout}

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 10,
              }}>
              <TouchableOpacity
                onPress={this.termsAndConditionsCheckboxTapped.bind(this)}>
                <Image
                  style={{width: 30, height: 30, marginRight: 8}}
                  source={termsAndConditionsCheckboxImageSource}
                />
              </TouchableOpacity>

              <Text>
                I confirm I have read the{' '}
                <Text
                  style={[styles.linkText, {textDecorationLine: 'underline'}]}
                  onPress={() =>
                    PlatformController.openURL(
                      'https://www.classprofessional.co.uk/terms-of-use/parapass-terms-and-conditions/',
                    )
                  }>
                  Terms and Conditions
                </Text>
                .
              </Text>
            </View>

            <TouchableOpacity onPress={this.submit.bind(this)}>
              <Text
                style={[
                  styles.primaryActionButton,
                  submitButtonEnabledStyle,
                  {marginTop: 10},
                ]}>
                Log in
              </Text>
            </TouchableOpacity>

            {this.state.ssoConfig.length > 0 && (
              <TouchableOpacity onPress={this.switchToSSO.bind(this)}>
                <Text style={[styles.primaryActionButton, {marginTop: 10}]}>
                  Log in with your trust
                </Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity>
              <Text
                onPress={() =>
                  PlatformController.openURL(
                    'https://www.classprofessional.co.uk/app-reset',
                  )
                }
                style={[
                  styles.descriptionText,
                  styles.linkText,
                  {textAlign: 'center', marginTop: 20},
                ]}>
                Forgot your password?
              </Text>
            </TouchableOpacity>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                position: 'relative',
                marginTop: 22,
                marginBottom: 18,
              }}>
              <View
                style={{
                  height: 1,
                  backgroundColor: '#cfd4d9',
                  width: '100%',
                  position: 'absolute',
                  top: 8,
                }}
              />
              <Text
                style={{
                  backgroundColor: 'white',
                  paddingHorizontal: 6,
                  zIndex: 10,
                  color: '#4a5056',
                }}>
                Don't have an account?
              </Text>
            </View>

            <TouchableOpacity onPress={() => this.switchToRegister()}>
              <View
                style={{
                  backgroundColor: '#e8ecee',
                  borderRadius: 8,
                }}>
                <DynamicText style={styles.secondaryActionButton}>
                  Register
                </DynamicText>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.loginType === 'register') {
      contentLayout = (
        <View
          style={{
            marginHorizontal: 'auto',
            minWidth: Platform.OS === 'web' ? 700 : '60%',
          }}>
          <View
            style={{
              backgroundColor: '#FFF',
              paddingHorizontal: 10,
              paddingTop: 10,
              paddingBottom: 20,
              marginTop: 10,
            }}>
            <Text style={[styles.titleText]}>Register</Text>
            <View />

            <Text style={[styles.textInputLabel]}>First Name</Text>

            <View style={[styles.textInputWrapper]}>
              <TextInput
                ref={'firstNameInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.firstNameTextDidChange(text)}
                value={this.state.firstNameText}
                placeholder={"What's your first name?"}
                placeholderTextColor={'#999'}
              />
            </View>

            <Text style={[styles.textInputLabel]}>Last Name</Text>

            <View style={[styles.textInputWrapper]}>
              <TextInput
                ref={'lastNameInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.lastNameTextDidChange(text)}
                value={this.state.lastNameText}
                placeholder={"What's your last name?"}
                placeholderTextColor={'#999'}
              />
            </View>

            <Text style={[styles.textInputLabel]}>Email address</Text>

            <View
              style={[
                styles.textInputWrapper,
                {
                  borderColor: showEmailMismatchMessage ? 'red' : '#DADADA',
                  borderWidth: showEmailMismatchMessage ? 2 : 1,
                },
              ]}>
              <TextInput
                ref={'usernamInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.emailTextDidChange(text)}
                value={this.state.emailText}
                keyboardType={'email-address'}
                placeholder={'example@email.co.uk'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
              />
            </View>

            {this.state.loginFailedErrorMessage
              .toLowerCase()
              .includes('already exists') && (
              <View style={{flexDirection: 'column', marginTop: 10}}>
                <Text style={styles.errorText}>
                  {'This email address is already registered. Try '}
                  <Text
                    onPress={() => this.switchToLogin()}
                    style={[
                      styles.linkText,
                      {textDecorationLine: 'underline'},
                    ]}>
                    logging in
                  </Text>
                  {' or '}
                  <Text
                    onPress={() =>
                      PlatformController.openURL(
                        'https://www.classprofessional.co.uk/app-reset',
                      )
                    }
                    style={[
                      [styles.linkText, {textDecorationLine: 'underline'}],
                    ]}>
                    resetting your password.
                  </Text>
                </Text>
              </View>
            )}

            <Text style={styles.textInputLabel}>Re-enter email</Text>

            <View
              style={[
                styles.textInputWrapper,
                {
                  borderColor: showEmailMismatchMessage ? 'red' : '#DADADA',
                  borderWidth: showEmailMismatchMessage ? 2 : 1,
                },
              ]}>
              <TextInput
                ref={'usernamInput'}
                onBlur={() => this.setState({checkEmail: true})}
                onFocus={() => this.setState({checkEmail: false})}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.emailConfirmationTextDidChange(text)}
                value={this.state.emailConfirmationText}
                keyboardType={'email-address'}
                placeholder={'Re-enter your email'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
                onSubmitEditing={event => this.submit()}
              />
            </View>

            {showEmailMismatchMessage && (
              <View style={{flexDirection: 'column'}}>
                <Text style={styles.errorText}>
                  The email you've entered doesn't match.
                </Text>
              </View>
            )}

            <Text style={[styles.textInputLabel]}>Create a password</Text>

            <View
              style={[
                styles.textInputWrapper,
                {
                  justifyContent: 'center',
                  borderColor: showPasswordMismatchMessage ? 'red' : '#DADADA',
                  borderWidth: showPasswordMismatchMessage ? 2 : 1,
                },
              ]}>
              <TextInput
                ref={'passwordInput'}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text => this.passwordTextDidChange(text)}
                value={this.state.passwordText}
                secureTextEntry={this.state.hidePassword}
                placeholder={'Create a password'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState({hidePassword: !this.state.hidePassword})
                }
                style={{position: 'absolute', right: 8}}>
                <Text style={{color: '#4a5056'}}>
                  {this.state.hidePassword ? 'Show' : 'Hide'}
                </Text>
              </TouchableOpacity>
            </View>

            <Text style={[styles.textInputLabel]}>Confirm Password</Text>

            {/* "redirects": {
				"ios":
				"android":
				"web": 
			} */}

            <View
              style={[
                styles.textInputWrapper,
                {
                  marginBottom: 0,
                  justifyContent: 'center',
                  borderColor: showPasswordMismatchMessage ? 'red' : '#DADADA',
                  borderWidth: showPasswordMismatchMessage ? 2 : 1,
                },
              ]}>
              <TextInput
                ref={'passwordInput'}
                onBlur={() => this.setState({checkPass: true})}
                onFocus={() => this.setState({checkPass: false})}
                style={{
                  fontSize: 14,
                  height: 40,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                }}
                onChangeText={text =>
                  this.passwordConfirmationTextDidChange(text)
                }
                value={this.state.passwordConfirmationText}
                secureTextEntry={this.state.hideConfirmationPassword}
                placeholder={'Confirm password'}
                placeholderTextColor={'#999'}
                autoCapitalize={'none'}
              />
              <TouchableOpacity
                onPress={() =>
                  this.setState({
                    hideConfirmationPassword:
                      !this.state.hideConfirmationPassword,
                  })
                }
                style={{position: 'absolute', right: 8}}>
                <Text style={{color: '#4a5056'}}>
                  {this.state.hideConfirmationPassword ? 'Show' : 'Hide'}
                </Text>
              </TouchableOpacity>
            </View>

            {showPasswordMismatchMessage && (
              <View style={{flexDirection: 'column'}}>
                <Text style={styles.errorText}>
                  The password you've entered doesn't match.
                </Text>
              </View>
            )}

            {this.state.loginFailedErrorMessage.length > 0 &&
              !this.state.loginFailedErrorMessage
                .toLowerCase()
                .includes('already exists') && (
                <View style={{flexDirection: 'column', marginTop: 10}}>
                  <Text style={styles.errorText}>
                    Failed to create account.
                  </Text>
                </View>
              )}

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 20,
              }}>
              <TouchableOpacity
                onPress={this.termsAndConditionsCheckboxTapped.bind(this)}>
                <Image
                  style={{width: 30, height: 30, marginRight: 8}}
                  source={termsAndConditionsCheckboxImageSource}
                />
              </TouchableOpacity>

              <Text>
                I confirm I have read the{' '}
                <Text
                  style={[styles.linkText, {textDecorationLine: 'underline'}]}
                  onPress={() =>
                    PlatformController.openURL(
                      'https://www.classprofessional.co.uk/terms-of-use/parapass-terms-and-conditions/',
                    )
                  }>
                  Terms and Conditions
                </Text>
                .
              </Text>
            </View>

            <TouchableOpacity onPress={this.submit.bind(this)}>
              <Text
                style={[styles.primaryActionButton, submitButtonEnabledStyle]}>
                Create account
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (this.state.loginType === 'SSO') {
      contentLayout = (
        <View
          style={{
            maxWidth: 700,
            width: '100%',
            paddingHorizontal: 20,
          }}>
          <SSOLogin
            data={this.state.ssoConfig}
            onError={this.errorHandler}
            onLoadStart={() => this.props.showLoggingInIndicator('Logging In')}
            onLoadEnd={this.props.hideLoggingInIndicator}
          />
          {loginFailedLayout}
        </View>
      );
    }

    var logoMarginTop = 0;

    if (Platform.OS === 'ios') {
      logoMarginTop = getInset('top');
    }

    var ambulanceBarTiles = [],
      ambulanceBarTileWidth = 46,
      windowWidth = Dimensions.get('window').width;

    for (
      var ambulanceBarTileIndex = 0;
      ambulanceBarTileIndex < Math.ceil(windowWidth / ambulanceBarTileWidth);
      ambulanceBarTileIndex++
    ) {
      ambulanceBarTiles.push(
        <View key={ambulanceBarTileIndex} style={{flexDirection: 'row'}}>
          <View
            style={{width: 23, height: 7, backgroundColor: colors.StandbyGreen}}
          />
          <View
            style={{
              width: 23,
              height: 7,
              backgroundColor: colors.StandbyYellow,
            }}
          />
        </View>,
      );
    }

    var bottomPaddingView = null;

    if (PlatformController.deviceHasBottomSafeArea()) {
      bottomPaddingView = <View style={{height: 20}} />;
    }

    return (
      <View style={styles.container}>
        <View
          style={{
            alignItems: 'stretch',
            backgroundColor: colors.NavBar,
            height: 94,
          }}>
          <View
            style={{
              flex: 1,
              marginTop: logoMarginTop,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {(this.state.loginType === 'register' ||
              this.state.loginType === 'SSO') && (
              <View
                style={{
                  position: 'absolute',
                  left: 0,
                }}>
                <HeaderButtons
                  HeaderButtonComponent={props => (
                    <HeaderButton
                      onPress={() => this.switchToLogin()}
                      IconComponent={Ionicons}
                      iconSize={23}
                      color="white"
                      {...props}
                    />
                  )}>
                  <Item
                    title="back"
                    IconComponent={props => (
                      <View
                        style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Ionicons
                          name={
                            Platform.OS === 'android'
                              ? 'md-arrow-back'
                              : 'chevron-back'
                          }
                          color={'white'}
                          size={30}
                          style={{
                            paddingLeft: Platform.OS === 'android' ? 8 : 0,
                          }}
                        />
                        {Platform.OS === 'ios' || Platform.OS === 'web' ? (
                          <Text
                            style={{
                              color: 'white',
                              fontSize: 18,
                            }}>
                            Back
                          </Text>
                        ) : null}
                      </View>
                    )}
                    iconName={
                      Platform.OS === 'android'
                        ? 'md-arrow-back'
                        : 'chevron-back'
                    }
                    style={{
                      paddingLeft: Platform.OS === 'web' ? 12 : 0,
                    }}
                  />
                </HeaderButtons>
              </View>
            )}
            <Image
              style={{width: 150, height: 24}}
              resizeMode={'contain'}
              source={require('../assets/Logo_ParaPass_Short.png')}
            />
          </View>
          <View style={{flexDirection: 'row', height: 7}}>
            {ambulanceBarTiles}
          </View>
        </View>

        <KeyboardAvoidingView
          style={{flex: 1, backgroundColor: 'white'}}
          behavior="height"
          keyboardVerticalOffset={0}>
          <ScrollView
            style={{
              flex: 1,
            }}
            contentContainerStyle={{
              justifyContent: 'space-between',
              flexGrow: 1,
              alignItems: 'center',
            }}
            keyboardShouldPersistTaps="handled">
            {contentLayout}
            <View
              style={{
                alignItems: 'center',
                marginTop: 20,
                marginBottom: Platform.OS === 'web' ? 20 : getInset('top') + 20,
              }}>
              <Text>
                Need assistance?{' '}
                <Text
                  onPress={() =>
                    PlatformController.openURL(
                      'https://www.classprofessional.co.uk/faqs#parapass-app',
                    )
                  }
                  style={{
                    color: '#2a7df6',
                    textDecorationLine: 'underline',
                  }}>
                  Read our FAQs
                </Text>
              </Text>
              <Text style={{marginTop: 12}}>
                Please read our{' '}
                <Text
                  style={{color: '#2a7df6', textDecorationLine: 'underline'}}
                  onPress={() =>
                    PlatformController.openURL(
                      'https://www.classprofessional.co.uk/terms-of-use/parapass-terms-and-conditions/',
                    )
                  }>
                  Privacy Policy and Terms & Conditions
                </Text>
              </Text>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        {bottomPaddingView}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey000,
  },
  boldText: {
    fontWeight: '600',
  },
  linkText: {
    color: colors.ButtonBlue,
    fontWeight: '500',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 8,
    marginBottom: 10,
    color: colors.Grey900,
  },
  titleTextSmall: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 8,
    marginBottom: 10,
    color: colors.Grey900,
  },
  descriptionText: {
    fontSize: 15,
    color: colors.Grey900,
    marginVertical: 8,
  },
  disclaimerTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  textInputLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4a5056',
    marginVertical: 6,
  },
  textInputWrapper: {
    backgroundColor: colors.Grey000,
    borderColor: '#DADADA',
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 10,
  },
  errorText: {
    fontSize: 14,
    color: '#FF3333',
    marginBottom: 8,
  },
  primaryActionButton: {
    fontSize: 17,
    backgroundColor: colors.ButtonBlue,
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingVertical: 18,
    paddingHorizontal: 12,
    borderRadius: 8,
    overflow: 'hidden',
  },
  secondaryActionButton: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#2a7df6',
    paddingVertical: 18,
    paddingHorizontal: 12,
    // borderRadius: 8,
  },
});
