/**
 * PPP
 * https://github.com/facebook/react-native
 * @flow
 */
'use strict';

import {Platform, Dimensions, Linking} from 'react-native';

module.exports = {
  getCSSForBook(book: Object) {
    let css = '';

    if (book.ID === 'decisionMakingThirdEdition') {
      css = `
      table {
        border-collapse: collapse;
      }
      a {
        text-decoration: none;
        color: inherit;
        color: #0c4da2;
      }
      
      td {
        vertical-align: top;
      }
      sup {
        font-size: 65%;
        line-height: 0.5em;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .underline {
        text-decoration: underline;
      }
      .strike-through {
        text-decoration: line-through;
      }
      div.avoid {
        page-break-inside: avoid;
      }
      .half {
        font-size: 135%;
        font-weight: normal;
        font-family: sans-serif;
        margin-top: 5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .center {
        font-size: 100%;
        font-weight: normal;
        margin-top: 5em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .center0 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tit1 {
        font-size: 230%;
        font-family: sans-serif;
        margin-top: 2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
        color: #ffffff;
      }
      .tit2 {
        color: #ffffff;
        font-size: 110%;
        font-weight: normal;
        font-family: sans-serif;
        margin-top: 2.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tit3 {
        font-size: 100%;
        font-family: sans-serif;
        margin-top: 4em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
        color: #0c4da2;
      }
      .tit4 {
        font-size: 130%;
        font-weight: bold;
        font-family: sans-serif;
        margin-top: 0em;
        margin-bottom: 2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tit5 {
        font-size: 150%;
        font-weight: bold;
        font-family: sans-serif;
        margin-top: 1.7em;
        margin-bottom: 3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tit6 {
        font-size: 175%;
        font-weight: bold;
        font-family: sans-serif;
        margin-top: 1.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tit7 {
        font-size: 240%;
        font-weight: bold;
        font-family: sans-serif;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .copy {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .copyt {
        font-size: 80%;
        font-weight: normal;
        margin-top: 3em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .copy1 {
        font-size: 80%;
        font-weight: normal;
        margin-top: 0.1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .copy2 {
        font-size: 80%;
        font-weight: normal;
        margin-top: 2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .fmt {
        font-size: 145%;
        font-weight: bold;
        font-family: sans-serif;
        border-bottom: solid 2px;
        padding-bottom: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.4em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .toc1 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1.5em;
        margin-bottom: 0em;
        margin-left: 1.85em;
        margin-right: 0em;
        text-indent: -1.85em;
        text-align: left;
        color: #0c4da2;
      }
      .toc2 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 2.1em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .cct {
        padding-left: 1em;
      }
      .toc3 {
        font-size: 90%;
        font-weight: bold;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 2.1em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .toc3a {
        font-size: 90%;
        font-weight: bold;
        margin-top: 0.8em;
        margin-bottom: 0em;
        margin-left: 2.1em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .toc4 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 3.5em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .toc {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .toca {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .edi {
        font-size: 120%;
        color: #3c3c3b;
        font-weight: normal;
        font-family: sans-serif;
        margin-top: 1em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .ct {
        font-size: 170%;
        font-family: sans-serif;
        font-weight: bold;
        margin-top: 2em;
        margin-bottom: 0.5em;
        margin-left: 1.8em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .ct1 {
        float: left;
        margin-left: -1.8em;
        color: #575656;
      }
      .aut {
        font-size: 120%;
        font-family: sans-serif;
        border-top: solid 2px;
        font-weight: normal;
        padding-top: 0.5em;
        color: #3c3c3b;
        margin-top: 0em;
        margin-bottom: 0.1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .box {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.7em;
        margin-top: 2.5em;
        background: #e1e3f3;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        border: 1px solid #0c4da2;
      }
      .boxt {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
        margin-top: 2.5em;
        background: #0c4da2;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .boxv {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0em;
        padding-left: 0.8em;
        padding-right: 0em;
        padding-bottom: 0em;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .toc-1 {
        font-size: 95%;
        font-weight: bold;
        font-family: sans-serif;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 2.7em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .tc {
        float: left;
        margin-left: -2em;
      }
      .toc-2 {
        font-size: 90%;
        font-weight: normal;
        font-family: sans-serif;
        color: #575656;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 5.8em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .noindents {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .noindent1 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .hang {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 0em;
        text-indent: -2.2em;
        text-align: left;
      }
      .hg {
        float: left;
        margin-left: -9em;
      }
      .h2 {
        color: #ffffff;
        font-size: 150%;
        font-style: normal;
        text-align: center;
        font-weight: normal;
        margin-top: 2em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
      }
      h2.h2a {
        color: #0c4da2;
        font-size: 170%;
        font-style: normal;
        text-align: center;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
      }
      h2.h2f {
        color: #0c4da2;
        font-size: 170%;
        font-style: normal;
        text-align: center;
        font-weight: bold;
        margin-top: 2em;
        margin-bottom: 0.8em;
        margin-left: 0em;
        margin-right: 0em;
      }
      h2.h2fa {
        color: #0c4da2;
        font-size: 170%;
        font-style: normal;
        text-align: center;
        font-weight: bold;
        margin-top: 2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
      }
      h2.h2b {
        font-size: 120%;
        font-style: normal;
        text-align: center;
        font-weight: normal;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
      }
      .h3 {
        font-size: 115%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
        border-bottom: 1px solid #0c4da2;
        padding-bottom: 0.2em;
      }
      .h3a {
        color: #0c4da2;
        font-size: 110%;
        font-weight: bold;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .h4 {
        font-size: 100%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 1.5em;
        margin-bottom: 0.3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .h5 {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 0.3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .h5a {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .sh3 {
        float: left;
        margin-left: -1.8em;
      }
      .sh4 {
        float: left;
        margin-left: -1.5em;
      }
      .noindent {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .noindent0 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .noindentv {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .indent {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 1.2em;
        text-align: justify;
      }
      .refa {
        font-size: 85%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: -1.5em;
        text-align: left;
      }
      .ref_a {
        font-size: 85%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .bl {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        margin-left: 0em;
        padding-left: 2.4em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .ol {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        margin-left: 0em;
        padding-left: 2.2em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      span.fnt {
        font-size: 90%;
        font-family: sans-serif;
        font-weight: bold;
        font-style: italic;
      }
      .noindenta {
        font-size: 85%;
        font-family: sans-serif;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .indenta {
        font-size: 85%;
        font-family: sans-serif;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 1em;
        text-indent: 1.2em;
        text-align: justify;
      }
      .block {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .block0 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .block0a {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .block0b {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .block_a {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: -1em;
        text-align: justify;
      }
      .blockb {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: -1em;
        text-align: justify;
      }
      .sh5 {
        float: left;
        margin-left: -1.5em;
      }
      .idxp {
        font-size: 100%;
        font-weight: normal;
        font-style: normal;
        margin-top: -0.8em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .idx1 {
        font-size: 100%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        margin-left: 2em;
        margin-right: 0em;
        text-indent: -2em;
        text-align: left;
      }
      .idx2 {
        font-size: 100%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        margin-left: 3em;
        margin-right: 0em;
        text-indent: -2em;
        text-align: left;
      }
      .image {
        font-weight: normal;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .imagec {
        font-weight: normal;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .imagef {
        font-weight: normal;
        font-style: normal;
        margin-top: 10em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .imagea {
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .img {
        font-weight: normal;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      p.cpy,
      p.cpyf,
      p.cpyf1 {
        font-family: serif;
        font-size: 85%;
        line-height: 1.25em;
        margin-bottom: 0.1em;
        margin-top: 0.1em;
      }
      p.cpyf {
        margin-top: 1em;
      }
      p.cpyf1 {
        margin-top: 10em;
      }
      .caption {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .tab {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .credit {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: -0.6em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .captiont {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .tfn {
        font-size: 75%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 1em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .tfn1 {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .tfn2 {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .tfn0 {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 1em;
        margin-bottom: 0.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .imaget {
        font-weight: normal;
        font-style: normal;
        margin-top: 0.5em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tabb {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        width: 100%;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 1.2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .tabb0 {
        font-size: 80%;
        font-family: sans-serif;
        font-weight: normal;
        width: 100%;
        font-style: normal;
        margin-top: 1.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .th {
        padding-top: 0.1em;
        padding-bottom: 0.1em;
        padding-left: 0.2em;
        border-top: solid 2px;
        border-bottom: solid 1px;
        padding-right: 0.2em;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .tb {
        padding-top: 0.1em;
        padding-bottom: 0.1em;
        padding-left: 0.2em;
        padding-right: 0.2em;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .tbb {
        padding-top: 0.1em;
        padding-bottom: 0.1em;
        padding-left: 0.2em;
        padding-right: 0.2em;
        margin-top: 0em;
        border-bottom: solid 1px;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .thead {
        font-weight: bold;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .tbody {
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .nor {
        font-style: normal;
      }
      .bmt {
        font-size: 145%;
        font-weight: bold;
        font-family: sans-serif;
        border-bottom: solid 2px;
        padding-bottom: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.4em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .hangv {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 6.5em;
        margin-right: 1em;
        text-indent: -4.5em;
        text-align: justify;
      }
      .hangva {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 6.5em;
        margin-right: 1em;
        text-indent: -4.5em;
        text-align: justify;
      }
      span.sps {
        padding-left: 2.8em;
      }
      span.spt {
        padding-left: 1em;
      }
      span.spsa {
        padding-left: 1.2em;
      }
      span.spacea {
        padding-left: 1.3em;
      }
      span.spaceb {
        padding-left: 0.8em;
      }
      span.spst {
        padding-left: 1em;
      }
      span.spsta {
        padding-left: 0.5em;
      }
      span.spstb {
        padding-left: 0.8em;
      }
      span.spstc {
        padding-left: 1.2em;
      }
      .illa {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .ill {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0.3em;
        margin-bottom: 0em;
        text-indent: -3.7em;
        margin-left: 3.7em;
        margin-right: 0em;
        text-align: justify;
      }
      .illt {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        text-indent: -3.7em;
        margin-left: 3.7em;
        margin-right: 0em;
        text-align: justify;
      }
      .ded {
        font-size: 100%;
        font-style: normal;
        margin-top: 6em;
        margin-bottom: 2.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .ded0 {
        font-size: 100%;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      table.tablea {
        border-collapse: collapse;
        width: 100%;
        margin: 1em 0em 0.5em 0em;
      }
      table.tablef {
        border-collapse: collapse;
        width: 100%;
        margin: 1em 0em 2.5em 0em;
      }
      table.tableb {
        border-collapse: collapse;
        width: 100%;
        margin: 0.5em 0em 0em 0em;
      }
      th.thleft {
        font-size: 90%;
        padding: 0.5em;
        text-align: left;
        background-color: #0c4da2;
        color: #ffffff;
      }
      th.thleft0 {
        font-size: 85%;
        padding-left: 0.4em;
        padding-right: 0.4em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        text-align: center;
      }
      th.thleft_0 {
        border-right: 1px solid black;
        font-size: 85%;
        padding-left: 0.4em;
        padding-right: 0.4em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        text-align: center;
      }
      th.thlefta {
        font-size: 85%;
        padding: 0.3em;
        text-align: center;
      }
      td.tdleft {
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em;
        text-align: left;
        background-color: #0c4da2;
        border-bottom: 4px solid #0c4da2;
      }
      td.tdleft0 {
        font-size: 90%;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        text-align: left;
        background-color: #f0f1f9;
        border: 1px solid #0c4da2;
      }
      td.tdlefta {
        padding-top: 1em;
        padding-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em;
        text-align: left;
        vertical-align: top;
        background-color: #e1e3f3;
        border-bottom: 4px solid #0c4da2;
      }
      td.tdleft_b {
        font-size: 85%;
        padding-left: 0em;
        padding-right: 0.4em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        text-align: left;
        vertical-align: top;
        border-right: 1px solid black;
      }
      ol.nav_ol {
        list-style-type: none;
      }
      span.italic {
        font-style: italic;
      }
      span.bold {
        font-weight: bold;
      }
      .indent1 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 1.2em;
        text-align: justify;
      }
      .tab_cap {
        font-size: 80%;
        font-weight: normal;
        font-style: normal;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tab_tit {
        font-weight: bold;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .tabp {
        font-weight: normal;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .block1 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 1em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .blocka {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .indent2 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0.1em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 1.2em;
        text-align: justify;
      }
      .blockh {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 4em;
        margin-right: 1em;
        text-indent: -1em;
        text-align: justify;
      }
      .blockha {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 4em;
        margin-right: 1em;
        text-indent: -1em;
        text-align: justify;
      }
      .poem1 {
        font-size: 100%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .poem {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .poemind {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 6.5em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: justify;
      }
      .bull_list {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 2.2em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: justify;
      }
      .bull_listt {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0.25em;
        margin-bottom: 0em;
        margin-left: 2.2em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: justify;
      }
      .bull_listb {
        font-size: 100%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0.25em;
        margin-left: 2.2em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: justify;
      }
      span.bl {
        padding-left: 0.7em;
      }
      .h2_a {
        font-size: 150%;
        font-weight: normal;
        font-style: normal;
        margin-top: 3em;
        margin-bottom: 2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .bib {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: justify;
      }
      .indexn {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .index1 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: left;
      }
      .index {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: left;
      }
      .index_ind {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 2em;
        margin-right: 0em;
        text-indent: -1em;
        text-align: left;
      }
      .FN {
        font-size: 85%;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .FN1 {
        font-size: 85%;
        font-weight: normal;
        margin-top: 1.5em;
        margin-bottom: 0.3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .notea {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1.8em;
        margin-right: 0em;
        text-indent: -1.8em;
        text-align: justify;
      }
      .spn {
        color: #0c4da2;
        font-weight: bold;
      }
      .spna {
        padding-right: 1.05em;
      }
      .indent-a {
        font-size: 85%;
        font-family: sans-serif;
        font-weight: normal;
        margin: 0.3em 0em 0em 0em;
        margin-left: 1em;
        margin-right: 1em;
        text-indent: 1.2em;
        text-align: justify;
      }
      li.numbern {
        font-size: 90%;
        margin: 0em 0em 0em -0.9em;
        text-indent: -1.8em;
        font-weight: normal;
        text-align: justify;
      }
      ul.bull {
        margin-top: 1em;
        margin-bottom: 1em;
        text-align: left;
        list-style: none;
      }
      ul.bull0 {
        margin-top: 1em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bullq {
        margin-top: 0.7em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bull_0 {
        margin-top: 0em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bull0a {
        margin-top: 0.7em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bulla {
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bullv {
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        text-align: left;
        list-style: none;
      }
      ul.bull0 li.bull {
        font-size: 90%;
        margin: 0.5em 0em 0em 0em;
        text-indent: -1.35em;
        font-weight: normal;
        text-align: left;
      }
      ul.bullq li.bullq {
        font-size: 90%;
        margin: 0.7em 0em 0em -1.3em;
        text-indent: -1.7em;
        font-weight: normal;
        text-align: left;
      }
      ul.bull_0 li.bullv {
        font-size: 90%;
        margin: 0.3em 0em 0em 0em;
        text-indent: -1.35em;
        font-weight: normal;
        text-align: left;
      }
      ul.bullv li.bullv {
        font-size: 90%;
        margin: 0.7em 0em 0em 0em;
        text-indent: -1.35em;
        font-weight: normal;
        text-align: left;
      }
      ul.bull0a li.bull0 {
        font-size: 90%;
        margin: 0.3em 0em 0em 0em;
        text-indent: -1.35em;
        font-weight: normal;
        text-align: left;
      }
      ul.bulla li.bulla {
        font-size: 90%;
        margin: 0.7em 0em 0em -1.1em;
        text-indent: -1.1em;
        font-weight: normal;
        text-align: left;
      }
      .pad1 {
        padding-left: 0.75em;
      }
      .pad1a {
        padding-left: 1em;
      }
      span.bc {
        color: #0c4da2;
      }
      span.bca {
        color: #0c4da2;
        vertical-align: middle;
      }
      .fig {
        font-size: 90%;
        font-family: sans-serif;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .source {
        font-size: 80%;
        font-family: sans-serif;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .sourcea {
        font-size: 80%;
        font-family: sans-serif;
        margin-top: 0.3em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      span.blue {
        color: #0c4da2;
        font-weight: bold;
        padding-right: 1em;
      }
      .right {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: right;
      }
      .rightf {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: right;
      }
      p.BX1_H1 {
        font-size: 90%;
        font-style: normal;
        font-variant: normal;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-align: left;
      }
      p.BX3_T {
        font-size: 90%;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-align: center;
      }
      div.Basic-Text-Frame {
        padding-top: 0em;
        padding-bottom: 0.7em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: 0em;
        margin-right: 0em;
        background: #ede6f0;
        text-indent: 0em;
        border: 1px solid #977aa9;
      }
      div.Basic-Text-Frame2 {
        padding-top: 0em;
        padding-bottom: 0.7em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: 0em;
        margin-right: 0em;
        background: #e9d8e4;
        text-indent: 0em;
        border: 1px solid #9a237a;
      }
      div.Basic-Text-Frameb {
        padding-top: 0em;
        padding-bottom: 0.7em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: 0em;
        margin-right: 0em;
        background: #e1e3f3;
        text-indent: 0em;
        border: 1px solid #0c4da2;
      }
      div.Basic-Text-Framec {
        padding-top: 0em;
        padding-bottom: 0.7em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: 0em;
        margin-right: 0em;
        background: #e6f1f9;
        text-indent: 0em;
        border: 1px solid #0c4da2;
      }
      .BX1_TXL {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .BX1_TX {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .BX1_TX1 {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .BX1_SL {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: right;
      }
      .quote {
        font-size: 90%;
        font-family: sans-serif;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: left;
      }
      .quoter {
        font-size: 90%;
        font-family: sans-serif;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 1em;
        text-indent: 0em;
        text-align: right;
      }
      div.quotea {
        font-family: sans-serif;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 2.5em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      ol.num {
        margin-top: 0.3em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ol.num li.num {
        font-size: 85%;
        margin: 0.5em 0em 0em -1.35em;
        text-indent: -1.65em;
        font-weight: normal;
        text-align: left;
      }
      ol.number {
        margin-top: 1em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ol.number li.number {
        font-size: 90%;
        margin: 0.7em 0em 0em -1em;
        text-indent: -1.75em;
        font-weight: normal;
        text-align: left;
      }
      ol.numbera {
        margin-top: 0em;
        margin-bottom: 0em;
        text-align: left;
        list-style: none;
      }
      ol.numbera li.numbera {
        font-size: 90%;
        margin: 0.7em 0em 0em -1em;
        text-indent: -1.75em;
        font-weight: normal;
        text-align: left;
      }
      .padn {
        padding-left: 0.8em;
      }
      span.space {
        padding-left: 0.9em;
      }
      div.keep {
        margin-bottom: 0em;
        margin-top: 0em;
        page-break-inside: avoid;
      }
      p.BX1_H1m {
        font-size: 90%;
        font-style: normal;
        font-variant: normal;
        font-weight: bold;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
      }
      .boxm {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.7em;
        margin-top: 2em;
        background: #e5f2f9;
        margin-bottom: 2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        border: 1px solid #6d87b5;
      }
      .BX3_Tm {
        font-size: 90%;
        font-style: italic;
        font-weight: bold;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: center;
      }
      .rightm {
        font-size: 90%;
        font-weight: normal;
        margin-top: 1em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: right;
      }
      .BX2_BLm {
        font-size: 90%;
        margin: 0.3em 0em 0em -1.1em;
        text-indent: -1.1em;
        font-weight: normal;
        text-align: left;
      }
      .BX1_ULtm {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .BX1_ULm {
        font-size: 90%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .box1m {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.7em;
        margin-top: 1em;
        background: #e1e3f3;
        margin-bottom: 2em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        border: 1px solid #0c4da2;
      }
      .EOC-TXLm {
        font-size: 85%;
        font-weight: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      ul.bullam li.bulla1m {
        font-size: 100%;
        margin: 0.7em 0em 0em -1.5em;
        text-indent: -1.5em;
        font-weight: normal;
        text-align: left;
      }
      .pad1m {
        padding-left: 1.5em;
      }
      .h4am {
        font-size: 100%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 0.5em;
        margin-bottom: 0.3em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .box_brm {
        font-family: sans-serif;
        font-weight: normal;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.7em;
        margin-top: 1em;
        margin-bottom: 1.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        border: 1px solid #0c4da2;
      }
      p.H3_am {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      p.H3_m {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      .BL-TXm {
        font-weight: normal;
        font-style: normal;
        margin-top: 0.7em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
      }
      .ULFm {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 1.5em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .ULm {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 3em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: justify;
      }
      .GDEF1m {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 4em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: -1.5em;
        text-align: left;
      }
      .GDEFm {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: -1.5em;
        text-align: left;
      }
      p.IDX-ALPHAm {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 4em;
        margin-bottom: 0.35em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      p.IDX-ALPHA {
        font-size: 90%;
        color: #0c4da2;
        font-weight: bold;
        font-style: normal;
        margin-top: 1.25em;
        margin-bottom: 0.35em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      p.IDX-1 {
        font-size: 85%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 2.25em;
        margin-right: 0em;
        text-indent: -2.25em;
        text-align: left;
      }
      p.IDX-2 {
        font-size: 85%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 2.25em;
        margin-right: 0em;
        text-indent: -1.25em;
        text-align: left;
      }
      td.tdleft0m {
        font-size: 90%;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        text-align: left;
        background-color: #f1f2f8;
        border: 1px solid #0c4da2;
        vertical-align: top;
      }
      td.tdleft0rm {
        font-size: 90%;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        text-align: right;
        background-color: #f1f2f8;
        border: 1px solid #0c4da2;
        vertical-align: top;
      }
      .tab_m {
        margin-top: 0.3em;
        margin-bottom: 0em;
        text-align: left;
        background-color: #f1f2f8;
      }
      span.bold-italic {
        font-weight: bold;
        font-style: italic;
      }
      .TFN {
        font-size: 85%;
        font-weight: normal;
        margin-top: 0.5em;
        margin-bottom: 0em;
        margin-left: 0em;
        margin-right: 0em;
        text-indent: 0em;
        text-align: left;
      }
      sub {
        font-size: 65%;
        line-height: 0.5em;
      }
      .singleunderline {
        text-decoration: underline;
      }
      .REF1m {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 4em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: -1.5em;
        text-align: left;
      }
      .REFm {
        font-size: 90%;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2em;
        margin-bottom: 0em;
        margin-left: 1.5em;
        margin-right: 0em;
        text-indent: -1.5em;
        text-align: left;
      }
      
      .pad3 {
        padding-left: 2em;
      }
      
            `;
    } else if (book.ID === 'dermatology') {
      css = `
            /* Dermatology */
            
            .usefulLink {
                min-height: 75px;
                border: 1px solid black;
                border-radius: 5px;
                margin-bottom: 16px;
            }
            
            H2 {
                color: #569797;
            }
            
            .subParagraph{
                font-size: 11px;
            }
            
            .furtherReading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            
            .furtherReadingHeader{
                font-weight: bold;
                color: #9cc7c4;
            }
            
            .blueHeader {
                color: #669999;
                font-weight: bold;
            }
            
            .blueHeader2 {
                color: #669999;
                font-weight: bold;
                text-align: center;
            }
            
            .redHeader {
                color: #cc0000;
                font-weight: bold;
                text-align: center;
            }
            
            .blackHeader {
                color: #000000;
                font-weight: bold;
                text-align: center;
            }
            
            .blueBox {
                background: #82B5AC;
                border-radius: 5px;
                padding: 12px;
                /* margin-bottom: 16px; */
            }
            
            .blueBoxHeader{
                font-weight: bold;
            }
            
            .blueBoxBody1 {
                text-align: right;
            }
            
            .blueBoxBody2 {
                font-weight: bold;
            }
            
            .blueBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .blueBoxBody4 {
                text-align: center;
            }
            
            .greyBox {
                background:  #BAB9B7;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .greyBoxBody1{
                text-align: right;
            }
            
            .greyBoxBody2{
                font-weight: bold;
            }
            
            .greyBoxBody3{
                font-weight: bold;
                text-align: center;
            }
            
            .greyBoxBody4{
                text-align: center;
            }
            
            .greenBox {
                background: #CBDC9E;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .greenBoxBody1{
                text-align: center;
            }
            
            .greenBoxBody2{
                text-align: center;
                font-weight: bold;
            }
            
            .greenBoxBody3{
                text-align: right;
            }
            
            .greenBoxBody4{
                font-weight: bold;
            }
            
            .greenText {
                color: #51a101
            }
            
            .amberBox {
                background: #ff9a17;
                border-radius: 5px;
                color: black;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .amberBoxBody1 {
                font-weight: bold;
            }
            
            .amberBoxBody2 {
                text-align: center;
            }
            
            .amberBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .amberBoxBody4 {
                text-align: right;
            }
            
            .redFlag {
                content: url('bookImages/dermatology-10.png');
                height: 50px;
                width: 40px;
            }
            
            .amberFlag {
                content: url('bookImages/dermatology-11.png');
                height: 50px;
                width: 40px;
            }
            
            .lightRedBox {
                background: #cc6f6f;
                border-radius: 5px;
                color: #000000;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .lightRedBoxBody1 {
                
            }
            
            .lightRedBoxBody2 {
                font-weight: bold;
            }
            
            .lightRedBoxBody3 {
                font-weight: bold;
                text-align: center;
            }
            
            .lightRedBoxBody4 {
                text-align: center;
            }
            
            .redBox {
                background: #D4333D;
                border-radius: 5px;
                color: white;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .redBoxBody1 {
                
            }
            
            .redBoxBody2 {
                font-weight: bold;
            }
            
            .redBoxBody3 {
                text-align: right;
            }
            
            .redBoxBody4{
                font-weight: bold;
                text-align: center;
            }
            
            .redBoxBody5{
                text-align: center;
            }
            
            .yellowBoxContainer {
                display: flex;
                flex-direction: row;
            }
            
            .yellowBox {
                background: #F8E86D;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 16px;
            }
            
            .yellowBoxHeader {
                font-weight: bold;
            }
            
            .yellowBoxBody1{
                font-weight: bold;
            }
            
            .yellowBoxBody2 {
                text-align: right;
            }
            
            .yellowBoxBody3{
                text-align: center;
            }
            
            .yellowBoxBody4{
                font-weight: bold;
                text-align: center;
            }
            
            .usefulLinkHeader{
                font-size: 16px;
            }
            
            .usefulLinkBody1{
                font-size: 16px;
                padding-top: 6px;
                padding-left: 10px;
                padding-right: 10px;
            }
            
            .usefulLinkBody2{
                font-size: 12px;
                padding-bottom: 6px;
                padding-left: 10px;
                padding-right: 10px;
            }
            
            .htuBoxHeader{
                font-style: bold;
            }
            
            ul.greenBoxList {
                list-style-type: square;
            }
            
            ul.greenBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.greenBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.greenBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.greyBoxList {
                list-style-type: square;
            }
            
            ul.greyBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.greyBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.greyBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.blueBoxList {
                list-style-type: square;
            }
            
            ul.blueBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.blueBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.blueBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.yellowBoxList{
                list-style-type: square;
            }
            
            ul.yellowBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.yellowBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.yellowBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.redBoxList{
                list-style-type: square;
            }
            
            ul.redBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.redBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.redBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            ul.lightRedBoxList{
                list-style-type: square;
            }
            
            ul.lightRedBoxList2 {
                list-style: none;
                padding-left: 0;
            }
            
            li.lightRedBoxListItem2 {
                position: relative;
                padding-left: 1.5em;  /* space to preserve indentation on wrap */
            }
            
            li.lightRedBoxListItem2:before {
                position: absolute;
                left: 0;  /* place the SVG at the start of the padding */
                width: 20px;
                height: 20px;
                content: url('bookImages/dermatology-check.svg')
            }
            
            tr.tableHeaderRow1 {
                border-spacing: 2px 0;
            }
            
            th.tableHeader1 {
                background: #669999;
            }
            
            th.tableHeader2 {
                background: #ffdb4d;
            }
            
            td.tableData1 {
                background: #ffdb4d;
            }
            
            td.tableData2 {
                background: #a6a6a6;
            }
            
            td.tableData3{
                background: #a6a6a6;
                color: #cc0000;
                font-weight: bold;
            }
            
            table.verticalCellSpacing{
                border-spacing: 2px 0;
            }
            
            `;
    } else if (book.ID === 'emergencyBirth') {
      css = `
            .hh {
                color: #017a88
            }
            
            table.bor1{
                border: 1px solid #84bab5;
                border-collapse: collapse;
            }
            
            tr {
                border: 1px solid #84bab5;
            }
            
            td {
                border: 1px solid #84bab5;
            }
            
            .bor2 {
                background: #eef4f3;
            }
            
            .bor2n {
                background: #eef4f3;
            }
            
            .bor3 {
                background: #e1eeea;
            }
            
            .bor2gw {
                border-right: 1px solid white;
                background: #9ac5c1;
            }
            
            .bor2g {
                background: #9ac5c1;
            }
            
            .c1 {
                color: #017a88;
            }
            .bull1 {
                padding-left: 18px;
            }
            
            .bull2 {
                padding-left: 36px;
            }
            
            `;
    } else if (book.ID === 'humanFactors') {
      css = `
            .h2f{
                color: #c65107;
            }
            
            .separator-1 {
                width: 100%;
                height: 1px;
                background-color: #c65107;
            }
            
            .lot {
                background: #f3e9e6;
                border: 1px solid #a34c46;
                border-radius: 8px;
                padding: 12px;
            }
            
            .lot_bl1 {
                padding-left: 12px;
            }
            
            .lot_bl1b {
                color: #a34c46;
                font-size: 16px;
            }
            
            .fig_num {
                color: #67924d;
                font-weight: bold;
            }
            
            .tbn {
                color: #45a1c6;
                font-weight: bold;
            }
            
            table {
                border: 1px solid #45a1c6;
                border-collapse: collapse;
            }
            
            th {
                background: #45a1c6;
                color: white;
                padding: 8px;
            }
            
            tr {
                border: 1px solid #45a1c6;
            }
            
            td {
                border: 1px solid #45a1c6;
                padding: 8px;
                min-width: 100px;
            }
            
            .tr1 {
                background: #ebf2f7;
            }
            
            .tr2 {
                background: #dbe9f2;
            }
            
            .box2 {
                background: #f0f4ef;
                border-radius: 8px;
                padding: 12px;
            }
            
            .h4 {
                color: #65914a;
                font-weight: bold;
            }
            
            .bl1f {
                padding-left: 12px;
            }
            
            .bl1 {
                padding-left: 12px;
            }
            
            .bl1b {
                color: #c65107;
            }
            
            .nl1f {
                padding-left: 12px;
            }
            
            .nl1 {
                padding-left: 12px;
            }
            
            .nl1n {
                color: #c65107;
                font-weight: bold;
            }
            
            .ext {
                padding: 12px;
            }
            
            .ext_src {
                text-align: right;
            }
            
            .h3 {
                color: #27385c;
                font-weight: bold;
            }
            
            .src {
                text-align: right;
            }
            
            .tb_blb {
                color: #45a1c6;
            }
            
            .bx1_t {
                background: #a34c46;
                color: white;
                font-weight: bold;
                padding: 12px 12px 18px 12px;
                border-radius: 8px 8px 0px 0px;
            
            }
            
            .box1 {
                background: #f3e9e6;
                border: 1px solid #a34c46;
                padding: 12px;
                border-radius: 8px 8px 8px 8px;
                margin-top: -22px;
            }
            
            .bx1_bl {
                padding-left: 12px;
            }
            
            .bx1_blb {
                color: #a34c46;
            }
            
            .ulf {
                padding-left: 12px;
            }
            
            .bl2 {
                padding-left: 24px;
            }
            
            .bl2b {
                font-size: 9px;
                color: #a34c46;
                padding-right: 12px;
            }
            
            p.ul {
                padding-left: 12px;
            }
            
            `;
    } else if (book.ID === 'lawAndEthics') {
      css = `
            .bold {
                font-weight: bold;
            }
            
            .ital {
                font-style: italic;
            }
            
            .h2 {
                color: #003ba4;
                font-weight: bold;
                font-size: 16px;
            }
            
            .h3 {
                color: #003ba4;
                font-weight: bold;
                font-size: 16px;
            }
            
            .bx1 {
                background: #e5e7f4;
                border: 1px solid #003ba4;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .cs {
                background: #003ba4;
                display: flex;
                align-items: center;
                color: white;
                font-weight: bold;
            }
            
            .bx1_t {
                font-weight: bold;
                text-align: center;
            }
            
            .bx1_txl {
            
            }
            
            .bx2 {
                background: #b0b4d4;
                padding-top: 12px;
                padding-right: 12px;
                padding-bottom: 12px;
                padding-left: 12px;
                margin-bottom: 12px;
            }
            
            .bx2_t {
                font-weight: bold;
                
            }
            
            .bx2_txl {
                padding-left: 6px;
            }
            
            .bx3_t {
                text-align: center;
            }
            
            .bx3_txl {
                text-align: center;
            }
            
            .bld {
                color: #003ba4;
                font-size: 9px;
                padding-left: 12px;
                padding-right: 12px;
            }
            
            .bnl {
                color: #003ba4;
                font-weight: bold;
                padding-left: 12px;
                padding-right: 12px;
            }
            
            .fgn {
                color: #003ba4;
                font-weight: bold;
            }
            
            .obj {
                background: #003ba4;
                color: white;
                font-size: 14px;
                font-weight: bold;
            }
            
            .tn {
                font-weight: bold;
            }
            
            .tch1 {
                background: #003ba4;
                color: white;
                font-weight: bold;
                font-size: 14px;
                /* padding: 8px; */
            }
            
            .tb {
                background: #dddfec;
                /* padding: 8px; */
            }
            
            table.tt {
                border: 1px solid #003ba4;
            }
            
            table.cs {
                border: 1px solid #003ba4;
            }
            
            .bx2-h1 {
                font-weight: bold;
            }
            
            .nln {
                color: #003ba4;
                font-weight: bold;
                padding-left: 8px;
                padding-right: 8px;
            }
            
            .br {
                color: #003ba4;
            }
            
            .br1 {
                padding-left: 16px;
            }
            
            .br2 {
                padding-left: 16px;
            }
            
            .cnn {
                border-bottom: 1px solid #013CA6;
                height: 200px;
                border-collapse: collapse;
            }
            
            .cn {
                background-color: #013CA6;
                color: white;
                right: 0;
            }
            
            .ct {
                background-color: #E7E8F5;
                color: #013CA6
            }
            
            .epi {
                font-style: italic;
            }
            
            .boxhead {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 170px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .box {
                background-color: #E7E8F5;
                border: 2px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .box2 {
                background-color: #B9BEE0;
                border-radius: 5px 0px 5px 0px;
                padding: 12px;
            }
            
            .boxhead1 {
                font-weight: bold;
                font-style: italic;
            }
            
            .h4a {
                font-weight: bold;
                color: #003ba4;
            }
            
            .h4 {
                font-weight: bold;
                color: #003ba4;
                font-size: 16px;
            }
            
            .tch1 {
                font-weight: bold;
                background-color: #003ba4;
                color: #FFFFFF;
                padding: 4px;
            }
            
            .td1 {
                background: #F1F3F7;
                border: 1px solid #003ba4;
                padding: 4px;
            }
            
            .tab1 {
                border-collapse: collapse;
            }
            
            .bl {
                list-style: none; /* Remove default bullets */
            }
              
            .bl li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .box3 {
                background-color: #E7E8F5;
                border: 1px solid #0E4B90;
                padding: 12px;
            }
            
            .boxhead3 {
                font-weight: bold;
                text-align: center;
            }
            
            .box4 {
                background-color: #E7E8F5;
                border: 1px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
                margin-bottom: 12px;
            }
            
            .boxhead4 {
                font-weight: bold;
                text-align: center;
            }
            
            .h5 {
                font-weight: bold;
                color: #003ba4;
                font-size: 16px;
            }
            
            .bl-1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl-1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .bx-r {
                text-align: right;
            }
            
            .boxhead5 {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 285px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .h5a {
                font-weight: bold;
                color: #003ba4;
            }
            
            .boxhead4a {
                font-weight: bold;
            }
            
            .fig {
                font-weight: bold;
                color: #003ba4;
            }
            
            .epi-r {
                text-align: right;
            }
            
            .bl2-1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl2-1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            .bl1 {
                list-style: none; /* Remove default bullets */
            }
              
            .bl1 li::before {
                content: "\\2022";  /* Add content: \\2022 is the CSS Code/unicode for a bullet */
                font-size: 14px;
                color: #003ba4; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            
            `;
    } else if (book.ID === 'palliativeCare') {
      css = `
            /* Palliative Life and End of Life Care */

            .bold {
                font-weight: bold;
            }

            .ital {
                font-style: italic;
            }

            .bl {
                padding-left: 12px;
            }

            .blbl {
                padding-left: 24px;
            }

            .bl_f {
                padding-left: 12px;
            }

            .blb {
                font-size: 9px;
                color: #c85519;
                padding-right: 8px;
            }

            .st_bl {
                padding-left: 12px;
            }

            .st_blbl {
                padding-left: 24px;
            }

            .st_blbl_bl {
                padding-left: 36px;
            }

            .st_blb {
                font-size: 9px;
                color: #c85519;
                padding-right: 8px;
            }

            .separator-1 {
                width: 100%;
                height: 1px;
                background-color: #c85519;
            }

            .h1 {
                font-size: 14px;
                color: #c85519;
                font-weight: bold;
            }

            .h2 {
                font-size: 14px;
                color: #294268;
                font-weight: bold;
            }

            .h3 {
                color: #679555;
                font-weight: bold;
            }

            .bx1 {
                background: #f4e5c8;
                border-radius: 5px 0px 0px 5px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
            }

            .bx1_t {
                font-size: 14px;
                font-weight: bold;
                font-style: italic;
                color: #ae364f;
            }

            .bx2 {
                background: #e3ede3;
                border-radius: 5px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
                border: 1px solid #2e8948;
                margin-bottom: 12px;
            }

            .bx2_t {
                font-size: 14px;
                font-weight: bold;
                color: #2e8948;
                text-align: center;
            }

            .bx2_parac{
                text-align: center;
            }

            .bx2_bl {
                padding-left: 12px;
            }

            .bx2_blb {
                font-size: 9px;
                color: #2e8948;
                padding-right: 8px;
            }

            .bx3_t {
                background: #a14942;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 5px 5px 0px 0px;
                padding: 12px;
                margin: 0;
            }

            .bx3 {
                background: #f2e4e2;
                border: 1px solid #a14942;
            }

            .bx3_bl {
                padding-left: 12px;
            }

            .bx3_blb {
                font-size: 9px;
                color: #a14942;
                padding-right: 8px;
            }

            .bx4 {
                background: #e5ebf2;
                border: 1px solid #3684ad;
            }

            .cs_t {
                display: flex;
                align-items: center;
                background: #006b9f;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 5px 5px 0px 0px;
                width: 175px;
                margin: 0 0 0 20px
            }

            .cs {
                background: #dfe5ef;
                border: 1px solid #006b9f;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
                margin-bottom: 12px;
            }

            .cs_blb {
                font-size: 9px;
                color: #006b9f;
                padding-right: 8px;
            }

            .cs_bl{
                padding-left: 12px;
            }

            /* Tables */

            table {
                border: 1px solid #31a0c5;
                border-collapse: collapse;
            }

            .tbn {
                font-weight: bold;
                color: #31a0c5;
            }

            .vb {
                background: #31a0c5;
                color: white;
                font-weight: bold;
            }

            .cen {
                background: #31a0c5;
                color: white;
                font-weight: bold;
                text-align: center;
            }

            .tb_bl {
                padding-left: 12px;
            }

            .tb_blb {
                font-size: 9px;
                color: #31a0c5;
                padding-right: 8px;
            }

            .bc {
                background: #e5f0f6;
            }

            .bc1 {
                background: #d2e5f0;
            }

            /* Number lists */

            .nlnl {
                padding-left: 24px;
            }

            .nlnl_nl {
                padding-left: 36px;
            }

            .nl {
                padding-left: 12px;
            }

            .nl_f {
                padding-left: 12px;
            }

            .nl_l {
                padding-left: 12px;
            }

            .nln {
                color: #c85519;
                font-weight: bold;
            }
            
            `;
    } else if (book.ID === 'mentalHealth') {
      css = `
            /* Mental Health */

            .AHead {
                color: #0E4B90;
                font-size: 18px;
                font-weight: bold;
            }
            
            .BHead {
                color: #0E4B90;
                font-weight: bold;
                font-size: 14px;
            }
            
            a:link {
                color: #008080;
            }
            
            .thead > tr {
                background-color: #0E4B90 !important;
                color: #FFFFFF;
            }
            
            .TableBody {
                background-color: #DFE0EC;
                color: #000000;
                border: 1px solid #0E4B90;
                padding: 4px;
            }
            
            .Tlabel {
                font-weight: bold;
            }
            
            .ClassList {
                color: #0E4B90;
                font-size: 18px;
                padding-left: 12px;
                padding-right: 6px;
                font-weight: bold;
            }
            
            .listnum {
                color: #0E4B90;
                padding-left: 12px;
                padding-right: 6px;
                font-weight: bold;
            }
            
            table {
                border-collapse: collapse;
            }
            
            .box {
                background-color: #DFE0EC;
                border: 1px solid #0E4B90;
                border-radius: 5px;
                padding: 12px;
            }
            
            .boxTitle {
                font-weight: bold;
                color: #000000;
                text-align: center;
                font-size: 18px;
            }
            
            .list2 {
                padding-left: 12px;
            }
            
            .list3 {
                padding-left: 12px;
            }
            
            .boxTitle-t {
                background-color: #0E4B90;
                padding: 12px;
                color: #FFFFFF;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                width: 110px;
                text-align: center;
                margin: 0 0 0 24px;
            }
            
            .boxAhead {
                font-weight: bold;
            }
            
            .Tparar {
                text-align: right;
            }
            
            .extract {
                background-color: #DFE0EC;
                padding: 12px;
                border-radius: 12px;
            }
            
            `;
    }

    return css;
  },
};
