import React from 'react';

import PromoCard from './PromoCard';

import {usePromotions} from '../../context/PromotionsContext';

import {
  getPromotionDateStr,
  getPromotionStrings,
} from '../../constants/promotions';

const PromoQuizSection = ({screenProps}) => {
  const {promotion, isPromoAvailable, promoCode, isPromoCollected} =
    usePromotions();
  if (!promotion || !isPromoAvailable) {
    return null;
  }

  const promotionDateString = getPromotionDateStr(promotion.finalDate);
  const {title, subtitle, button} = getPromotionStrings(
    promotion,
    promoCode,
    isPromoCollected,
  );

  const analyticsParams = {
    content_type: 'promotion',
    content_id: promotion.id,
    content_name: promotion.name,
    trust: promotion.trust,
  };

  return (
    <PromoCard
      header={`REWARD ${promotionDateString}`}
      title={title}
      subtitle={subtitle}
      imageUrl={promotion.bannerImage.url}
      icon="Promotion"
      promoCode={promoCode}
      onPress={screenProps?.takeRandomQuiz}
      promoButtonOnPress={() => {
        screenProps?.handlePromoModalToShow('qrCode', analyticsParams);
      }}
      infoButtonOnPress={() => {
        screenProps?.handlePromoModalToShow('info', analyticsParams);
      }}
      disabledButtonText={button}
      activeButtonText={promoCode?.promoCode}
      buttonText={button}
      isButtonDisabled={isPromoCollected}
      showLogo={promotion.showLogo}
    />
  );
};

export default PromoQuizSection;
