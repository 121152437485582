/**
 * PPP
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import {Platform} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {EventRegister} from 'react-native-event-listeners';
import localforage from 'localforage';

import RNFetchBlob from '../classes/RNFetchBlob';
import DataController from './DataController';

export default {
  serverURL() {
    return 'https://aimermedia-api.co.uk';
    // return 'http://192.168.1.139:3001';
  },

  async customFetch(url, options) {
    return new Promise(async (resolve, reject) => {
      try {
        var response = null;

        if (options != null) {
          if (Platform.OS === 'android') {
            response = await RNFetchBlob.fetch(
              options.method,
              url,
              options.headers,
              options.body,
            );
            response.status = response.respInfo.status;
          } else {
            response = await fetch(url, options);
          }
        } else {
          if (Platform.OS === 'android') {
            response = await RNFetchBlob.fetch('GET', url);
          } else {
            response = await fetch(url);
          }
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  async logIn(email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/SF/login';
      var response = null;

      try {
        response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
      } catch (error) {
        reject(error);
      }

      var test = '';

      try {
        let responseJson = await response.json();

        if (responseJson.auth === true && responseJson.token != null) {
          // check if PPP tester
          var isPPPTester = await this.isPPPTester(
            responseJson.token,
            responseJson.contactID,
            email,
          );
          // var isPPPTester = false;

          try {
            if (responseJson.token) {
              await AsyncStorage.setItem('token', responseJson.token);
            } else {
              await AsyncStorage.setItem('token', '');
            }

            if (responseJson.contactID) {
              await AsyncStorage.setItem(
                'uid',
                JSON.stringify(responseJson.contactID),
              );
            } else {
              await AsyncStorage.setItem('uid', JSON.stringify(''));
            }

            if (responseJson.userName) {
              await AsyncStorage.setItem('userName', responseJson.userName);
            } else {
              await AsyncStorage.setItem('userName', '');
            }

            if (email) {
              await AsyncStorage.setItem('email', email);
            } else {
              await AsyncStorage.setItem('email', '');
            }

            await AsyncStorage.setItem(
              'isPPPTester',
              JSON.stringify(isPPPTester),
            );
          } catch (error) {
            // Error saving data
            console.log('error saving: ' + error);
          }

          resolve(responseJson.contactID);
        } else {
          reject(responseJson.body.ErrorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  async logInWithEmailAndContactID(email: string, contactID: string) {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/SF/exchange';
      var response = null;

      try {
        response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            ContactID: contactID,
          }),
        });
      } catch (error) {
        reject(error);
      }

      var test = '';

      try {
        let responseJson = await response.json();

        if (responseJson.success === true && responseJson.token != null) {
          // check if PPP tester
          var isPPPTester = await this.isPPPTester(
            responseJson.token,
            contactID,
            email,
          );

          try {
            if (responseJson.token) {
              await AsyncStorage.setItem('token', responseJson.token);
            } else {
              await AsyncStorage.setItem('token', '');
            }

            if (contactID) {
              await AsyncStorage.setItem('uid', contactID);
            } else {
              await AsyncStorage.setItem('uid', JSON.stringify(''));
            }

            if (responseJson.userName) {
              await AsyncStorage.setItem('userName', responseJson.userName);
            } else {
              await AsyncStorage.setItem('userName', '');
            }

            if (email) {
              await AsyncStorage.setItem('email', email);
            } else {
              await AsyncStorage.setItem('email', '');
            }

            await AsyncStorage.setItem(
              'isPPPTester',
              JSON.stringify(isPPPTester),
            );
          } catch (error) {
            // Error saving data
            console.log('error saving: ' + error);
          }

          resolve(contactID);
        } else {
          reject(responseJson.body.ErrorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  },

  async logOut() {
    console.log('calling logout');
    try {
      if (Platform.OS === 'web') {
        // clear local forage?
        await localforage.clear();
        await AsyncStorage.clear(); // clear all on web to remove old JRCALC CPD keys
      } else {
        await AsyncStorage.removeItem('token');
        await AsyncStorage.removeItem('uid');
        await AsyncStorage.removeItem('userName');
        await AsyncStorage.removeItem('email');
        await AsyncStorage.removeItem('staffGroups');
        await AsyncStorage.removeItem('trusts');
        await AsyncStorage.removeItem('isPPPTester');
        await AsyncStorage.removeItem('QuizQueue');
        await AsyncStorage.removeItem('ResultQueue');
        await AsyncStorage.removeItem('PPPMigratedCPD');
        await AsyncStorage.removeItem('recentSearchPhrases');
        await AsyncStorage.removeItem('recentSearchItems');
      }
    } catch (error) {
      // Error saving data
      console.log('error removing items: ' + error);
    }

    EventRegister.emit('userUpdated', '');
  },

  async registerUser(
    emailAddress: string,
    password: string,
    firstName: string,
    lastName: string,
  ): boolean {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/register';
      url = this.serverURL() + '/classapi/SF/register';
      var response = null;

      try {
        response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: emailAddress,
            password: password,
            firstName: firstName,
            lastName: lastName,
            CreatedSubscriberProduct: 'ParaPass',
          }),
        });
      } catch (error) {
        reject(error);
      }

      let responseJson = await response.json();

      if (responseJson.success === true) {
        resolve(true);
      } else {
        if (responseJson)
          if (responseJson.body.ErrorMessage === 'Failed Validation') {
            var validationList = responseJson.body.ValidationList;

            if (validationList != null && validationList.length > 0) {
              var firstValidationRule = validationList[0];
              reject(firstValidationRule.Message);
            }
          } else {
            reject(responseJson.body.ErrorMessage);
          }
      }
    });
  },

  async downloadUserInfo(contactID: number) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/userInfo';
        url = this.serverURL() + '/classapi/SF/userInfo';
        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
        }

        console.log(url);

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ContactID: contactID + '',
            token: token,
            product: 'ParaPass',
          }),
        });

        if (response.status === 401) {
          this.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();
        if (response.status === 200 && responseJson.success === true) {
          try {
            var subscriptions = responseJson.subscriptions;
            var staffGroups = responseJson.staffGroup;

            if (
              responseJson.dbRecord != null &&
              responseJson.dbRecord.length > 0
            ) {
              var dbRecord = responseJson.dbRecord[0];

              if (dbRecord.PPPMigratedCPD === 1) {
                await AsyncStorage.setItem(
                  'PPPMigratedCPD',
                  JSON.stringify(true),
                );
              } else if (dbRecord.PPPMigratedCPD === 0) {
                await AsyncStorage.removeItem('PPPMigratedCPD');
              }
            }

            if (responseJson.userLoginName != null) {
              await AsyncStorage.setItem('email', responseJson.userLoginName);
            }

            if (responseJson.userName) {
              await AsyncStorage.setItem('userName', responseJson.userName);
            } else {
              await AsyncStorage.setItem('userName', '');
            }

            await AsyncStorage.setItem(
              'subscriptions',
              JSON.stringify(subscriptions),
            );
            await AsyncStorage.setItem(
              'staffGroups',
              JSON.stringify(staffGroups),
            );
          } catch (error) {
            console.log('Failed to save subscriptions: ', error);
          }
        }

        resolve();
      } catch (error) {
        console.log(error);
        reject(null);
      }
    });
  },

  async getUser() {
    try {
      var token = await AsyncStorage.getItem('token');
      var uid = await AsyncStorage.getItem('uid');
      var userName = await AsyncStorage.getItem('userName');
      var email = await AsyncStorage.getItem('email');
      var staffGroups = await AsyncStorage.getItem('staffGroups');
      var plusTrusts = await AsyncStorage.getItem('trusts');
      var paraPassTrusts = await AsyncStorage.getItem('paraPassTrusts');
      var isPPPTester = await AsyncStorage.getItem('isPPPTester');
      var migratedCPDResults = await AsyncStorage.getItem('PPPMigratedCPD');
      var subscriptions = await AsyncStorage.getItem('subscriptions');
      var hasBookAccess = false;

      if (plusTrusts != null) {
        plusTrusts = JSON.parse(plusTrusts);
      }

      if (plusTrusts == null) {
        plusTrusts = [];
      }

      if (paraPassTrusts != null) {
        paraPassTrusts = JSON.parse(paraPassTrusts);
      }

      if (paraPassTrusts == null) {
        paraPassTrusts = [];
      }

      if (staffGroups != null) {
        staffGroups = JSON.parse(staffGroups);
      }

      if (staffGroups == null) {
        staffGroups = [];
      }

      if (isPPPTester != null) {
        isPPPTester = JSON.parse(isPPPTester);
      }

      if (isPPPTester == null) {
        isPPPTester = false;
      }

      if (migratedCPDResults != null) {
        migratedCPDResults = JSON.parse(migratedCPDResults);
      } else {
        migratedCPDResults = false;
      }

      if (subscriptions != null) {
        subscriptions = JSON.parse(subscriptions);
      }

      if (subscriptions == null) {
        subscriptions = [];
      }

      for (let subscription of subscriptions) {
        if (subscription.BundleAcess === 'true') {
          hasBookAccess = true;
        }
      }

      if (token != null && uid != null) {
        return {
          uid: uid,
          name: userName,
          email: email,
          staffGroups: staffGroups,
          plusTrusts: plusTrusts,
          paraPassTrusts: paraPassTrusts,
          isPPPTester: isPPPTester,
          migratedCPDResults: migratedCPDResults,
          hasBookAccess: hasBookAccess,
          subscriptions: subscriptions,
        };
      }
    } catch (error) {
      // Error saving data
      console.log('error getting user: ' + error);
    }

    return null;
  },

  async isPPPTester(token: string, contactID: string, email: string) {
    var url = this.serverURL() + '/classapi/pppapi/isPPPTester';
    var response = null;

    try {
      response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          contactID: contactID,
          email: email,
        }),
      });
    } catch (error) {
      console.log(error);
      return error;
    }

    let responseJson = await response.json();

    console.log('isPPPTester');
    // console.log(responseJson);

    if (responseJson.auth === true) {
      let messageBody = responseJson.body;
      var isPPPTester = messageBody.isPPPTester;

      return isPPPTester;
    } else {
      return false;
    }
  },

  async requestAccountDeletion(contactID: number) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/SF/requestAccountDel';
        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
        }

        console.log(url);

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ContactID: contactID + '',
            token: token,
            product: 'ParaPass',
          }),
        });

        if (response.status === 401) {
          this.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();
        if (response.status === 200 && responseJson.success === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        console.log(error);
        reject(null);
      }
    });
  },

  async logInSSO(accessToken: string, trust: string): Promise<IUser | null> {
    console.log('logInSSO');
    const url = this.serverURL() + '/classapi/SF/loginSSO';

    try {
      const response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({trust, accessToken}),
      });

      const data = await response.json();

      if (!data.success || !data.token || !data.contactID || !data.email) {
        throw new Error(data.error?.ErrorMessage || 'Invalid response');
      }

      const contactID = await this.logInWithEmailAndContactID(
        data.email,
        data.contactID,
      );
      return contactID;
    } catch (error) {
      console.error('Error logging in:', error);
      return null;
    }
  },
};
