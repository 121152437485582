/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {Alert, Platform, StyleSheet} from 'react-native';

import AMWebView from '../components/AMWebView';

import GuidelinesController from '../classes/GuidelinesController';
import LayoutController from '../classes/LayoutController';
import RNFS from '../classes/RNFS';
import TrustRender from '../classes/plusstatic/TrustRender';
import ScreenComponent from '../components/ScreenComponent';

import {colors} from '../classes/Colors';

var _ = require('lodash');
var moment = require('moment');

type Props = {};

type State = {
  glid: string,
  html: string,
};

export default class ReferenceScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'PGD',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        glid: this.props.navigation.getParam('glid', ''),
        html: '',
      };

      try {
        var from = this.props.navigation.getParam('from', 'unknown');
        firebase.analytics().logEvent('pgd_started', {
          glid: this.state.glid.name,
          from,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      var guidelineContents = this.state.guidelineContents;

      var fontSize = await LayoutController.getFontSize();

      var pgd = await GuidelinesController.getTrustGuidelineJSONForGLID(
        this.state.glid,
      );

      if (pgd == null) {
        Alert.alert(
          'PGD unavailable',
          'Please try restarting the app and check for updates',
        );
      } else {
        var errorList = [];
        const aTrustRender = new TrustRender(errorList);

        var trust = '';

        if (this.props.screenProps.user.plusTrusts.length > 0) {
          trust = this.props.screenProps.user.plusTrusts[0];
        }

        var contentHtml = aTrustRender.htmlFromJson(pgd, trust);

        contentHtml = contentHtml.replace(/css\//g, '');

        if (Platform.OS === 'web') {
          if (this.props.screenProps.user.isPPPTester) {
            contentHtml = contentHtml.replace(
              /img\//g,
              'https://aace.s3.amazonaws.com/' + trust + '/',
            );
          } else {
            contentHtml = contentHtml.replace(
              /img\//g,
              'https://jrcalc.s3.amazonaws.com/' + trust + '/img/',
            );
          }
        } else {
          contentHtml = contentHtml.replace(
            /img\//g,
            'file://' + RNFS.DocumentDirectoryPath + '/GLImage/',
          );
        }

        var paddingString = 'padding: 10px;';

        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          paddingString = '';
        }

        var html =
          '<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" initial-scale=1><link rel="stylesheet" type="text/css" href="style.css"><link rel="stylesheet" type="text/css" href="style2.css"><link rel="stylesheet" type="text/css" href="Feed2.css"><link rel="stylesheet" type="text/css" href="SharedCss.css"><link rel="stylesheet" type="text/css" href="CM.css"><style>body {font-size: ' +
          fontSize +
          'px; margin: 0px !important} .content{overflow-x: scroll; padding: 0px !important;} .card{margin-left: -10px; margin-right: -10px} link {display: none !important;}</style><script src="textHighlighting.js"></script> <script src="jquery-3.5.1.min.js"></script></head><body><div class="content" style="' +
          paddingString +
          '">';

        html += contentHtml;

        html = html + '</div></body></html>';

        var that = this;

        this.setState({html: html});
      }
    }
  }

  render() {
    var baseUrl = 'web/';

    if (Platform.OS === 'android') {
      baseUrl = 'file:///android_asset/';
    }

    var shouldScalePageToFit = false;

    if (Platform.OS === 'android') {
      shouldScalePageToFit = true;
    }

    var webContentLayout = (
      <AMWebView
        style={{flex: 1}}
        source={{html: this.state.html, baseUrl: baseUrl}}
        originWhitelist={['file://']}
        useWebKit={true}
        allowFileAccess={true}
        scalesPageToFit={shouldScalePageToFit}
        javaScriptEnabled={true}
        allowsFullscreenVideo={true}
      />
    );

    if (Platform.OS === 'web') {
      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: this.state.html}}></div>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        {webContentLayout}
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
