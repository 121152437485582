import React, {createContext, useContext, useState, useEffect} from 'react';

import RemoteDataController from '../classes/RemoteDataController';
import DataController from '../classes/DataController';

const UpVotesContext = createContext(undefined);

const UpVotesProvider = ({user, children, startedLoadingDataInterval}) => {
  const [upVotes, setUpVotes] = useState([]);
  const isUpVote = id => {
    const upVoteForId = upVotes?.find(({ContentID}) => ContentID === id);
    if (!upVoteForId) {
      return null;
    }

    return Boolean(upVoteForId.UpVote);
  };

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    const getUpVotes = async () => {
      const localUpVotes = await DataController.getUpVotes();
      if (localUpVotes?.length > 0) {
        setUpVotes(localUpVotes);
      } else {
        const remoteUpVotes = await RemoteDataController.downloadUpVotes();
        setUpVotes(remoteUpVotes);
      }
    };
    getUpVotes();
  }, [user?.uid, startedLoadingDataInterval]);

  const updateUpVotesHandler = async (
    contentId,
    upVote,
    contentType,
    trust,
  ) => {
    // if (isUpVote(upVote.ContentID)) {
    //   return;
    // }

    // const prevUpVotes = [...upVotes];

    // Optimistically update the UI
    // setUpVotes(prev => [...prev, upVote]);

    try {
      const updatedUpVotes = await RemoteDataController.uploadUpVote(
        contentId,
        upVote,
        contentType,
        trust,
      );

      setUpVotes(updatedUpVotes);
    } catch (error) {
      // reset the state if the upload fails
      //   setUpVotes(prevUpVotes);
      console.error('Error updating upVotes', error);
    }
  };

  return (
    <UpVotesContext.Provider
      value={{
        upVotes,
        updateUpVotesHandler,
        isUpVote,
      }}>
      {children}
    </UpVotesContext.Provider>
  );
};

const useUpVotes = (type: string) => {
  const context = useContext(UpVotesContext);
  if (!context) {
    throw new Error('useUpVotes must be used within an UpVotesProvider');
  }

  const {upVotes, ...rest} = context;

  if (type && Array.isArray(upVotes)) {
    const filteredUpVotes = upVotes.filter(upVote => upVote.type === type);
    return {...rest, upVote: filteredUpVotes};
  }

  return context;
};

export {UpVotesProvider, useUpVotes};
