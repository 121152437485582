import React, {createContext, useContext, useState, useEffect} from 'react';

import PromotionsController from '../classes/PromotionsController';
import RemoteDataController from '../classes/RemoteDataController';

const PromotionsContext = createContext(undefined);

const PromotionsProvider = ({user, children, isDownloadingOnlineData}) => {
  const [promotion, setPromotion] = useState(null);
  const [isPromoCollected, setIsPromoCollected] = useState(false);
  const [promoCode, setPromoCode] = useState(null);

  const resetState = () => {
    setPromotion(null);
    setIsPromoCollected(false);
    setPromoCode(null);
  };

  const getPromotions = async () => {
    try {
      const localPromotions =
        await PromotionsController.getPromotionsFromFile();
      const localPromotionForUser = await PromotionsController.getPromotions(
        user.paraPassTrusts,
        localPromotions,
      );

      setPromotion(localPromotionForUser);
    } catch (error) {
      console.error('Error fetching promotions:', error);
    }
  };

  const getNewPromoCode = async () => {
    const isPromotionAvailable = PromotionsController.findIfPromotionAvailable(
      promotion,
      user,
      promoCode,
    );

    if (!promotion || !isPromotionAvailable) {
      return;
    }

    try {
      const newPromoCode = await RemoteDataController.getNewPromoCode(
        promotion.name,
        promotion.trust,
        promotion.id,
      );
      setPromoCode(newPromoCode);
    } catch (error) {
      console.error('Error fetching new promo code:', error);
    }
  };

  const getExistingPromoCode = async () => {
    try {
      const localPromoCode = await PromotionsController.getPromoCodeFromFile();
      setPromoCode(localPromoCode);
    } catch (error) {
      console.error('Error fetching existing promo code:', error);
    }
  };

  const promoCollectedHandler = () => setIsPromoCollected(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    getPromotions();
  }, [user, isDownloadingOnlineData]);

  useEffect(() => {
    if (!user || !promotion) {
      return;
    }
    getExistingPromoCode();
  }, [user, isDownloadingOnlineData, promotion]);

  useEffect(() => {
    resetState();
  }, [user]);

  const isPromoAvailable = PromotionsController.findIfPromotionAvailable(
    promotion,
    user,
    promoCode,
  );

  const contextValue = {
    promotion,
    isPromoAvailable,
    isPromoCollected,
    promoCollectedHandler,
    getNewPromoCode,
    promoCode,
  };

  return (
    <PromotionsContext.Provider value={contextValue}>
      {children}
    </PromotionsContext.Provider>
  );
};

const usePromotions = () => {
  const context = useContext(PromotionsContext);
  if (!context) {
    throw new Error('usePromotions must be used within a PromotionsProvider');
  }
  return context;
};

export {PromotionsProvider, usePromotions};
