import React from 'react';

import {
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';

import {colors} from '../classes/Colors';
import DataController from '../classes/DataController';

import DynamicText from './DynamicText';
import TrustTag from './TrustTag';

const getIconSource = type => {
  switch (type) {
    case 'PSIRFs': {
      return require('../assets/PSIRF/PSIRF_alert_pale_green.png');
    }
    default: {
      return require('../assets/Icon_PGD_Blue.png');
    }
  }
};

const DashboardNavCard = ({trust, onPress, type, title, description}) => {
  const fallbackTitle = `${DataController.getTrustDisplayName(trust)} ${type}`;
  const fallbackDescription = `See the latest ${type} from your trust`;
  const imageSource = getIconSource(type);
  const cardPadding = Platform.OS === 'web' ? 24 : 4;

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={styles.container}
      onPress={onPress}>
      <View style={[styles.content, {paddingVertical: cardPadding}]}>
        <Image style={styles.icon} source={imageSource} />
        <View style={styles.textContainer}>
          <TrustTag trust={trust} />
          {/* Styles passed as object so it can be interpreted by DynamicText */}
          <DynamicText
            style={{
              fontSize: 17,
              fontWeight: '800',
              marginBottom: 6,
              letterSpacing: -0.4,
              color: colors.Grey900,
            }}>
            {title ?? fallbackTitle}
          </DynamicText>
          <DynamicText
            style={{
              fontSize: 13,
              letterSpacing: -0.36,
              color: colors.Grey800,
            }}>
            {description ?? fallbackDescription}
          </DynamicText>
        </View>
      </View>
      <Image
        style={styles.disclosureIcon}
        source={require('../assets/Button_Disclosure_Right_Blue.png')}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    paddingLeft: 4,
    paddingRight: 14,
    paddingVertical: 10,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    maxWidth: '90%',
  },
  icon: {
    marginLeft: 1,
    marginTop: 1,
    width: 40,
    height: 40,
  },
  textContainer: {
    marginLeft: 8,
    maxWidth: '90%',
  },
  disclosureIcon: {
    width: 26,
    height: 26,
    marginLeft: 4,
  },
});

export default DashboardNavCard;
