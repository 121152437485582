import React from 'react';

import {StyleSheet, Text, View} from 'react-native';

import SSOTrustCell from './SSOTrustCell';
import {colors} from '../classes/Colors';

const SSOLogin = ({onError, onLoadStart, onLoadEnd, data}) => {
  return (
    <View style={[styles.container]}>
      <Text style={styles.title}>Login with your trust</Text>
      {data.map(({trust, config, redirects}, index) => (
        <SSOTrustCell
          key={trust + index}
          trust={trust}
          redirects={redirects}
          config={config}
          onError={onError}
          onLoadStart={onLoadStart}
          onLoadEnd={onLoadEnd}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 20,
    backgroundColor: colors.white,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 8,
    marginBottom: 10,
    color: colors.Grey900,
  },
  loading: {
    fontSize: 18,
    marginTop: 8,
    marginBottom: 10,
    color: colors.Grey900,
  },
});

export default SSOLogin;
