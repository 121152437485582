'use strict';

import React, {Component, PureComponent} from 'react';
import {View, Text, Platform, Image} from 'react-native';

import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';
import DynamicText from '../components/DynamicText';

var moment = require('moment');

export default class QuizCell extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    var quizSetName = '';
    var quizSetDate = '';
    var typeLayout = null;
    var pgdAcknowledgedLayout = null;
    var questionCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var borderLeftColor = colors.white;
    var borderLeftWidth = 0;

    if (this.props.quizSet != null) {
      quizSetDate = moment(this.props.quizSet.date).format('DD MMM YYYY');

      if (this.props.quizSet.type === 'topic') {
        quizSetName = this.props.quizSet.title;

        if (
          this.props.quizSet.trust != null &&
          this.props.quizSet.trust !== ''
        ) {
          var trust = this.props.quizSet.trust;

          typeLayout = (
            <View
              style={{
                justifyContent: 'center',
                paddingHorizontal: 6,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)}
              </Text>
            </View>
          );
        } else {
          typeLayout = (
            <Image
              style={{width: 38, height: 14}}
              source={require('../assets/Logo_JRCALC_Small.png')}
            />
          );
        }
      }

      if (this.props.quizSet.type === 'guideline') {
        quizSetName = this.props.quizSet.title;
        var sectionColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
            {this.props.quizSet.section}
          </DynamicText>
        );
      }

      if (this.props.quizSet.type === 'section') {
        quizSetName = this.props.quizSet.section;
        borderLeftWidth = 4;
        borderLeftColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        var sectionColor = DataController.getPrimaryColorForSection(
          this.props.quizSet.section,
        );
        typeLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
            {this.props.quizSet.section}
          </DynamicText>
        );
      }

      if (this.props.quizSet.type === 'standby') {
        quizSetName = this.props.quizSet.title;
        typeLayout = (
          <Image
            style={{width: 85, height: 13}}
            source={require('../assets/Logo_Standby_Small.png')}
          />
        );
      }

      if (this.props.quizSet.type === 'caseStudy') {
        quizSetName = this.props.quizSet.title;
        typeLayout = (
          <DynamicText
            style={{
              fontSize: 12,
              fontWeight: '500',
              color: colors.SectDefault,
            }}>
            Case Study
          </DynamicText>
        );
      }

      if (this.props.quizSet.questions != null) {
        questionCount = this.props.quizSet.questions.length;
      }
    }

    if (this.props.quiz != null) {
      questionCount = this.props.quiz.questions.length;
    }

    if (this.props.results != null) {
      for (
        var resultIndex = 0;
        resultIndex < this.props.results?.length;
        resultIndex++
      ) {
        var result = this.props.results[resultIndex];

        if (result.correct) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    }

    unansweredCount = questionCount - correctCount - incorrectCount;

    var typeDotSeparatorLayout = null;

    if (typeLayout != null) {
      typeDotSeparatorLayout = (
        <View
          style={{
            marginHorizontal: 6,
            width: 2,
            height: 2,
            backgroundColor: colors.Grey700,
            borderRadius: 1,
          }}
        />
      );
    }

    var quizTimeLayout = null;
    var statusLayout = null;
    var statusDotSeparatorLayout = null;

    if (this.props.quiz != null) {
      var dateString = moment(this.props.quiz.time).format('DD MMM YYYY');
      var timeString = moment(this.props.quiz.time).format('HH:mm');
    }

    if (
      this.props.quizSet != null &&
      this.props.quizSet.pgd === true &&
      this.props.user != null &&
      this.props.user.plusTrusts.length > 0
    ) {
      if (this.props.quiz == null) {
        // don't show authorised on individual quizzes incase confusing
        statusDotSeparatorLayout = (
          <View
            style={{
              marginHorizontal: 6,
              width: 2,
              height: 2,
              backgroundColor: colors.Grey700,
              borderRadius: 1,
            }}
          />
        );
        if (
          this.props.quizSet.pgdInfo != null &&
          this.props.quizSet.pgdInfo.acknowledged === true
        ) {
          statusLayout = (
            <DynamicText
              style={{fontSize: 12, fontWeight: '600', color: colors.Green500}}>
              Authorised
            </DynamicText>
          );
        } else {
          statusLayout = (
            <DynamicText
              style={{fontSize: 12, fontWeight: '600', color: colors.Red500}}>
              Unauthorised
            </DynamicText>
          );
        }
      }
    } else {
      if (correctCount >= questionCount) {
        statusDotSeparatorLayout = (
          <View
            style={{
              marginHorizontal: 6,
              width: 2,
              height: 2,
              backgroundColor: colors.Grey700,
              borderRadius: 1,
            }}
          />
        );
        statusLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '600', color: colors.Green500}}>
            Completed ({questionCount - unansweredCount} / {questionCount})
          </DynamicText>
        );
      } else if (
        (unansweredCount >= 0 && unansweredCount < questionCount) ||
        incorrectCount === questionCount
      ) {
        statusDotSeparatorLayout = (
          <View
            style={{
              marginHorizontal: 6,
              width: 2,
              height: 2,
              backgroundColor: colors.Grey700,
              borderRadius: 1,
            }}
          />
        );
        statusLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '600', color: '#E38307'}}>
            In Progress ({questionCount - unansweredCount} / {questionCount})
          </DynamicText>
        );
      } else if (unansweredCount === questionCount) {
        statusDotSeparatorLayout = (
          <View
            style={{
              marginHorizontal: 6,
              width: 2,
              height: 2,
              backgroundColor: colors.Grey700,
              borderRadius: 1,
            }}
          />
        );
        statusLayout = (
          <DynamicText
            style={{fontSize: 12, fontWeight: '600', color: '#858d94'}}>
            Not Started (0 / {questionCount})
          </DynamicText>
        );
      }
    }

    // highlight quiz set name
    let nameLayout = [];
    if (
      quizSetName.length > 0 &&
      this.props.searchText &&
      this.props.searchText.length > 0
    ) {
      let highlightedNameParts = quizSetName
        .toLowerCase()
        .split(this.props.searchText.toLowerCase());

      let index = 0;

      for (
        let highlightedNamePartIndex = 0;
        highlightedNamePartIndex < highlightedNameParts.length;
        highlightedNamePartIndex++
      ) {
        let highlightedNamePart =
          highlightedNameParts[highlightedNamePartIndex];

        if (highlightedNamePartIndex !== 0) {
          let correctCaseHighlight = quizSetName.substr(
            index,
            this.props.searchText.length,
          );

          nameLayout.push(
            <Text style={{backgroundColor: '#DDEBFD'}}>
              {correctCaseHighlight}
            </Text>,
          );
          index += this.props.searchText.length;
        }

        let correctCaseNamePart = quizSetName.substr(
          index,
          highlightedNamePart.length,
        );
        nameLayout.push(<Text>{correctCaseNamePart}</Text>);

        index += highlightedNamePart.length;
      }
    } else {
      nameLayout = <Text>{quizSetName}</Text>;
    }

    var infoLayout = (
      <View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {typeLayout}
          {/* TODO hidden ahead of changes coming in 2.4 */}
          {!this.props.fromAllResultsScreen && (
            <>
              {typeDotSeparatorLayout}
              <DynamicText style={{fontSize: 12, color: colors.Grey700}}>
                {quizSetDate}
              </DynamicText>
            </>
          )}
          {statusDotSeparatorLayout}
          {statusLayout}
        </View>

        <DynamicText
          style={{
            marginTop: 6,
            fontSize: 19,
            fontWeight: '600',
            letterSpacing: -0.04,
            color: colors.Grey900,
          }}>
          {nameLayout}
        </DynamicText>
        {quizTimeLayout}
      </View>
    );

    if (LayoutController.isCompact()) {
      return (
        <View
          style={{
            paddingLeft: 16,
            paddingRight: 12,
            paddingVertical: 15,
            borderBottomWidth: 1,
            borderBottomColor: colors.Grey100,
            borderLeftWidth: borderLeftWidth,
            borderLeftColor: borderLeftColor,
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1}}>{infoLayout}</View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            paddingLeft: 10,
            paddingRight: 6,
            paddingVertical: 15,
            borderBottomWidth: 1,
            borderColor: colors.Grey100,
            borderLeftWidth: borderLeftWidth,
            borderLeftColor: borderLeftColor,
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1}}>
              {infoLayout}
              {pgdAcknowledgedLayout}
            </View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </View>
      );
    }
  }
}
