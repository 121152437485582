/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  ActivityIndicator,
} from 'react-native';

import DataController from '../classes/DataController';
import RemoteDataController from '../classes/RemoteDataController';
import PlatformController from '../classes/PlatformController';

import {Modal} from '../classes/Modal';

import ScreenComponent from '../components/ScreenComponent';
import ProgressView from '../components/ProgressView';

import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';

var _ = require('lodash');
var moment = require('moment');
import {firebase} from '../classes/Firebase';
import ReadMore from 'react-native-read-more-text';

type Props = {};

type State = {
  quizSet: [],
  isSequential: boolean,
  sendingPGDAuthorisation: boolean,
  pgdQuizCompleted: boolean,
  shouldShowPGDDialogue: boolean,
  uxTestingVersion: string,
};

export default class QuizOverviewScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Overview'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var selectedQuizSet = this.props.navigation.getParam('quizSet', {});
      var updatedQuizSet = selectedQuizSet;

      for (
        let quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (quizSet.name === selectedQuizSet.name) {
          updatedQuizSet = quizSet;
          break;
        }
      }

      var uxTestingVersion = 'A';

      this.state = {
        quizSet: updatedQuizSet,
        isSequential: false,
        sendingPGDAuthorisation: false,
        pgdQuizCompleted: false,
        shouldShowPGDDialogue: false,
        uxTestingVersion: uxTestingVersion,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');

      var isSequential = false;

      if (this.state.quizSet.type === 'topic') {
        var topic = _.find(this.props.screenProps.topics, {
          PackName: this.state.quizSet.topicName,
        });

        if (topic != null && topic.Sequential === true) {
          isSequential = true;
        }
      }

      if (
        this.state.quizSet.type === 'standby' ||
        this.state.quizSet.type === 'caseStudy'
      ) {
        isSequential = true;
      }

      this.setState({isSequential: isSequential});

      this.checkPGDStatus();
      this.updatePGDAcknowledgedState();
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        optionsButtonTapped: that.optionsButtonTapped.bind(that),
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenProps.quizSets !== prevProps.screenProps.quizSets) {
      for (
        let quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (quizSet.name === this.state.quizSet.name) {
          this.setState({quizSet: quizSet}, function () {
            this.updatePGDAcknowledgedState();
          });
          break;
        }
      }
    }
  }

  optionsButtonTapped() {
    console.log('options');
  }

  viewResults() {
    // todo - look at pushing/replacing rather than navigating so that results screen updates with correct quizSet and data showing
    this.props.navigation.navigate(
      'QuizSetResults',
      {
        quizSet: this.state.quizSet,
      },
      this.state,
    );
  }

  startQuizTapped() {
    if (this.state.quizSet.pgd === true) {
      this.takeQuiz('PGD');
    } else if (this.state.isSequential === false) {
      this.takeQuiz('10 Questions');
    } else {
      this.takeQuiz('All Questions');
    }

    try {
      var from = this.props.navigation.getParam('from', 'unknown');
      firebase.analytics().logEvent('start_quiz_button_tapped', {
        uxTestingVersion: this.state.uxTestingVersion,
        from: from,
      });
    } catch (error) {
      console.log(error);
    }
  }

  rowQuizButtonTapped(quizMode: string) {
    this.takeQuiz(quizMode);

    try {
      var from = this.props.navigation.getParam('from', 'unknown');
      firebase.analytics().logEvent('start_quiz_button_tapped', {
        uxTestingVersion: this.state.uxTestingVersion,
        from: from,
      });
    } catch (error) {
      console.log(error);
    }
  }

  takeQuiz(quizMode: string) {
    var questionsForQuizSet = this.state.quizSet.questions.slice();

    if (questionsForQuizSet.length > 0) {
      var latestResultsForQuizSets =
        this.props.screenProps.latestResultsForQuizSets[
          this.state.quizSet.name
        ];
      var correctlyAnsweredQuestions = [];
      var incorrectlyAnsweredQuestions = [];
      var mandatoryQuestions = [];

      for (
        let questionIndex = 0;
        questionIndex < questionsForQuizSet.length;
        questionIndex++
      ) {
        let question = questionsForQuizSet[questionIndex];
        if (question.Mandatory) {
          mandatoryQuestions.push(question.Name);
        }
      }

      if (latestResultsForQuizSets == null) {
        latestResultsForQuizSets = [];
      }

      for (
        let resultIndex = 0;
        resultIndex < latestResultsForQuizSets.length;
        resultIndex++
      ) {
        var result = latestResultsForQuizSets[resultIndex];
        var questionName = result.packName + '-' + result.questionID;

        if (result.correct) {
          correctlyAnsweredQuestions.push(questionName);
        } else {
          incorrectlyAnsweredQuestions.push(questionName);
        }
      }

      if (this.state.isSequential) {
        questionsForQuizSet = _.sortBy(questionsForQuizSet, ['QuestionID']);
      } else {
        // sort so questions are ordered: mandatory questions, incorrect questions, unanswered questions, correctly answered questions (but questions in each of those categories are still shuffled from earlier)
        questionsForQuizSet.sort(function (question1, question2) {
          var question1Value = 2;
          var question2Value = 2;

          if (mandatoryQuestions.includes(question1.Name)) {
            question1Value = 0;
          } else if (incorrectlyAnsweredQuestions.includes(question1.Name)) {
            question1Value = 1;
          } else if (correctlyAnsweredQuestions.includes(question1.Name)) {
            question1Value = 3;
          }

          if (mandatoryQuestions.includes(question2.Name)) {
            question2Value = 0;
          } else if (incorrectlyAnsweredQuestions.includes(question2.Name)) {
            question2Value = 1;
          } else if (correctlyAnsweredQuestions.includes(question2.Name)) {
            question2Value = 3;
          }

          return question1Value - question2Value;
        });
      }

      var questionsForQuiz = questionsForQuizSet;

      if (quizMode === '10 Questions') {
        questionsForQuiz = questionsForQuizSet.slice(0, 10);
      } else if (quizMode === 'PGD') {
        questionsForQuiz = questionsForQuizSet.slice(0, 5);
      } else if (quizMode === 'Incorrect Questions') {
        questionsForQuiz = [];

        for (
          let quizSetQuestionIndex = 0;
          quizSetQuestionIndex < questionsForQuizSet.length;
          quizSetQuestionIndex++
        ) {
          var quizSetQuestion = questionsForQuizSet[quizSetQuestionIndex];

          if (incorrectlyAnsweredQuestions.includes(quizSetQuestion.Name)) {
            questionsForQuiz.push(quizSetQuestion);
          }
        }
      }

      if (this.state.isSequential === false) {
        // shuffle picked questions so mandatory questions aren't always first
        questionsForQuiz = _.shuffle(questionsForQuiz);
      }

      var now = new Date();
      var nowTimestamp = now.getTime();

      var questionIDsForQuiz = questionsForQuiz.map(function (question) {
        return question.PackName + '-' + question.QuestionID;
      });

      var quiz = {
        name: this.state.quizSet.name + '-' + nowTimestamp,
        time: nowTimestamp,
        quizSet: this.state.quizSet.name,
        quizMode: quizMode,
        questions: questionIDsForQuiz,
      };

      var from = this.props.navigation.getParam('from', 'Quiz Overview');

      this.props.navigation.navigate(
        'Quiz',
        {
          quiz: quiz,
          quizSet: this.state.quizSet,
          questions: questionsForQuiz,
          from: from,
        },
        this.state,
      );
    }
  }

  async checkPGDStatus() {
    if (
      this.state.quizSet.pgd === true &&
      this.state.quizSet.pgdInfo.acknowledged !== true
    ) {
      await RemoteDataController.downloadLatestSinglePGDAcknowledgement(
        this.props.screenProps.user.plusTrusts,
        this.props.screenProps.user.uid,
        this.state.quizSet.pgdInfo,
      );
      await this.props.screenProps.updatePGDAcknowledgements();
      this.props.screenProps.updateQuizSets(false);
    }
  }

  updatePGDAcknowledgedState() {
    var foundQuizAnsweredCorrectly = false;

    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.quizzes.length;
      quizIndex++
    ) {
      var quiz = this.props.screenProps.quizzes[quizIndex];

      if (quiz.quizSet === this.state.quizSet.name && quiz.quizMode === 'PGD') {
        var resultsForQuiz =
          this.props.screenProps.resultsForQuizzes[quiz.name];

        if (resultsForQuiz != null) {
          var correctCountForQuiz = 0;

          for (
            let quizResultIndex = 0;
            quizResultIndex < resultsForQuiz.length;
            quizResultIndex++
          ) {
            var result = resultsForQuiz[quizResultIndex];

            if (result.correct === true) {
              correctCountForQuiz++;
            }
          }

          if (this.state.quizSet.pma === true) {
            // PMA quizzes expire after 1 year - so ignore quizzes that are older than a year when deciding if result can be used
            let isOlderThan1Year =
              moment().diff(moment(quiz.time), 'years') > 0;
            if (isOlderThan1Year) {
              continue;
            }
          }

          if (correctCountForQuiz === 5) {
            foundQuizAnsweredCorrectly = true;
            break;
          }
        }
      }
    }

    if (foundQuizAnsweredCorrectly) {
      this.setState({pgdQuizCompleted: true});
    }
  }

  readPGDButtonTapped() {
    this.props.navigation.navigate(
      'PGDViewer',
      {
        glid: this.state.quizSet.pgdInfo.GLID,
      },
      this.state,
    );
  }

  async acknowledgePGD() {
    if (this.state.sendingPGDAuthorisation) {
      return;
    }

    if (
      this.props.screenProps.trustInfo.authorisationType === 'Manual' &&
      this.state.shouldShowPGDDialogue === false
    ) {
      this.setState({shouldShowPGDDialogue: true});
      return;
    }

    try {
      this.setState({sendingPGDAuthorisation: true});

      if (this.props.screenProps.user != null) {
        await RemoteDataController.acknowledgePGD(
          this.props.screenProps.user.plusTrusts,
          this.props.screenProps.user.uid,
          this.state.quizSet.pgdInfo,
        );
        await this.props.screenProps.updatePGDAcknowledgements();
        this.props.screenProps.updateQuizSets(false);
      }

      this.setState({
        sendingPGDAuthorisation: false,
        shouldShowPGDDialogue: false,
      });
    } catch (error) {
      // Alert.alert(error);
      console.log(error);
      this.setState({sendingPGDAuthorisation: false});
    }
  }

  render() {
    var quizDescriptionText = '';

    var questionCountText = '10';
    var questionPluralText = 's';

    if (this.state.quizSet.questions.length === 1) {
      questionCountText = '1';
      questionPluralText = '';
    } else if (this.state.quizSet.questions.length < 10) {
      questionCountText = this.state.quizSet.questions.length;
    }

    let pgdQuizType = 'PGD';
    if (this.state.quizSet && this.state.quizSet.pma === true) {
      pgdQuizType = 'PMA';
    }

    if (this.state.quizSet.type === 'topic') {
      quizDescriptionText =
        'Choose between a short quiz of ' +
        questionCountText +
        ' question' +
        questionPluralText +
        ' or a timed quiz of 5 minutes to practise this topic. New questions are added regularly.';

      for (
        var topicIndex = 0;
        topicIndex < this.props.screenProps.topics.length;
        topicIndex++
      ) {
        var aTopic = this.props.screenProps.topics[topicIndex];

        if (aTopic.PackName === this.state.quizSet.topicName) {
          if (aTopic.Description != null) {
            quizDescriptionText = aTopic.Description;
          }
        }
      }
    } else if (this.state.quizSet.type === 'guideline') {
      quizDescriptionText =
        'Choose between a short quiz of ' +
        questionCountText +
        ' question' +
        questionPluralText +
        ' or a timed quiz of 5 minutes to practise this guideline. New questions are added regularly.';
    } else if (this.state.quizSet.type === 'section') {
      quizDescriptionText =
        'Choose between a short quiz of ' +
        questionCountText +
        ' question' +
        questionPluralText +
        ' or a timed quiz of 5 minutes to practise this section. New questions are added regularly.';
    }

    var quizDescriptionLayout = null;

    if (quizDescriptionText !== '') {
      quizDescriptionLayout = (
        <ReadMore
          numberOfLines={7}
          renderTruncatedFooter={handlePress => (
            <Text
              style={{
                fontSize: 16,
                fontWeight: '600',
                color: colors.ButtonBlue,
                marginTop: 6,
              }}
              onPress={handlePress}>
              Read more
            </Text>
          )}
          renderRevealedFooter={handlePress => (
            <Text
              style={{
                fontSize: 16,
                fontWeight: '600',
                color: colors.ButtonBlue,
                marginTop: 6,
              }}
              onPress={handlePress}>
              Show less
            </Text>
          )}>
          <DynamicText
            style={{
              marginTop: 8,
              fontSize: 16,
              letterSpacing: -0.2,
            }}>
            {quizDescriptionText}
          </DynamicText>
        </ReadMore>
      );
    }

    var quizSetDateLayout = null;
    if (this.state.quizSet.date) {
      var quizSetDate = moment(this.state.quizSet.date).format('DD MMM YYYY');
      quizSetDateLayout = (
        <DynamicText
          style={{fontSize: 12, fontWeight: '600', color: colors.Grey700}}>
          {quizSetDate}
        </DynamicText>
      );
    }

    var questionCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;

    if (this.state.quizSet.questions != null) {
      questionCount = this.state.quizSet.questions.length;
    }

    var results =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];

    if (results != null) {
      for (var resultIndex = 0; resultIndex < results?.length; resultIndex++) {
        var result = results[resultIndex];

        if (result.correct) {
          correctCount++;
        } else {
          incorrectCount++;
        }
      }
    }

    unansweredCount = questionCount - correctCount - incorrectCount;

    var viewResultsLayout = null;

    if (correctCount > 0 || incorrectCount > 0) {
      viewResultsLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={this.viewResults.bind(this)}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingVertical: 16,
              backgroundColor: colors.white,
            }}>
            <DynamicText
              style={{
                marginRight: 4,
                fontSize: 17,
                fontWeight: '600',
                color: colors.Grey900,
              }}>
              View past results
            </DynamicText>
            <Image
              style={{width: 8, height: 13}}
              source={require('../assets/Button_SmallDisclosure_Right.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    var typeLayout = null;

    if (this.state.quizSet.type === 'topic') {
      let trust = null;
      if (this.state.quizSet.trust != null && this.state.quizSet.trust !== '') {
        trust = this.state.quizSet.trust;
      }

      if (this.state.quizSet.pgd === true && trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)} {pgdQuizType}
              </Text>
            </View>
          </View>
        );
      } else if (trust != null) {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                alignSelf: 'flex-start',
                justifyContent: 'center',
                paddingHorizontal: 4,
                height: 16,
                borderRadius: 2,
                backgroundColor: DataController.getColorForTrust(trust),
              }}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '500',
                  letterSpacing: -0.1,
                  color: colors.white,
                }}>
                {DataController.getTrustDisplayName(trust)}
              </Text>
            </View>
          </View>
        );
      } else {
        typeLayout = (
          <Image
            style={{width: 38, height: 14}}
            source={require('../assets/Logo_JRCALC_Small.png')}
          />
        );
      }
    } else if (this.state.quizSet.type === 'guideline') {
      var sectionColor = DataController.getPrimaryColorForSection(
        this.state.quizSet.section,
      );
      if (this.state.quizSet.trust != null) {
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View
            style={{
              alignSelf: 'flex-start',
              justifyContent: 'center',
              paddingHorizontal: 4,
              height: 16,
              borderRadius: 2,
              backgroundColor: DataController.getColorForTrust(trust),
            }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: '500',
                letterSpacing: -0.1,
                color: colors.white,
              }}>
              {DataController.getTrustDisplayName(trust)}
            </Text>
          </View>
        </View>;
      } else {
        typeLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image
              style={{width: 38, height: 14}}
              source={require('../assets/Logo_JRCALC_Small.png')}
            />
            <View
              style={{
                marginHorizontal: 6,
                width: 2,
                height: 2,
                backgroundColor: colors.Grey700,
                borderRadius: 1,
              }}
            />
            <DynamicText
              style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
              {this.state.quizSet.section}
            </DynamicText>
          </View>
        );
      }
    } else if (this.state.quizSet.type === 'section') {
      var sectionColor = DataController.getPrimaryColorForSection(
        this.state.quizSet.section,
      );
      typeLayout = (
        <DynamicText
          style={{fontSize: 12, fontWeight: '500', color: sectionColor}}>
          {this.state.quizSet.section}
        </DynamicText>
      );
    } else if (this.state.quizSet.type === 'standby') {
      typeLayout = (
        <Image
          style={{width: 85, height: 13}}
          source={require('../assets/Logo_Standby_Small.png')}
        />
      );
    } else if (this.state.quizSet.type === 'caseStudy') {
      typeLayout = (
        <DynamicText
          style={{fontSize: 12, fontWeight: '500', color: colors.SectDefault}}>
          Case Study
        </DynamicText>
      );
    }

    var pgdAuthoriseButtonLayout = null;
    var pgdAuthoriseTopLayout = null;

    if (this.state.quizSet.pgd && this.state.pgdQuizCompleted) {
      var sendingPGDAuthorisationLayout = null;
      if (this.state.sendingPGDAuthorisation) {
        sendingPGDAuthorisationLayout = (
          <ActivityIndicator
            style={{marginLeft: 10}}
            color={colors.white}
            animating={true}
            size={'small'}></ActivityIndicator>
        );
      }

      if (this.state.quizSet.pgdInfo.acknowledged) {
        pgdAuthoriseTopLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                marginHorizontal: 6,
                width: 2,
                height: 2,
                backgroundColor: colors.Grey700,
                borderRadius: 1,
              }}
            />
            <DynamicText
              style={{fontSize: 12, fontWeight: '500', color: colors.Green500}}>
              Authorised
            </DynamicText>
          </View>
        );
        pgdAuthoriseButtonLayout = (
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginTop: 15,
              marginHorizontal: 4,
              paddingVertical: 8,
              backgroundColor: '#F8F9F9',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              borderWidth: 1,
              borderColor: colors.Grey500,
            }}>
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.Grey700,
              }}>
              Authorised
            </DynamicText>
            <Image
              style={{
                width: 30,
                height: 30,
                marginLeft: 6,
                tintColor: colors.Grey700,
              }}
              source={require('../assets/Answer_Tick_Correct.png')}
            />
          </View>
        );
      } else {
        pgdAuthoriseTopLayout = (
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View
              style={{
                marginHorizontal: 6,
                width: 2,
                height: 2,
                backgroundColor: colors.Grey700,
                borderRadius: 1,
              }}
            />
            <DynamicText
              style={{fontSize: 12, fontWeight: '500', color: colors.Red500}}>
              Unauthorised
            </DynamicText>
          </View>
        );
        pgdAuthoriseButtonLayout = (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{flex: 1, marginHorizontal: 4}}
            onPress={this.acknowledgePGD.bind(this)}>
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                marginTop: 15,
                paddingVertical: 16,
                backgroundColor: colors.ButtonBlue,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
              }}>
              <DynamicText
                style={{
                  fontSize: 16,
                  fontWeight: '700',
                  letterSpacing: -0.39,
                  color: colors.white,
                }}>
                Authorise {pgdQuizType}
              </DynamicText>
              {sendingPGDAuthorisationLayout}
            </View>
          </TouchableOpacity>
        );
      }
    }

    var tenQuestionQuizLayout = null;

    if (
      this.state.uxTestingVersion === 'B' &&
      this.state.isSequential === false &&
      this.state.quizSet.pgd !== true
    ) {
      tenQuestionQuizLayout = (
        <TouchableOpacity
          onPress={this.rowQuizButtonTapped.bind(this, '10 Questions')}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 16,
              paddingLeft: 20,
              paddingRight: 10,
              borderBottomWidth: 1,
              borderColor: colors.Grey100,
            }}>
            <View style={{flex: 1}}>
              <DynamicText
                style={{
                  fontSize: 19,
                  fontWeight: '600',
                  letterSpacing: -0.49,
                  color: colors.Grey900,
                }}>
                {questionCountText} Question{questionPluralText}
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 6,
                  fontSize: 13,
                  letterSpacing: 0,
                  color: colors.Grey800,
                }}
                numberOfLines={0}>
                A short {questionCountText} question quiz to brush up your
                knowledge
              </DynamicText>
            </View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    var fiveMinuteQuizLayout = null;

    if (this.state.isSequential === false && this.state.quizSet.pgd !== true) {
      fiveMinuteQuizLayout = (
        <TouchableOpacity
          onPress={this.rowQuizButtonTapped.bind(this, '5 Minutes')}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 16,
              paddingLeft: 20,
              paddingRight: 10,
              borderBottomWidth: 1,
              borderColor: colors.Grey100,
            }}>
            <View style={{flex: 1}}>
              <DynamicText
                style={{
                  fontSize: 19,
                  fontWeight: '600',
                  letterSpacing: -0.49,
                  color: colors.Grey900,
                }}>
                5 Minutes
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 6,
                  fontSize: 13,
                  letterSpacing: 0,
                  color: colors.Grey800,
                }}
                numberOfLines={0}>
                Answer as many questions as you can in 5 minutes
              </DynamicText>
            </View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    var incorrectQuestionsQuizLayout = null;

    if (
      this.state.isSequential === false &&
      this.state.quizSet.pgd !== true &&
      incorrectCount > 0
    ) {
      incorrectQuestionsQuizLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1, marginHorizontal: 4}}
          onPress={this.rowQuizButtonTapped.bind(this, 'Incorrect Questions')}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginTop: 15,
              paddingVertical: 16,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Retry incorrect questions
            </DynamicText>
          </View>
        </TouchableOpacity>
      );
    }

    var allQuestionsQuizLayout = null;

    if (
      this.state.quizSet.pgd !== true &&
      (this.state.isSequential !== true || this.state.uxTestingVersion === 'B')
    ) {
      allQuestionsQuizLayout = (
        <TouchableOpacity
          onPress={this.rowQuizButtonTapped.bind(this, 'All Questions')}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 16,
              paddingLeft: 20,
              paddingRight: 10,
              borderBottomWidth: 1,
              borderColor: colors.Grey100,
            }}>
            <View style={{flex: 1}}>
              <DynamicText
                style={{
                  fontSize: 19,
                  fontWeight: '600',
                  letterSpacing: -0.49,
                  color: colors.Grey900,
                }}>
                All Questions
              </DynamicText>
              <DynamicText
                style={{
                  marginTop: 6,
                  fontSize: 13,
                  letterSpacing: 0,
                  color: colors.Grey800,
                }}
                numberOfLines={0}>
                Work your way through all questions
              </DynamicText>
            </View>
            <Image
              style={{width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    var pgdQuizLayout = null;
    var readPGDButtonLayout = null;

    if (this.state.quizSet.pgd === true) {
      readPGDButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1, marginHorizontal: 4}}
          onPress={this.readPGDButtonTapped.bind(this)}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              marginTop: 15,
              paddingVertical: 16,
              borderWidth: 1,
              borderColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <DynamicText
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Read up on this {pgdQuizType}
            </DynamicText>
          </View>
        </TouchableOpacity>
      );
    }

    var pgdDialogueMessage = `This ${pgdQuizType} will now appear as authorised in both your ParaPass app and JRCALC PLUS app. Continue to see your results for this quiz.`;
    var pgdDialogueButtonText = 'Continue';
    var authorisationText = '';

    if (this.props.screenProps.trustInfo.authorisationType === 'Manual') {
      pgdDialogueMessage =
        'You are now able to authorise this PGD through the button below. Please note that authorising the PGD in ParaPass will mean that the PGD will appear as authorised in your JRCALC PLUS app.';
      pgdDialogueButtonText = `Acknowledge ${pgdQuizType}`;
      authorisationText = this.props.screenProps.trustInfo.authorisationText;
      if (
        this.state.quizSet.pma === true &&
        this.props.screenProps.trustInfo.pmaAuthorisationText
      ) {
        authorisationText =
          this.props.screenProps.trustInfo.pmaAuthorisationText;
      }
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView styles={{flex: 1}}>
          <View
            style={{
              alignItems: 'stretch',
              paddingHorizontal: 20,
              paddingVertical: 15,
              backgroundColor: colors.white,
              borderBottomWidth: 1,
              borderBottomColor: colors.Grey200,
            }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {typeLayout}
              {typeLayout != null ? (
                <View
                  style={{
                    marginHorizontal: 6,
                    width: 2,
                    height: 2,
                    backgroundColor: colors.Grey700,
                    borderRadius: 1,
                  }}
                />
              ) : null}
              {quizSetDateLayout}
              {pgdAuthoriseTopLayout}
            </View>
            <DynamicText
              style={{
                marginTop: 10,
                fontSize: 23,
                fontWeight: 'bold',
                letterSpacing: -0.4,
                color: colors.Grey900,
              }}>
              {this.state.quizSet.title}
            </DynamicText>
            {quizDescriptionLayout}

            {pgdAuthoriseButtonLayout}

            {this.state.uxTestingVersion === 'A' ? (
              <TouchableOpacity
                activeOpacity={0.7}
                style={{flex: 1, marginHorizontal: 4}}
                onPress={this.startQuizTapped.bind(this)}>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: 15,
                    paddingVertical: 16,
                    backgroundColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 8,
                  }}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    Start quiz
                  </DynamicText>
                </View>
              </TouchableOpacity>
            ) : null}

            {readPGDButtonLayout}

            {incorrectQuestionsQuizLayout}
          </View>

          <View style={{height: 15, backgroundColor: colors.Grey100}} />

          <View
            style={{
              alignItems: 'stretch',
              paddingHorizontal: 20,
              paddingTop: 10,
              paddingBottom: 15,
              backgroundColor: colors.white,
              borderBottomWidth: 1,
              borderBottomColor: colors.Grey300,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <DynamicText
                style={{
                  fontSize: 13,
                  fontWeight: '700',
                  color: colors.Grey900,
                }}>
                Quiz progress
              </DynamicText>
            </View>

            <ProgressView
              style={{marginTop: 12, height: 4, overflow: 'hidden'}}
              correctAnswers={correctCount}
              incorrectAnswers={incorrectCount}
              unansweredQuestions={unansweredCount}
            />

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <View>
                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                  <DynamicText
                    style={{
                      fontSize: 34,
                      fontWeight: '700',
                      color: colors.Green500,
                    }}>
                    {correctCount}
                  </DynamicText>
                  <DynamicText
                    style={{
                      marginBottom: 8,
                      fontSize: 11,
                      fontWeight: '600',
                      color: colors.Grey800,
                    }}>
                    {' '}
                    / {questionCount}
                  </DynamicText>
                </View>
                <DynamicText
                  style={{
                    fontSize: 11,
                    fontWeight: '600',
                    color: colors.Grey900,
                  }}>
                  Correct
                </DynamicText>
              </View>

              <View>
                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                  <DynamicText
                    style={{
                      fontSize: 34,
                      fontWeight: '700',
                      color: colors.Red500,
                    }}>
                    {incorrectCount}
                  </DynamicText>
                </View>
                <DynamicText
                  style={{
                    fontSize: 11,
                    fontWeight: '600',
                    color: colors.Grey900,
                  }}>
                  Incorrect
                </DynamicText>
              </View>

              <View>
                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                  <DynamicText
                    style={{
                      fontSize: 34,
                      fontWeight: '700',
                      color: colors.Grey700,
                    }}>
                    {unansweredCount}
                  </DynamicText>
                </View>
                <DynamicText
                  style={{
                    fontSize: 11,
                    fontWeight: '600',
                    color: colors.Grey900,
                  }}>
                  Unanswered
                </DynamicText>
              </View>
            </View>
          </View>
          {viewResultsLayout}

          <View style={{height: 15, backgroundColor: colors.Grey100}} />

          {tenQuestionQuizLayout != null ||
          pgdQuizLayout != null ||
          fiveMinuteQuizLayout != null ||
          allQuestionsQuizLayout != null ? (
            <View style={{marginBottom: 20, backgroundColor: colors.white}}>
              {this.state.uxTestingVersion === 'A' ? (
                <DynamicText
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 14,
                    fontSize: 13,
                    fontWeight: '700',
                    letterSpacing: -0.3,
                    color: colors.Grey800,
                  }}>
                  Other quizzes
                </DynamicText>
              ) : null}
              <View style={{height: 1, backgroundColor: colors.Grey100}} />

              {tenQuestionQuizLayout}

              {pgdQuizLayout}

              {fiveMinuteQuizLayout}

              {allQuestionsQuizLayout}
            </View>
          ) : null}
        </ScrollView>

        <Modal
          visible={this.state.shouldShowPGDDialogue}
          onBackButtonPress={() =>
            this.setState({shouldShowPGDDialogue: false})
          }
          presentationStyle="fullScreen"
          onDismiss={() => this.setState({shouldShowPGDDialogue: false})}
          ons
          animationType="slide"
          style={{flex: 1}}>
          <View style={{flex: 1, backgroundColor: colors.Grey300}}>
            <View
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                height: PlatformController.getTopInset() + 44,
                backgroundColor: colors.NavBar,
              }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: 44,
                }}
                onPress={() => this.setState({shouldShowPGDDialogue: false})}>
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 16,
                    paddingHorizontal: 16,
                  }}>
                  Close
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                margin: 10,
                backgroundColor: colors.white,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
              }}>
              <ScrollView
                style={{flex: 1}}
                contentContainerStyle={{
                  paddingHorizontal: 10,
                  paddingVertical: 16,
                }}>
                <DynamicText
                  style={{
                    fontSize: 20,
                    fontWeight: '600',
                    textAlign: 'center',
                    letterSpacing: -0.4,
                    color: colors.Grey900,
                  }}>
                  You have successfully passed this {pgdQuizType} quiz.
                </DynamicText>
                <DynamicText
                  style={{
                    marginTop: 8,
                    fontSize: 16,
                    textAlign: 'center',
                    letterSpacing: -0.24,
                    color: colors.Grey900,
                  }}>
                  {pgdDialogueMessage}
                </DynamicText>

                {authorisationText !== '' ? (
                  <View
                    style={{
                      marginTop: 16,
                      padding: 9,
                      backgroundColor: colors.DisclaimerRed,
                      borderWidth: 1,
                      borderRadius: 3,
                      borderColor: 'red',
                    }}>
                    <Text
                      style={{
                        fontSize: 15,
                        color: colors.Red700,
                        lineHeight: 20,
                      }}>
                      {authorisationText}
                    </Text>
                  </View>
                ) : null}
              </ScrollView>
              <TouchableOpacity
                style={{margin: 10}}
                activeOpacity={0.7}
                onPress={this.acknowledgePGD.bind(this)}>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 0,
                    paddingVertical: 16,
                    backgroundColor: colors.ButtonBlue,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 6,
                    shadowOffset: {width: 0, height: 1},
                    shadowRadius: 1,
                    shadowColor: 'black',
                    shadowOpacity: 0.2,
                  }}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.white,
                    }}>
                    {pgdDialogueButtonText}
                  </DynamicText>
                  {sendingPGDAuthorisationLayout}
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
