/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
  TextInput,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import BookCell from '../components/BookCell';
import DynamicText from '../components/DynamicText';
import FilterChip from '../components/FilterChip';
import PSIRFSearchCard from '../components/PSIRF/PSIRFSearchCard';
import {colors} from '../classes/Colors';
import PlatformController from '../classes/PlatformController';
import DataController from '../classes/DataController';
import {firebase} from '../classes/Firebase';

var _ = require('lodash');

type Props = {};

type State = {
  searchResultItems: [],
  searchText: string,
  selectedSearchFilter: string,
  onlyShowStartingFilter: boolean,
  recentSearchPhrases: [],
  recentSearchItems: [],
};

export default class SearchScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      animationEnabled: false,
      title: '',
      headerShown: false,
    };
  };

  constructor(props: Props) {
    super(props);
    if (_.isEmpty(this.props.initialState)) {
      let startingFilter = this.props.navigation.getParam(
        'startingFilter',
        null,
      );
      let onlyShowStartingFilter = false;

      if (startingFilter == null) {
        startingFilter = 'All';
      } else {
        onlyShowStartingFilter = true;
      }

      this.state = {
        searchResultItems: [],
        searchText: '',
        selectedSearchFilter: startingFilter,
        onlyShowStartingFilter: onlyShowStartingFilter,
        recentSearchPhrases: [],
        recentSearchItems: [],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      if (this.searchInput != null) {
        this.searchInput.focus();
      }
      this.updateRecentSearchPhrasesAndItems();
    }
  }

  async updateRecentSearchPhrasesAndItems() {
    let recentSearchPhrases = await DataController.getRecentSearchPhrases();
    let recentSearchItems = await DataController.getRecentSearchItems();

    this.setState({recentSearchPhrases, recentSearchItems});
  }

  async clearRecentSearchPhrases() {
    await DataController.clearRecentSearchPhrases();
    this.updateRecentSearchPhrasesAndItems();
  }

  async clearRecentSearchItems() {
    await DataController.clearRecentSearchItems();
    this.updateRecentSearchPhrasesAndItems();
  }

  searchTextDidChange(text: string) {
    this.setState({searchText: text}, () => {
      this.filterResults(text);
    });
  }

  clearSearch() {
    this.searchTextDidChange('');
  }

  doesSearchItemBelongToFilter(item) {
    const filter = this.state.selectedSearchFilter;

    // If 'All' is selected, show everything
    if (filter === 'All') {
      return true;
    }

    if (item.book) {
      return filter === 'Book';
    } else if (item.DisplayTitle != null) {
      return filter === 'PSIRF';
    } else {
      // Handle different quizSet cases
      if (filter === 'Quiz') {
        return (
          item.type === 'guideline' ||
          item.type === 'topic' ||
          item.type === 'section'
        );
      } else if (filter === 'Standby CPD') {
        return item.type === 'standby';
      } else if (filter === 'Case Study') {
        return item.type === 'caseStudy';
      } else if (filter === 'PGD') {
        return item.pgd === true && item.pma !== true;
      } else if (filter === 'PMA') {
        return item.pma === true;
      } else if (filter === 'Book') {
        return false; // Items without 'book' should return false
      } else if (filter === 'PSIRF') {
        return false; // Items without 'PSIRF' should return false
      }
    }

    return true;
  }

  filterResults(text: string) {
    let lowercaseText = text.toLowerCase();

    var quizSets = this.props.screenProps.quizSets.slice();
    var books = this.props.screenProps.books.slice();
    const psirfs = this.props.screenProps.psirfs.slice();
    quizSets = _.orderBy(quizSets, ['title'], ['asc']);

    var filteredMatches = [];
    var exactMatches = [];

    if (text.length > 0) {
      for (let psirfIndex = 0; psirfIndex < psirfs.length; psirfIndex++) {
        if (
          this.state.selectedSearchFilter === 'PSIRF' ||
          this.state.selectedSearchFilter === 'All'
        ) {
          var psirf = psirfs[psirfIndex];

          let score = 0;
          let titleMatch = false;

          if (
            psirf.Name.toLowerCase() === lowercaseText ||
            psirf.DisplayTitle.toLowerCase() === lowercaseText
          ) {
            // exactMatches.push({score: 0, type: 'quizSet', item: quizSet});
            score += 100;
            titleMatch = true;
          } else if (
            psirf.Name.toLowerCase().includes(lowercaseText) ||
            psirf.DisplayTitle.toLowerCase().includes(lowercaseText)
          ) {
            // filteredMatches.push({score: 0, type: 'quizSet', item: quizSet});
            score += 1;
            titleMatch = true;
          }

          if (score > 0) {
            filteredMatches.push({
              score: score,
              titleMatch: titleMatch,
              type: 'psirf',
              item: psirf,
            });
          }
        }
      }

      for (
        let quizSetIndex = 0;
        quizSetIndex < quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = quizSets[quizSetIndex];

        // filter quiz sets based on search filters
        if (this.state.selectedSearchFilter === 'Book') {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Quiz' &&
          quizSet.type !== 'guideline' &&
          quizSet.type !== 'topic' &&
          quizSet.type !== 'section'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Standby CPD' &&
          quizSet.type !== 'standby'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Case Study' &&
          quizSet.type !== 'caseStudy'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'PGD' &&
          (quizSet.pgd !== true || quizSet.pma === true)
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'PMA' &&
          quizSet.pma !== true
        ) {
          continue;
        }

        if (this.doesSearchItemBelongToFilter(quizSet) === false) {
          continue;
        }

        let score = 0;
        let titleMatch = false;

        if (
          quizSet.name.toLowerCase() === lowercaseText ||
          (quizSet.title != null &&
            quizSet.title.toLowerCase() === lowercaseText)
        ) {
          // exactMatches.push({score: 0, type: 'quizSet', item: quizSet});
          score += 100;
          titleMatch = true;
        } else if (
          quizSet.name.toLowerCase().includes(lowercaseText) ||
          (quizSet.title != null &&
            quizSet.title.toLowerCase().includes(lowercaseText))
        ) {
          // filteredMatches.push({score: 0, type: 'quizSet', item: quizSet});
          score += 1;
          titleMatch = true;
        }
        if (quizSet.guideline != null) {
          for (
            let guidelineIndex = 0;
            guidelineIndex < this.props.screenProps.guidelines.length;
            guidelineIndex++
          ) {
            let guideline = this.props.screenProps.guidelines[guidelineIndex];
            if (guideline.GLID === quizSet.guideline) {
              if (
                guideline.IndexWords != null &&
                guideline.IndexWords.includes(lowercaseText)
              ) {
                score += 1;
              }
            }
          }
        }

        if (score > 0) {
          filteredMatches.push({
            score: score,
            titleMatch: titleMatch,
            type: 'quizSet',
            item: quizSet,
          });
        }
      }

      if (this.props.screenProps.showBooksTab) {
        for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
          if (
            this.state.selectedSearchFilter !== 'All' &&
            this.state.selectedSearchFilter !== 'Book'
          ) {
            continue;
          }

          let book = books[bookIndex];

          // if searching inside a particular book - skip all other books
          let bookToSearch = this.props.navigation.getParam(
            'bookToSearch',
            null,
          );
          if (bookToSearch != null && book.ID !== bookToSearch) {
            continue;
          }

          for (
            let chapterIndex = 0;
            chapterIndex < book.chapters.length;
            chapterIndex++
          ) {
            let chapter = book.chapters[chapterIndex];

            let score = 0;
            let titleMatch = false;

            if (
              chapter.name != null &&
              chapter.name.toLowerCase() === text.toLowerCase()
            ) {
              // exactMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionIndex: 0}});
              score += 100;
              titleMatch = true;
            } else if (
              chapter.name != null &&
              chapter.name.toLowerCase().includes(text.toLowerCase())
            ) {
              // filteredMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionIndex: 0}});
              score += 1;
              titleMatch = true;
            }

            if (score > 0) {
              filteredMatches.push({
                score: score,
                titleMatch: titleMatch,
                type: 'book',
                item: {
                  book: book,
                  chapterName: chapter.name,
                  chapterNumber: chapter.chapter,
                  chapterIndex: chapterIndex,
                  sectionIndex: 0,
                },
              });
            }

            for (
              let sectionIndex = 0;
              sectionIndex < chapter.sections.length;
              sectionIndex++
            ) {
              let section = chapter.sections[sectionIndex];

              let score = 0;
              let titleMatch = false;
              let pageMatches = 0;

              if (
                section.name != null &&
                section.name.toLowerCase() === text.toLowerCase()
              ) {
                // exactMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionName: section.name, sectionIndex: sectionIndex}});
                score += 100;
                titleMatch = true;
              } else if (
                section.name != null &&
                section.name.toLowerCase().includes(text.toLowerCase())
              ) {
                // filteredMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionName: section.name, sectionIndex: sectionIndex}});
                score += 1;
                titleMatch = true;
              }

              if (section.html != null) {
                let match;
                var re = new RegExp(text, 'gi');
                var sectionHtmlLowercase = section.html.toLowerCase();
                if (text.length > 0) {
                  while ((match = re.exec(sectionHtmlLowercase)) != null) {
                    score += 1;
                    pageMatches += 1;
                  }
                }
              }

              // if (section.html != null && section.html.toLowerCase().includes(text.toLowerCase())) {
              //   score += 1;
              //   pageMatches += 1;
              // }

              if (score > 0) {
                filteredMatches.push({
                  score: score,
                  titleMatch: titleMatch,
                  pageMatches: pageMatches,
                  type: 'book',
                  item: {
                    book: book,
                    chapterName: chapter.name,
                    chapterNumber: chapter.chapter,
                    chapterIndex: chapterIndex,
                    sectionName: section.name,
                    sectionIndex: sectionIndex,
                  },
                });
              }
            }
          }
        }
      }
    }

    // filteredMatches = exactMatches.concat(filteredMatches);
    filteredMatches = _.orderBy(
      filteredMatches,
      ['titleMatch', 'score'],
      ['desc', 'desc'],
    );

    this.setState({searchResultItems: filteredMatches});

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }
  }

  renderSearchResultItem({item}) {
    if (item.type === 'book') {
      var bookItem = item.item;

      return (
        <TouchableOpacity onPress={this.goToBookSection.bind(this, bookItem)}>
          <BookCell
            key={`${bookItem.book.ID}-${bookItem.chapterIndex}-${bookItem.sectionIndex}`}
            bookName={bookItem.book.name}
            chapterName={bookItem.chapterName}
            chapterNumber={bookItem.chapterNumber}
            sectionName={bookItem.sectionName}
            searchText={this.state.searchText}
            titleMatch={item.titleMatch}
            pageMatches={item.pageMatches}
            fontSize={this.props.screenProps.fontSize}
          />
        </TouchableOpacity>
      );
    } else if (item.type === 'psirf') {
      const {item: psirf} = item;
      return (
        <PSIRFSearchCard
          key={psirf.ID}
          title={psirf.Name}
          subtitle={psirf.DisplayTitle}
          onPress={() => this.goToPsirf(psirf)}
          id={psirf.ID}
        />
      );
    } else {
      // item is a quiz set
      var quizSet = item.item;

      var results =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      var inProgress = false;
      if (
        this.props.screenProps.inProgressQuizzes.find(
          aQuizSet => aQuizSet.quizSet === quizSet.name,
        )
      ) {
        inProgress = true;
      }

      return (
        <TouchableOpacity
          onPress={this.goToQuizOverview.bind(
            this,
            quizSet,
            undefined,
            this.props.navigation,
          )}>
          <QuizCell
            key={quizSet.name}
            quizSet={quizSet}
            results={results}
            searchText={this.state.searchText}
            inProgress={inProgress}
            fontSize={this.props.screenProps.fontSize}
            user={this.props.screenProps.user}
          />
        </TouchableOpacity>
      );
    }
  }

  async goToQuizOverview(quizSet: Object) {
    await DataController.saveRecentSearchItem(quizSet);

    if (this.state.searchText.length > 2) {
      await DataController.saveRecentSearchPhrase(this.state.searchText);
    }

    this.updateRecentSearchPhrasesAndItems();

    if (quizSet.type === 'standby') {
      var selectedIssue = null;

      for (
        var issueIndex = 0;
        issueIndex < this.props.screenProps.issues.length;
        issueIndex++
      ) {
        var issue = this.props.screenProps.issues[issueIndex];

        if (issue.Name === quizSet.issueName) {
          selectedIssue = issue;
        }
      }

      this.props.navigation.navigate(
        'StandbyVolume',
        {
          quizSet: quizSet,
          issue: selectedIssue,
          from: 'Search',
        },
        this.state,
      );
    } else if (quizSet.type === 'caseStudy') {
      var selectedCaseStudy = {};

      for (
        var caseStudyIndex = 0;
        caseStudyIndex < this.props.screenProps.caseStudies.length;
        caseStudyIndex++
      ) {
        var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

        if (caseStudy.Name === quizSet.caseStudy) {
          selectedCaseStudy = caseStudy;
        }
      }

      this.props.navigation.navigate(
        'CaseStudyOverview',
        {
          quizSet: quizSet,
          caseStudy: selectedCaseStudy,
          from: 'Search',
        },
        this.state,
      );
    } else {
      this.props.navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizSet,
          from: 'Search',
        },
        this.state,
      );
    }

    try {
      firebase
        .analytics()
        .logEvent('search', {search_term: this.state.searchText});
    } catch (error) {
      console.log(error);
    }
  }

  async goToBookSection(item) {
    if (
      item.book != null &&
      item.chapterIndex != null &&
      item.sectionIndex != null
    ) {
      await DataController.saveRecentSearchItem(item);

      if (this.state.searchText.length > 2) {
        await DataController.saveRecentSearchPhrase(this.state.searchText);
      }

      this.updateRecentSearchPhrasesAndItems();

      this.props.navigation.navigate(
        'BookReading',
        {
          book: item.book,
          chapterIndex: item.chapterIndex,
          sectionIndex: item.sectionIndex,
          searchTerm: this.state.searchText,
          from: 'Search',
        },
        this.state,
      );
    }

    try {
      firebase
        .analytics()
        .logEvent('search', {search_term: this.state.searchText});
    } catch (error) {
      console.log(error);
    }
  }

  goToQuizOverview(quizSet: Object, from: string, navigation) {
    const nav = navigation || this?.props?.navigation;
    console.log(navigation);
    if (quizSet.type === 'standby') {
      var selectedIssue = null;

      for (
        var issueIndex = 0;
        issueIndex < this.props.screenProps.issues.length;
        issueIndex++
      ) {
        var issue = this.props.screenProps.issues[issueIndex];

        if (issue.Name === quizSet.issueName) {
          selectedIssue = issue;
        }
      }

      navigation.navigate(
        'StandbyVolume',
        {
          quizSet: quizSet,
          issue: selectedIssue,
          from,
        },
        this.state,
      );
    } else if (quizSet.type === 'caseStudy') {
      var selectedCaseStudy = {};

      for (
        var caseStudyIndex = 0;
        caseStudyIndex < this.props.screenProps.caseStudies.length;
        caseStudyIndex++
      ) {
        var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

        if (caseStudy.Name === quizSet.caseStudy) {
          selectedCaseStudy = caseStudy;
        }
      }

      navigation.navigate(
        'CaseStudyOverview',
        {
          quizSet: quizSet,
          caseStudy: selectedCaseStudy,
          from,
        },
        this.state,
      );
    } else {
      navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizSet,
          from,
        },
        this.state,
      );
    }
  }

  async goToPsirf(psirf) {
    await DataController.saveRecentSearchItem(psirf);

    if (this.state.searchText.length > 2) {
      await DataController.saveRecentSearchPhrase(this.state.searchText);
    }

    this.updateRecentSearchPhrasesAndItems();

    try {
      firebase.analytics().logEvent('search', {
        search_term: this.state.searchText,
        psirfId: psirf.ID,
      });
    } catch (error) {
      console.log(error);
    }

    this.props.navigation.navigate(
      'PSIRFDashboard',
      {
        data: this.props.screenProps.psirfs,
        quizOnPressHandler: (quizSet, from) =>
          this.goToQuizOverview(quizSet, from, this.props.navigation),
      },
      this.state,
    );
  }

  searchFilterTapped(name: string) {
    var that = this;

    this.setState({selectedSearchFilter: name}, () => {
      that.filterResults(that.state.searchText);
    });
  }

  keyExtractor(item, index) {
    return item.item.name;
  }

  renderFilterChip = filter => {
    return (
      <FilterChip
        key={filter}
        name={filter}
        selected={this.state.selectedSearchFilter === filter}
        onPress={this.searchFilterTapped.bind(this)}
      />
    );
  };

  shouldShowFilter = filter => {
    const {onlyShowStartingFilter, selectedSearchFilter} = this.state;
    const {showBooksTab} = this.props.screenProps;

    if (onlyShowStartingFilter) {
      return selectedSearchFilter === filter;
    }

    if (filter === 'Book') {
      return showBooksTab;
    }

    return true;
  };

  renderFilterChips = () => {
    const filters = [
      'All',
      'Quiz',
      'Standby CPD',
      'Case Study',
      'PGD',
      'PMA',
      'PSIRF',
      'Book',
    ];

    return filters.filter(this.shouldShowFilter).map(this.renderFilterChip);
  };

  render() {
    var searchTextInputHeight = 32;

    if (Platform.OS === 'android') {
      searchTextInputHeight = 42;
    }

    var clearSearchLayout = null;

    if (this.state.searchText?.length > 0) {
      clearSearchLayout = (
        <View>
          <TouchableOpacity onPress={this.clearSearch.bind(this)}>
            <Image
              style={styles.clearSearchIcon}
              source={require('../assets/SearchBar_Close.png')}
            />
          </TouchableOpacity>
        </View>
      );
    }

    let recentSearchItemsLayout = [];

    for (
      let recentSearchItemIndex = 0;
      recentSearchItemIndex < this.state.recentSearchItems.length;
      recentSearchItemIndex++
    ) {
      let recentSearchItem =
        this.state.recentSearchItems[recentSearchItemIndex];

      if (this.doesSearchItemBelongToFilter(recentSearchItem)) {
        let recentSearchItemType = 'quizSet';

        if (recentSearchItem.book != null) {
          recentSearchItemType = 'book';
        }

        if (recentSearchItem.DisplayTitle != null) {
          recentSearchItemType = 'psirf';
        }

        recentSearchItemsLayout.push(
          this.renderSearchResultItem({
            item: {type: recentSearchItemType, item: recentSearchItem},
          }),
        );
      }
    }

    return (
      <ScreenComponent style={styles.container}>
        <View style={styles.searchBarContainer}>
          <View style={styles.searchInputContainer}>
            <Image
              style={styles.searchIcon}
              source={require('../assets/SearchBar_Search.png')}
            />

            <TextInput
              ref={ref => (this.searchInput = ref)}
              style={[styles.searchTextInput, {height: searchTextInputHeight}]}
              underlineColorAndroid="transparent"
              onChangeText={text => this.searchTextDidChange(text)}
              value={this.state.searchText}
              placeholder={'Search'}
              placeholderTextColor={'#BBB'}
              autoCapitalize={'none'}
              autoCorrect={false}
              returnKeyType={'search'}
            />
            {clearSearchLayout}
          </View>
          <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
            <Text style={styles.cancelText}>Cancel</Text>
          </TouchableOpacity>
        </View>

        {Boolean(this.state.searchText.length) > 0 && (
          <ScrollView
            style={styles.filterScrollView}
            horizontal={true}
            contentContainerStyle={styles.filterContentContainer}>
            {this.renderFilterChips()}
          </ScrollView>
        )}

        {this.state.searchText.length > 0 ? (
          <DynamicText
            style={{
              fontSize: 13,
              fontWeight: '600',
              color: colors.Grey900,
              marginHorizontal: 10,
              marginBottom: 16,
            }}>
            {this.state.searchResultItems.length} result
            {this.state.searchResultItems.length !== 1 ? 's' : ''} for '
            {this.state.searchText}'
          </DynamicText>
        ) : null}

        {this.state.searchText.length > 0 ? (
          <View style={styles.searchResultContainer}>
            <FlatList
              style={styles.searchResultList}
              data={this.state.searchResultItems}
              extraData={[
                this.props.screenProps.latestResultsForQuizSets,
                this.props.screenProps.fontSize,
              ]}
              keyExtractor={this.keyExtractor.bind(this)}
              renderItem={this.renderSearchResultItem.bind(this)}
            />
          </View>
        ) : (
          <ScrollView>
            {this.state.recentSearchPhrases.length > 0 ? (
              <View>
                <Text style={styles.recentSearchesTitle}>Recent searches</Text>

                <View style={styles.recentSearchesContainer}>
                  {this.state.recentSearchPhrases.map((item, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        activeOpacity={0.8}
                        style={styles.recentSearchItem}
                        onPress={() => this.searchTextDidChange(item)}>
                        <Text style={styles.recentSearchItemText}>{item}</Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>

                <View style={styles.clearRecentSearchesContainer}>
                  <TouchableOpacity
                    onPress={this.clearRecentSearchPhrases.bind(this)}>
                    <Text style={styles.clearRecentSearchesText}>Clear</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}

            {recentSearchItemsLayout.length > 0 ? (
              <View>
                <Text style={styles.recentlyViewedTitle}>Recently viewed</Text>

                <View style={styles.recentlyViewedContainer}>
                  {recentSearchItemsLayout}
                </View>

                <View style={styles.clearRecentlyViewedContainer}>
                  <TouchableOpacity
                    onPress={this.clearRecentSearchItems.bind(this)}>
                    <Text style={styles.clearRecentlyViewedText}>Clear</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
          </ScrollView>
        )}
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.NavBar,
    paddingTop: PlatformController.getTopInset() + 24,
  },
  searchInputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.SearchBar,
    borderRadius: 5,
    marginLeft: 10,
    marginTop: 6,
    marginBottom: 6,
    paddingVertical: 4,
  },
  searchIcon: {
    marginLeft: 10,
  },
  searchTextInput: {
    flex: 1,
    fontSize: 16,
    marginHorizontal: 8,
    color: colors.white,
  },
  clearSearchIcon: {
    marginRight: 10,
  },
  cancelText: {
    color: colors.white,
    fontSize: 16,
    paddingHorizontal: 16,
  },
  filterScrollView: {
    flexGrow: 0,
  },
  filterContentContainer: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  searchResultContainer: {
    flex: 1,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  searchResultList: {
    flex: 1,
    zIndex: 0,
  },
  recentSearchesTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: colors.Grey900,
    marginHorizontal: 10,
    marginTop: 16,
    marginBottom: 10,
  },
  recentSearchesContainer: {
    backgroundColor: colors.white,
  },
  recentSearchItem: {
    borderBottomWidth: 1,
    borderColor: colors.Grey100,
  },
  recentSearchItemText: {
    fontSize: 17,
    fontWeight: '600',
    color: 'black',
    marginHorizontal: 10,
    marginVertical: 10,
  },
  clearRecentSearchesContainer: {
    alignItems: 'flex-end',
  },
  clearRecentSearchesText: {
    fontWeight: '600',
    color: colors.ButtonBlue,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 2,
  },
  recentlyViewedTitle: {
    fontSize: 13,
    fontWeight: '600',
    color: colors.Grey900,
    marginHorizontal: 10,
    marginVertical: 10,
  },
  recentlyViewedContainer: {
    backgroundColor: colors.white,
  },
  clearRecentlyViewedContainer: {
    alignItems: 'flex-end',
  },
  clearRecentlyViewedText: {
    fontWeight: '600',
    color: colors.ButtonBlue,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 20,
  },
});
