import {colors} from '../classes/Colors';

// TODO: check this is is checking newest edition of Decision Making third edition
export const getBookStatus = (book, bookReadingProgress) => {
  if (bookReadingProgress.includes(book.ID)) {
    return 'Read';
  } else if (bookReadingProgress?.some(item => item.includes(book.ID))) {
    return 'In Progress';
  }
  return null;
};

export const getBookStatusColor = status => {
  switch (status) {
    case 'Read':
      return colors.Green500;
    case 'In Progress':
      return colors.Orange400;
    default:
      return '#DCDDDF';
  }
};

// TODO: check this logic
export const calculateReadPercentage = (readData, bookChapters) => {
  // Helper to safely add times if they are valid numbers
  const addValidTime = time => time ?? 0;

  // Calculate total read time for the entire book
  const totalBookTime = bookChapters.reduce((total, chapter) => {
    total += addValidTime(chapter.readTime);
    return total;
  }, 0);

  // Calculate total time read based on `readData`
  const totalTimeRead = readData.reduce((total, entry) => {
    const [, chapterIndex, sectionIndex] = entry.split('-');
    const chapter = bookChapters.find(
      ch => ch.chapter === Number(chapterIndex),
    );

    const section = chapter?.sections.find(
      sec => sec.section === Number(sectionIndex),
    );

    if (section?.readTime) {
      return total + section.readTime;
    }

    if (chapter?.readTime) {
      return total + chapter.readTime;
    }

    return 0;
  }, 0);

  if (totalTimeRead === 0 || totalBookTime === 0) {
    return 0;
  }

  const readPercentage = (totalTimeRead / totalBookTime) * 100;
  return readPercentage;
};

export const getCurrentChapterNumber = (readData, bookChapters) => {
  let lastChapterRead = -1;

  readData.forEach(entry => {
    const parts = entry.split('-');
    const chapterIndex = parseInt(parts[1]);

    if (chapterIndex > lastChapterRead) {
      lastChapterRead = chapterIndex;
    }
  });

  // Find the corresponding chapter from the bookChapters array
  const currentChapter = bookChapters.find(
    ch => ch.chapter === lastChapterRead,
  );

  return currentChapter.chapter ?? 1; // Return the chapter object or null if no chapters read
};

export const getChapterName = (chapterNumber, bookChapters) => {
  const chapter = bookChapters.find(ch => ch.chapter === chapterNumber);
  return chapter ? chapter.name : 'N/A';
};

export const getBookProgressData = (book, bookReadingProgress) => {
  const firstChapterName = getChapterName(1, book.chapters);

  if (bookReadingProgress.includes(book.ID)) {
    const lastChapter = book?.chapters?.[book.chapters.length - 1];
    return {
      percentage: 100,
      number: lastChapter.chapter || 0,
      name: lastChapter.name || 'N/A',
    };
  }

  const progressForBook = bookReadingProgress?.filter(bookProgress =>
    bookProgress.includes(book.ID),
  );

  if (progressForBook.length === 0) {
    return {
      percentage: 0,
      number: 1,
      name: firstChapterName,
    };
  }

  const filteredProgress =
    filterSectionsFromProgressIfChapterExists(progressForBook);

  const progressPercentage = calculateReadPercentage(
    filteredProgress,
    book.chapters,
  );

  const currentChapterNumber = getCurrentChapterNumber(
    filteredProgress,
    book.chapters,
  );

  const chapterName = getChapterName(currentChapterNumber, book.chapters);

  return {
    percentage: progressPercentage.toFixed(0),
    number: currentChapterNumber,
    name: chapterName,
  };
};

const filterSectionsFromProgressIfChapterExists = progress => {
  // Create a Set to track which chapters are present
  const chaptersPresent = new Set();

  // First, add main chapters to the set
  progress.forEach(item => {
    const [chapterName, chapterNumber, sectionNumber] = item.split('-');
    if (!sectionNumber) {
      chaptersPresent.add(`${chapterName}-${chapterNumber}`);
    }
  });

  // Filter to keep only main chapters and sections without main chapters
  return progress.filter(item => {
    const [chapterName, chapterNumber, sectionNumber] = item.split('-');
    const chapterKey = `${chapterName}-${chapterNumber}`;

    // Keep only if it's a main chapter or a section without a corresponding main chapter
    return !sectionNumber || !chaptersPresent.has(chapterKey);
  });
};

export const findBookVideoLink = book => {
  return book?.blurb?.videoId;
};

/**
 * Checks if any quizzes in the book are present in the given list of quiz sets.
 *
 * @param {Object} book - Book object with chapters and sections.
 * @param {Array<Object>} quizSets - Array of quiz set objects with `name` property.
 *
 * @returns {Boolean} True if any quiz in the book is present in the list of quiz sets, false otherwise.
 */
export const doesBookHaveQuiz = (book, quizSets) => {
  const quizSetNames = quizSets.map(quizSet => quizSet.name);
  const bookQuizzes = book?.chapters
    .flatMap(chapter => chapter.sections)
    .map(section => section.relatedQuiz)
    .filter(Boolean);

  return quizSetNames?.some(quizSetName => bookQuizzes.includes(quizSetName));
};

/**
 * Checks if any quizzes in the chapter are present in the given list of quiz sets.
 *
 * @param {Object} chapter - Chapter object with sections.
 * @param {Array<Object>} quizSets - Array of quiz set objects with `name` property.
 *
 * @returns {Boolean} True if any quiz in the chapter is present in the list of quiz sets, false otherwise.
 */
export const doesChapterHaveQuiz = (chapter, quizSets) => {
  const quizSetNames = quizSets.map(quizSet => quizSet.name);
  const chapterQuizzes = chapter?.sections?.map(section => section.relatedQuiz);
  return chapterQuizzes.some(quiz => quizSetNames.includes(quiz));
};

/**
 * Replace all occurrences of a substring with another string.
 *
 * @param {string} str - Original string.
 * @param {string} find - Substring to replace.
 * @param {string} replace - Replacement string.
 *
 * @returns {string} New string with all occurrences of `find` replaced with `replace`.
 */
export const replaceAll = (str, find, replace) => {
  return str.replace(find, replace);
};
