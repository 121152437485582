/**
 * PPP
 *
 * @format
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  Animated,
  ActivityIndicator,
  StatusBar,
  LayoutAnimation,
  TouchableOpacity,
  UIManager,
  Alert,
  Linking,
  BackHandler,
  LogBox,
} from 'react-native';
import Tts from 'react-native-tts';
import 'react-native-gesture-handler';
import {FirebaseMessagingTypes} from '@react-native-firebase/messaging';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {EventRegister} from 'react-native-event-listeners';
import * as WebBrowser from 'expo-web-browser';

import Navigation from './navigation/Navigation';
import NavigationStack from './navigation/NavigationStack';

import {Tabs, MenuScreens} from './classes/Tabs';

import DynamicText from './components/DynamicText';
import PromoModal from './components/Promotion/PromoModal';

import DataController from './classes/DataController';
import AuthenticationController from './classes/AuthenticationController';
import RemoteDataController from './classes/RemoteDataController';
import LayoutController from './classes/LayoutController';
import GuidelinesController from './classes/GuidelinesController';
import PlatformController from './classes/PlatformController';
import DeviceInfoController from './classes/DeviceInfoController';
import CommunicationsController from './classes/CommunicationsController';
import {
  IapPurchaseErrorListener,
  IapPurchaseUpdatedListener,
  IapGetSubscriptions,
  IapInitConnection,
  IapFinishTransaction,
  IapGetAvailablePurchases,
  IapEndConnection,
  IapRequestSubscription,
} from './classes/InAppPurchaseController';
import {colors} from './classes/Colors';
import {firebase} from './classes/Firebase';
import TrackPlayer from './classes/TrackPlayer';

import LoginScreen from './screens/LoginScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import OnboardingScreen from './screens/OnboardingScreen';

import AppWrapper from './AppWrapper';

import {logAnalyticsEvent} from './utils/firebase';

import {analyticsEventNames} from './constants/promotions';

import {AcknowledgementsProvider} from './context/AcknowledgementsContext';
import {UpVotesProvider} from './context/UpVotesContext';
import {PromotionsProvider} from './context/PromotionsContext';
import PromotionsController from './classes/PromotionsController';

var moment = require('moment');

LogBox.ignoreAllLogs();

var _ = require('lodash');

global.customFontSize = LayoutController.defaultFontSize();

const itemSkus = Platform.select({
  ios: ['co.uk.class.cpd.1month', 'co.uk.class.cpd.12months'],
  android: [
    'co.uk.class.cpd.1month.android',
    'co.uk.class.cpd.12months.android',
  ],
});

const springAnimationProperties = {
  type: 'easeOut',
  springDamping: 1,
  property: 'opacity',
};

const animationConfig = {
  duration: 200,
  create: springAnimationProperties,
  update: springAnimationProperties,
  delete: springAnimationProperties,
};

type Props = {};

type State = {
  tabs: [],
  questions: [],
  topics: [],
  issues: [],
  caseStudies: [],
  featuredCards: [],
  authors: [],
  trustInfo: Object,
  quizSets: [],
  quizzes: [],
  results: [],
  latestResultsForQuizSets: Object,
  resultsForQuizzes: [],
  inProgressQuizzes: [],
  guidelines: [],
  pgds: [],
  pgdAcknowledgements: [],
  excludedGuidelines: [],
  plusTimelineData: [],
  plusQuizSuggestions: [],
  didYouKnowItems: [],
  books: [],
  bookBookmarks: [],
  bookReadingProgress: [],
  user: ?Object,
  showLoggingInOverlay: boolean,
  loggingInMessage: string,
  shouldShowMenu: boolean,
  menuBackgroundOpacity: Animated,
  menuBackgroundPointEvents: string,
  menuButtonProgress: Animated,
  showAccountDeletionAlert: boolean,
  isLoadingInitialData: boolean,
  isLoadingData: boolean,
  loadingScreenText: string,
  shouldShowLongLoadingMessage: boolean,
  loadingBarText: string,
  startedLoadingDataInterval: boolean,
  hasDecidedIfLoggedIn: boolean,
  fontSize: number,
  shouldShowOnboardingModal: boolean,
  products: [],
  currentSubscription: Object,
  currentlyRestoringSubscription: boolean,
  validPPPSubscription: boolean,
  deepLink: Object,
  showBooksTab: boolean,
  appFeatures: [],
  dailyChallenge: Object,
  initialDashboardCardStates: [],
  extraResources: [],
  videos: [],
  podcasts: [],
  videoHistory: [],
  audioHistory: [],
  alertIsShown: boolean,
  showModal: Boolean,
  psrifs: [],
  psirfNavCard: Object,
  isDownloadingOnlineData: boolean,
};

WebBrowser.maybeCompleteAuthSession();

class App extends Component {
  constructor(props: Props) {
    super(props);

    if (Platform.OS !== 'web') {
      // NativeModules.DevSettings.setIsDebuggingRemotely(true);
    }

    this.state = {
      tabs: Tabs,
      questions: [],
      topics: [],
      issues: [],
      caseStudies: [],
      featuredCards: [],
      authors: [],
      trustInfo: {},
      quizSets: [],
      quizzes: [],
      results: [],
      latestResultsForQuizSets: {},
      resultsForQuizzes: {},
      inProgressQuizzes: [],
      guidelines: [],
      pgds: [],
      pgdAcknowledgements: [],
      excludedGuidelines: [],
      plusTimelineData: [],
      plusQuizSuggestions: [],
      didYouKnowItems: [],
      books: [],
      bookBookmarks: [],
      bookReadingProgress: [],
      user: null,
      showLoggingInOverlay: false,
      loggingInMessage: '',
      shouldShowMenu: false,
      menuBackgroundOpacity: new Animated.Value(0),
      menuBackgroundPointEvents: 'none',
      menuButtonProgress: new Animated.Value(0),
      showAccountDeletionAlert: false,
      isLoadingInitialData: true,
      isLoadingData: true,
      loadingScreenText: 'Loading Quizzes',
      shouldShowLongLoadingMessage: false,
      loadingBarText: 'Checking for updates',
      startedLoadingDataInterval: false,
      hasDecidedIfLoggedIn: false,
      fontSize: LayoutController.defaultFontSize(),
      shouldShowOnboardingModal: false,
      products: [],
      currentSubscription: null,
      currentlyRestoringSubscription: false,
      validPPPSubscription: false,
      deepLink: null,
      showBooksTab: false,
      appFeatures: [],
      dailyChallenge: null,
      initialDashboardCardStates: [],
      extraResources: [],
      videos: [],
      podcasts: [],
      videoHistory: [],
      audioHistory: [],
      alertIsShown: false,
      promoModalToShow: null,
      psirfs: [],
      psirfNavCard: null,
      isDownloadingOnlineData: false,
    };

    if (Platform.OS === 'android') {
      // fix for android to load firestore properly
      const originalSend = XMLHttpRequest.prototype.send;
      XMLHttpRequest.prototype.send = function (body) {
        if (body === '') {
          originalSend.call(this);
        } else {
          originalSend.call(this, body);
        }
      };
    }

    if (Platform.OS === 'android') {
      // allows layout animation
      UIManager.setLayoutAnimationEnabledExperimental &&
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    this.setDefaultFontFamily();
  }

  async componentDidMount() {
    var that = this;

    if (Platform.OS === 'android' || Platform.OS == 'ios') {
      try {
        await Tts.getInitStatus();
        console.log('TTS initialized');
      } catch (error) {
        console.log('Failed to initialize TTS');
      }
    }

    // get video and audio history
    const vidHistory = await DataController.getVideoHistory();
    const audHistory = await DataController.getAudioHistory();
    this.setState({
      videoHistory: vidHistory,
      audioHistory: audHistory,
    });

    await TrackPlayer.setupTP();

    await LayoutController.loadFontSize();

    if (Platform.OS === 'android') {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        function () {
          // don't close app when back button is pressed
          try {
            if (that.state.shouldShowMenu) {
              that.showMenu(false);
            } else {
              that._navigation.navigatorTabs._navigation.goBack();
            }
          } catch (error) {
            console.log(error);
          }
          return true;
        },
      );
    }

    // resend unsent quizzes and results
    RemoteDataController.resendOutstandingResults();
    RemoteDataController.resendOutstandingQuizzes();
    RemoteDataController.sendRequestQueue();

    this.configureSubscriptions();

    // check onboarding
    var completedOnboarding = await DataController.hasCompletedOnboarding();

    if (completedOnboarding !== true && Platform.OS !== 'web') {
      this.showOnboarding();
    }

    var user = null;
    var user = await AuthenticationController.getUser();

    this.userUpdated(user);

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      await this.configurePushNotifications();
      this.checkNotificationPermission();
    }

    // add event listeners
    this.userUpdatedListener = EventRegister.addEventListener(
      'userUpdated',
      async data => {
        var updatedUser = await AuthenticationController.getUser();
        that.userUpdated(updatedUser);
      },
    );

    this.fontSizeUpdatedListener = EventRegister.addEventListener(
      'fontSizeUpdated',
      async data => {
        var fontSize = await LayoutController.getFontSize();
        that.setState({fontSize: fontSize});
      },
    );

    if (Platform.OS === 'android') {
      Linking.addEventListener('url', this.handleOpenURL.bind(this));
      Linking.getInitialURL().then(url => {
        if (url) {
          this.handleOpenURL({url: url});
        }
      });
    } else if (Platform.OS === 'ios') {
      Linking.addEventListener('url', this.handleOpenURL.bind(this));
      Linking.getInitialURL().then(url => {
        if (url) {
          this.handleOpenURL({url: url});
        }
      });
    } else if (Platform.OS === 'web') {
      var url = window.location.href;
      this.handleOpenURL({url: url});
    }

    let dashboardCardStates = await DataController.getDashboardCardStates();
    this.setState({initialDashboardCardStates: dashboardCardStates});

    let resources = await RemoteDataController.getExtraResources();
    const filteredResources = resources.filter(
      it =>
        it.enabled &&
        it.type !== 'guideline' &&
        (it.products.includes('ALL') || it.products.includes('ParaPass')),
    );
    this.setState({extraResources: filteredResources});
  }

  componentWillUnmount() {
    EventRegister.removeEventListener(this.userUpdatedListener);
    EventRegister.removeEventListener(this.fontSizeUpdatedListener);

    if (this.purchaseUpdateSubscription) {
      this.purchaseUpdateSubscription.remove();
      this.purchaseUpdateSubscription = null;
    }
    if (this.purchaseErrorSubscription) {
      this.purchaseErrorSubscription.remove();
      this.purchaseErrorSubscription = null;
    }

    if (Platform.OS !== 'web') {
      IapEndConnection();
    }
  }

  async configurePushNotifications() {
    try {
      const firstStatus = await firebase.messaging().hasPermission(); // this variable is only used to help detect if permissions modal is shown
      const authStatus = await firebase.messaging().requestPermission();
      const enabled = authStatus === 1;

      // This code block checks if the notifications permission modal was shown and sends the user's response to firebase.
      // Note that some Android users are not counted for because their permissions are set automatically (without being shown a modal).
      if (firstStatus !== authStatus) {
        if (authStatus === 1) {
          // permission modal was shown and user enabled notifications
          firebase
            .analytics()
            .setUserProperty('nots_enabled_via_modal', 'true');
        } else {
          // permission modal was shown and user did NOT enable notifications
          firebase
            .analytics()
            .setUserProperty('nots_enabled_via_modal', 'false');
        }
      }

      if (enabled) {
        firebase.messaging().onNotificationOpenedApp(async remoteMessage => {
          if (remoteMessage) {
            this.handleRemoteMessage(remoteMessage);
          }
        });

        try {
          let remoteMessage = await firebase
            .messaging()
            .getInitialNotification();
          this.handleRemoteMessage(remoteMessage);
        } catch (error) {
          console.log(error);
        }

        this.updatePushNotificationTopics();
      }
    } catch (error) {
      console.log('Error configuring push notifications: ' + error);
    }
  }

  async checkNotificationPermission() {
    try {
      const hasPermission = await firebase.messaging().hasPermission();

      if (hasPermission) {
        firebase.analytics().setUserProperty('notifications_enabled', 'true');
      } else {
        firebase.analytics().setUserProperty('notifications_enabled', 'false');
      }
    } catch (error) {
      console.error('Error checking messaging permission:', error);
    }
  }

  async handleRemoteMessage(
    remoteMessage: FirebaseMessagingTypes.RemoteMessage,
  ) {
    const remoteData = remoteMessage?.data;
    if (remoteData?.book) {
      // this only works with a timeout for some reason // TODO fix
      setTimeout(() => {
        const url = 'cpgcpd://book/' + remoteData?.book;
        this.handleOpenURL({url: url});
      }, 2000);
    } else if (remoteData?.quiz) {
      const url = 'cpgcpd://quiz/' + remoteData?.quiz;
      this.handleOpenURL({url: url});
    } else if (remoteData?.pgdQuiz) {
      const url = 'cpgcpd://glid/' + remoteData?.pgdQuiz;
      this.handleOpenURL({url: url});
    } else if (remoteData?.video) {
      const url = 'cpgcpd://video/id=' + remoteData?.video;
      this.handleOpenURL({url: url});
    } else if (remoteData?.podcast) {
      const url = 'cpgcpd://podcast/id=' + remoteData?.podcast;
      this.handleOpenURL({url: url});
    } else if (remoteData?.psirf) {
      const url = 'cpgcpd://psirf/id=' + remoteData?.psirf;
      this.handleOpenURL({url: url});
    }
  }

  updateVideoHistory(videoId, currentTime, seconds) {
    this.setState(
      prevState => {
        let newHistory = [];
        const i = prevState.videoHistory.findIndex(
          it => it.videoId === videoId,
        );
        if (i === -1) {
          newHistory = [
            ...prevState.videoHistory,
            {
              videoId: videoId,
              currentTime: currentTime,
              secondsWatched: seconds,
            },
          ];
        } else {
          newHistory = [...prevState.videoHistory];
          newHistory[i] = {
            videoId: videoId,
            currentTime: currentTime,
            secondsWatched: seconds,
          };
        }
        return {videoHistory: newHistory};
      },
      () => {
        DataController.saveVideoHistory(this.state.videoHistory);
      },
    );
  }

  async updateAudioHistory(podcastId, progressPosition) {
    this.setState(
      prevState => {
        let newHistory = [];
        const i = prevState.audioHistory.findIndex(
          it => it.audioId === podcastId,
        );
        if (i === -1) {
          newHistory = [
            ...prevState.audioHistory,
            {
              audioId: podcastId,
              currentTime: progressPosition,
              secondsListened: 0,
            },
          ];
        } else {
          newHistory = [...prevState.audioHistory];
          newHistory[i] = {
            audioId: podcastId,
            currentTime: progressPosition,
            secondsListened: (newHistory[i].secondsListened += 1),
          };
        }
        return {audioHistory: newHistory};
      },
      () => {
        DataController.saveAudioHistory(this.state.audioHistory);
      },
    );
  }

  async updatePushNotificationTopics() {
    try {
      let currentPushNotificationTopics =
        await DataController.getPushNotificationTopics();
      let updatedPushNotificationTopics = [];

      if (this.state.user != null && this.state.user.paraPassTrusts != null) {
        for (let trust of this.state.user.paraPassTrusts) {
          if (updatedPushNotificationTopics.includes(trust) === false) {
            updatedPushNotificationTopics.push(trust);
            await firebase.messaging().subscribeToTopic(trust);
          }
        }
      }

      // add extra push notifications topics
      if (this.state.user != null) {
        if (this.state.user.subscriptions.length) {
          for (let sub of this.state.user.subscriptions) {
            if (sub.pushNotificationTopics.length) {
              for (let top of sub.pushNotificationTopics) {
                if (updatedPushNotificationTopics.includes(top) === false) {
                  updatedPushNotificationTopics.push(top);
                  await firebase.messaging().subscribeToTopic(top);
                }
              }
            }
          }
        }
      }

      updatedPushNotificationTopics.push('allUsers');
      await firebase.messaging().subscribeToTopic('allUsers');

      // !!!!!!!!!!!!!!! THERE ARE LIVE USERS SUBSCRIBED TO 'aimerTest' Topic - don't send anything to this topic !!!!!!!!!!!!!!!!
      // updatedPushNotificationTopics.push('classDeveloperTest');
      // await firebase.messaging().subscribeToTopic('classDeveloperTest');

      for (let currentPushNotificationTopic of currentPushNotificationTopics) {
        if (
          updatedPushNotificationTopics.includes(
            currentPushNotificationTopic,
          ) === false
        ) {
          firebase
            .messaging()
            .unsubscribeFromTopic(currentPushNotificationTopic);
        }
      }

      DataController.savePushNotificationTopics(updatedPushNotificationTopics);
    } catch (error) {
      console.log('error updating push notifications:' + error);
    }
  }

  async handleOpenURL(event: Object) {
    console.log('handleOpenURL: ', event.url);
    try {
      if (Platform.OS === 'web' && event.url.includes('cpgcpd://') === false) {
        var url = window.location.href;

        if (url.includes('glid')) {
          var glid = /glid=([^&]+)/.exec(url)[1];
          var jrcalcPlusContactID = /uid=([^&]+)/.exec(url)[1];

          if (glid != null) {
            // go to quizSet once finished loading
            if (this.state.isLoadingInitialData === true) {
              this.setState({deepLink: {glid: glid, uid: jrcalcPlusContactID}});
            } else {
              this.openPGDQuizSetWithGLID(glid, jrcalcPlusContactID);
            }
          }
        }
        if (url.includes('quiz')) {
          var name = null;
          var jrcalcPlusContactID = null;

          try {
            name = /quiz=([^&]+)/.exec(url)[1];
            jrcalcPlusContactID = /uid=([^&]+)/.exec(url)[1];
            console.log(name);
          } catch (error) {}

          if (name != null) {
            // go to quizSet once finished loading
            if (this.state.isLoadingInitialData === true) {
              this.setState({deepLink: {name: name, jrcalcPlusContactID}});
            } else {
              this.openQuizSetWithName(name, jrcalcPlusContactID);
            }
          }
        }
        if (event.url.includes('book-')) {
          let book = null;
          let chapterNumber = null;
          let sectionNumber = null;

          let bookComponents = event.url.split('-');
          if (bookComponents.length > 1) {
            book = bookComponents[1];
          }
          if (bookComponents.length > 2) {
            chapterNumber = parseInt(bookComponents[2]);
          }
          if (bookComponents.length > 3) {
            sectionNumber = parseInt(bookComponents[3]);
          }

          if (this.state.isLoadingInitialData === true) {
            this.setState({
              deepLink: {
                name: 'book',
                book: book,
                chapterNumber: chapterNumber,
                sectionNumber: sectionNumber,
              },
            });
          } else {
            this.goToBook(book, chapterNumber, sectionNumber, {});
          }
        }
        if (url.includes('video')) {
          const videoId = url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'video', videoId: videoId}});
          } else {
            this.openVideoScreen(videoId, 'Deep Link');
          }
        }
        if (url.includes('podcast')) {
          const podcastId = url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'podcast', podcastId: podcastId}});
          } else {
            this.openPodcastScreen(podcastId);
          }
        }
        // Made slightly more specific to prevent errors on preview site
        if (url.includes('?psirf=')) {
          const psirfId = url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'psirf', psirfId: psirfId}});
          } else {
            this.openPSIRFScreen(psirfId);
          }
        }
      } else {
        if (event.url.includes('cpgcpd://promotion')) {
          console.log('cpgcpd://promotion');
          this.openPromotionScreen();
        }
        if (event.url.includes('cpgcpd://glid')) {
          // example for swastjrcalc@test.co.uk: cpgcpd://glid/SWT0800/uid/282638
          var glidAndUID = event.url.replace('cpgcpd://glid/', '');
          var glid = glidAndUID.substr(0, glidAndUID.indexOf('/uid'));
          var jrcalcPlusContactID = glidAndUID.substr(
            glidAndUID.indexOf('/uid/') + 5,
          );

          if (glid != null) {
            // go to quizSet once finished loading
            if (this.state.isLoadingInitialData === true) {
              this.setState({deepLink: {glid: glid, uid: jrcalcPlusContactID}});
            } else {
              this.openPGDQuizSetWithGLID(glid, jrcalcPlusContactID);
            }
          }
        }
        if (event.url.includes('cpgcpd://quiz')) {
          let name = '';
          let jrcalcPlusContactID = '';
          if (event.url.includes('/uid')) {
            var nameAndUID = event.url.replace('cpgcpd://quiz/', '');
            name = nameAndUID.substr(0, nameAndUID.indexOf('/uid'));
            jrcalcPlusContactID = nameAndUID.substr(
              nameAndUID.indexOf('/uid/') + 5,
            );
          } else {
            name = event.url.replace('cpgcpd://quiz/', '');
          }
          if (name != null && name !== '') {
            // go to quizSet once finished loading
            if (this.state.isLoadingInitialData === true) {
              this.setState({deepLink: {name: name, uid: jrcalcPlusContactID}});
            } else {
              this.openQuizSetWithName(name, jrcalcPlusContactID);
            }
          }
        }
        if (event.url.includes('cpgcpd://book')) {
          let book = null;
          let chapterNumber = null;
          let sectionNumber = null;

          let bookComponents = event.url.split('-');
          book = bookComponents[0].substring(14); // removes "cpgcpd://book/"
          chapterNumber = parseInt(bookComponents[1]);
          sectionNumber = parseInt(bookComponents[2]);

          if (this.state.isLoadingInitialData === true) {
            this.setState({
              deepLink: {
                name: 'book',
                book: book,
                chapterNumber: chapterNumber,
                sectionNumber: sectionNumber,
              },
            });
          } else {
            this.goToBook(book, chapterNumber, sectionNumber, {});
          }
        }
        if (event.url.includes('cpgcpd://video')) {
          const videoId = event.url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'video', videoId: videoId}});
          } else {
            this.openVideoScreen(videoId, 'Deep Link');
          }
        }
        if (event.url.includes('cpgcpd://podcast')) {
          const podcastId = event.url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'podcast', podcastId: podcastId}});
          } else {
            this.openPodcastScreen(podcastId);
          }
        }
        if (event.url.includes('cpgcpd://psirf')) {
          const psirfId = event.url.split('=')[1];
          if (this.state.isLoadingInitialData === true) {
            this.setState({deepLink: {name: 'psirf', psirfId: psirfId}});
          } else {
            this.openPSIRFScreen(psirfId);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async openVideoScreen(videoId, from) {
    if (!this.state.user) {
      console.log("No user, can't access video screen");
      return;
    }

    const videoToPlay = this.state.videos.find(vid => vid.id === videoId);

    if (!videoToPlay && !this.state.alertIsShown) {
      this.setState({alertIsShown: true});
      if (Platform.OS === 'web') {
        if (alert('Video cannot be found for the current user.')) {
          console.log('ok pressed');
        }
      } else {
        Alert.alert(
          'Video cannot be found',
          'Video cannot be found for the current user.',
          [
            {
              text: 'OK',
              onPress: () => {
                console.log('ok pressed');
                this.setState({alertIsShown: false});
              },
            },
          ],
          {cancelable: false},
        );
      }
      return;
    }

    this._navigation.navigate(
      'Video',
      {
        video: videoToPlay,
        from,
      },
      this.state,
    );
  }

  openPromotionScreen() {
    this._navigation.navigate(
      'QuizDashboard',
      {
        menuButtonTapped: () => this.showMenu(true),
      },
      this.state,
    );
  }

  // TODO: working but need to tidy up and test
  openPSIRFScreen(psirfId) {
    const psirf = this.state.psirfs.find(psirf => psirf.ID === psirfId);

    if (!psirf?.content) {
      this.showAlert(
        'PSIRF not found',
        'Could not find PSIRF with ID: ' + psirfId,
      );
      return;
    }

    const quizLink = psirf?.content.find(content => content.type === 'Quiz')
      ?.content.QuizLink;

    if (!quizLink) {
      this._navigation.navigate(
        'PSIRF',
        {data: psirf},
        {navigation: this._navigation},
      );
      return;
    }

    const quizsetForPsirf = this.state.quizSets.find(
      quizset => quizset.name === quizLink,
    );

    const quizOnPressHandler = () =>
      this._navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizsetForPsirf,
          from: 'PSIRF',
        },
        this.state.screenProps,
      );

    this._navigation.navigate(
      'PSIRF',
      {data: psirf, quizOnPressHandler},
      {navigation: this._navigation},
    );
  }

  async openPodcastScreen(podcastId) {
    if (!this.state.user) {
      console.log("No user, can't access podcast screen");
      return;
    }

    const podcastToPlay = this.state.podcasts.find(pod => pod.id === podcastId);

    if (!podcastToPlay && !this.state.alertIsShown) {
      this.setState({alertIsShown: true});
      if (Platform.OS === 'web') {
        if (alert('Podcast cannot be found for the current user.')) {
          console.log('ok pressed');
        }
      } else {
        Alert.alert(
          'Podcast cannot be found',
          'Podcast cannot be found for the current user.',
          [
            {
              text: 'OK',
              onPress: () => {
                console.log('ok pressed');
                this.setState({alertIsShown: false});
              },
            },
          ],
          {cancelable: false},
        );
      }
      return;
    }

    this._navigation.navigate(
      'Podcast',
      {
        podcast: podcastToPlay,
        audioHistory: this.state.audioHistory,
        updateAudioHistory: this.updateAudioHistory.bind(this),
      },
      this.state,
    );
  }

  showAlert(title, message) {
    if (!this.state.alertIsShown) {
      this.setState({alertIsShown: true});
      if (Platform.OS === 'web') {
        if (alert(message)) {
          console.log('ok pressed');
        }
      } else {
        Alert.alert(title, message, [
          {
            text: 'OK',
            onPress: () => {
              console.log('ok pressed');
              this.setState({alertIsShown: false});
            },
          },
        ]);
      }
    }
  }

  async openQuizSetWithName(name: string, uid: string) {
    if (
      uid != null &&
      uid !== '' &&
      this.state.user &&
      this.state.user.uid !== uid
    ) {
      if (Platform.OS === 'web') {
        setTimeout(() => {
          if (
            alert(
              'You are currently logged in to a different account than in JRCALC Plus. Please log out and log in with your JCALC Plus account to save your answers to that account.',
            )
          ) {
            console.log('ok pressed');
          }
        }, 1100);
      } else {
        setTimeout(() => {
          Alert.alert(
            'Logged in with different account',
            'You are currently logged into a different account than in JRCALC Plus. Please log out and log in with your JCALC Plus account to save your answers to that account.',
            [{text: 'OK', onPress: () => console.log('ok pressed')}],
            {cancelable: false},
          );
        }, 1100);
      }
    }

    let quizSetFound = false;

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      if (quizSet.name === name) {
        quizSetFound = true;

        let screenName = 'QuizOverview';
        let params = {quizSet: quizSet};

        if (quizSet.type === 'standby') {
          screenName = 'StandbyVolume';
          let issueFound = false;
          for (
            var issueIndex = 0;
            issueIndex < this.state.issues.length;
            issueIndex++
          ) {
            const issue = this.state.issues[issueIndex];
            if (issue.Name === quizSet.issueName) {
              issueFound = true;
              params = {quizSet: quizSet, issue: issue};
              break;
            }
          }
          if (issueFound == false) {
            quizSetFound = false;
          }
        }

        if (quizSet.type === 'caseStudy') {
          screenName = 'CaseStudyOverview';
          let caseStudyFound = false;
          for (
            var caseStudyIndex = 0;
            caseStudyIndex < this.state.caseStudies.length;
            caseStudyIndex++
          ) {
            var caseStudy = this.state.caseStudies[caseStudyIndex];
            if (caseStudy.Name === quizSet.caseStudy) {
              caseStudyFound = true;
              params = {quizSet: quizSet, caseStudy: caseStudy};
              break;
            }
          }
          if (caseStudyFound == false) {
            quizSetFound = false;
          }
        }

        if (quizSetFound) {
          // match found - go to quiz overview
          if (Platform.OS === 'web') {
            this._navigation.navigate(screenName, params, this.state);
          } else {
            if (
              this._navigation &&
              this._navigation.navigatorTabs != null &&
              this._navigation.navigatorTabs._navigation != null &&
              this._navigation.navigatorTabs._navigation._childrenNavigation &&
              this._navigation.navigatorTabs._navigation._childrenNavigation
                .Dashboard != null
            ) {
              this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.popToTop();

              this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.push(
                screenName,
                params,
                this.state,
              );
            } else {
              this._navigation.navigate(screenName, params, this.state);
            }
          }

          return;
        }
      }
    }

    if (quizSetFound == false) {
      if (Platform.OS === 'web') {
        setTimeout(() => {
          if (
            alert(
              'No quiz found. There was either an error loading the quiz or you are unauthorised to take it.',
            )
          ) {
            console.log('ok pressed');
          }
        }, 1000);
      } else {
        setTimeout(() => {
          Alert.alert(
            'No quiz found',
            'There was either an error loading the quiz or you are unauthorised to take it.',
            [{text: 'OK', onPress: () => console.log('ok pressed')}],
            {cancelable: false},
          );
        }, 1000);
      }
    }
  }

  async openPGDQuizSetWithGLID(glid: string, uid: string) {
    if (this.state.user && this.state.user.uid !== uid) {
      if (Platform.OS === 'web') {
        setTimeout(() => {
          if (
            alert(
              'You are currently logged in to a different account than in JRCALC Plus. Please log out and log in with your JCALC Plus account to save your answers to that account.',
            )
          ) {
            console.log('ok pressed');
          }
        }, 1100);
      } else {
        setTimeout(() => {
          Alert.alert(
            'Logged in with different account',
            'You are currently logged into a different account than in JRCALC Plus. Please log out and log in with your JCALC Plus account to save your answers to that account.',
            [{text: 'OK', onPress: () => console.log('ok pressed')}],
            {cancelable: false},
          );
        }, 1100);
      }
    }

    if (glid != null && glid !== '') {
      var glidFound = false;

      for (
        let quizSetIndex = 0;
        quizSetIndex < this.state.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.state.quizSets[quizSetIndex];

        if (
          quizSet.type === 'topic' &&
          quizSet.pgdInfo != null &&
          quizSet.pgdInfo.GLID === glid
        ) {
          glidFound = true;

          if (
            quizSet.pgd !== true ||
            (quizSet.pgd === true && this.state.user.uid === uid)
          ) {
            // match found - go to quiz overview
            if (Platform.OS === 'web') {
              this._navigation.navigate(
                'QuizOverview',
                {
                  quizSet: quizSet,
                  from: 'PGD Deeplink',
                },
                this.state,
              );
            } else {
              if (
                this._navigation.navigatorTabs != null &&
                this._navigation.navigatorTabs._navigation != null &&
                this._navigation.navigatorTabs._navigation
                  ._childrenNavigation &&
                this._navigation.navigatorTabs._navigation._childrenNavigation
                  .Dashboard != null
              ) {
                this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.popToTop();

                this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.push(
                  'QuizOverview',
                  {
                    quizSet: quizSet,
                  },
                  this.state,
                );
              } else {
                this._navigation.navigate(
                  'QuizOverview',
                  {
                    quizSet: quizSet,
                    from: 'PGD Deeplink',
                  },
                  this.state,
                );
              }
            }
          }

          break;
        }
      }

      if (glidFound === false) {
        if (Platform.OS === 'web') {
          setTimeout(() => {
            if (
              alert(
                "No quiz found. There is either no quiz or you may be unauthorised to take it. Alternatively, try opening the side menu from the Dashboard, tapping 'Profile,' and then tapping the 'Reload Data' button.",
              )
            ) {
              console.log('ok pressed');
            }
          }, 1000);
        } else {
          setTimeout(() => {
            Alert.alert(
              'No quiz found',
              "There is either no quiz or you may be unauthorised to take it. Alternatively, try opening the side menu from the Dashboard, tapping 'Profile,' and then tapping the 'Reload Data' button.",
              [{text: 'OK', onPress: () => console.log('ok pressed')}],
              {cancelable: false},
            );
          }, 1000);
        }
      }
    } else {
      console.log('glid: ', glid);
    }
  }

  takeQuiz(
    quizSet: Object,
    quizMode: string,
    from: string,
    currentScreenState,
  ) {
    var isSequential = false;
    if (quizSet.type === 'topic') {
      var topic = _.find(this.state.topics, {PackName: quizSet.topicName});
      if (topic != null && topic.Sequential === true) {
        isSequential = true;
      }
    }
    if (quizSet.type === 'standby' || quizSet.type === 'caseStudy') {
      isSequential = true;
    }

    var questionsForQuizSet = quizSet.questions.slice();

    if (questionsForQuizSet.length > 0) {
      var latestResultsForQuizSets =
        this.state.latestResultsForQuizSets[quizSet.name];
      var correctlyAnsweredQuestions = [];
      var incorrectlyAnsweredQuestions = [];
      var mandatoryQuestions = [];

      for (
        let questionIndex = 0;
        questionIndex < questionsForQuizSet.length;
        questionIndex++
      ) {
        let question = questionsForQuizSet[questionIndex];
        if (question.Mandatory) {
          mandatoryQuestions.push(question.Name);
        }
      }

      if (latestResultsForQuizSets == null) {
        latestResultsForQuizSets = [];
      }

      for (
        let resultIndex = 0;
        resultIndex < latestResultsForQuizSets.length;
        resultIndex++
      ) {
        var result = latestResultsForQuizSets[resultIndex];
        var questionName = result.packName + '-' + result.questionID;

        if (result.correct) {
          correctlyAnsweredQuestions.push(questionName);
        } else {
          incorrectlyAnsweredQuestions.push(questionName);
        }
      }

      if (isSequential) {
        questionsForQuizSet = _.sortBy(questionsForQuizSet, ['QuestionID']);
      } else {
        // sort so questions are ordered: mandatory questions, incorrect questions, unanswered questions, correctly answered questions (but questions in each of those categories are still shuffled from earlier)
        questionsForQuizSet.sort(function (question1, question2) {
          var question1Value = 2;
          var question2Value = 2;

          if (mandatoryQuestions.includes(question1.Name)) {
            question1Value = 0;
          } else if (incorrectlyAnsweredQuestions.includes(question1.Name)) {
            question1Value = 1;
          } else if (correctlyAnsweredQuestions.includes(question1.Name)) {
            question1Value = 3;
          }

          if (mandatoryQuestions.includes(question2.Name)) {
            question2Value = 0;
          } else if (incorrectlyAnsweredQuestions.includes(question2.Name)) {
            question2Value = 1;
          } else if (correctlyAnsweredQuestions.includes(question2.Name)) {
            question2Value = 3;
          }

          return question1Value - question2Value;
        });
      }

      var questionsForQuiz = questionsForQuizSet;

      if (quizMode === '10 Questions') {
        questionsForQuiz = questionsForQuizSet.slice(0, 10);
      } else if (quizMode === 'PGD') {
        questionsForQuiz = questionsForQuizSet.slice(0, 5);
      } else if (quizMode === 'Incorrect Questions') {
        questionsForQuiz = [];

        for (
          let quizSetQuestionIndex = 0;
          quizSetQuestionIndex < questionsForQuizSet.length;
          quizSetQuestionIndex++
        ) {
          var quizSetQuestion = questionsForQuizSet[quizSetQuestionIndex];

          if (incorrectlyAnsweredQuestions.includes(quizSetQuestion.Name)) {
            questionsForQuiz.push(quizSetQuestion);
          }
        }
      }

      if (isSequential === false) {
        // shuffle picked questions so mandatory questions aren't always first
        questionsForQuiz = _.shuffle(questionsForQuiz);
      }

      var now = new Date();
      var nowTimestamp = now.getTime();

      var questionIDsForQuiz = questionsForQuiz.map(function (question) {
        return question.PackName + '-' + question.QuestionID;
      });

      var quiz = {
        name: quizSet.name + '-' + nowTimestamp,
        time: nowTimestamp,
        quizSet: quizSet.name,
        quizMode: quizMode,
        questions: questionIDsForQuiz,
      };

      this.navigateToScreen(
        'Quiz',
        {
          quiz: quiz,
          quizSet: quizSet,
          questions: questionsForQuiz,
          from: from,
        },
        currentScreenState,
      );
    }
  }

  goToBook(bookID, chapterNumber, sectionNumber, currentScreenState) {
    let book = null;
    if (bookID != null) {
      var books = this.state.books.slice();
      book = books.find(aBook => aBook.ID === bookID);
    }

    if (book != null && chapterNumber == null && sectionNumber == null) {
      // navigate to book
      this.navigateToScreen(
        'BookContents',
        {
          book: book,
        },
        currentScreenState,
      );

      let bookName = book.name;
      if (
        this.state.user &&
        this.state.user.paraPassTrusts &&
        this.state.user.paraPassTrusts.length > 0
      ) {
        let trust = this.state.user.paraPassTrusts[0];
        bookName = bookName + ' - ' + trust;
      }

      try {
        firebase
          .analytics()
          .logEvent('book_viewed', {bookViewedName: bookName});
      } catch (error) {
        console.log(error);
      }
    } else if (book != null && chapterNumber != null && sectionNumber == null) {
      // navigate to chapter
      if (book) {
        for (
          let allChaptersIndex = 0;
          allChaptersIndex < book.chapters.length;
          allChaptersIndex++
        ) {
          let aChapter = book.chapters[allChaptersIndex];
          if (aChapter.chapter === chapterNumber) {
            this.navigateToScreen(
              'BookReading',
              {
                book: book,
                chapterIndex: allChaptersIndex,
                sectionIndex: 0,
              },
              currentScreenState,
            );
          }
        }
      }
    } else if (book != null && chapterNumber != null && sectionNumber != null) {
      // navigate to section
      if (book) {
        for (
          let allChaptersIndex = 0;
          allChaptersIndex < book.chapters.length;
          allChaptersIndex++
        ) {
          let aChapter = book.chapters[allChaptersIndex];
          for (
            let allSectionsIndex = 0;
            allSectionsIndex < aChapter.sections.length;
            allSectionsIndex++
          ) {
            let aSection = aChapter.sections[allSectionsIndex];
            if (
              aChapter.chapter === chapterNumber &&
              aSection.section === sectionNumber
            ) {
              this.navigateToScreen(
                'BookReading',
                {
                  book: book,
                  chapterIndex: allChaptersIndex,
                  sectionIndex: allSectionsIndex,
                },
                currentScreenState,
              );
            }
          }
        }
      }
    }
  }

  navigateToScreen(screenName, params, currentScreenState) {
    if (Platform.OS === 'web') {
      this._navigation.navigate(screenName, params, currentScreenState);
    } else {
      if (
        this._navigation &&
        this._navigation.navigatorTabs != null &&
        this._navigation.navigatorTabs._navigation != null &&
        this._navigation.navigatorTabs._navigation._childrenNavigation &&
        this._navigation.navigatorTabs._navigation._childrenNavigation
          .Dashboard != null
      ) {
        this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.popToTop();

        this._navigation.navigatorTabs._navigation._childrenNavigation.Dashboard.push(
          screenName,
          params,
          currentScreenState,
        );
      } else {
        this._navigation.navigate(screenName, params, currentScreenState);
      }
    }
  }

  userUpdated(user) {
    // need to set to true when running iOS simulator - false on real iOS device
    var shouldProcessUser = true;

    if (user == null) {
      shouldProcessUser = false;
    }

    if (shouldProcessUser) {
      console.log('user changed');

      this.setState({user: user, hasDecidedIfLoggedIn: true});

      this.hideLoggingInIndicator();

      this.checkCurrentSubscriptions();

      this.updatePushNotificationTopics();

      this.loadData();

      try {
        if (user.plusTrusts != null && user.plusTrusts.length > 0) {
          firebase.analytics().setUserProperty('plusTrust', user.plusTrusts[0]);
        } else {
          firebase.analytics().setUserProperty('plusTrust', 'No Trust');
        }

        if (user.paraPassTrusts != null && user.paraPassTrusts.length > 0) {
          firebase
            .analytics()
            .setUserProperty('paraPassTrust', user.paraPassTrusts[0]);
        } else {
          firebase.analytics().setUserProperty('paraPassTrust', 'No Trust');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({user: null, hasDecidedIfLoggedIn: true});
    }
  }

  async loadData() {
    try {
      this.setState({showErrorLoadingMessage: false});

      const isPPPTester =
        this.state.user != null && this.state.user.isPPPTester === true;

      await Promise.all([
        RemoteDataController.downloadLatestPGDAcknowledgements(
          this.state.user.plusTrusts,
          this.state.user.uid,
        ),
        RemoteDataController.downloadTrustInfo(
          this.state.user.plusTrusts,
          this.state.user.uid,
        ),
        RemoteDataController.downloadPlusTimeLineData(this.state.user.uid),
      ]);

      let [
        guidelines,
        pgds,
        pgdAcknowledgements,
        excludedGuidelines,
        trustInfo,
        allBooks,
        bookBookmarks,
        bookReadingProgress,
        appFeatures,
        psirfData,
      ] = await Promise.all([
        GuidelinesController.getAllGuidelines(),
        GuidelinesController.getAllPGDs(),
        GuidelinesController.getAllPGDAcknowledgements(),
        GuidelinesController.getExcludedGuidelines(),
        DataController.getTrustInfo(),
        DataController.getAllBooks(),
        DataController.getBookBookmarks(),
        DataController.getBookReadingProgress(),
        DataController.getAppFeatures(),
        this.downloadPsirfs(),
      ]);

      const promotions = await RemoteDataController.downloadPromotions();
      const promotionForUser = await PromotionsController.getPromotionForUser(
        this.state.user.paraPassTrusts,
        promotions,
      );
      if (promotionForUser != null) {
        await RemoteDataController.getExistingPromoCode(
          promotionForUser.name,
          promotionForUser.trust,
          promotionForUser.id,
        );
      }

      var plusTimelineData =
        this.state.user != null
          ? await DataController.getPlusTimelineData(this.state.user.uid)
          : [];

      const books = allBooks.filter(book => {
        return (
          this.state.user &&
          _.intersection(this.state.user.paraPassTrusts, book.trusts).length > 0
        );
      });

      this.setState({
        guidelines,
        pgds,
        pgdAcknowledgements,
        excludedGuidelines,
        plusTimelineData,
        trustInfo,
        books,
        bookBookmarks,
        bookReadingProgress,
        psirfs: psirfData.psirfs,
        psirfNavCard: psirfData.psirfNavCard,
      });

      await this.getCachedData();

      if (this.state.quizSets.length === 0 && Platform.OS !== 'web') {
        this.setState({shouldShowLongLoadingMessage: true});
      }

      await this.checkCurrentSubscriptions();
      var user = await AuthenticationController.getUser();
      this.setState({user: user});

      // download app features
      try {
        let downloadedAppFeatures =
          await RemoteDataController.downloadAppFeatures(
            this.state.user.uid,
            isPPPTester,
          );
        if (downloadedAppFeatures != null && downloadedAppFeatures.length > 0) {
          appFeatures = downloadedAppFeatures;
        }
      } catch (error) {
        console.log(error);
      }

      // filter app features
      let filteredAppFeatures = [];
      if (user != null) {
        let userTrusts = _.union(user.plusTrusts, user.paraPassTrusts);
        for (
          let appFeatureIndex = 0;
          appFeatureIndex < appFeatures.length;
          appFeatureIndex++
        ) {
          let appFeature = appFeatures[appFeatureIndex];
          if (isPPPTester && appFeature.paraPassTesting) {
            if (
              appFeature.paraPassTesting.includes('ALL') ||
              _.intersection(appFeature.paraPassTesting, userTrusts).length >
                0 ||
              (userTrusts.length > 0 &&
                appFeature.paraPassTesting.includes('ALL TRUSTS')) ||
              (userTrusts.length === 0 &&
                appFeature.paraPassTesting.includes('NON TRUST USERS'))
            ) {
              filteredAppFeatures.push(appFeature.name);
            }
          } else if (!isPPPTester && appFeature.paraPassLive) {
            if (
              appFeature.paraPassLive.includes('ALL') ||
              _.intersection(appFeature.paraPassLive, userTrusts).length > 0 ||
              (userTrusts.length > 0 &&
                appFeature.paraPassLive.includes('ALL TRUSTS')) ||
              (userTrusts.length === 0 &&
                appFeature.paraPassLive.includes('NON TRUST USERS'))
            ) {
              filteredAppFeatures.push(appFeature.name);
            }
          }
        }
      }

      // filteredAppFeatures.push('paraPassParaFolioIntegrations');

      this.setState({appFeatures: filteredAppFeatures});

      // show books tab if trust has books
      let showBooksTab = user.hasBookAccess && this.state.books.length > 0;
      if (this.state.showBooksTab !== showBooksTab) {
        this.setState({showBooksTab});
      }

      const userTrusts = _.union(
        this.state.user.plusTrusts,
        this.state.user.paraPassTrusts,
      );

      // download latest guidelines, pgds and trust info
      await this.downloadLatestGuidelinesAndPGD(userTrusts);

      this.setState({loadingScreenText: 'Getting Latest PGD Acknowledgements'});

      if (this.state.user != null) {
        const plusTimelineData = await DataController.getPlusTimelineData(
          this.state.user.uid,
        );
        this.setState({plusTimelineData});
      }

      if (this.state.topics.length === 0) {
        // no topics found so must be first time loading - set guidelines after downloading them

        const [
          guidelines,
          pgds,
          pgdAcknowledgements,
          excludedGuidelines,
          plusTimelineData,
        ] = await Promise.all([
          GuidelinesController.getAllGuidelines(),
          GuidelinesController.getAllPGDs(),
          GuidelinesController.getAllPGDAcknowledgements(),
          GuidelinesController.getExcludedGuidelines(),
          DataController.getPlusTimelineData(this.state.user.uid),
        ]);

        this.setState({
          guidelines,
          pgds,
          pgdAcknowledgements,
          excludedGuidelines,
          plusTimelineData,
        });
      }

      await this.downloadOnlineData();
    } catch (error) {
      console.log('error loading data: ', error);

      this.setState({showErrorLoadingMessage: true});
    }

    var that = this;

    if (this.state.startedLoadingDataInterval === false) {
      setInterval(() => {
        if (this.state.user != null) {
          that.setState({isDownloadingOnlineData: true});
          RemoteDataController.resendOutstandingResults();
          RemoteDataController.resendOutstandingQuizzes();
          RemoteDataController.downloadAcknowledgements();
          RemoteDataController.downloadUpVotes();
          that.downloadOnlineData();
          that.setState({isDownloadingOnlineData: false});
        }
      }, 600000); // 10 minutes

      this.setState({startedLoadingDataInterval: true});
    }
  }

  async getCachedData() {
    try {
      console.log('Getting cached data');
      var t0 = new Date().getTime();

      const [
        topics,
        issues,
        caseStudies,
        featuredCards,
        authors,
        didYouKnowItems,
        videoAndPodcastItems,
        quizzes,
        results,
      ] = await Promise.all([
        DataController.getAllTopics(),
        DataController.getAllIssues(),
        DataController.getAllCaseStudies(),
        DataController.getAllFeaturedCards(),
        DataController.getAllAuthors(),
        DataController.getDidYouKnowItems(),
        DataController.getVideoAndPodcastItems(),
        DataController.getAllQuizzes(),
        DataController.getAllResults(),
      ]);

      const ppTrusts = this.state.validPPPSubscription
        ? this.state.user.paraPassTrusts
        : [];
      const userTrusts = _.union(this.state.user.plusTrusts, ppTrusts);

      const videos =
        videoAndPodcastItems == null
          ? []
          : videoAndPodcastItems?.Videos?.filter(
              vid => vid.Trust === 'ALL' || userTrusts.includes(vid.Trust),
            );
      const podcasts =
        videoAndPodcastItems == null
          ? []
          : videoAndPodcastItems?.Podcasts?.filter(
              pod => pod.Trust === 'ALL' || userTrusts.includes(pod.Trust),
            );

      this.setState({
        didYouKnowItems,
        videos,
        podcasts,
      });

      await this.updateTopics(topics, true, 0);
      await this.updateIssues(issues, true, 0);
      await this.updateFeaturedCards(featuredCards, true, 0);
      await this.updateAuthors(authors, true, 0);
      await this.updateQuizzes(quizzes, true, 0);
      await this.updateResults(results, true, 0);
      // Don't parallelize these or the quizzes wont load properly
      await this.updateCaseStudies(caseStudies, true, 0);

      var t1 = new Date().getTime();
      console.log('Loading json files took ' + (t1 - t0) + ' milliseconds.');
    } catch (error) {
      console.error('Error loading cached data:', error);
    }
  }

  async downloadDidYouKnowItems() {
    try {
      await RemoteDataController.downloadDidYouKnowItems();
      const didYouKnowItems = await DataController.getDidYouKnowItems();

      if (didYouKnowItems != null) {
        this.setState({didYouKnowItems});
      }
    } catch (error) {
      console.log('did you know error: ', error);
    }
  }

  /**
   * Update the psirfs for the user
   * @param {Object} options An object with two properties: `records` and `NavCard`
   * @param {Array} options.records An array of psirfs
   * @param {Array} options.NavCard An array of nav cards
   */
  async updatePsirfs({records, NavCard}) {
    // only update psirfs for the user
    const userTrusts = this.state.user.plusTrusts;
    const psirfsForUser = this.getPsirfsForUser(records);
    const psirfsForTrusts = this.getPsirfsForTrusts(psirfsForUser, userTrusts);
    const filteredPsirfs = this.filterPsirfWithoutTopSection(psirfsForTrusts);
    const psirfsWithImages = await Promise.all(
      filteredPsirfs.map(psirf => DataController.downloadPsirfImage(psirf)),
    );

    const navCardForTrust = NavCard.find(navCard =>
      userTrusts.includes(navCard.Trust),
    );

    this.setState({psirfs: psirfsWithImages, psirfNavCard: navCardForTrust});
  }

  /**
   * If the user is a PPP tester, return all PSIRFs.
   * Otherwise, return only the PSIRFs that are live.
   *
   * @param {Object[]} psirfs - List of all PSIRFs
   * @return {Object[]} - Filtered list of PSIRFs
   */
  getPsirfsForUser(psirfs) {
    const isPPPTester = this.state.user != null && this.state.user.isPPPTester;
    if (isPPPTester) {
      return psirfs;
    }

    return psirfs.filter(psirf => psirf.Live);
  }

  /**
   * Filters the PSIRFs based on the provided trusts.
   *
   * @param {Object[]} psirfs - List of PSIRFs to filter.
   * @param {string[]} trusts - List of trusts to match against.
   * @return {Object[]} - Filtered list of PSIRFs that belong to the specified trusts or are available to all.
   */
  getPsirfsForTrusts(psirfs, trusts) {
    return psirfs.filter(
      psirf => trusts.includes(psirf.Trust) || psirf.Trust === 'ALL',
    );
  }

  filterPsirfWithoutTopSection(data) {
    return data.filter(item =>
      item.content.some(contentItem => contentItem.type === 'TopSection'),
    );
  }

  async getPsirfs() {
    const psirfs = await DataController.getPsirfs();
    if (!psirfs?.records?.length) {
      const remotePsirfs = await RemoteDataController.downloadPsirfs();
      return {psirfs: remotePsirfs.records, navCards: remotePsirfs.NavCard};
    }
    return {psirfs: psirfs.records, navCards: psirfs.NavCard};
  }

  async downloadPsirfs() {
    const userTrusts = this.state.user.paraPassTrusts;
    try {
      const {psirfs, navCards} = await this.getPsirfs();
      const psirfsForUser = this.getPsirfsForUser(psirfs);
      const navCardForTrust = navCards?.find(navCard =>
        userTrusts.includes(navCard.Trust),
      );
      const psirfsForTrusts = this.getPsirfsForTrusts(
        psirfsForUser,
        userTrusts,
      );
      const filteredPsirfs = this.filterPsirfWithoutTopSection(psirfsForTrusts);
      const psirfsWithImages = await Promise.all(
        filteredPsirfs.map(psirf => DataController.downloadPsirfImage(psirf)),
      );

      return {psirfs: psirfsWithImages, psirfNavCard: navCardForTrust};
    } catch (error) {
      console.log('psirfs error:', error);
    }
  }

  async downloadVideosAndPodcasts() {
    try {
      const ppTrusts = this.state.validPPPSubscription
        ? this.state.user.paraPassTrusts
        : [];
      const userTrusts = _.union(this.state.user.plusTrusts, ppTrusts);
      await RemoteDataController.downloadVideosAndPodcasts();
      const videoAndPodcastItems =
        await DataController.getVideoAndPodcastItems();

      if (videoAndPodcastItems != null) {
        this.setState({
          videos: videoAndPodcastItems.Videos.filter(
            vid => vid.Trust === 'ALL' || userTrusts.includes(vid.Trust),
          ),
          podcasts: videoAndPodcastItems.Podcasts.filter(
            pod => pod.Trust === 'ALL' || userTrusts.includes(pod.Trust),
          ),
        });
      }
    } catch (error) {
      console.log('videos and podcasts error:', error);
    }
  }

  async downloadBookProgress() {
    try {
      await RemoteDataController.downloadBookProgress(this.state.user.uid);
      let bookProgress = await DataController.getBookReadingProgress();

      this.setState({
        bookReadingProgress: bookProgress,
        loadingScreenText: 'Getting Results',
      });
    } catch (error) {
      console.log('book progress error:', error);
    }
  }

  async downloadAndSaveDailyChallenge() {
    try {
      const dailyChallenge = await RemoteDataController.downloadDailyChallenge(
        this.state.user.uid,
      );
      if (dailyChallenge != null) {
        this.setState({dailyChallenge});
      }
    } catch (error) {
      console.log('daily challenge error:', error);
    }
  }

  async downloadOnlineData() {
    console.log('downloadOnlineData');
    this.setState({
      loadingScreenText: 'Getting Latest Quizzes',
      loadingBarText: 'Checking for updates',
    });

    try {
      const [
        topicsLastDownloadedDate,
        issuesLastDownloadedDate,
        caseStudiesLastDownloadedDate,
        authorsLastDownloadedDate,
        quizzesLastDownloadedDate,
        resultsLastDownloadedDate,
      ] =
        Platform.OS === 'web'
          ? [0, 0, 0, 0, 0, 0]
          : await Promise.all([
              DataController.getTopicsLastDownloadedDate(),
              DataController.getIssuesLastDownloadedDate(),
              DataController.getCaseStudiesLastDownloadedDate(),
              DataController.getAuthorsLastDownloadedDate(),
              DataController.getQuizzesLastDownloadedDate(),
              DataController.getResultsLastDownloadedDate(),
            ]);

      const localPsirfs = await DataController.getPsirfs();
      const [topics, issues, caseStudies, authors] = await Promise.all([
        DataController.getAllTopics(),
        DataController.getAllIssues(),
        DataController.getAllCaseStudies(),
        DataController.getAllAuthors(),
      ]);

      const [
        updatedTopicList,
        updatedIssueList,
        updatedCaseStudyList,
        updatedAuthorList,
        updatedFeaturedCardList,
        updatedQuizList,
        updatedResultsList,
        updatedPsirfs,
      ] = await Promise.all([
        RemoteDataController.downloadItemsModifiedSinceDate(
          'pppTopics',
          topicsLastDownloadedDate,
          this.state.user.uid,
          topics.length,
        ),
        RemoteDataController.downloadItemsModifiedSinceDate(
          'pppIssues',
          issuesLastDownloadedDate,
          this.state.user.uid,
          issues.length,
        ),
        RemoteDataController.downloadItemsModifiedSinceDate(
          'pppCaseStudies',
          caseStudiesLastDownloadedDate,
          this.state.user.uid,
          caseStudies.length,
        ),
        RemoteDataController.downloadItemsModifiedSinceDate(
          'pppAuthors',
          authorsLastDownloadedDate,
          this.state.user.uid,
          authors.length,
        ),
        RemoteDataController.downloadFeaturedCards(),
        RemoteDataController.downloadQuizzesSinceDate(
          quizzesLastDownloadedDate,
          this.state.user.uid,
        ),
        RemoteDataController.downloadResultsSinceDate(
          resultsLastDownloadedDate,
          this.state.user.uid,
        ),
        RemoteDataController.downloadPsirfsIfVersionChanged(
          localPsirfs.version,
        ),
      ]);

      const promotions = await RemoteDataController.downloadPromotions();
      const promotionForUser = await PromotionsController.getPromotionForUser(
        this.state.user.paraPassTrusts,
        promotions,
      );
      if (promotionForUser != null) {
        await RemoteDataController.getExistingPromoCode(
          promotionForUser.name,
          promotionForUser.trust,
          promotionForUser.id,
        );
      }

      await Promise.all([
        this.downloadAndSaveDailyChallenge(),
        this.downloadDidYouKnowItems(),
        this.downloadVideosAndPodcasts(),
        this.downloadBookProgress(),
      ]);

      var totalUpdates = 0;

      if (updatedFeaturedCardList != null) {
        await this.updateFeaturedCards(updatedFeaturedCardList, false, 0);
      }

      if (updatedTopicList != null && updatedTopicList.items.length > 0) {
        await this.updateTopics(
          updatedTopicList.items,
          false,
          updatedTopicList.lastDownloadedDate,
        );
        totalUpdates += updatedTopicList.items.length;
      }

      if (updatedIssueList != null && updatedIssueList.items.length > 0) {
        await this.updateIssues(
          updatedIssueList.items,
          false,
          updatedIssueList.lastDownloadedDate,
        );
        totalUpdates += updatedIssueList.items.length;
      }

      if (
        updatedCaseStudyList != null &&
        updatedCaseStudyList.items.length > 0
      ) {
        await this.updateCaseStudies(
          updatedCaseStudyList.items,
          false,
          updatedCaseStudyList.lastDownloadedDate,
        );
        totalUpdates += updatedCaseStudyList.items.length;
      }

      if (updatedAuthorList != null && updatedAuthorList.items.length > 0) {
        this.updateAuthors(
          updatedAuthorList.items,
          false,
          updatedAuthorList.lastDownloadedDate,
        );
        totalUpdates += updatedAuthorList.items.length;
      }

      if (updatedQuizList != null && updatedQuizList.items.length > 0) {
        this.updateQuizzes(
          updatedQuizList.items,
          false,
          updatedQuizList.lastDownloadedDate,
        );
        totalUpdates += updatedQuizList.items.length;
      }

      if (updatedResultsList != null && updatedResultsList.items.length > 0) {
        this.updateResults(
          updatedResultsList.items,
          false,
          updatedResultsList.lastDownloadedDate,
        );
        totalUpdates += updatedResultsList.items.length;
      }

      if (updatedPsirfs?.records?.length > 0) {
        this.updatePsirfs(updatedPsirfs);
      }

      if (this.state.user.migratedCPDResults !== true) {
        try {
          this.setState({loadingBarText: 'Importing quizzes from JRCALC:CPD'});

          const migrationResponse =
            await RemoteDataController.migrateCPDResults(this.state.user);

          if (
            migrationResponse.didMigrate != null &&
            migrationResponse.didMigrate === true
          ) {
            // data was migrated so get quizzes and results again
            await this.setStateAsync({results: [], quizzes: []});

            const [updatedQuizList, updatedResultsList] = await Promise.all([
              RemoteDataController.downloadQuizzesSinceDate(
                0,
                this.state.user.uid,
              ),
              RemoteDataController.downloadResultsSinceDate(
                0,
                this.state.user.uid,
              ),
            ]);

            if (updatedQuizList != null && updatedQuizList.items.length > 0) {
              this.updateQuizzes(
                updatedQuizList.items,
                false,
                updatedQuizList.lastDownloadedDate,
              );
              totalUpdates += updatedQuizList.items.length;
            }

            if (
              updatedResultsList != null &&
              updatedResultsList.items.length > 0
            ) {
              this.updateResults(
                updatedResultsList.items,
                false,
                updatedResultsList.lastDownloadedDate,
              );
              totalUpdates += updatedResultsList.items.length;
            }
          }
        } catch (error) {
          console.log(error);
        }

        this.setState({loadingBarText: 'Checking for updates'});
      }

      const showErrorLoadingMessage = this.state.quizSets.length === 0;

      this.setState({
        isLoadingData: false,
        showErrorLoadingMessage,
        shouldShowLongLoadingMessage: false,
      });
    } catch (error) {
      console.log(error);

      this.setState({
        isLoadingData: false,
        shouldShowLongLoadingMessage: false,
      });

      if (error === 'No token provided') {
        AuthenticationController.logOut();
      }
    }

    console.log('finished getting online data');
  }

  async updatePGDAcknowledgements() {
    if (this.state.user.plusTrusts.length > 0) {
      var pgdAcknowledgements =
        await GuidelinesController.getAllPGDAcknowledgements();
      this.setState({pgdAcknowledgements: pgdAcknowledgements});
    }
  }

  async downloadLatestGuidelinesAndPGD(userTrusts) {
    try {
      this.setState({loadingScreenText: 'Getting Latest Guidelines'});

      await Promise.all([
        RemoteDataController.downloadLatestGuidelines(this.state.user.uid),
        RemoteDataController.downloadExcludedGuidelines(
          userTrusts,
          this.state.user.uid,
        ),
        RemoteDataController.downloadLatestTrustGuidelines(
          userTrusts,
          this.state.user.uid,
          this.state.user.isPPPTester,
        ),
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async updateLoadingProgress(loadingText: string) {
    this.setState({loadingScreenText: loadingText});
  }

  async updateTopics(topics, isCache: boolean, downloadDate: ?number) {
    var questionsForUser = this.state.questions.slice();
    var topicsForUser = this.state.topics.slice();
    var allTopics = this.state.topics.slice();

    var questionsForUserObject = {};
    var topicsForUserObject = {};
    var allTopicsObject = {};

    for (
      let questionsForUserIndex = 0;
      questionsForUserIndex < questionsForUser.length;
      questionsForUserIndex++
    ) {
      var questionForUser = questionsForUser[questionsForUserIndex];
      questionsForUserObject[questionForUser.Name] = questionForUser;
    }

    for (
      let topicsForUserIndex = 0;
      topicsForUserIndex < topicsForUser.length;
      topicsForUserIndex++
    ) {
      var topicForUser = topicsForUser[topicsForUserIndex];
      topicsForUserObject[topicForUser.PackName] = topicForUser;
    }

    for (
      let allTopicsIndex = 0;
      allTopicsIndex < allTopics.length;
      allTopicsIndex++
    ) {
      var aTopic = allTopics[allTopicsIndex];
      allTopicsObject[aTopic.PackName] = aTopic;
    }

    console.log('questionsForUser start - topic: ', questionsForUser.length);

    if (Platform.OS === 'web') {
      allTopics = []; // web version gets all data again as it doesn't save data locally
      topicsForUser = [];
    }

    for (let topicIndex = 0; topicIndex < topics.length; topicIndex++) {
      const topic = topics[topicIndex];
      var topicIsForUser = true;

      // exclude pgd topics which aren't relevant to users trust/staff group
      if (topic.Type === 'PGD' || topic.Type === 'PMA') {
        var isPGDTopicForUser = await DataController.isPGDTopicForUser(
          topic,
          this.state.user,
        );

        if (isPGDTopicForUser === false) {
          topicIsForUser = false;
        }
      } else {
        // check if topic trust matches user trust
        if (topic.Trust != null && topic.Trust !== '') {
          if (this.state.user.paraPassTrusts.includes(topic.Trust) === false) {
            topicIsForUser = false;
          }
        }
      }

      // check if topic is LiveInApp
      if (topic.LiveInApp == null && topic.PMA == null) {
        if (this.state.user == null || this.state.user.isPPPTester !== true) {
          topicIsForUser = false;
        }
      }

      if (topic.Archived === true) {
        topicIsForUser = false;
      }

      if (topicIsForUser) {
        // add questions from topic to questionsForUser array
        for (
          let topicQuestionIndex = 0;
          topicQuestionIndex < topic.Questions.length;
          topicQuestionIndex++
        ) {
          var topicQuestion = topic.Questions[topicQuestionIndex];

          // check if question has a trust and if so if it matches users trust
          if (topicQuestion.Trust != null && topicQuestion.Trust !== '') {
            if (
              this.state.user.plusTrusts.includes(topicQuestion.Trust) ===
                false &&
              this.state.user.paraPassTrusts.includes(topicQuestion.Trust) ===
                false
            ) {
              continue;
            }
          }

          // let matchingQuestionIndex = _.findIndex(questionsForUser, {Name: topicQuestion.Name});
          // if (matchingQuestionIndex === -1) {
          //   questionsForUser.push(topicQuestion);
          // } else {
          //   questionsForUser[matchingQuestionIndex] = topicQuestion;
          // }

          if (topicQuestion.ImageAsset != null) {
            await DataController.downloadQuestionImage(topicQuestion);
          }

          questionsForUserObject[topicQuestion.Name] = topicQuestion;
        }

        // add topic to topicsForUser
        // let matchingTopicIndex = _.findIndex(topicsForUser, {PackName: topic.PackName});
        // if (matchingTopicIndex === -1) {
        //   topicsForUser.push(topic);
        // } else {
        //   topicsForUser[matchingTopicIndex] = topic;
        // }

        topicsForUserObject[topic.PackName] = topic;
      }

      // add topic to allTopics
      // let matchingTopicIndex = _.findIndex(allTopics, {PackName: topic.PackName});
      // if (matchingTopicIndex === -1) {
      //   allTopics.push(topic);
      // } else {
      //   allTopics[matchingTopicIndex] = topic;
      // }

      allTopicsObject[topic.PackName] = topic;
    }

    console.log('updated topics - ' + topics.length);

    questionsForUser = Object.values(questionsForUserObject);
    topicsForUser = Object.values(topicsForUserObject);
    allTopics = Object.values(allTopicsObject);

    if (isCache === false) {
      // combine cached topics with new topics and save;
      await DataController.saveAllTopics(allTopics, downloadDate);
    }

    await this.setStateAsync({
      topics: topicsForUser,
      questions: questionsForUser,
    });

    // console.log('questionsForUser end - topic: ', questionsForUser.length);

    this.updateQuizSets(isCache);
  }

  async updateIssues(issues, isCache: boolean, downloadDate: ?number) {
    var questionsForUser = this.state.questions.slice();
    var issuesForUser = this.state.issues.slice();
    var allIssues = this.state.issues.slice();

    var questionsForUserObject = {};

    for (
      let questionsForUserIndex = 0;
      questionsForUserIndex < questionsForUser.length;
      questionsForUserIndex++
    ) {
      var questionForUser = questionsForUser[questionsForUserIndex];
      questionsForUserObject[questionForUser.Name] = questionForUser;
    }

    // console.log('questionsForUser start - issue: ', questionsForUser.length);

    if (Platform.OS === 'web') {
      allIssues = []; // web version gets all data again as it doesn't save data locally
      issuesForUser = [];
    }

    for (var issueIndex = 0; issueIndex < issues.length; issueIndex++) {
      const issue = issues[issueIndex];
      var issueIsForUser = true;

      // check if issue is LiveInApp
      if (issue.LiveInApp == null) {
        if (this.state.user == null || this.state.user.isPPPTester !== true) {
          issueIsForUser = false;
        }
      }

      if (issue.Archived === true) {
        issueIsForUser = false;
      }

      if (issueIsForUser) {
        // add questions from issue to questionsForUser array
        for (
          let issueQuestionIndex = 0;
          issueQuestionIndex < issue.Questions.length;
          issueQuestionIndex++
        ) {
          var issueQuestion = issue.Questions[issueQuestionIndex];

          if (issueQuestion.ImageAsset != null) {
            await DataController.downloadQuestionImage(issueQuestion);
          }

          questionsForUserObject[issueQuestion.Name] = issueQuestion;
        }

        // add issue to issuesForUser
        let matchingIssueIndex = _.findIndex(issuesForUser, {Name: issue.Name});
        if (matchingIssueIndex === -1) {
          issuesForUser.push(issue);
        } else {
          issuesForUser[matchingIssueIndex] = issue;
        }
      }

      // add issue to all issues
      let matchingIssueIndex = _.findIndex(allIssues, {Name: issue.Name});
      if (matchingIssueIndex === -1) {
        allIssues.push(issue);
      } else {
        allIssues[matchingIssueIndex] = issue;
      }
    }

    this.downloadStandbyIssueImages(issues);

    // console.log('updated issues - ' + issues.length);

    if (isCache === false) {
      // combine cached issues with new issues and save;
      await DataController.saveAllIssues(allIssues, downloadDate);
    }

    issuesForUser = _.orderBy(issuesForUser, ['PublicDate'], ['desc']);

    questionsForUser = Object.values(questionsForUserObject);

    await this.setStateAsync({
      issues: issuesForUser,
      questions: questionsForUser,
    });

    // console.log('questionsForUser end - issue: ', questionsForUser.length);

    this.updateQuizSets(isCache);
  }

  async downloadStandbyIssueImages(issues) {
    for (var issueIndex = 0; issueIndex < issues.length; issueIndex++) {
      const issue = issues[issueIndex];
      await DataController.downloadStandbyIssueImages(issue);

      // refresh images every 10 issues downloaded - so progress can be seen
      if (issueIndex > 0 && issueIndex % 10 === 0) {
        if (issues.length > 0) {
          EventRegister.emit('imagesUpdated', '');
        }
      }
    }

    if (issues.length > 0) {
      EventRegister.emit('imagesUpdated', '');
    }
  }

  async updateCaseStudies(
    caseStudies,
    isCache: boolean,
    downloadDate: ?number,
  ) {
    var questionsForUser = this.state.questions.slice();
    var caseStudiesForUser = this.state.caseStudies.slice();
    var allCaseStudies = this.state.caseStudies.slice();
    var questionsForUserObject = {};

    for (
      let questionsForUserIndex = 0;
      questionsForUserIndex < questionsForUser.length;
      questionsForUserIndex++
    ) {
      var questionForUser = questionsForUser[questionsForUserIndex];
      questionsForUserObject[questionForUser.Name] = questionForUser;
    }

    // console.log(
    //   'questionsForUser start - caseStudy: ',
    //   questionsForUser.length,
    // );

    if (Platform.OS === 'web') {
      allCaseStudies = []; // web version gets all data again as it doesn't save data locally
      caseStudiesForUser = [];
    }

    for (
      var caseStudyIndex = 0;
      caseStudyIndex < caseStudies.length;
      caseStudyIndex++
    ) {
      const caseStudy = caseStudies[caseStudyIndex];
      var caseStudyIsForUser = true;

      // check if user should have access to case study
      if (this.state.user != null) {
        if (caseStudy.Trust != null) {
          if (
            this.state.user.paraPassTrusts.includes(caseStudy.Trust) ||
            (this.state.user.plusTrusts.includes(caseStudy.Trust) &&
              this.state.validPPPSubscription)
          ) {
            // case study belongs to users trust
            if (this.state.user.isPPPTester === true) {
              caseStudyIsForUser = true;
            } else {
              if (caseStudy.LiveForTrust) {
                caseStudyIsForUser = true;
              } else {
                caseStudyIsForUser = false;
              }
            }
          } else {
            caseStudyIsForUser = false;
          }
        } else {
          if (!this.state.validPPPSubscription) {
            caseStudyIsForUser = false;
          } else if (caseStudy.LiveInApp == null) {
            if (this.state.user.isPPPTester !== true) {
              caseStudyIsForUser = false;
            }
          }
        }
      } else {
        caseStudyIsForUser = false;
      }

      if (caseStudy.Archived === true) {
        caseStudyIsForUser = false;
      }

      if (caseStudyIsForUser) {
        // add questions from caseStudy to questionsForUser array
        for (
          let caseStudyQuestionIndex = 0;
          caseStudyQuestionIndex < caseStudy.Questions.length;
          caseStudyQuestionIndex++
        ) {
          var caseStudyQuestion = caseStudy.Questions[caseStudyQuestionIndex];

          // let matchingQuestionIndex = _.findIndex(questionsForUser, {Name: caseStudyQuestion.Name});

          // if (matchingQuestionIndex === -1) {
          //   questionsForUser.push(caseStudyQuestion);
          // } else {
          //   questionsForUser[matchingQuestionIndex] = caseStudyQuestion;
          // }

          if (caseStudyQuestion.ImageAsset != null) {
            await DataController.downloadQuestionImage(caseStudyQuestion);
          }

          questionsForUserObject[caseStudyQuestion.Name] = caseStudyQuestion;
        }

        // add caseStudy to caseStudiesForUser
        let matchingCaseStudyIndex = _.findIndex(caseStudiesForUser, {
          Name: caseStudy.Name,
        });
        if (matchingCaseStudyIndex === -1) {
          caseStudiesForUser.push(caseStudy);
        } else {
          caseStudiesForUser[matchingCaseStudyIndex] = caseStudy;
        }
      }

      // add caseStudy to allCaseStudies
      let matchingCaseStudyIndex = _.findIndex(allCaseStudies, {
        Name: caseStudy.Name,
      });
      if (matchingCaseStudyIndex === -1) {
        allCaseStudies.push(caseStudy);
      } else {
        allCaseStudies[matchingCaseStudyIndex] = caseStudy;
      }
    }

    this.downloadCaseStudyImages(caseStudies);

    // console.log('updated caseStudies - ' + caseStudies.length);

    if (isCache === false) {
      // combine cached caseStudies with new caseStudies and save;
      DataController.saveAllCaseStudies(allCaseStudies, downloadDate);
    }

    caseStudiesForUser = _.orderBy(
      caseStudiesForUser,
      ['PublicDate'],
      ['desc'],
    );

    questionsForUser = Object.values(questionsForUserObject);

    await this.setStateAsync({
      caseStudies: caseStudiesForUser,
      questions: questionsForUser,
    });

    // console.log('questionsForUser end - caseStudy: ', questionsForUser.length);

    await this.updateQuizSets(isCache);
  }

  async downloadCaseStudyImages(caseStudies) {
    for (
      var caseStudyIndex = 0;
      caseStudyIndex < caseStudies.length;
      caseStudyIndex++
    ) {
      const caseStudy = caseStudies[caseStudyIndex];
      await DataController.downloadCaseStudyImages(caseStudy);
    }

    if (caseStudies.length > 0) {
      // setTimeout(() => {
      EventRegister.emit('imagesUpdated', '');
      // }, 1000);
    }
  }

  async updateFeaturedCards(
    featuredCards,
    isCache: boolean,
    downloadDate: ?number,
  ) {
    var allFeaturedCards = this.state.featuredCards.slice();
    var featuredCardsForUser = this.state.featuredCards.slice();

    if (Platform.OS === 'web') {
      allFeaturedCards = []; // web version gets all data again as it doesn't save data locally
      featuredCardsForUser = [];
    }

    for (
      let featuredCardIndex = 0;
      featuredCardIndex < featuredCards.length;
      featuredCardIndex++
    ) {
      const featuredCard = featuredCards[featuredCardIndex];

      let featuredCardIsForUser = false;
      if (
        featuredCard.Trusts.includes('ALL') ||
        _.intersection(this.state.user.plusTrusts, featuredCard.Trusts).length >
          0 ||
        _.intersection(this.state.user.paraPassTrusts, featuredCard.Trusts)
          .length > 0
      ) {
        if (
          featuredCard.Live === true ||
          (this.state.user != null && this.state.user.isPPPTester)
        ) {
          featuredCardIsForUser = true;
        }
      }

      if (featuredCardIsForUser) {
        let matchingFeaturedCardIndex = _.findIndex(featuredCardsForUser, {
          id: featuredCard.id,
        });

        if (matchingFeaturedCardIndex === -1) {
          featuredCardsForUser.push(featuredCard);
        } else {
          featuredCardsForUser[matchingFeaturedCardIndex] = featuredCard;
        }
      }

      let matchingFeaturedCardIndex = _.findIndex(allFeaturedCards, {
        id: featuredCard.id,
      });

      if (matchingFeaturedCardIndex === -1) {
        allFeaturedCards.push(featuredCard);
      } else {
        allFeaturedCards[matchingFeaturedCardIndex] = featuredCard;
      }
    }

    // sort into sort order then last modified
    try {
      featuredCardsForUser = _.orderBy(
        featuredCardsForUser,
        ['SortOrder', 'LastModified'],
        ['asc', 'desc'],
      );
    } catch (error) {
      console.log(error);
    }

    // Download images
    await Promise.all(
      featuredCards.map(async featuredCard =>
        DataController.downloadFeaturedCardImages(featuredCard),
      ),
    );

    await this.setStateAsync({featuredCards: featuredCardsForUser});
    this.updateQuizSets(isCache);
  }

  async updateAuthors(authors, isCache: boolean, downloadDate: ?number) {
    var allAuthors = this.state.authors.slice();

    if (Platform.OS === 'web') {
      allAuthors = []; // web version gets all data again as it doesn't save data locally
    }

    for (var authorIndex = 0; authorIndex < authors.length; authorIndex++) {
      const author = authors[authorIndex];

      let matchingAuthorIndex = _.findIndex(allAuthors, {Name: author.Name});

      if (matchingAuthorIndex === -1) {
        allAuthors.push(author);
      } else {
        allAuthors[matchingAuthorIndex] = author;
      }
    }

    console.log('updated authors - ' + authors.length);

    if (isCache === false) {
      // combine cached authors with new authors and save;
      DataController.saveAllAuthors(allAuthors, downloadDate);
    }

    this.setState({authors: allAuthors});
  }

  updateQuizzes(quizzes, isCache: boolean, downloadDate: ?number) {
    var allQuizzes = this.state.quizzes.slice();

    var allQuizzesObject = {};

    for (
      let allQuizzesIndex = 0;
      allQuizzesIndex < allQuizzes.length;
      allQuizzesIndex++
    ) {
      var aQuiz = allQuizzes[allQuizzesIndex];
      allQuizzesObject[aQuiz.name] = aQuiz;
    }

    if (Platform.OS === 'web') {
      allQuizzes = []; // web version gets all data again as it doesn't save data locally
    }

    for (var quizIndex = 0; quizIndex < quizzes?.length; quizIndex++) {
      const quiz = quizzes[quizIndex];

      // let matchingQuizIndex = _.findIndex(allQuizzes, {name: quiz.name});

      // if (matchingQuizIndex === -1) {
      //   allQuizzes.push(quiz);
      // } else {
      //   allQuizzes[matchingQuizIndex] = quiz;
      // }

      allQuizzesObject[quiz.name] = quiz;
    }

    allQuizzes = Object.values(allQuizzesObject);

    allQuizzes = _.orderBy(allQuizzes, ['time'], ['desc']);

    console.log('updated quizzes - ' + quizzes?.length);

    if (isCache === false) {
      // combine cached quizzes with new quizzes and save;
      DataController.saveAllQuizzes(allQuizzes, downloadDate);
    }

    this.setState({
      quizzes: allQuizzes,
    });
  }

  async updateResults(results, isCache: boolean, downloadDate: ?number) {
    var allResults = this.state.results.slice();

    var allResultsObject = {};

    for (
      let allResultsIndex = 0;
      allResultsIndex < allResults.length;
      allResultsIndex++
    ) {
      var aResult = allResults[allResultsIndex];
      allResultsObject[
        aResult.quizName + '-' + aResult.packName + '-' + aResult.questionID
      ] = aResult;
    }

    if (Platform.OS === 'web') {
      allResults = []; // web version gets all data again as it doesn't save data locally
    }

    for (var resultIndex = 0; resultIndex < results?.length; resultIndex++) {
      const result = results[resultIndex];

      // check quiz exists for result (was causing bug where there were results with no quizzes or questions)
      var quizFound = false;
      for (
        let quizIndex = 0;
        quizIndex < this.state.quizzes?.length;
        quizIndex++
      ) {
        var quiz = this.state.quizzes[quizIndex];
        if (result.quizName === quiz.name) {
          quizFound = true;
          break;
        }
      }

      if (quizFound === false) {
        continue;
      }

      // let matchingResultIndex = _.findIndex(allResults, {quizName: result.quizName, questionID: result.questionID});

      // if (matchingResultIndex === -1) {
      //   allResults.push(result);
      // } else {
      //   allResults[matchingResultIndex] = result;
      // }

      if (
        allResultsObject[
          result.quizName + '-' + result.packName + '-' + result.questionID
        ] != null
      ) {
        // console.log('duplicate results');
      }

      allResultsObject[
        result.quizName + '-' + result.packName + '-' + result.questionID
      ] = result;
    }

    allResults = Object.values(allResultsObject);

    allResults = _.orderBy(allResults, ['time'], ['desc']);

    console.log('updated results - ' + results?.length);

    if (isCache === false) {
      // combine cached quizzes with new quizzes and save;
      DataController.saveAllResults(allResults, downloadDate);
    }

    var that = this;

    await this.setStateAsync({results: allResults});
    that.updateQuizResults();
  }

  async updateQuizResults() {
    if (this.state.quizSets.length === 0) {
      return; // don't process until there is quiz sets
    }

    var t0 = new Date().getTime();
    console.log('starting to update quiz results');

    var results = _.orderBy(
      this.state.results,
      ['packName', 'questionID', 'time'],
      ['asc', 'asc', 'desc'],
    );

    var latestResultsForQuizSets = {};
    var latestResultsForQuestions = [];
    var latestResultsForQuestions = [];
    var resultsForQuizzes = {};

    // get latest results for each question
    for (let resultIndex = 0; resultIndex < results?.length; resultIndex++) {
      var result = results[resultIndex];

      var existingResults = _.filter(
        latestResultsForQuestions,
        function (existingResult) {
          return (
            existingResult.packName === result.packName &&
            existingResult.questionID === result.questionID
          );
        },
      );

      if (existingResults.length === 0) {
        latestResultsForQuestions.push(result);
      }
    }

    var t1 = new Date().getTime();
    console.log('Filtering quiz results took ' + (t1 - t0) + ' milliseconds.');
    // Alert.alert("Filtering quiz results took " + (t1 - t0) + " milliseconds.");

    var guidelinesForQuestions = {};
    var sectionsForQuestions = {};
    var currentResults = []; // results for questions still in the app (not been removed)

    for (
      let latestResultForQuestionIndex = 0;
      latestResultForQuestionIndex < latestResultsForQuestions.length;
      latestResultForQuestionIndex++
    ) {
      var latestResultForQuestion =
        latestResultsForQuestions[latestResultForQuestionIndex];
      var resultQuestionName =
        latestResultForQuestion.packName +
        '-' +
        latestResultForQuestion.questionID;
      for (
        let questionIndex = 0;
        questionIndex < this.state.questions.length;
        questionIndex++
      ) {
        var question = this.state.questions[questionIndex];
        if (question.Name === resultQuestionName) {
          currentResults.push(latestResultForQuestion);
          break;
        }
      }
    }

    for (
      let questionIndex = 0;
      questionIndex < this.state.questions.length;
      questionIndex++
    ) {
      var question = this.state.questions[questionIndex];

      if (question.Reference != null) {
        var guideline = question.Reference.split(' - ')[0];
        guidelinesForQuestions[question.Name] = guideline;
      }

      if (question.Section != null) {
        sectionsForQuestions[question.Name] = question.Section;
      }
    }

    // get latest results for each question in quiz set
    for (
      let resultIndex = 0;
      resultIndex < latestResultsForQuestions.length;
      resultIndex++
    ) {
      var result = latestResultsForQuestions[resultIndex];

      if (currentResults.includes(result) === false) {
        continue;
      }

      var questionName = result.packName + '-' + result.questionID;

      for (
        let quizSetIndex = 0;
        quizSetIndex < this.state.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.state.quizSets[quizSetIndex];

        let questionsNamesForQuizSet = quizSet.questions.map(
          question => question.Name,
        );

        if (questionsNamesForQuizSet.includes(questionName)) {
          var quizSetResults = latestResultsForQuizSets[quizSet.name];

          if (quizSetResults == null) {
            quizSetResults = [];
          }

          if (quizSet.type === 'topic') {
            if (quizSet.topicName === result.packName) {
              quizSetResults.push(result);
            }
          }

          if (quizSet.type === 'guideline') {
            var questionGuideline = guidelinesForQuestions[questionName];

            if (questionGuideline != null) {
              if (quizSet.guideline === questionGuideline) {
                quizSetResults.push(result);
              }
            }
          }

          if (quizSet.type === 'section') {
            var questionSection = sectionsForQuestions[questionName];

            if (questionSection != null) {
              if (quizSet.section === questionSection) {
                quizSetResults.push(result);
              }
            }
          }

          if (quizSet.type === 'standby') {
            if (quizSet.issueName === result.packName) {
              quizSetResults.push(result);
            }
          }

          if (quizSet.type === 'caseStudy') {
            if (quizSet.caseStudy === result.packName) {
              quizSetResults.push(result);
            }
          }

          latestResultsForQuizSets[quizSet.name] = quizSetResults;
        }
      }
    }

    var t2 = new Date().getTime();
    console.log('QuizSet results took ' + (t2 - t0) + ' milliseconds.');
    // Alert.alert("QuizSet results took " + (t2 - t0) + " milliseconds.");

    for (
      let resultIndex = 0, resultsLength = results?.length;
      resultIndex < resultsLength;
      resultIndex++
    ) {
      var result = results[resultIndex];

      for (
        let quizIndex = 0, quizzesLength = this.state.quizzes?.length;
        quizIndex < quizzesLength;
        quizIndex++
      ) {
        var quiz = this.state.quizzes[quizIndex];

        if (result.quizName === quiz.name) {
          if (resultsForQuizzes[quiz.name] == null) {
            resultsForQuizzes[quiz.name] = [result];
          } else {
            resultsForQuizzes[quiz.name].push(result);
          }
          break;
        }
      }
    }

    var t3 = new Date().getTime();
    console.log('Quiz results took ' + (t3 - t0) + ' milliseconds.');
    // Alert.alert("Quiz results took " + (t3 - t0) + " milliseconds.");

    var inProgressQuizzes = [];

    for (
      let quizIndex = 0;
      quizIndex < this.state.quizzes?.length;
      quizIndex++
    ) {
      var quiz = this.state.quizzes[quizIndex];
      var resultsForQuiz = resultsForQuizzes[quiz.name];

      if (resultsForQuiz != null) {
        if (resultsForQuiz.length !== quiz.questions.length) {
          inProgressQuizzes.push(quiz);
        }
      }
    }

    this.setState({
      results: results,
      latestResultsForQuizSets: latestResultsForQuizSets,
      resultsForQuizzes: resultsForQuizzes,
      inProgressQuizzes: inProgressQuizzes,
    });

    var tEnd = new Date().getTime();
    console.log('Updating quiz results took ' + (tEnd - t0) + ' milliseconds.');
    // Alert.alert("Updating quiz results took " + (tEnd - t0) + " milliseconds.");
  }

  async updateQuizSets(isCache: boolean) {
    console.log(
      'starting to update quizsets - !!!!' +
        this.state.topics.length +
        ',' +
        this.state.issues.length +
        ',' +
        this.state.caseStudies.length,
    );

    if (this.state.topics.length === 0 || this.state.issues.length === 0) {
      return;
    }

    try {
      var t0 = new Date().getTime();

      var quizSets = [];
      var issueQuestionNames = [];
      var caseStudyQuestionNames = [];
      var sequentialTopicNames = [];

      for (
        let topicIndex = 0;
        topicIndex < this.state.topics.length;
        topicIndex++
      ) {
        var topic = this.state.topics[topicIndex];

        var releaseDate = moment(topic.ReleaseDate).unix() * 1000;

        if (topic.Sequential) {
          sequentialTopicNames.push(topic.PackName);
        }

        if (
          this.state.validPPPSubscription ||
          topic.Type === 'PGD' ||
          topic.Type === 'PMA'
        ) {
          var topicQuizSet = {
            name: topic.PackName,
            title: topic.Title,
            type: 'topic',
            topicName: topic.PackName,
            date: releaseDate,
          };

          if (topic.Trust != null && topic.Trust !== '') {
            topicQuizSet.trust = topic.Trust;
          }

          if (topic.Type === 'PGD' || topic.Type === 'PMA') {
            topicQuizSet.pgd = true;
            topicQuizSet.pgdInfo = {};

            if (topic.Type === 'PMA') {
              topicQuizSet.pma = true;
            }

            for (
              let pgdIndex = 0;
              pgdIndex < this.state.pgds.length;
              pgdIndex++
            ) {
              var pgd = this.state.pgds[pgdIndex];

              if (pgd.GLID === topic.GLID) {
                topicQuizSet.pgdInfo.title = pgd.Title;
                topicQuizSet.pgdInfo.versionNumber =
                  pgd.publ_major_version_no + '.' + pgd.publ_minor_version_no;
                topicQuizSet.pgdInfo.GLID = pgd.GLID;

                for (
                  let pgdAcknowledgementIndex = 0;
                  pgdAcknowledgementIndex <
                  this.state.pgdAcknowledgements.length;
                  pgdAcknowledgementIndex++
                ) {
                  var pgdAcknowledgement =
                    this.state.pgdAcknowledgements[pgdAcknowledgementIndex];

                  if (pgdAcknowledgement.GLID === topic.GLID) {
                    if (
                      pgdAcknowledgement.ACKTime != null &&
                      pgdAcknowledgement.ACKTime !== ''
                    ) {
                      topicQuizSet.pgdInfo.acknowledged = true;
                    } else {
                      topicQuizSet.pgdInfo.acknowledged = false;
                    }
                    break;
                  }
                }

                break;
              }
            }

            if (topicQuizSet.pgdInfo.GLID == null) {
              topicQuizSet.pgdInfo.GLID = topic.GLID;
            }
          }

          quizSets.push(topicQuizSet);
        }
      }

      if (this.state.validPPPSubscription) {
        for (
          let issueIndex = 0;
          issueIndex < this.state.issues.length;
          issueIndex++
        ) {
          var issue = this.state.issues[issueIndex];

          var issueDate = moment(issue.PublicDate).unix() * 1000;

          for (
            let issueQuestionIndex = 0;
            issueQuestionIndex < issue.Questions.length;
            issueQuestionIndex++
          ) {
            var issueQuestion = issue.Questions[issueQuestionIndex];
            issueQuestionNames.push(issueQuestion.Name);
          }

          quizSets.push({
            name: issue.Name,
            title: issue.Title,
            type: 'standby',
            issueName: issue.Name,
            questions: issue.Questions,
            date: issueDate,
          });
        }

        for (
          let caseStudyIndex = 0;
          caseStudyIndex < this.state.caseStudies.length;
          caseStudyIndex++
        ) {
          var caseStudy = this.state.caseStudies[caseStudyIndex];
          var caseStudyLastModifiedDate =
            moment(caseStudy.PublicDate).unix() * 1000;

          for (
            let caseStudyQuestionIndex = 0;
            caseStudyQuestionIndex < caseStudy.Questions.length;
            caseStudyQuestionIndex++
          ) {
            var caseStudyQuestion = caseStudy.Questions[caseStudyQuestionIndex];
            caseStudyQuestionNames.push(caseStudyQuestion.Name);
          }

          quizSets.push({
            name: caseStudy.Name,
            title: caseStudy.Title,
            type: 'caseStudy',
            caseStudy: caseStudy.Name,
            questions: caseStudy.Questions,
            date: caseStudyLastModifiedDate,
          });
        }

        // create guideline and section quizSets
        var questionGuidelines = [];
        var sections = [];

        for (
          let questionIndex = 0;
          questionIndex < this.state.questions.length;
          questionIndex++
        ) {
          var question = this.state.questions[questionIndex];

          // if (question.PackName === 'TestingTopic' && (this.state.user == null || this.state.user.isPPPTester !== true) ) {
          //   continue;
          // }

          if (question.Section != null) {
            if (
              issueQuestionNames.includes(question.Name) === false &&
              caseStudyQuestionNames.includes(question.Name) === false
            ) {
              // don't include standby or case study questions

              if (sequentialTopicNames.includes(question.PackName) === false) {
                // don't include sequential questions

                if (sections.includes(question.Section) === false) {
                  sections.push(question.Section);
                }
              }
            }
          }

          if (question.Reference != null && question.Reference !== '') {
            if (
              issueQuestionNames.includes(question.Name) === false &&
              caseStudyQuestionNames.includes(question.Name) === false
            ) {
              // don't include standby or case study questions

              if (sequentialTopicNames.includes(question.PackName) === false) {
                // don't include sequential questions

                try {
                  var guidelineID = question.Reference.split(' - ')[0];
                  var guidelineTitle = question.Reference.split(' - ')[1];

                  if (guidelineID === 'G0060') {
                    console.log('found G0060');
                  }

                  let matchingGuideline = _.find(questionGuidelines, {
                    glid: guidelineID,
                  });

                  if (matchingGuideline == null) {
                    questionGuidelines.push({
                      glid: guidelineID,
                      title: guidelineTitle,
                    });
                  }
                } catch (error) {
                  console.log("Couldn't parse guideline: ", error);
                }
              }
            }
          }
        }

        for (
          let sectionIndex = 0;
          sectionIndex < sections.length;
          sectionIndex++
        ) {
          var section = sections[sectionIndex];

          if (section === 'PGD' || section === 'PMA') {
            continue;
          }

          quizSets.push({
            name: section,
            title: section,
            type: 'section',
            section: section,
          });
        }

        for (
          let questionGuidelineIndex = 0;
          questionGuidelineIndex < questionGuidelines.length;
          questionGuidelineIndex++
        ) {
          var questionGuideline = questionGuidelines[questionGuidelineIndex];
          var guidelineID = questionGuideline.glid;

          let guideline = _.find(this.state.guidelines, {GLID: guidelineID});

          // throw('reasons');

          if (guideline != null) {
            var guidelineName =
              questionGuideline.title + ' (Guideline not available)';

            if (guideline.Year === 'current') {
              guidelineName = guideline.Title;
            }

            var guidelineSection =
              GuidelinesController.getGuidelineSectionForGuideline(guideline);

            quizSets.push({
              name: guidelineID,
              title: guidelineName,
              type: 'guideline',
              section: guidelineSection,
              guideline: guidelineID,
            });
          }
        }
      }

      var t1 = new Date().getTime();
      console.log('Creating QuizSets took ' + (t1 - t0) + ' milliseconds.');

      // add questions to quizSets
      for (
        let questionIndex = 0, questionsLength = this.state.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.state.questions[questionIndex];

        // if (question.PackName === 'TestingTopic' && (this.state.user == null || this.state.user.isPPPTester !== true) ) {
        //   continue;
        // }

        if (
          issueQuestionNames.includes(question.Name) ||
          caseStudyQuestionNames.includes(question.Name)
        ) {
          continue; // standby and case study questions don't need to be added to quizSets as they've already been added above
        }

        var guideline = '';
        if (question.Reference != null && question.Reference !== '') {
          guideline = question.Reference.split(' - ')[0];
        }

        // exclude questions that are part of excluded guidelines - this should also hide the matching guideline quizset as it will have no questions
        if (this.state.excludedGuidelines.includes(guideline)) {
          continue;
        }

        var questionIsSequential = sequentialTopicNames.includes(
          question.PackName,
        );

        var questionLastModified = Date.parse(question.LastModified); //moment(question.LastModified).unix() * 1000;

        for (
          let quizSetIndex = 0;
          quizSetIndex < quizSets.length;
          quizSetIndex++
        ) {
          let quizSet = quizSets[quizSetIndex];

          if (quizSet.questions == null) {
            quizSet.questions = [];
          }

          if (quizSet.type === 'topic') {
            if (question.PackName === quizSet.topicName) {
              // if (quizSet.date == null) {
              //   quizSet.date = questionLastModified;
              // } else if (quizSet.date > questionLastModified) {
              //   quizSet.date = questionLastModified;
              // }

              quizSet.questions.push(question);
            }
          }

          if (quizSet.type === 'guideline') {
            if (guideline !== '') {
              if (questionIsSequential === false) {
                // don't include sequential questions

                if (guideline === quizSet.guideline) {
                  if (quizSet.date == null) {
                    quizSet.date = questionLastModified;
                  } else if (quizSet.date > questionLastModified) {
                    quizSet.date = questionLastModified;
                  }

                  quizSet.questions.push(question);
                }
              }
            }
          }

          if (quizSet.type === 'section') {
            if (questionIsSequential === false) {
              // don't include sequential questions

              if (question.Section === quizSet.section) {
                if (quizSet.date == null) {
                  quizSet.date = questionLastModified;
                } else if (quizSet.date > questionLastModified) {
                  quizSet.date = questionLastModified;
                }

                quizSet.questions.push(question);
              }
            }
          }
        }
      }

      // remove quiz sets without any questions
      quizSets = quizSets.filter(
        quizSet => quizSet.questions != null && quizSet.questions.length > 0,
      );

      // save quizSets
      if (quizSets.length > 0) {
        await this.setStateAsync({quizSets});
      }

      // update plus quiz suggestions
      let plusQuizSuggestions = [];
      for (
        let plusTimelineDataIndex = 0;
        plusTimelineDataIndex < this.state.plusTimelineData.length;
        plusTimelineDataIndex++
      ) {
        var aPlusTimelineData =
          this.state.plusTimelineData[plusTimelineDataIndex];

        for (
          let quizSetIndex = 0;
          quizSetIndex < quizSets.length;
          quizSetIndex++
        ) {
          let quizSet = quizSets[quizSetIndex];

          if (
            quizSet.type === 'guideline' &&
            quizSet.name === aPlusTimelineData.GLID
          ) {
            let plusQuizSuggestion = aPlusTimelineData;
            plusQuizSuggestion.quizSet = quizSet;
            plusQuizSuggestions.push(aPlusTimelineData);
          }
        }
      }
      this.setState({plusQuizSuggestions});

      var that = this;

      if (this.state.questions.length > 0) {
        console.log('removing loading screen');

        if (isCache === true) {
          console.log('about to remove loading screen');

          setTimeout(() => {
            that.setState({isLoadingInitialData: false});
          }, 200);
        } else {
          setTimeout(() => {
            that.setState({isLoadingInitialData: false});
          }, 200);
        }

        if (this.state.deepLink != null) {
          if (this.state.deepLink.name === 'book') {
            let book = this.state.deepLink.book;
            let chapterNumber = this.state.deepLink.chapterNumber;
            let sectionNumber = this.state.deepLink.sectionNumber;

            setTimeout(() => {
              this.goToBook(book, chapterNumber, sectionNumber, {});
            }, 5000);
          } else if (this.state.deepLink.name === 'video') {
            this.openVideoScreen(this.state.deepLink.videoId, 'Deep Link');
          } else if (this.state.deepLink.name === 'podcast') {
            this.openPodcastScreen(this.state.deepLink.podcastId);
          } else if (this.state.deepLink.name === 'psirf') {
            console.log('opening psirf screen', this.state.deepLink);
            this.openPSIRFScreen(this.state.deepLink.psirfId);
          } else if (this.state.deepLink.glid != null) {
            this.openPGDQuizSetWithGLID(
              this.state.deepLink.glid,
              this.state.deepLink.uid,
            );
          } else if (this.state.deepLink.name != null) {
            this.openQuizSetWithName(
              this.state.deepLink.name,
              this.state.deepLink.uid,
            );
          }

          this.setState({deepLink: null});
        }
      }

      await this.updateQuizResults();

      var t3 = new Date().getTime();
      console.log('UpdateQuizSets took ' + (t3 - t0) + ' milliseconds.');

      console.log('updated quizSets - ' + quizSets.length);
    } catch (error) {
      console.log(error);
      this.setState({showErrorLoadingMessage: true});
    }
  }

  async checkCurrentSubscriptions() {
    var subscriptions = await DataController.getSubscriptions();

    var validPPPSubscriptionFound = false;
    var currentSubscription = null;
    var currentSubscriptionExpiryDate = moment(0);

    for (
      let subscriptionIndex = 0;
      subscriptionIndex < subscriptions.length;
      subscriptionIndex++
    ) {
      var subscription = subscriptions[subscriptionIndex];

      if (
        subscription.SubscriptionID === 61 ||
        subscription.SubscriptionID === 66 ||
        subscription.SubscriptionID === 90
      ) {
        var dateString = subscription.ExpiryDate.replace('/Date(', '');
        dateString = dateString.replace(')/', '');
        var expiryDateSeconds = parseInt(dateString);

        // expiryDateSeconds = 1580297103000;

        var expiryDate = moment(expiryDateSeconds);
        var expiryDateEndOfDay = expiryDate.endOf('day');
        var today = moment();

        if (expiryDateEndOfDay.isAfter(today)) {
          validPPPSubscriptionFound = true;
        }

        if (currentSubscription != null) {
          if (expiryDate.isAfter(currentSubscriptionExpiryDate)) {
            currentSubscription = subscription;
            currentSubscriptionExpiryDate = expiryDate;
          }
        } else {
          currentSubscription = subscription;
          currentSubscriptionExpiryDate = expiryDate;
        }
      }
    }

    if (this.state.user && this.state.user.isPPPTester) {
      validPPPSubscriptionFound = true;
    }

    await this.setStateAsync({
      validPPPSubscription: validPPPSubscriptionFound,
      currentSubscription: currentSubscription,
    });
    this.updateQuizSets();
  }

  setDefaultFontFamily() {
    Text.defaultProps = Text.defaultProps || {};
    Text.defaultProps.allowFontScaling = false;

    DynamicText.defaultProps = DynamicText.defaultProps || {};
    DynamicText.defaultProps.fontSize = 30;

    TouchableOpacity.defaultProps = TouchableOpacity.defaultProps || {};
    TouchableOpacity.defaultProps.activeOpacity = 0.5;
  }

  async createResult(
    packName: string,
    questionID: number,
    quiz: Object,
    answer: string,
    correct: boolean,
    retakingIncorrectQuestion: boolean,
  ) {
    var currentTime = new Date();
    var currentTimeStamp = currentTime.getTime();
    var currentTimeString = moment(currentTimeStamp).utc().format();
    var resultID = packName + '-' + questionID + '-' + quiz.name;

    var result = {
      resultID: resultID,
      contactID: this.state.user.uid,
      time: currentTimeString,
      packName: packName,
      questionID: questionID,
      quizName: quiz.name,
      answer: answer,
      correct: correct,
    };

    var results = this.state.results;

    // overwrite result with new answer
    if (retakingIncorrectQuestion) {
      for (let resultIndex = 0; resultIndex < results?.length; resultIndex++) {
        var aResult = results[resultIndex];

        if (
          aResult.packName === packName &&
          aResult.quizName === quiz.name &&
          aResult.questionID === questionID &&
          aResult.contactID + '' === this.state.user.uid
        ) {
          aResult.answer = answer;
          aResult.correct = correct;
          aResult.time = result.time;

          results[resultIndex] = aResult;
          break;
        }
      }
    } else {
      results.push(result);
    }

    // save result locally
    DataController.saveAllResults(results, currentTimeStamp);

    // add result to queue
    RemoteDataController.addResultToQueue(result);

    // save result to state
    await this.setStateAsync({results: results});
    // this.updateQuizResults();

    // send result to server
    try {
      var response = await RemoteDataController.sendResult(
        result.contactID,
        result.answer,
        result.correct,
        result.packName,
        result.questionID,
        result.quizName,
        result.time,
      );
      // remove from queue once confirmed result is saved on server
      if (response != null) {
        if (response.success === true) {
          if (Platform.OS === 'android') {
            // ToastAndroid.showWithGravity(result.packName + "-" + result.questionID + " received", ToastAndroid.LONG, ToastAndroid.BOTTOM);
          }
          RemoteDataController.removeResultFromQueue(result);
        } else {
          // Alert.alert("Result sent unsuccessfully: " + result);
        }
      } else {
        // Alert.alert("Result response was empty: " + result);
      }
    } catch (error) {
      console.log(error);
      // Alert.alert("Result failed to send: " + result + error);
    }
  }

  async createQuiz(quiz: Object) {
    var quizTime = parseInt(quiz.time);
    quiz.time = moment(quizTime).utc().format();

    var quizzes = this.state.quizzes;
    quizzes.push(quiz);
    quizzes = _.orderBy(quizzes, ['time'], ['desc']);

    // save result locally
    await DataController.saveAllQuizzes(quizzes, quizTime);

    // add quiz to queue
    await RemoteDataController.addQuizToQueue(quiz);
    await this.setStateAsync({quizzes: quizzes});

    // send result to server
    try {
      var response = await RemoteDataController.createQuiz(
        quiz.name,
        this.state.user.uid,
        quiz.quizMode,
        quiz.quizSet,
        quiz.questions,
        quiz.time,
      );
      // remove from queue once confirmed result is saved on server
      if (response != null) {
        if (response.success === true) {
          if (Platform.OS === 'android') {
            // ToastAndroid.showWithGravity(quiz.name + " received", ToastAndroid.LONG, ToastAndroid.CENTER);
          }
          RemoteDataController.removeQuizFromQueue(quiz);
        } else {
          // Alert.alert("Quiz sent unsuccessfully: " + quiz);
        }
      } else {
        // Alert.alert("Quiz response was empty: " + quiz);
      }
    } catch (error) {
      console.log(error);
      // Alert.alert("Quiz failed to send: " + quiz + error);
    }
  }

  async saveBookBookmark(bookID, chapterNumber, sectionNumber) {
    const bookBookmarks = await DataController.saveBookBookmark(
      bookID,
      chapterNumber,
      sectionNumber,
    );
    if (bookBookmarks != null) {
      this.setState({bookBookmarks});
    }
  }

  async updateBookReadingProgress(name: string, type: string) {
    let bookReadingProgress = this.state.bookReadingProgress.slice();
    let markAsRead = false;

    if (bookReadingProgress.includes(name)) {
      // already exists - so mark as unread
      bookReadingProgress = bookReadingProgress.filter(e => e !== name);
    } else {
      // doesn't exist - so mark as read
      bookReadingProgress.push(name);
      markAsRead = true;
    }

    if (this.state.user && type && name) {
      RemoteDataController.updateBookProgress(
        this.state.user.uid,
        type,
        name,
        markAsRead,
      );
    }

    this.setState({bookReadingProgress});
  }

  logOut() {
    AuthenticationController.logOut();
    DataController.deleteResults();
    DataController.deleteQuizzes();
    DataController.deleteTopics();
    DataController.deleteTrustInfo();
    DataController.deleteBookReadingProgess();
    DataController.deletePsirfs();
    DataController.deleteAcknowledgements();
    PromotionsController.deletePromotionsFile();
    PromotionsController.deletePromoCodeFile();

    this.setState({
      questions: [],
      topics: [],
      issues: [],
      caseStudies: [],
      quizSets: [],
      quizzes: [],
      results: [],
      featuredCards: [],
      authors: [],
      trustInfo: {},
      latestResultsForQuizSets: {},
      resultsForQuizzes: {},
      isLoadingInitialData: true,
      isLoadingData: true,
      loadingScreenText: 'Loading Quizzes',
      shouldShowLongLoadingMessage: false,
      psirfs: [],
      psirfNavCard: null,
    });
  }

  async resyncAllData() {
    await Promise.all([
      DataController.deleteResults(),
      DataController.deleteQuizzes(),
      DataController.deleteTopics(),
      DataController.deleteIssues(),
      DataController.deleteCaseStudies(),
      DataController.deleteFeaturedCards(),
      DataController.deleteAuthors(),
      DataController.deleteGuidelines(),
      DataController.deleteTrustInfo(),
      DataController.deletePsirfs(),
      DataController.deleteAcknowledgements(),
      PromotionsController.deletePromotionsFile(),
      PromotionsController.deletePromoCodeFile(),
    ]);

    await this.setStateAsync({
      questions: [],
      topics: [],
      issues: [],
      caseStudies: [],
      quizSets: [],
      quizzes: [],
      results: [],
      featuredCards: [],
      authors: [],
      trustInfo: {},
      latestResultsForQuizSets: {},
      resultsForQuizzes: {},
      isLoadingInitialData: true,
      isLoadingData: true,
      loadingScreenText: 'Loading Quizzes',
      shouldShowLongLoadingMessage: false,
      psirfs: [],
      psirfNavCard: null,
    });

    await this.loadData();
  }

  async configureSubscriptions() {
    var that = this;

    if (Platform.OS !== 'web') {
      // purchases
      try {
        await IapInitConnection();
        const products = await IapGetSubscriptions({skus: itemSkus});
        this.setState({products});
      } catch (err) {
        console.warn(err); // standardized err.code and err.message available
      }

      try {
        this.purchaseUpdateSubscription = IapPurchaseUpdatedListener(
          async (
            purchase: InAppPurchase | SubscriptionPurchase | ProductPurchase,
          ) => {
            console.log('purchaseUpdatedListener', purchase);

            var receipt = purchase.transactionReceipt;

            if (Platform.OS === 'android') {
              receipt = purchase.purchaseToken;
            }

            if (receipt) {
              var subscriptionID = -1;

              if (
                purchase.productId === 'co.uk.class.cpd.1month' ||
                purchase.productId === 'co.uk.class.cpd.1month.android'
              ) {
                subscriptionID = 61;
              } else if (
                purchase.productId === 'co.uk.class.cpd.12months' ||
                purchase.productId === 'co.uk.class.cpd.12months.android'
              ) {
                subscriptionID = 66;
              }

              if (
                subscriptionID !== -1 &&
                subscriptionID !== null &&
                this.state.user != null
              ) {
                // send subscription to Wyvern
                try {
                  await RemoteDataController.sendPurchaseInfo(
                    subscriptionID,
                    this.state.user.uid,
                    purchase.transactionDate,
                    purchase.transactionId,
                    purchase.productId,
                    receipt,
                  );

                  // Tell the store that you have delivered what has been paid for.
                  // Failure to do this will result in the purchase being refunded on Android and
                  // the purchase event will reappear on every relaunch of the app until you succeed
                  // in doing the below. It will also be impossible for the user to purchase consumables
                  // again untill you do this.
                  await IapFinishTransaction({purchase});
                } catch (error) {
                  console.log(error);
                  Alert.alert(
                    'Subscription failed to update.',
                    "Sorry your subscription couldn't be updated at the moment. Please try restoring it from the menu.",
                  );
                }
              }

              if (this.state.user != null) {
                await AuthenticationController.downloadUserInfo(
                  this.state.user.uid,
                );
                await DataController.saveTrusts(this.state.user.uid);
                that.checkCurrentSubscriptions();
              }
            }
          },
        );

        this.purchaseErrorSubscription = IapPurchaseErrorListener(error => {
          console.warn('purchaseErrorListener', error);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async purchaseAndroidSubscription(sku: string) {
    console.debug('Purchasing: ', sku);
    const subscriptions = await IapGetSubscriptions({skus: itemSkus});
    // Get subscription by sku from subscription
    // list provided by react-native-iap after calling the getSubscriptions method
    const subscription = subscriptions.find(s => s.productId === sku);

    // Early return on invalid subscription
    if (!subscriptions) {
      throw new Error(
        'Invalid sku provided. No subscription with given sku could be found.',
      );
    }

    // Handle android purchase request
    if (Platform.OS === 'android') {
      const androidSubscription = subscription;

      // Get offer token. If you have multiple offers, you need to choose the correct one here
      // We only got one offer per subscription
      const offerToken =
        androidSubscription.subscriptionOfferDetails[0]?.offerToken;

      if (!offerToken) {
        throw new Error('Subscription does not contain a valid offerToken');
      }

      const subscriptionRequest = {
        subscriptionOffers: [
          {
            sku,
            offerToken,
          },
        ],
      };

      await IapRequestSubscription(subscriptionRequest);
    }
  }

  async requestSubscription(productId: string) {
    if (!productId) {
      this.configureSubscriptions();
      Alert.alert(
        'Subscription unavailable',
        "This subscription isn't available right now. Please try again.",
      );
      return;
    }

    try {
      if (Platform.OS === 'android') {
        await this.purchaseAndroidSubscription(productId);
      } else {
        console.log('IapRequestPurchase for iOS');
        await IapRequestSubscription({sku: productId});
      }
    } catch (err) {
      console.warn('Subscription Error:', err.code, err.message);
      Alert.alert(
        'Purchase Error',
        'There was an issue processing your subscription. Please try again later.',
      );
    }
  }

  async restoreSubscription() {
    try {
      this.setState({currentlyRestoringSubscription: true});

      const purchases = await IapGetAvailablePurchases();

      if (purchases.length > 0) {
        var latestPurchase = null;

        var subscriptionID = -1;

        for (
          let purchaseIndex = purchases.length - 1;
          purchaseIndex >= 0;
          purchaseIndex--
        ) {
          let purchase = purchases[purchaseIndex];

          if (
            purchase.productId === 'co.uk.class.cpd.1month' ||
            purchase.productId === 'co.uk.class.cpd.1month.android'
          ) {
            subscriptionID = 61;
          } else if (
            purchase.productId === 'co.uk.class.cpd.12months' ||
            purchase.productId === 'co.uk.class.cpd.12months.android'
          ) {
            subscriptionID = 66;
          }

          if (subscriptionID !== -1) {
            latestPurchase = purchase;
            break;
          }
        }

        // send subscription to server
        if (latestPurchase != null) {
          try {
            var receipt = latestPurchase.transactionReceipt;

            if (Platform.OS === 'android') {
              receipt = latestPurchase.purchaseToken;
            }

            var result = await RemoteDataController.sendPurchaseInfo(
              subscriptionID,
              this.state.user.uid,
              latestPurchase.transactionDate,
              latestPurchase.transactionId,
              latestPurchase.productId,
              receipt,
            );

            if (result) {
              await AuthenticationController.downloadUserInfo(
                this.state.user.uid,
              );
              await DataController.saveTrusts(this.state.user.uid);

              await this.checkCurrentSubscriptions();

              Alert.alert(
                'Restore Successful',
                'You successfully restored your purchases',
              );
            } else {
              Alert.alert(
                'Restore Failed',
                "The subscription couldn't be restored at the moment. If this keeps happening, please contact support.",
              );
            }
          } catch (error) {
            console.log(error);
            Alert.alert(
              'Restore Failed',
              "The subscription couldn't be restored at the moment. If this keeps happening, please contact support.",
            );
          }
        } else {
          Alert.alert('There was no subscription to restore');
        }
      } else {
        Alert.alert('There was nothing to restore');
      }
    } catch (err) {
      console.warn(err); // standardized err.code and err.message available
      if (err != null && err.code != null && err.code !== 'E_USER_CANCELLED') {
        Alert.alert(err.message);
      }
    }

    this.setState({currentlyRestoringSubscription: false});
  }

  async sendSupportEmail(subject, supportType) {
    var subscription = null;

    try {
      var purchases = [];

      if (Platform.OS !== 'web') {
        purchases = await IapGetAvailablePurchases();

        if (purchases != null) {
          if (Platform.OS === 'ios') {
            var uniqueTransactionReceipts = [];

            for (
              let purchaseIndex = 0;
              purchaseIndex < purchases.length;
              purchaseIndex++
            ) {
              var purchase = purchases[purchaseIndex];

              if (
                uniqueTransactionReceipts.includes(
                  purchase.transactionReceipt,
                ) === false
              ) {
                uniqueTransactionReceipts.push(purchase.transactionReceipt);
              }
            }

            subscription = uniqueTransactionReceipts;
          } else {
            subscription = purchases;
          }
        }
      }

      if (purchases.length > 0) {
        var latestPurchase = purchases[purchases.length - 1];

        var receipt = latestPurchase.transactionReceipt;

        if (Platform.OS === 'android') {
          receipt = latestPurchase.purchaseToken;
        }
      } else {
        console.log('There are no purchases.');
      }
    } catch (err) {
      console.warn(err); // standardized err.code and err.message available
    }

    if (subscription == null || subscription === []) {
      subscription = {subscription: 'No device subscription'};
    }

    var body =
      `

UserID: ` +
      this.state.user.uid +
      `
Version: ` +
      DeviceInfoController.getAppVersion() +
      `
Brand: ` +
      DeviceInfoController.getBrand() +
      `
Model: ` +
      DeviceInfoController.getModel() +
      `
Platform: ` +
      Platform.OS +
      `
OS Version: ` +
      DeviceInfoController.getSystemVersion() +
      `
`;

    var attachments = [];

    if (Platform.OS !== 'web') {
      await DataController.saveDataToFile(subscription, 'receipt.json', false);

      var path = DataController.getFilePath('receipt.json');

      attachments.push({
        path: path, // The absolute path of the file from which to read data.
        type: 'json', // Mime Type: jpg, png, doc, ppt, html, pdf, csv
        //   // mimeType - use only if you want to use custom type
        //   name: '',   // Optional: Custom filename for attachment
      });
    }

    var emailAddress = 'jrcalccpd@class.co.uk';
    if (supportType === 'feedback') {
      emailAddress = 'appfeedback@class.co.uk';
    } else if (supportType === 'deleteAccount') {
      emailAddress = 'apps@class.co.uk';
    }

    try {
      CommunicationsController.sendEmail(
        [emailAddress],
        [],
        [],
        subject,
        body,
        attachments,
      );
      // PlatformController.openURL('mailto:jrcalccpd@class.co.uk?subject=' + subject + '&body=' + body);
    } catch (error) {
      console.log(error);
    }
  }

  showMenu(shouldShow: boolean) {
    if (shouldShow !== this.state.shouldShowMenu) {
      this.setState({shouldShowMenu: shouldShow});
    }

    var newmenuBackgroundOpacity = 0;
    var menuBackgroundPointEvents = 'none';

    if (shouldShow) {
      newmenuBackgroundOpacity = 0.5;
      menuBackgroundPointEvents = 'auto';
    }

    Animated.timing(
      // Animate over time
      this.state.menuBackgroundOpacity, // The animated value to drive
      {
        toValue: newmenuBackgroundOpacity, // Animate to opacity: 1 (opaque)
        duration: 300, // Make it take a while
        useNativeDriver: true,
      },
    ).start();

    LayoutAnimation.configureNext(animationConfig);
    this.setState({
      showMenu: !this.state.showMenu,
      menuBackgroundPointEvents: menuBackgroundPointEvents,
    });
  }

  showLoggingInIndicator(message: string = 'Loading') {
    this.setState({showLoggingInOverlay: true, loggingInMessage: message});
  }

  hideLoggingInIndicator() {
    this.setState({showLoggingInOverlay: false});
  }

  showOnboarding() {
    this.setState({shouldShowOnboardingModal: true});
  }

  hideOnboarding() {
    DataController.completedOnboarding();
    this.setState({shouldShowOnboardingModal: false});
  }

  showAccountDeletionAlert(shouldShow: boolean) {
    this.setState({showAccountDeletionAlert: shouldShow});
  }

  async requestAccountDeletion() {
    this.showAccountDeletionAlert(false);
  }

  async sendAccountDeletionRequest() {
    try {
      let success = await AuthenticationController.requestAccountDeletion(
        this.state.user.uid,
      );
      if (success === true) {
        this.logOut();
        Alert.alert(
          'Your account has been requested to be deleted',
          "You'll be notified when your account has successfully been deleted.",
        );
      } else {
        Alert.alert('Request failed to send', 'Please try again shortly.');
      }
    } catch (error) {
      Alert.alert('Request failed to send', 'Please try again shortly.');
    }

    this.showAccountDeletionAlert(false);
  }

  openSubscriptionMenu() {
    var that = this;

    that._menuNavigationStack.navigate('Subscription', {}, that.state);

    setTimeout(() => {
      that.showMenu(true);
    }, 300);
  }

  switchToLogin() {
    setTimeout(() => {
      if (this._loginScreen != null) {
        this._loginScreen.switchToLogin();
      }
    }, 300);
  }

  switchToRegister() {
    setTimeout(() => {
      if (this._loginScreen != null) {
        this._loginScreen.switchToRegister();
      }
    }, 300);
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  takeRandomQuiz() {
    const quizzes = this.state.quizSets.filter(
      quizSet => quizSet.type !== 'caseStudy',
    );
    const randomQuizSet = quizzes[Math.floor(Math.random() * quizzes.length)];

    this._navigation.navigate(
      'QuizOverview',
      {
        quizSet: randomQuizSet,
        from: analyticsEventNames.takeQuiz,
      },
      this.state.screenProps,
    );
  }

  handlePromoModalToShow(
    promoModalToShow: 'qrCode' | 'info' | null,
    analyticsParams = {},
  ) {
    if (promoModalToShow != null) {
      logAnalyticsEvent(analyticsEventNames[promoModalToShow], analyticsParams);
    }
    this.setState({promoModalToShow});
  }

  render() {
    var screenProps = {
      title: 'Create React Native Web App',
      user: this.state.user,
      showMenu: this.showMenu.bind(this),
      questions: this.state.questions,
      topics: this.state.topics,
      issues: this.state.issues,
      caseStudies: this.state.caseStudies,
      quizSets: this.state.quizSets,
      quizzes: this.state.quizzes,
      results: this.state.results,
      guidelines: this.state.guidelines,
      latestResultsForQuizSets: this.state.latestResultsForQuizSets,
      resultsForQuizzes: this.state.resultsForQuizzes,
      inProgressQuizzes: this.state.inProgressQuizzes,
      featuredCards: this.state.featuredCards,
      pgds: this.state.pgds,
      updatePGDAcknowledgements: this.updatePGDAcknowledgements.bind(this),
      authors: this.state.authors,
      books: this.state.books,
      bookBookmarks: this.state.bookBookmarks,
      saveBookBookmark: this.saveBookBookmark.bind(this),
      bookReadingProgress: this.state.bookReadingProgress,
      updateBookReadingProgress: this.updateBookReadingProgress.bind(this),
      trustInfo: this.state.trustInfo,
      createResult: this.createResult.bind(this),
      updateQuizResults: this.updateQuizResults.bind(this),
      updateQuizSets: this.updateQuizSets.bind(this),
      createQuiz: this.createQuiz.bind(this),
      takeQuiz: this.takeQuiz.bind(this),
      goToBook: this.goToBook.bind(this),
      isLoadingInitialData: this.state.isLoadingInitialData,
      isLoadingData: this.state.isLoadingData,
      loadingScreenText: this.state.loadingScreenText,
      loadingBarText: this.state.loadingBarText,
      logOut: this.logOut.bind(this),
      resyncAllData: this.resyncAllData.bind(this),
      fontSize: this.state.fontSize,
      showOnboarding: this.showOnboarding.bind(this),
      hideOnboarding: this.hideOnboarding.bind(this),
      showAccountDeletionAlert: this.showAccountDeletionAlert.bind(this),
      openSubscriptionMenu: this.openSubscriptionMenu.bind(this),
      switchToLogin: this.switchToLogin.bind(this),
      switchToRegister: this.switchToRegister.bind(this),
      requestSubscription: this.requestSubscription.bind(this),
      restoreSubscription: this.restoreSubscription.bind(this),
      products: this.state.products,
      validPPPSubscription: this.state.validPPPSubscription,
      currentSubscription: this.state.currentSubscription,
      currentlyRestoringSubscription: this.state.currentlyRestoringSubscription,
      sendSupportEmail: this.sendSupportEmail.bind(this),
      showBooksTab: this.state.showBooksTab,
      features: this.state.appFeatures,
      plusQuizSuggestions: this.state.plusQuizSuggestions,
      dailyChallenge: this.state.dailyChallenge,
      didYouKnowItems: this.state.didYouKnowItems,
      initialDashboardCardStates: this.state.initialDashboardCardStates,
      handleOpenURL: this.handleOpenURL.bind(this),
      openQuizSetWithName: this.openQuizSetWithName.bind(this),
      extraResources: this.state.extraResources,
      videos: this.state.videos,
      podcasts: this.state.podcasts,
      videoHistory: this.state.videoHistory,
      audioHistory: this.state.audioHistory,
      updateVideoHistory: this.updateVideoHistory.bind(this),
      updateAudioHistory: this.updateAudioHistory.bind(this),
      takeRandomQuiz: this.takeRandomQuiz.bind(this),
      handlePromoModalToShow: this.handlePromoModalToShow.bind(this),
      psirfs: this.state.psirfs,
      psirfNavCard: this.state.psirfNavCard,
      showAlert: this.showAlert.bind(this),
    };

    var loggingInOverlayLayout = null;

    if (this.state.showLoggingInOverlay === true) {
      loggingInOverlayLayout = (
        <View
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}>
          <View
            style={{
              width: 170,
              height: 140,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}>
            <ActivityIndicator
              animating={true}
              size={'large'}
              color={colors.white}></ActivityIndicator>
            <Text style={{color: '#FFF', marginTop: 40, fontSize: 20}}>
              {this.state.loggingInMessage}
            </Text>
          </View>
        </View>
      );
    }

    var shouldShowLoginModal = false;
    if (this.state.user == null && this.state.hasDecidedIfLoggedIn) {
      shouldShowLoginModal = true;
    }

    var shouldShowSubscriptionModal = false;
    if (
      this.state.user != null &&
      this.state.validPPPSubscription === false &&
      this.state.quizSets.length === 0 &&
      this.state.isLoadingInitialData === false
    ) {
      shouldShowSubscriptionModal = true;
    }

    var shouldShowModal = true;
    var modalToShow = '';

    if (this.state.shouldShowOnboardingModal) {
      modalToShow = 'onboarding';
    } else if (shouldShowLoginModal) {
      modalToShow = 'login';
    } else if (shouldShowSubscriptionModal) {
      modalToShow = 'subscription';
    } else {
      shouldShowModal = false;
    }

    var modalToShowLayout = <View />;

    if (modalToShow === 'onboarding') {
      modalToShowLayout = <OnboardingScreen screenProps={screenProps} />;
    } else if (modalToShow === 'login') {
      modalToShowLayout = (
        <View style={{flex: 1}}>
          <LoginScreen
            ref={c => (this._loginScreen = c)}
            showLoggingInIndicator={this.showLoggingInIndicator.bind(this)}
            hideLoggingInIndicator={this.hideLoggingInIndicator.bind(this)}
            errorMessage={this.state.errorMessage}
          />
          {loggingInOverlayLayout}
        </View>
      );
    } else if (modalToShow === 'subscription') {
      modalToShowLayout = (
        <SubscriptionScreen screenProps={screenProps} presentedModally={true} />
      );
    }

    var menuLeftPosition = -LayoutController.getMenuWidth();
    if (this.state.shouldShowMenu) {
      menuLeftPosition = 0;
    }

    var menuBackgroundLayout = (
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: this.state.menuBackgroundOpacity,
          backgroundColor: '#000',
        }}
        pointerEvents={this.state.menuBackgroundPointEvents}>
        <TouchableOpacity
          style={{flex: 1}}
          onPress={this.showMenu.bind(this, false)}></TouchableOpacity>
      </Animated.View>
    );

    var menuLayout = (
      <View
        style={{
          position: 'absolute',
          overflow: 'hidden',
          top: 0,
          bottom: 0,
          left: menuLeftPosition,
          width: LayoutController.getMenuWidth(),
          backgroundColor: '#FFF',
        }}>
        <NavigationStack
          ref={c => (this._menuNavigationStack = c)}
          key={'menu'}
          isActive={true}
          screens={MenuScreens}
          screenProps={screenProps}
        />
      </View>
    );

    var loadingDataLayout = null;

    var longLoadingMessageLayout = null;

    var errorLoadingLayout = null;

    if (this.state.shouldShowLongLoadingMessage) {
      longLoadingMessageLayout = (
        <Text
          style={{
            color: '#FFF',
            marginTop: 20,
            fontSize: 20,
            textAlign: 'center',
          }}>
          You need to have a connection the first time you launch this update or
          change profile, this may take a minute
        </Text>
      );
    }

    if (this.state.showErrorLoadingMessage) {
      errorLoadingLayout = (
        <View>
          <Text
            style={{
              color: '#FFF',
              marginTop: 20,
              fontSize: 20,
              textAlign: 'center',
            }}>
            There was an error whilst loading
          </Text>
          <TouchableOpacity onPress={this.loadData.bind(this)}>
            <View
              style={{
                marginTop: 16,
                alignSelf: 'center',
                backgroundColor: colors.ButtonBlue,
              }}>
              <Text
                style={{
                  marginVertical: 15,
                  paddingHorizontal: 30,
                  fontSize: 16,
                  fontWeight: '600',
                  letterSpacing: -0.39,
                  textAlign: 'center',
                  color: colors.white,
                }}>
                Try again
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    if (
      this.state.isLoadingInitialData ||
      (this.state.user == null && this.state.hasDecidedIfLoggedIn)
    ) {
      loadingDataLayout = (
        <View
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: colors.NavBar,
          }}>
          <View>
            <Image
              style={{width: 150, height: 28}}
              resizeMode={'contain'}
              source={require('./assets/Logo_ParaPass_Short.png')}
            />
          </View>

          <View
            style={{
              width: 300,
              marginTop: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ActivityIndicator
              animating={true}
              size={'large'}
              color={colors.white}></ActivityIndicator>
            <Text
              style={{
                color: '#FFF',
                marginTop: 20,
                fontSize: 20,
                textAlign: 'center',
              }}>
              {this.state.loadingScreenText}
            </Text>
            {longLoadingMessageLayout}
            {errorLoadingLayout}
          </View>
        </View>
      );
    }

    var modalLayout = null;

    if (shouldShowModal) {
      modalLayout = (
        <View
          style={{
            flex: 1,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: colors.Grey000,
          }}>
          {modalToShowLayout}
        </View>
      );
    }

    let accountDeletionAlertLayout = null;

    if (this.state.showAccountDeletionAlert) {
      accountDeletionAlertLayout = (
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: 'center',
          }}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 0.6,
              backgroundColor: '#000',
            }}></View>
          <View
            style={{
              margin: 32,
              maxWidth: 350,
              backgroundColor: 'white',
              borderRadius: 10,
            }}>
            <View style={{paddingHorizontal: 16, paddingVertical: 24}}>
              <Text style={{fontSize: 17, fontWeight: '800'}}>
                Are you sure you want to request your account to be deleted?
              </Text>
              <Text style={{marginTop: 8, fontSize: 16}}>
                Tapping{' '}
                <Text style={{fontWeight: '800'}}>
                  Yes, request account deletion
                </Text>{' '}
                will alert our support team to delete your account. Our support
                team will deal with your request within a few days and you will
                be notified once your account has successfully been deleted.
                {'\n\n'}
                Please note that deleting your account will not cancel your
                subscription automatically. To do this you will need to go to
                Settings app and manage your subscription from there.
              </Text>
            </View>
            <View style={{height: 1, backgroundColor: colors.Grey300}} />
            <TouchableOpacity
              onPress={this.sendAccountDeletionRequest.bind(this)}>
              <Text
                style={{
                  paddingVertical: 13,
                  fontSize: 16,
                  fontWeight: '600',
                  color: colors.Red500,
                  textAlign: 'center',
                }}>
                Yes, request account deletion
              </Text>
            </TouchableOpacity>
            <View style={{height: 1, backgroundColor: colors.Grey300}} />
            <TouchableOpacity
              onPress={() => this.showAccountDeletionAlert(false)}>
              <Text
                style={{
                  paddingVertical: 13,
                  fontSize: 16,
                  fontWeight: '600',
                  color: colors.ButtonBlue,
                  textAlign: 'center',
                }}>
                No, cancel request
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    var mobileWebLayout = null;

    if (PlatformController.isMobileWeb()) {
      mobileWebLayout = (
        <View
          style={{
            flex: 1,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: colors.NavBar,
          }}>
          <View>
            <Image
              style={{width: 230, height: 148}}
              resizeMode={'contain'}
              source={require('./assets/Web_Logo_ParaPass.png')}
            />
          </View>

          <View
            style={{
              width: 330,
              marginTop: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text style={{color: '#FFF', fontSize: 15, textAlign: 'center'}}>
              Did you know ParaPass is available to download on the App Store
              and Google Play?
            </Text>
            <Text style={{color: '#FFF', fontSize: 15, textAlign: 'center'}}>
              Download the app now through the links below.
            </Text>

            <TouchableOpacity
              onPress={() =>
                PlatformController.openURL(
                  'https://apps.apple.com/us/app/jrcalc-cpd/id1220803129?ls=1',
                )
              }>
              <Image
                style={{width: 180, height: 60, marginTop: 36}}
                resizeMode={'contain'}
                source={require('./assets/Web_Label_Apple.png')}
              />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() =>
                PlatformController.openURL(
                  'https://play.google.com/store/apps/details?id=co.uk.classprofessional.cpdquiz&hl=en',
                )
              }>
              <Image
                style={{width: 202, height: 60, marginTop: 30}}
                resizeMode={'contain'}
                source={require('./assets/Web_Label_Google.png')}
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    }

    return (
      <PromotionsProvider
        user={this.state.user}
        isDownloadingOnlineData={this.state.isDownloadingOnlineData}>
        <UpVotesProvider
          user={this.state.user}
          startedLoadingDataInterval={this.state.startedLoadingDataInterval}>
          <AcknowledgementsProvider
            user={this.state.user}
            startedLoadingDataInterval={this.state.startedLoadingDataInterval}>
            <SafeAreaProvider>
              <AppWrapper>
                <View style={{flex: 1}}>
                  <StatusBar barStyle="light-content" />
                  <View style={styles.container}>
                    <Navigation
                      ref={c => (this._navigation = c)}
                      tabs={this.state.tabs}
                      screenProps={screenProps}
                      style={{flex: 1}}
                    />
                  </View>
                  {menuBackgroundLayout}
                  {menuLayout}
                  {loadingDataLayout}
                  {modalLayout}
                  {accountDeletionAlertLayout}
                  {mobileWebLayout}
                </View>
                <PromoModal
                  onPress={() => this.handlePromoModalToShow(null)}
                  title={
                    this.state.promoModalToShow === 'qrCode' &&
                    'More information'
                  }
                  isModalVisible={this.state.promoModalToShow != null}
                  modalToShow={this.state.promoModalToShow}
                />
              </AppWrapper>
            </SafeAreaProvider>
          </AcknowledgementsProvider>
        </UpVotesProvider>
      </PromotionsProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default App;
