import React from 'react';

import {StyleSheet, Text, TouchableOpacity, Platform} from 'react-native';

import {
  exchangeCodeAsync,
  makeRedirectUri,
  useAuthRequest,
  useAutoDiscovery,
  ResponseType,
} from 'expo-auth-session';
import {EventRegister} from 'react-native-event-listeners';

import AuthenticationController from '../classes/AuthenticationController';
import DataController from '../classes/DataController';

// this is for iso/android
const getRedirectUriForNativePlatform = redirects => {
  const redirectUriForPlatform = redirects?.[Platform.OS];
  if (redirectUriForPlatform) {
    return redirectUriForPlatform;
  }
  return null;
};

const SSOTrustCell = ({
  trust,
  config,
  redirects,
  onError,
  onLoadStart,
  onLoadEnd,
}) => {
  const redirectsForPlatform = getRedirectUriForNativePlatform(redirects);
  const redirectUri = makeRedirectUri({
    scheme: 'http',
    useProxy: true,
    path: '',
    native: redirectsForPlatform,
  });

  const autoDiscovery = useAutoDiscovery(
    `https://login.microsoftonline.com/${config.tenantId}/v2.0`,
  );

  const [request, _response, promptAsync] = useAuthRequest(
    {
      clientId: config.clientId,
      redirectUri,
      usePKCE: Platform.OS === 'android' ? false : config.pkceEnabled,
      responseType: ResponseType.Code,
      prompt: config.prompt,
      scopes: [config.scope],
    },
    autoDiscovery,
  );

  const handleLogin = async () => {
    try {
      const codeResponse = await promptAsync();

      if (request && codeResponse?.type === 'success' && autoDiscovery) {
        onLoadStart();
        const res = await exchangeCodeAsync(
          {
            clientId: config.clientId,
            code: codeResponse.params.code,
            extraParams: request.codeVerifier
              ? {code_verifier: request.codeVerifier}
              : undefined,
            redirectUri,
          },
          autoDiscovery,
        );

        if (res && res.accessToken) {
          const contactID = await AuthenticationController.logInSSO(
            res.accessToken,
            trust,
          );
          await AuthenticationController.downloadUserInfo(contactID);
          await DataController.saveTrusts(contactID);
          EventRegister.emit('userUpdated', '');
        }
      }
    } catch (e) {
      console.log('auth error: ', e);
      onError(`Authentication Error: ${e.message}`);
    } finally {
      onLoadEnd();
    }
  };

  const trustColor = DataController.getColorForTrust(trust);

  return (
    <TouchableOpacity onPress={handleLogin}>
      <Text
        style={[
          styles.trustButton,
          {borderColor: trustColor, color: trustColor},
        ]}>
        {trust}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  trustButton: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingVertical: 18,
    paddingHorizontal: 12,
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 10,
    borderWidth: 2,
  },
});

export default SSOTrustCell;
