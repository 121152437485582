/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  Image,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import DataController from '../classes/DataController';
import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';

import AMImageViewer from '../components/AMImageViewer';
import DynamicText from '../components/DynamicText';
import ScreenComponent from '../components/ScreenComponent';

import Swiper from 'react-native-web-swiper';

import BookSectionSlide from './BookSectionSlide';

var _ = require('lodash');

const slideSpringAnimationProperties = {
  type: 'easeOut',
  springDamping: 1,
  property: 'opacity',
};

const slideAnimationConfig = {
  // duration: 200,
  create: slideSpringAnimationProperties,
  update: slideSpringAnimationProperties,
  delete: slideSpringAnimationProperties,
};

type Props = {};

type State = {
  book: Object,
  selectedChapterIndex: number,
  selectedSectionIndex: number,
  startingIndex: number,
  allSections: [],
  shouldShowContentsModal: boolean,
  showSearch: boolean,
  searchText: String,
  numberOfOccurences: number,
  currentOccurenceIndex: number,
  imageViewerVisible: boolean,
  imageModalPath: string,
  bookReadingProgressCounter: number,
};

export default class BookReadingScreen extends Component {
  static navigationOptions = ({navigation}) => {
    let title = navigation.getParam('title', '');

    let isBookmarked = navigation.getParam('isBookmarked', false);
    let searchActive = navigation.getParam('searchActive', false);

    let bookmarkIcon = (
      <Image
        style={{marginRight: 10, width: 44, height: 44}}
        source={require('../assets/Button_Bookmark_1Default.png')}
      />
    );

    if (isBookmarked) {
      bookmarkIcon = (
        <Image
          style={{marginRight: 10, width: 44, height: 44}}
          source={require('../assets/Button_Bookmark_2Active.png')}
        />
      );
    }

    let searchIcon = (
      <Image
        style={{marginRight: 10, width: 44, height: 44}}
        source={require('../assets/Button_Page_Search_1_Default.png')}
      />
    );

    if (searchActive) {
      searchIcon = (
        <Image
          style={{marginRight: 10, width: 44, height: 44}}
          source={require('../assets/Button_Page_Search_2_Active.png')}
        />
      );
    }

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity
          style={{height: 44}}
          onPress={navigation.getParam('saveBookBookmark')}>
          {bookmarkIcon}
        </TouchableOpacity>

        <TouchableOpacity
          style={{height: 44}}
          onPress={navigation.getParam('toggleSearch')}>
          {searchIcon}
        </TouchableOpacity>
      </View>
    );

    let animationEnabled = true;
    if (Platform.OS === 'android') {
      animationEnabled = false;
    }

    return {
      animationEnabled: animationEnabled,
      title: title,
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      let book = this.props.navigation.getParam('book', {});
      let selectedChapterIndex = this.props.navigation.getParam(
        'chapterIndex',
        0,
      );
      let selectedSectionIndex = this.props.navigation.getParam(
        'sectionIndex',
        0,
      );
      let searchTerm = this.props.navigation.getParam('searchTerm', '');
      let startingIndex = 0;

      let allSections = [];

      if (book) {
        for (
          let allChaptersIndex = 0;
          allChaptersIndex < book.chapters.length;
          allChaptersIndex++
        ) {
          let aChapter = book.chapters[allChaptersIndex];
          for (
            let allSectionsIndex = 0;
            allSectionsIndex < aChapter.sections.length;
            allSectionsIndex++
          ) {
            let aSection = aChapter.sections[allSectionsIndex];
            allSections.push(`${allChaptersIndex}-${allSectionsIndex}`);
            if (
              allChaptersIndex === selectedChapterIndex &&
              allSectionsIndex === selectedSectionIndex
            ) {
              startingIndex = allSections.length - 1;
              this.setTitle(book, allSections, startingIndex);
            }
          }
        }
      }

      let searchText = '';
      let shouldShouldSearch = false;

      if (searchTerm != null && searchTerm !== '' && searchTerm.length >= 2) {
        searchText = searchTerm;
        shouldShouldSearch = true;
      }

      this.state = {
        book: book,
        selectedChapterIndex: selectedChapterIndex,
        selectedSectionIndex: selectedSectionIndex,
        allSections: allSections,
        startingIndex: startingIndex,
        shouldShowContentsModal: false,
        showSearch: shouldShouldSearch,
        searchText: searchText,
        numberOfOccurences: 0,
        currentOccurenceIndex: 0,
        imageViewerVisible: false,
        imageModalPath: '',
        bookReadingProgressCounter: 0,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      this.reportPageChange();
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        saveBookBookmark: that.saveBookBookmark.bind(that),
        toggleSearch: that.toggleSearch.bind(that),
      });
      that.updateBookmarkStatus();
    }, 100);

    // Change behavior of chapter links on web
    if (Platform.OS === 'web') {
      const chapterLinks = document.querySelectorAll('button');
      const chapterAnchors = document.querySelectorAll('a');

      // Iterate over each anchor and modify styles and behavior
      chapterAnchors.forEach(chapterAnchor => {
        if (chapterAnchor.style) {
          chapterAnchor.style.all = 'unset';
          chapterAnchor.style.color = 'black';
          chapterAnchor.style.cursor = 'default'; // or 'auto'
        }

        // Add click event listener to prevent default behavior
        chapterAnchor.addEventListener('click', event => {
          event.preventDefault(); // Prevent the default navigation
          console.log('link clicked');
          // Add any additional custom behavior here
        });
      });

      // Iterate over each anchor and modify styles and behavior
      chapterLinks.forEach(chapterLink => {
        if (chapterLink?.style) {
          // Apply desired styles
          chapterLink.style.textDecoration = 'underline';
          chapterLink.style.color = 'rgb(85, 26, 139)';
          chapterLink.style.backgroundColor = 'transparent';
          chapterLink.style.border = 'none';
          chapterLink.style.cursor = 'pointer';
          chapterLink.style.fontSize = '16px';
          chapterLink.style.padding = '0px';
        }

        const chapterInfo = chapterLink.getAttribute('href').replace(/CH/g, '');
        chapterLink.addEventListener('click', () => {
          let parts = chapterInfo.split('-');
          if (parts.length === 2) {
            let chapterIndex = parseInt(parts[0]);
            let sectionIndex = parseInt(parts[1]);

            that.goToSection(chapterIndex, sectionIndex);
          }
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.screenProps.bookBookmarks !==
      this.props.screenProps.bookBookmarks
    ) {
      this.updateBookmarkStatus();
    }

    if (
      prevProps.screenProps.bookReadingProgress !==
      this.props.screenProps.bookReadingProgress
    ) {
      this.setState({
        bookReadingProgressCounter: this.state.bookReadingProgressCounter + 1,
      });
    }
  }

  getActiveSectionInfo() {
    if (this.state.book) {
      let bookID = this.state.book.ID;

      let chapterNumber = -1;
      let sectionNumber = -1;
      let numberOfSectionsInChapter = 0;

      if (this.swiper) {
        let activeIndex = this.swiper.getActiveIndex();
        let item = this.state.allSections[activeIndex];
        let chapterIndex = parseInt(item.split('-')[0]);
        let sectionIndex = parseInt(item.split('-')[1]);

        if (this.state.book) {
          for (
            let allChaptersIndex = 0;
            allChaptersIndex < this.state.book.chapters.length;
            allChaptersIndex++
          ) {
            let aChapter = this.state.book.chapters[allChaptersIndex];
            for (
              let allSectionsIndex = 0;
              allSectionsIndex < aChapter.sections.length;
              allSectionsIndex++
            ) {
              let aSection = aChapter.sections[allSectionsIndex];
              if (
                allChaptersIndex === chapterIndex &&
                allSectionsIndex === sectionIndex
              ) {
                chapterNumber = aChapter.chapter;
                sectionNumber = aSection.section;
                numberOfSectionsInChapter = aChapter.sections.length;
              }
            }
          }
        }

        let activeSectionInfo = {
          bookID: bookID,
          chapterNumber: chapterNumber,
          chapterIndex: chapterIndex,
          sectionNumber: sectionNumber,
          sectionIndex: sectionIndex,
          numberOfSectionsInChapter: numberOfSectionsInChapter,
        };

        return activeSectionInfo;
      }
    }

    return null;
  }

  async setTitle(book, allSections, index) {
    try {
      let item = allSections[index];
      let chapterIndex = parseInt(item.split('-')[0]);
      let sectionIndex = parseInt(item.split('-')[1]);

      let chapterNumber = 0;
      let sectionNumber = 0;
      let numberOfSectionsInChapter = 0;

      if (book) {
        for (
          let allChaptersIndex = 0;
          allChaptersIndex < book.chapters.length;
          allChaptersIndex++
        ) {
          let aChapter = book.chapters[allChaptersIndex];
          for (
            let allSectionsIndex = 0;
            allSectionsIndex < aChapter.sections.length;
            allSectionsIndex++
          ) {
            let aSection = aChapter.sections[allSectionsIndex];
            if (
              allChaptersIndex === chapterIndex &&
              allSectionsIndex === sectionIndex
            ) {
              chapterNumber = aChapter.chapter;
              sectionNumber = aSection.section;
              numberOfSectionsInChapter = aChapter.sections.length;

              this.setState({
                selectedChapterIndex: allChaptersIndex,
                selectedSectionIndex: allSectionsIndex,
              });
            }
          }
        }
      }

      let title = `Chp ${chapterNumber} - ${
        sectionIndex + 1
      } of ${numberOfSectionsInChapter}`;
      this.props.navigation.setParams({title: title});
    } catch (error) {
      console.log(error);
    }
  }

  updateBookmarkStatus() {
    let activeSectionInfo = this.getActiveSectionInfo();

    if (activeSectionInfo != null) {
      let bookmarkName = `${activeSectionInfo.bookID}-${activeSectionInfo.chapterNumber}-${activeSectionInfo.sectionNumber}`;

      DataController.saveBookReadingPosition(
        activeSectionInfo.bookID,
        activeSectionInfo.chapterNumber,
        activeSectionInfo.sectionNumber,
      );

      if (this.props.screenProps.bookBookmarks.includes(bookmarkName)) {
        this.props.navigation.setParams({isBookmarked: true});
        return;
      }
    }

    this.props.navigation.setParams({isBookmarked: false});
    return;
  }

  reportPageChange() {
    let activeSectionInfo = this.getActiveSectionInfo();

    if (activeSectionInfo != null) {
      let sectionName = `${activeSectionInfo.bookID}-${activeSectionInfo.chapterNumber}-${activeSectionInfo.sectionNumber}`;

      if (
        this.props.screenProps.user &&
        this.props.screenProps.user.paraPassTrusts &&
        this.props.screenProps.user.paraPassTrusts.length > 0
      ) {
        let trust = this.props.screenProps.user.paraPassTrusts[0];
        sectionName = sectionName + ' - ' + trust;
      }

      try {
        firebase.analytics().logEvent('book__section_viewed', {
          bookSectionViewedName: sectionName,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  saveBookBookmark() {
    let activeSectionInfo = this.getActiveSectionInfo();

    if (activeSectionInfo != null) {
      if (
        activeSectionInfo.chapterNumber !== -1 &&
        activeSectionInfo.sectionNumber !== -1
      ) {
        this.props.screenProps.saveBookBookmark(
          activeSectionInfo.bookID,
          activeSectionInfo.chapterNumber,
          activeSectionInfo.sectionNumber,
        );
      }
    }
  }

  contentsSectionTapped(chapterIndex, sectionIndex) {
    let index = this.state.allSections.indexOf(
      `${chapterIndex}-${sectionIndex}`,
    );
    if (index !== -1 && this.swiper) {
      this.swiper.goTo(index);
    }
    this.setState({shouldShowContentsModal: false});
  }

  goToSection(chapterNumber, sectionNumber) {
    if (this.state.book) {
      for (
        let allChaptersIndex = 0;
        allChaptersIndex < this.state.book.chapters.length;
        allChaptersIndex++
      ) {
        let aChapter = this.state.book.chapters[allChaptersIndex];
        for (
          let allSectionsIndex = 0;
          allSectionsIndex < aChapter.sections.length;
          allSectionsIndex++
        ) {
          let aSection = aChapter.sections[allSectionsIndex];
          if (
            aChapter.chapter === chapterNumber &&
            aSection.section === sectionNumber
          ) {
            let index = this.state.allSections.indexOf(
              `${allChaptersIndex}-${allSectionsIndex}`,
            );
            if (index !== -1 && this.swiper) {
              this.swiper.goTo(index);
            }
            break;
          }
        }
      }
    }
  }

  goToCaseStudy(caseStudyName) {
    if (caseStudyName != null) {
      for (
        var quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (
          quizSet.type === 'caseStudy' &&
          quizSet.caseStudy === caseStudyName
        ) {
          for (
            var caseStudyIndex = 0;
            caseStudyIndex < this.props.screenProps.caseStudies.length;
            caseStudyIndex++
          ) {
            var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];
            if (caseStudy.Name === quizSet.caseStudy) {
              this.props.navigation.navigate(
                'CaseStudyOverview',
                {
                  quizSet: quizSet,
                  caseStudy: caseStudy,
                },
                this.state,
              );
            }
          }
        }
      }
    }
  }

  toggleSearch() {
    let shouldShow = !this.state.showSearch;

    this.props.navigation.setParams({searchActive: shouldShow});
    // reset search text if search not visible
    if (shouldShow === false) {
      this.setState({searchTextest: ''});
    }

    let that = this;

    if (shouldShow && this.searchInput != null) {
      setTimeout(() => {
        that.searchInput.focus();
      }, 500);
    }

    this.setState({showSearch: shouldShow});
  }

  goToPreviousSection() {
    if (this.swiper) {
      let activeIndex = this.swiper.getActiveIndex();
      if (activeIndex > 0) {
        this.swiper.goTo(activeIndex - 1);
      }
    }
  }

  goToNextSection() {
    if (this.swiper) {
      let activeIndex = this.swiper.getActiveIndex();
      this.swiper.goTo(activeIndex + 1);
    }
  }

  searchTextDidChange(text: string) {
    this.setState({searchText: text});
  }

  previousOccurence() {
    if (this.state.currentOccurenceIndex > 0) {
      this.setState({
        currentOccurenceIndex: this.state.currentOccurenceIndex - 1,
      });
    }
  }

  nextOccurence() {
    if (this.state.currentOccurenceIndex < this.state.numberOfOccurences - 1) {
      this.setState({
        currentOccurenceIndex: this.state.currentOccurenceIndex + 1,
      });
    }
  }

  addHighlights(originalHtml: string, isActive: boolean) {
    let searchString = this.state.searchText
      .replace(/\\/g, '')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let highlightedHtml = originalHtml;
    let startIndices = this.getIndicesOf(highlightedHtml, searchString);
    let start = 0;

    let numberOfOccurencesChanged =
      isActive && startIndices.length !== this.state.numberOfOccurences;
    let currentOccurenceIndex = -1;

    if (this.state.showSearch) {
      currentOccurenceIndex = numberOfOccurencesChanged
        ? 0
        : this.state.currentOccurenceIndex;
    }

    let highlightDisplacement = 0;

    if (startIndices.length > 0) {
      for (let i = 0; i < startIndices.length; i++) {
        start = startIndices[i];

        let highlightStart = "<span class='searchHighlight'>";
        let highlightEnd = '</span>';

        if (i === currentOccurenceIndex) {
          highlightStart =
            "<span class='searchHighlight searchHighlightCurrent'>";
        }

        highlightedHtml =
          highlightedHtml.substr(0, start + highlightDisplacement) +
          highlightStart +
          highlightedHtml.substr(
            start + highlightDisplacement,
            searchString.length,
          ) +
          highlightEnd +
          highlightedHtml.substr(
            start + highlightDisplacement + searchString.length,
          );

        highlightDisplacement += highlightStart.length + highlightEnd.length;
      }
    }

    if (numberOfOccurencesChanged) {
      this.setState({
        numberOfOccurences: startIndices.length,
        currentOccurenceIndex: currentOccurenceIndex,
      });
    }

    return highlightedHtml;
  }

  getIndicesOf(stringToSearch: string, searchString: string) {
    if (searchString === null || searchString.length === 0) {
      return [];
    }

    let startIndex = 0;
    let index;
    let tag;
    const indices: any[] = [];
    const htmlTags: any[] = [];

    searchString = searchString.toLowerCase();
    stringToSearch = stringToSearch.toLowerCase();

    let regex = /<(?:.|\n)*?>/gi;

    while ((tag = regex.exec(stringToSearch)) != null) {
      htmlTags.push({tag: tag[0], index: tag.index});
    }

    while ((index = stringToSearch.indexOf(searchString, startIndex)) > -1) {
      let withinTag = false;

      for (let i = 0; i < htmlTags.length; i++) {
        const tag = htmlTags[i];
        let start = tag.index;
        let end = tag.index + tag.tag.length;

        if (index > start && index < end) {
          withinTag = true;
        }
      }

      if (!withinTag) {
        indices.push(index);
      }

      startIndex = index + searchString.length;
    }

    return indices;
  }

  openImageModal(imagePath) {
    let updatedImagePath = imagePath;
    if (Platform.OS === 'android') {
      updatedImagePath = updatedImagePath.replace(
        'file:///android_asset/',
        'asset:/',
      );
    }
    this.setState({imageViewerVisible: true, imageModalPath: updatedImagePath});
  }

  closeImageModal() {
    this.setState({imageViewerVisible: false});
  }

  paraFolioButtonTapped(type: string) {
    try {
      let title = '';
      let activityDate = 0;

      if (this.state.book && this.state.book.name) {
        title = this.state.book.name;
      }

      let timestamp = new Date();
      activityDate = timestamp.getTime();

      PlatformController.openURL(
        `https://parafolio.co.uk/?addreflection=true&title=${title}&activitydate=${activityDate}&type=book`,
      );
    } catch (error) {
      console.log(error);
    }
  }

  readButtonTapped(type: string) {
    let book = this.state.book;

    if (book) {
      for (
        let allChaptersIndex = 0;
        allChaptersIndex < book.chapters.length;
        allChaptersIndex++
      ) {
        let aChapter = book.chapters[allChaptersIndex];

        if (
          type === 'chapter' &&
          allChaptersIndex === this.state.selectedChapterIndex
        ) {
          let name = book.ID + '-' + aChapter.chapter;
          this.props.screenProps.updateBookReadingProgress(name, 'chapter');
          break;
        }

        for (
          let allSectionsIndex = 0;
          allSectionsIndex < aChapter.sections.length;
          allSectionsIndex++
        ) {
          let aSection = aChapter.sections[allSectionsIndex];
          if (
            allChaptersIndex === this.state.selectedChapterIndex &&
            allSectionsIndex === this.state.selectedSectionIndex
          ) {
            if (type === 'section' && aSection.section != null) {
              let name =
                book.ID + '-' + aChapter.chapter + '-' + aSection.section;
              this.props.screenProps.updateBookReadingProgress(name, 'section');
            }
          }
        }
      }
    }
  }

  isSectionRead(name: string) {
    return this.props.screenProps.bookReadingProgress.includes(name);
  }

  isChapterRead(name: string) {
    var n = name.lastIndexOf('-');
    var chapterName = name.substring(0, n);

    return this.props.screenProps.bookReadingProgress.includes(chapterName);
  }

  onNavigationChange(event) {
    if (event.title) {
      var htmlHeight = Number(event.title); //convert to number
      htmlHeight = htmlHeight;
    }
  }

  getQuizDetails = (bookName, chapterNumber, section) => {
    if (!section.relatedQuiz) {
      return null;
    }

    const quizset = this.props.screenProps.quizSets.find(
      quizSet => quizSet.name === section?.relatedQuiz,
    );

    const onPressHandler = () => {
      this.props.navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizset,
          from: `${bookName} - ${chapterNumber} - ${section.section}`,
        },
        this.props.state,
      );
    };
    return {quizset, onPressHandler};
  };

  render() {
    var that = this;
    let book = this.state.book;

    var bottomPadding = 10;

    var bottomLeftButtonLayout = <View style={{flex: 1}} />;

    if (true) {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.goToPreviousSection.bind(this)}>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Image
              style={{width: 44, height: 44}}
              source={require('../assets/Button_Disclosure_Left.png')}
            />
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Previous
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    var bottomRightButtonLayout = <View style={{flex: 1}} />;

    if (true) {
      bottomRightButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1}}
          onPress={this.goToNextSection.bind(this)}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Next
            </Text>
            <Image
              style={{width: 44, height: 44}}
              source={require('../assets/Button_Disclosure_Blue.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    // layout chapter contents modal
    let contentsLayout = [];

    let index = 0;

    if (this.swiper) {
      index = this.swiper.getActiveIndex();
    }

    let item = this.state.allSections[index];
    let chapterIndex = parseInt(item.split('-')[0]);
    let activeSectionIndex = parseInt(item.split('-')[1]);

    let chapter = this.state.book.chapters[chapterIndex];

    if (chapter != null) {
      contentsLayout.push(
        <View
          style={{
            paddingBottom: 16,
            borderBottomColor: '#dee2e6',
            borderBottomWidth: 1,
          }}>
          <Text
            style={{fontSize: 12, fontWeight: '600', color: colors.Grey900}}>
            Chapter {chapter.chapter}
          </Text>
          <Text
            style={{
              marginTop: 4,
              fontSize: 16,
              fontWeight: '500',
              color: colors.Grey900,
            }}>
            {chapter.name}
          </Text>
        </View>,
      );

      for (
        let sectionIndex = 0;
        sectionIndex < chapter.sections.length;
        sectionIndex++
      ) {
        let section = chapter.sections[sectionIndex];

        let activeSection = sectionIndex === activeSectionIndex;

        let sectionName = '';
        if (section.name) {
          sectionName = section.name.trim();
        }

        let currentSectionIndicator = (
          <View style={{marginRight: 10, width: 24, height: 24}} />
        );

        if (activeSection) {
          currentSectionIndicator = (
            <Image
              style={{marginRight: 10, width: 24, height: 24}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          );
        }

        let sectionStyle = {
          flex: 1,
          fontSize: 16,
          fontWeight: '500',
          lineHeight: 24,
          letterSpacing: -0.25,
          color: '#212529',
        };
        if (activeSection) {
          sectionStyle = {
            flex: 1,
            fontSize: 16,
            fontWeight: '700',
            lineHeight: 24,
            letterSpacing: -0.25,
            color: '#212529',
          };
        }

        contentsLayout.push(
          <TouchableOpacity
            key={`${chapter.chapter}-section-${sectionIndex}`}
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={this.contentsSectionTapped.bind(
              this,
              chapterIndex,
              sectionIndex,
            )}>
            {currentSectionIndicator}
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomColor: '#dee2e6',
                borderBottomWidth: 1,
                paddingRight: 9,
                paddingVertical: 16,
              }}>
              <DynamicText style={sectionStyle}>{sectionName}</DynamicText>
            </View>
          </TouchableOpacity>,
        );
      }
    }

    var searchTextInputHeight = 32;
    if (Platform.OS === 'android') {
      searchTextInputHeight = 42;
    }

    var occurencesLayout = null;
    if (this.state.searchText.length > 0) {
      occurencesLayout = (
        <View>
          <Text
            style={{color: colors.white, fontWeight: '500', marginRight: 10}}>
            {this.state.numberOfOccurences === 0
              ? 0
              : this.state.currentOccurenceIndex + 1}{' '}
            of {this.state.numberOfOccurences}
          </Text>
        </View>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        {this.state.showSearch ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: colors.NavBar,
            }}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: colors.SearchBar,
                borderRadius: 5,
                marginHorizontal: 10,
                marginTop: 6,
                marginBottom: 6,
                paddingVertical: 4,
              }}>
              <Image
                style={{marginLeft: 10, width: 22, height: 22}}
                source={require('../assets/SearchBar_Search.png')}
              />

              <TextInput
                ref={ref => (this.searchInput = ref)}
                style={{
                  flex: 1,
                  fontSize: 16,
                  marginHorizontal: 8,
                  height: searchTextInputHeight,
                  color: colors.white,
                }}
                underlineColorAndroid="transparent"
                onChangeText={text => this.searchTextDidChange(text)}
                value={this.state.searchText}
                placeholder={'Find...'}
                placeholderTextColor={'#BBB'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'search'}
              />
              {occurencesLayout}
            </View>
            <TouchableOpacity onPress={this.previousOccurence.bind(this)}>
              <Image
                style={{marginRight: 10, width: 36, height: 36}}
                source={require('../assets/Button_Disclosure_Up_White.png')}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={this.nextOccurence.bind(this)}>
              <Image
                style={{marginRight: 10, width: 36, height: 36}}
                source={require('../assets/Button_Disclosure_Down_White.png')}
              />
            </TouchableOpacity>
          </View>
        ) : null}

        <Swiper
          controlsEnabled={false}
          gesturesEnabled={() => {
            return false;
          }}
          from={this.state.startingIndex}
          springConfig={{friction: 9}}
          ref={ref => (this.swiper = ref)}
          onIndexChanged={index => {
            that.setTitle(that.state.book, that.state.allSections, index);
            setTimeout(() => {
              that.updateBookmarkStatus();
              that.reportPageChange();
            }, 100);
          }}>
          {this.state.allSections.map((item, index) => {
            let chapterIndex = item.split('-')[0];
            let sectionIndex = item.split('-')[1];
            let sectionHTML = '';
            let chapterName = '';
            let name = '';
            let isLastSectionOfChapter = false;
            let quizDetails = null;

            try {
              chapterIndex = parseInt(chapterIndex);
              sectionIndex = parseInt(sectionIndex);
            } catch (error) {}

            if (book != null) {
              if (chapterIndex < book.chapters.length) {
                let chapter = book.chapters[chapterIndex];
                if (sectionIndex < chapter.sections.length) {
                  let section = chapter.sections[sectionIndex];
                  quizDetails = this.getQuizDetails(
                    book.name,
                    chapter.chapter,
                    section,
                  );
                  sectionHTML = section.html;
                  chapterName = chapter.name;

                  isLastSectionOfChapter =
                    sectionIndex === chapter.sections.length - 1;

                  name =
                    book.ID + '-' + chapter.chapter + '-' + section.section;
                }
              }
            }

            return (
              <BookSectionSlide
                key={name}
                name={name}
                book={book}
                chapterName={chapterName}
                sectionHTML={sectionHTML}
                quizDetails={quizDetails}
                isLastSectionOfChapter={isLastSectionOfChapter}
                isChapterRead={this.isChapterRead.bind(this)}
                isSectionRead={this.isSectionRead.bind(this)}
                addHighlights={this.addHighlights.bind(this)}
                openImageModal={this.openImageModal.bind(this)}
                readButtonTapped={this.readButtonTapped.bind(this)}
                paraFolioButtonTapped={this.paraFolioButtonTapped.bind(this)}
                goToSection={this.goToSection.bind(this)}
                goToCaseStudy={this.goToCaseStudy.bind(this)}
                fontSize={that.props.screenProps.fontSize}
              />
            );
          })}
        </Swiper>

        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            height: 61 + bottomPadding,
            borderTopWidth: 1,
            borderColor: colors.Grey300,
          }}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              marginBottom: bottomPadding,
            }}>
            {bottomLeftButtonLayout}

            <TouchableOpacity
              activeOpacity={0.7}
              style={{flex: 1}}
              onPress={() => this.setState({shouldShowContentsModal: true})}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '700',
                    letterSpacing: -0.39,
                    color: colors.ButtonBlue,
                  }}>
                  Contents
                </Text>
              </View>
            </TouchableOpacity>

            {bottomRightButtonLayout}
          </View>
        </View>

        <Modal
          visible={this.state.shouldShowContentsModal}
          onBackButtonPress={() =>
            this.setState({shouldShowContentsModal: false})
          }
          presentationStyle="fullScreen"
          onDismiss={() => this.setState({shouldShowContentsModal: false})}
          animationType="slide"
          style={{flex: 1}}>
          <View style={{flex: 1}}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                height: PlatformController.getTopInset() + 20 + 44,
                backgroundColor: colors.NavBar,
              }}>
              <View style={{flex: 1}} />
              <View style={{flex: 1, justifyContent: 'center', height: 44}}>
                <Text
                  style={{
                    textAlign: 'center',
                    color: colors.white,
                    fontSize: 17,
                    fontWeight: '600',
                    paddingHorizontal: 16,
                  }}>
                  Contents
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  height: 44,
                }}
                onPress={() => this.setState({shouldShowContentsModal: false})}>
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 16,
                    paddingHorizontal: 16,
                  }}>
                  Close
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                backgroundColor: colors.white,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
              }}>
              <ScrollView
                style={{flex: 1}}
                contentContainerStyle={{
                  paddingHorizontal: 10,
                  paddingVertical: 16,
                }}>
                {contentsLayout}
              </ScrollView>
            </View>
          </View>
        </Modal>

        <AMImageViewer
          visible={this.state.imageViewerVisible}
          imageUrls={[{url: this.state.imageModalPath, props: {}}]}
          onClose={this.closeImageModal.bind(this)}
        />
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
