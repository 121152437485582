/**
 * PPP
 * @flow
 */

import {Platform} from 'react-native';
import GLList from '../GuidelinesJSON/GLList';
import GLListSup from '../GuidelinesJSONSup/GLList';
import GLList2019 from '../Guidelines2019JSON/GLList';
import DataController from '../classes/DataController';
import RemoteDataController from '../classes/RemoteDataController';
import AsyncStorage from '@react-native-async-storage/async-storage';

var _ = require('lodash');

export default {
  // getGuideline2016JSONForGLID(glid: string): Object {

  //   switch (glid) {
  //     case 'D0010': return require('../GuidelinesJSON/JSON/D0010.json');
  //     case 'D0020': return require('../GuidelinesJSON/JSON/D0020.json');
  //     case 'D0030': return require('../GuidelinesJSON/JSON/D0030.json');
  //     case 'D0040': return require('../GuidelinesJSON/JSON/D0040.json');
  //     case 'D0050': return require('../GuidelinesJSON/JSON/D0050.json');
  //     case 'D0060': return require('../GuidelinesJSON/JSON/D0060.json');
  //     case 'D0070': return require('../GuidelinesJSON/JSON/D0070.json');
  //     case 'D0080': return require('../GuidelinesJSON/JSON/D0080.json');
  //     case 'D0090': return require('../GuidelinesJSON/JSON/D0090.json');
  //     case 'D0100': return require('../GuidelinesJSON/JSON/D0100.json');
  //     case 'D0110': return require('../GuidelinesJSON/JSON/D0110.json');
  //     case 'D0120': return require('../GuidelinesJSON/JSON/D0120.json');
  //     case 'D0130': return require('../GuidelinesJSON/JSON/D0130.json');
  //     case 'D0140': return require('../GuidelinesJSON/JSON/D0140.json');
  //     case 'D0150': return require('../GuidelinesJSON/JSON/D0150.json');
  //     case 'D0160': return require('../GuidelinesJSON/JSON/D0160.json');
  //     case 'D0170': return require('../GuidelinesJSON/JSON/D0170.json');
  //     case 'D0180': return require('../GuidelinesJSON/JSON/D0180.json');
  //     case 'D0190': return require('../GuidelinesJSON/JSON/D0190.json');
  //     case 'D0200': return require('../GuidelinesJSON/JSON/D0200.json');
  //     case 'D0210': return require('../GuidelinesJSON/JSON/D0210.json');
  //     case 'D0215': return require('../GuidelinesJSON/JSON/D0215.json');
  //     case 'D0220': return require('../GuidelinesJSON/JSON/D0220.json');
  //     case 'D0230': return require('../GuidelinesJSON/JSON/D0230.json');
  //     case 'D0240': return require('../GuidelinesJSON/JSON/D0240.json');
  //     case 'D0250': return require('../GuidelinesJSON/JSON/D0250.json');
  //     case 'D0260': return require('../GuidelinesJSON/JSON/D0260.json');
  //     case 'D0270': return require('../GuidelinesJSON/JSON/D0270.json');
  //     case 'D0280': return require('../GuidelinesJSON/JSON/D0280.json');
  //     case 'D0290': return require('../GuidelinesJSON/JSON/D0290.json');
  //     case 'D0300': return require('../GuidelinesJSON/JSON/D0300.json');
  //     case 'D0310': return require('../GuidelinesJSON/JSON/D0310.json');
  //     case 'D0320': return require('../GuidelinesJSON/JSON/D0320.json');
  //     case 'D0330': return require('../GuidelinesJSON/JSON/D0330.json');
  //     case 'D0340': return require('../GuidelinesJSON/JSON/D0340.json');
  //     case 'D0350': return require('../GuidelinesJSON/JSON/D0350.json');
  //     case 'D0360': return require('../GuidelinesJSON/JSON/D0360.json');
  //     case 'D0370': return require('../GuidelinesJSON/JSON/D0370.json');
  //     case 'D0380': return require('../GuidelinesJSON/JSON/D0380.json');
  //     case 'G0005': return require('../GuidelinesJSON/JSON/G0005.json');
  //     case 'G0010': return require('../GuidelinesJSON/JSON/G0010.json');
  //     case 'G0020': return require('../GuidelinesJSON/JSON/G0020.json');
  //     case 'G0030': return require('../GuidelinesJSON/JSON/G0030.json');
  //     case 'G0040': return require('../GuidelinesJSON/JSON/G0040.json');
  //     case 'G0050': return require('../GuidelinesJSON/JSON/G0050.json');
  //     case 'G0060': return require('../GuidelinesJSON/JSON/G0060.json');
  //     case 'G0065': return require('../GuidelinesJSON/JSON/G0065.json');
  //     case 'G0070': return require('../GuidelinesJSON/JSON/G0070.json');
  //     case 'G0075': return require('../GuidelinesJSON/JSON/G0075.json');
  //     case 'G0080': return require('../GuidelinesJSON/JSON/G0080.json');
  //     case 'G0090': return require('../GuidelinesJSON/JSON/G0090.json');
  //     case 'G0100': return require('../GuidelinesJSON/JSON/G0100.json');
  //     case 'G0110': return require('../GuidelinesJSON/JSON/G0110.json');
  //     case 'G0120': return require('../GuidelinesJSON/JSON/G0120.json');
  //     case 'G0130': return require('../GuidelinesJSON/JSON/G0130.json');
  //     case 'G0140': return require('../GuidelinesJSON/JSON/G0140.json');
  //     case 'G0150': return require('../GuidelinesJSON/JSON/G0150.json');
  //     case 'G0160': return require('../GuidelinesJSON/JSON/G0160.json');
  //     case 'G0170': return require('../GuidelinesJSON/JSON/G0170.json');
  //     case 'G0180': return require('../GuidelinesJSON/JSON/G0180.json');
  //     case 'G0185': return require('../GuidelinesJSON/JSON/G0185.json');
  //     case 'G0190': return require('../GuidelinesJSON/JSON/G0190.json');
  //     case 'G0200': return require('../GuidelinesJSON/JSON/G0200.json');
  //     case 'G0210': return require('../GuidelinesJSON/JSON/G0210.json');
  //     case 'G0220': return require('../GuidelinesJSON/JSON/G0220.json');
  //     case 'G0230': return require('../GuidelinesJSON/JSON/G0230.json');
  //     case 'G0240': return require('../GuidelinesJSON/JSON/G0240.json');
  //     case 'G0245': return require('../GuidelinesJSON/JSON/G0245.json');
  //     case 'G0250': return require('../GuidelinesJSON/JSON/G0250.json');
  //     case 'G0260': return require('../GuidelinesJSON/JSON/G0260.json');
  //     case 'G0270': return require('../GuidelinesJSON/JSON/G0270.json');
  //     case 'G0280': return require('../GuidelinesJSON/JSON/G0280.json');
  //     case 'G0290': return require('../GuidelinesJSON/JSON/G0290.json');
  //     case 'G0300': return require('../GuidelinesJSON/JSON/G0300.json');
  //     case 'G0310': return require('../GuidelinesJSON/JSON/G0310.json');
  //     case 'G0320': return require('../GuidelinesJSON/JSON/G0320.json');
  //     case 'G0330': return require('../GuidelinesJSON/JSON/G0330.json');
  //     case 'G0340': return require('../GuidelinesJSON/JSON/G0340.json');
  //     case 'G0350': return require('../GuidelinesJSON/JSON/G0350.json');
  //     case 'G0360': return require('../GuidelinesJSON/JSON/G0360.json');
  //     case 'G0370': return require('../GuidelinesJSON/JSON/G0370.json');
  //     case 'G0380': return require('../GuidelinesJSON/JSON/G0380.json');
  //     case 'G0390': return require('../GuidelinesJSON/JSON/G0390.json');
  //     case 'G0400': return require('../GuidelinesJSON/JSON/G0400.json');
  //     case 'G0410': return require('../GuidelinesJSON/JSON/G0410.json');
  //     case 'G0420': return require('../GuidelinesJSON/JSON/G0420.json');
  //     case 'G0430': return require('../GuidelinesJSON/JSON/G0430.json');
  //     case 'G0440': return require('../GuidelinesJSON/JSON/G0440.json');
  //     case 'G0450': return require('../GuidelinesJSON/JSON/G0450.json');
  //     case 'G0460': return require('../GuidelinesJSON/JSON/G0460.json');
  //     case 'G0470': return require('../GuidelinesJSON/JSON/G0470.json');
  //     case 'G0480': return require('../GuidelinesJSON/JSON/G0480.json');
  //     case 'G0490': return require('../GuidelinesJSON/JSON/G0490.json');
  //     case 'G0500': return require('../GuidelinesJSON/JSON/G0500.json');
  //     case 'G0510': return require('../GuidelinesJSON/JSON/G0510.json');
  //     case 'G0520': return require('../GuidelinesJSON/JSON/G0520.json');
  //     case 'G0530': return require('../GuidelinesJSON/JSON/G0530.json');
  //     case 'G0540': return require('../GuidelinesJSON/JSON/G0540.json');
  //     case 'G0550': return require('../GuidelinesJSON/JSON/G0550.json');
  //     case 'G0560': return require('../GuidelinesJSON/JSON/G0560.json');
  //     case 'G0570': return require('../GuidelinesJSON/JSON/G0570.json');
  //     case 'G0580': return require('../GuidelinesJSON/JSON/G0580.json');
  //     case 'G0590': return require('../GuidelinesJSON/JSON/G0590.json');
  //     case 'G0600': return require('../GuidelinesJSON/JSON/G0600.json');
  //     case 'G0610': return require('../GuidelinesJSON/JSON/G0610.json');
  //     case 'G0620': return require('../GuidelinesJSON/JSON/G0620.json');
  //     case 'G0630': return require('../GuidelinesJSON/JSON/G0630.json');
  //     case 'G0640': return require('../GuidelinesJSON/JSON/G0640.json');
  //     case 'G0650': return require('../GuidelinesJSON/JSON/G0650.json');
  //     case 'G0660': return require('../GuidelinesJSON/JSON/G0660.json');
  //     case 'G0670': return require('../GuidelinesJSON/JSON/G0670.json');
  //     case 'G0680': return require('../GuidelinesJSON/JSON/G0680.json');
  //     case 'G0690': return require('../GuidelinesJSON/JSON/G0690.json');
  //     case 'G0700': return require('../GuidelinesJSON/JSON/G0700.json');
  //     case 'G0710': return require('../GuidelinesJSON/JSON/G0710.json');
  //     case 'G0720': return require('../GuidelinesJSON/JSON/G0720.json');
  //     case 'P0010': return require('../GuidelinesJSON/JSON/P0010.json');
  //     case 'P0020': return require('../GuidelinesJSON/JSON/P0020.json');
  //     case 'P0030': return require('../GuidelinesJSON/JSON/P0030.json');
  //     case 'P0040': return require('../GuidelinesJSON/JSON/P0040.json');
  //     case 'P0050': return require('../GuidelinesJSON/JSON/P0050.json');
  //     case 'P0060': return require('../GuidelinesJSON/JSON/P0060.json');
  //     case 'P0070': return require('../GuidelinesJSON/JSON/P0070.json');
  //     case 'P0080': return require('../GuidelinesJSON/JSON/P0080.json');
  //     case 'P0090': return require('../GuidelinesJSON/JSON/P0090.json');
  //     case 'P0100': return require('../GuidelinesJSON/JSON/P0100.json');
  //     case 'P0110': return require('../GuidelinesJSON/JSON/P0110.json');
  //     case 'P0120': return require('../GuidelinesJSON/JSON/P0120.json');
  //     case 'P0130': return require('../GuidelinesJSON/JSON/P0130.json');
  //     case 'P0140': return require('../GuidelinesJSON/JSON/P0140.json');
  //     case 'P0150': return require('../GuidelinesJSON/JSON/P0150.json');
  //     case 'P0160': return require('../GuidelinesJSON/JSON/P0160.json');
  //     case 'P0170': return require('../GuidelinesJSON/JSON/P0170.json');
  //     case 'S0010': return require('../GuidelinesJSON/JSON/S0010.json');
  //     case 'S0020': return require('../GuidelinesJSON/JSON/S0020.json');
  //     case 'S0030': return require('../GuidelinesJSON/JSON/S0030.json');
  //     case 'S0040': return require('../GuidelinesJSON/JSON/S0040.json');
  //     case 'S0050': return require('../GuidelinesJSON/JSON/S0050.json');
  //     case 'S0060': return require('../GuidelinesJSON/JSON/S0060.json');
  //     case 'S0070': return require('../GuidelinesJSON/JSON/S0070.json');
  //     case 'S0080': return require('../GuidelinesJSON/JSON/S0080.json');
  //     case 'S0090': return require('../GuidelinesJSON/JSON/S0090.json');
  //     case 'S0100': return require('../GuidelinesJSON/JSON/S0100.json');
  //   }
  // },

  // getGuideline2017JSONForGLID(glid: string): Object {

  //   switch (glid) {
  //     case 'D0010': return require('../GuidelinesJSONSup/JSON/D0010.json');
  //     case 'D0020': return require('../GuidelinesJSONSup/JSON/D0020.json');
  //     case 'D0030': return require('../GuidelinesJSONSup/JSON/D0030.json');
  //     case 'D0040': return require('../GuidelinesJSONSup/JSON/D0040.json');
  //     case 'D0050': return require('../GuidelinesJSONSup/JSON/D0050.json');
  //     case 'D0060': return require('../GuidelinesJSONSup/JSON/D0060.json');
  //     case 'D0070': return require('../GuidelinesJSONSup/JSON/D0070.json');
  //     case 'D0080': return require('../GuidelinesJSONSup/JSON/D0080.json');
  //     case 'D0090': return require('../GuidelinesJSONSup/JSON/D0090.json');
  //     case 'D0100': return require('../GuidelinesJSONSup/JSON/D0100.json');
  //     case 'D0109': return require('../GuidelinesJSONSup/JSON/D0109.json');
  //     case 'D0110': return require('../GuidelinesJSONSup/JSON/D0110.json');
  //     case 'D0120': return require('../GuidelinesJSONSup/JSON/D0120.json');
  //     case 'D0130': return require('../GuidelinesJSONSup/JSON/D0130.json');
  //     case 'D0140': return require('../GuidelinesJSONSup/JSON/D0140.json');
  //     case 'D0150': return require('../GuidelinesJSONSup/JSON/D0150.json');
  //     case 'D0160': return require('../GuidelinesJSONSup/JSON/D0160.json');
  //     case 'D0170': return require('../GuidelinesJSONSup/JSON/D0170.json');
  //     case 'D0180': return require('../GuidelinesJSONSup/JSON/D0180.json');
  //     case 'D0190': return require('../GuidelinesJSONSup/JSON/D0190.json');
  //     case 'D0200': return require('../GuidelinesJSONSup/JSON/D0200.json');
  //     case 'D0210': return require('../GuidelinesJSONSup/JSON/D0210.json');
  //     case 'D0215': return require('../GuidelinesJSONSup/JSON/D0215.json');
  //     case 'D0220': return require('../GuidelinesJSONSup/JSON/D0220.json');
  //     case 'D0230': return require('../GuidelinesJSONSup/JSON/D0230.json');
  //     case 'D0240': return require('../GuidelinesJSONSup/JSON/D0240.json');
  //     case 'D0250': return require('../GuidelinesJSONSup/JSON/D0250.json');
  //     case 'D0260': return require('../GuidelinesJSONSup/JSON/D0260.json');
  //     case 'D0270': return require('../GuidelinesJSONSup/JSON/D0270.json');
  //     case 'D0280': return require('../GuidelinesJSONSup/JSON/D0280.json');
  //     case 'D0290': return require('../GuidelinesJSONSup/JSON/D0290.json');
  //     case 'D0300': return require('../GuidelinesJSONSup/JSON/D0300.json');
  //     case 'D0310': return require('../GuidelinesJSONSup/JSON/D0310.json');
  //     case 'D0320': return require('../GuidelinesJSONSup/JSON/D0320.json');
  //     case 'D0330': return require('../GuidelinesJSONSup/JSON/D0330.json');
  //     case 'D0340': return require('../GuidelinesJSONSup/JSON/D0340.json');
  //     case 'D0350': return require('../GuidelinesJSONSup/JSON/D0350.json');
  //     case 'D0360': return require('../GuidelinesJSONSup/JSON/D0360.json');
  //     case 'D0370': return require('../GuidelinesJSONSup/JSON/D0370.json');
  //     case 'D0380': return require('../GuidelinesJSONSup/JSON/D0380.json');
  //     case 'D0390': return require('../GuidelinesJSONSup/JSON/D0390.json');
  //     case 'G0005': return require('../GuidelinesJSONSup/JSON/G0005.json');
  //     case 'G0010': return require('../GuidelinesJSONSup/JSON/G0010.json');
  //     case 'G0020': return require('../GuidelinesJSONSup/JSON/G0020.json');
  //     case 'G0030': return require('../GuidelinesJSONSup/JSON/G0030.json');
  //     case 'G0040': return require('../GuidelinesJSONSup/JSON/G0040.json');
  //     case 'G0050': return require('../GuidelinesJSONSup/JSON/G0050.json');
  //     case 'G0060': return require('../GuidelinesJSONSup/JSON/G0060.json');
  //     case 'G0065': return require('../GuidelinesJSONSup/JSON/G0065.json');
  //     case 'G0067': return require('../GuidelinesJSONSup/JSON/G0067.json');
  //     case 'G0070': return require('../GuidelinesJSONSup/JSON/G0070.json');
  //     case 'G0075': return require('../GuidelinesJSONSup/JSON/G0075.json');
  //     case 'G0080': return require('../GuidelinesJSONSup/JSON/G0080.json');
  //     case 'G0090': return require('../GuidelinesJSONSup/JSON/G0090.json');
  //     case 'G0100': return require('../GuidelinesJSONSup/JSON/G0100.json');
  //     case 'G0110': return require('../GuidelinesJSONSup/JSON/G0110.json');
  //     case 'G0120': return require('../GuidelinesJSONSup/JSON/G0120.json');
  //     case 'G0130': return require('../GuidelinesJSONSup/JSON/G0130.json');
  //     case 'G0140': return require('../GuidelinesJSONSup/JSON/G0140.json');
  //     case 'G0150': return require('../GuidelinesJSONSup/JSON/G0150.json');
  //     case 'G0160': return require('../GuidelinesJSONSup/JSON/G0160.json');
  //     case 'G0170': return require('../GuidelinesJSONSup/JSON/G0170.json');
  //     case 'G0180': return require('../GuidelinesJSONSup/JSON/G0180.json');
  //     case 'G0185': return require('../GuidelinesJSONSup/JSON/G0185.json');
  //     case 'G0190': return require('../GuidelinesJSONSup/JSON/G0190.json');
  //     case 'G0200': return require('../GuidelinesJSONSup/JSON/G0200.json');
  //     case 'G0210': return require('../GuidelinesJSONSup/JSON/G0210.json');
  //     case 'G0220': return require('../GuidelinesJSONSup/JSON/G0220.json');
  //     case 'G0230': return require('../GuidelinesJSONSup/JSON/G0230.json');
  //     case 'G0240': return require('../GuidelinesJSONSup/JSON/G0240.json');
  //     case 'G0245': return require('../GuidelinesJSONSup/JSON/G0245.json');
  //     case 'G0250': return require('../GuidelinesJSONSup/JSON/G0250.json');
  //     case 'G0260': return require('../GuidelinesJSONSup/JSON/G0260.json');
  //     case 'G0270': return require('../GuidelinesJSONSup/JSON/G0270.json');
  //     case 'G0280': return require('../GuidelinesJSONSup/JSON/G0280.json');
  //     case 'G0290': return require('../GuidelinesJSONSup/JSON/G0290.json');
  //     case 'G0300': return require('../GuidelinesJSONSup/JSON/G0300.json');
  //     case 'G0310': return require('../GuidelinesJSONSup/JSON/G0310.json');
  //     case 'G0320': return require('../GuidelinesJSONSup/JSON/G0320.json');
  //     case 'G0330': return require('../GuidelinesJSONSup/JSON/G0330.json');
  //     case 'G0340': return require('../GuidelinesJSONSup/JSON/G0340.json');
  //     case 'G0350': return require('../GuidelinesJSONSup/JSON/G0350.json');
  //     case 'G0355': return require('../GuidelinesJSONSup/JSON/G0355.json');
  //     case 'G0360': return require('../GuidelinesJSONSup/JSON/G0360.json');
  //     case 'G0370': return require('../GuidelinesJSONSup/JSON/G0370.json');
  //     case 'G0380': return require('../GuidelinesJSONSup/JSON/G0380.json');
  //     case 'G0390': return require('../GuidelinesJSONSup/JSON/G0390.json');
  //     case 'G0400': return require('../GuidelinesJSONSup/JSON/G0400.json');
  //     case 'G0410': return require('../GuidelinesJSONSup/JSON/G0410.json');
  //     case 'G0420': return require('../GuidelinesJSONSup/JSON/G0420.json');
  //     case 'G0425': return require('../GuidelinesJSONSup/JSON/G0425.json');
  //     case 'G0430': return require('../GuidelinesJSONSup/JSON/G0430.json');
  //     case 'G0440': return require('../GuidelinesJSONSup/JSON/G0440.json');
  //     case 'G0450': return require('../GuidelinesJSONSup/JSON/G0450.json');
  //     case 'G0460': return require('../GuidelinesJSONSup/JSON/G0460.json');
  //     case 'G0470': return require('../GuidelinesJSONSup/JSON/G0470.json');
  //     case 'G0480': return require('../GuidelinesJSONSup/JSON/G0480.json');
  //     case 'G0490': return require('../GuidelinesJSONSup/JSON/G0490.json');
  //     case 'G0500': return require('../GuidelinesJSONSup/JSON/G0500.json');
  //     case 'G0510': return require('../GuidelinesJSONSup/JSON/G0510.json');
  //     case 'G0520': return require('../GuidelinesJSONSup/JSON/G0520.json');
  //     case 'G0530': return require('../GuidelinesJSONSup/JSON/G0530.json');
  //     case 'G0535': return require('../GuidelinesJSONSup/JSON/G0535.json');
  //     case 'G0540': return require('../GuidelinesJSONSup/JSON/G0540.json');
  //     case 'G0550': return require('../GuidelinesJSONSup/JSON/G0550.json');
  //     case 'G0560': return require('../GuidelinesJSONSup/JSON/G0560.json');
  //     case 'G0570': return require('../GuidelinesJSONSup/JSON/G0570.json');
  //     case 'G0580': return require('../GuidelinesJSONSup/JSON/G0580.json');
  //     case 'G0590': return require('../GuidelinesJSONSup/JSON/G0590.json');
  //     case 'G0600': return require('../GuidelinesJSONSup/JSON/G0600.json');
  //     case 'G0610': return require('../GuidelinesJSONSup/JSON/G0610.json');
  //     case 'G0615': return require('../GuidelinesJSONSup/JSON/G0615.json');
  //     case 'G0620': return require('../GuidelinesJSONSup/JSON/G0620.json');
  //     case 'G0630': return require('../GuidelinesJSONSup/JSON/G0630.json');
  //     case 'G0640': return require('../GuidelinesJSONSup/JSON/G0640.json');
  //     case 'G0650': return require('../GuidelinesJSONSup/JSON/G0650.json');
  //     case 'G0660': return require('../GuidelinesJSONSup/JSON/G0660.json');
  //     case 'G0670': return require('../GuidelinesJSONSup/JSON/G0670.json');
  //     case 'G0680': return require('../GuidelinesJSONSup/JSON/G0680.json');
  //     case 'G0690': return require('../GuidelinesJSONSup/JSON/G0690.json');
  //     case 'G0700': return require('../GuidelinesJSONSup/JSON/G0700.json');
  //     case 'G0710': return require('../GuidelinesJSONSup/JSON/G0710.json');
  //     case 'G0720': return require('../GuidelinesJSONSup/JSON/G0720.json');
  //     case 'GAUTH': return require('../GuidelinesJSONSup/JSON/GAUTH.json');
  //     case 'P0010': return require('../GuidelinesJSONSup/JSON/P0010.json');
  //     case 'P0020': return require('../GuidelinesJSONSup/JSON/P0020.json');
  //     case 'P0030': return require('../GuidelinesJSONSup/JSON/P0030.json');
  //     case 'P0040': return require('../GuidelinesJSONSup/JSON/P0040.json');
  //     case 'P0050': return require('../GuidelinesJSONSup/JSON/P0050.json');
  //     case 'P0060': return require('../GuidelinesJSONSup/JSON/P0060.json');
  //     case 'P0070': return require('../GuidelinesJSONSup/JSON/P0070.json');
  //     case 'P0080': return require('../GuidelinesJSONSup/JSON/P0080.json');
  //     case 'P0090': return require('../GuidelinesJSONSup/JSON/P0090.json');
  //     case 'P0100': return require('../GuidelinesJSONSup/JSON/P0100.json');
  //     case 'P0110': return require('../GuidelinesJSONSup/JSON/P0110.json');
  //     case 'P0120': return require('../GuidelinesJSONSup/JSON/P0120.json');
  //     case 'P0130': return require('../GuidelinesJSONSup/JSON/P0130.json');
  //     case 'P0140': return require('../GuidelinesJSONSup/JSON/P0140.json');
  //     case 'P0150': return require('../GuidelinesJSONSup/JSON/P0150.json');
  //     case 'P0160': return require('../GuidelinesJSONSup/JSON/P0160.json');
  //     case 'P0170': return require('../GuidelinesJSONSup/JSON/P0170.json');
  //     case 'S0010': return require('../GuidelinesJSONSup/JSON/S0010.json');
  //     case 'S0020': return require('../GuidelinesJSONSup/JSON/S0020.json');
  //     case 'S0030': return require('../GuidelinesJSONSup/JSON/S0030.json');
  //     case 'S0040': return require('../GuidelinesJSONSup/JSON/S0040.json');
  //     case 'S0050': return require('../GuidelinesJSONSup/JSON/S0050.json');
  //     case 'S0060': return require('../GuidelinesJSONSup/JSON/S0060.json');
  //     case 'S0070': return require('../GuidelinesJSONSup/JSON/S0070.json');
  //     case 'S0080': return require('../GuidelinesJSONSup/JSON/S0080.json');
  //     case 'S0090': return require('../GuidelinesJSONSup/JSON/S0090.json');
  //     case 'S0100': return require('../GuidelinesJSONSup/JSON/S0100.json');
  //   }
  // },

  // getGuideline2019JSONForGLID(glid: string): Object {

  //   switch (glid) {

  //     case 'D0010': return require('../Guidelines2019JSON/JSON/D0010.json');
  //     case 'D0018': return require('../Guidelines2019JSON/JSON/D0018.json');
  //     case 'D0020': return require('../Guidelines2019JSON/JSON/D0020.json');
  //     case 'D0030': return require('../Guidelines2019JSON/JSON/D0030.json');
  //     case 'D0040': return require('../Guidelines2019JSON/JSON/D0040.json');
  //     case 'D0050': return require('../Guidelines2019JSON/JSON/D0050.json');
  //     case 'D0060': return require('../Guidelines2019JSON/JSON/D0060.json');
  //     case 'D0070': return require('../Guidelines2019JSON/JSON/D0070.json');
  //     case 'D0080': return require('../Guidelines2019JSON/JSON/D0080.json');
  //     case 'D0090': return require('../Guidelines2019JSON/JSON/D0090.json');
  //     case 'D0100': return require('../Guidelines2019JSON/JSON/D0100.json');
  //     case 'D0109': return require('../Guidelines2019JSON/JSON/D0109.json');
  //     case 'D0110': return require('../Guidelines2019JSON/JSON/D0110.json');
  //     case 'D0120': return require('../Guidelines2019JSON/JSON/D0120.json');
  //     case 'D0130': return require('../Guidelines2019JSON/JSON/D0130.json');
  //     case 'D0140': return require('../Guidelines2019JSON/JSON/D0140.json');
  //     case 'D0150': return require('../Guidelines2019JSON/JSON/D0150.json');
  //     case 'D0160': return require('../Guidelines2019JSON/JSON/D0160.json');
  //     case 'D0170': return require('../Guidelines2019JSON/JSON/D0170.json');
  //     case 'D0180': return require('../Guidelines2019JSON/JSON/D0180.json');
  //     case 'D0190': return require('../Guidelines2019JSON/JSON/D0190.json');
  //     case 'D0200': return require('../Guidelines2019JSON/JSON/D0200.json');
  //     case 'D0210': return require('../Guidelines2019JSON/JSON/D0210.json');
  //     case 'D0215': return require('../Guidelines2019JSON/JSON/D0215.json');
  //     case 'D0230': return require('../Guidelines2019JSON/JSON/D0230.json');
  //     case 'D0240': return require('../Guidelines2019JSON/JSON/D0240.json');
  //     case 'D0250': return require('../Guidelines2019JSON/JSON/D0250.json');
  //     case 'D0260': return require('../Guidelines2019JSON/JSON/D0260.json');
  //     case 'D0270': return require('../Guidelines2019JSON/JSON/D0270.json');
  //     case 'D0280': return require('../Guidelines2019JSON/JSON/D0280.json');
  //     case 'D0300': return require('../Guidelines2019JSON/JSON/D0300.json');
  //     case 'D0310': return require('../Guidelines2019JSON/JSON/D0310.json');
  //     case 'D0320': return require('../Guidelines2019JSON/JSON/D0320.json');
  //     case 'D0330': return require('../Guidelines2019JSON/JSON/D0330.json');
  //     case 'D0340': return require('../Guidelines2019JSON/JSON/D0340.json');
  //     case 'D0360': return require('../Guidelines2019JSON/JSON/D0360.json');
  //     case 'D0370': return require('../Guidelines2019JSON/JSON/D0370.json');
  //     case 'D0380': return require('../Guidelines2019JSON/JSON/D0380.json');
  //     case 'D0390': return require('../Guidelines2019JSON/JSON/D0390.json');
  //     case 'G0005': return require('../Guidelines2019JSON/JSON/G0005.json');
  //     case 'G0010': return require('../Guidelines2019JSON/JSON/G0010.json');
  //     case 'G0020': return require('../Guidelines2019JSON/JSON/G0020.json');
  //     case 'G0030': return require('../Guidelines2019JSON/JSON/G0030.json');
  //     case 'G0040': return require('../Guidelines2019JSON/JSON/G0040.json');
  //     case 'G0050': return require('../Guidelines2019JSON/JSON/G0050.json');
  //     case 'G0060': return require('../Guidelines2019JSON/JSON/G0060.json');
  //     case 'G0065': return require('../Guidelines2019JSON/JSON/G0065.json');
  //     case 'G0067': return require('../Guidelines2019JSON/JSON/G0067.json');
  //     case 'G0070': return require('../Guidelines2019JSON/JSON/G0070.json');
  //     case 'G0075': return require('../Guidelines2019JSON/JSON/G0075.json');
  //     case 'G0077': return require('../Guidelines2019JSON/JSON/G0077.json');
  //     case 'G0085': return require('../Guidelines2019JSON/JSON/G0085.json');
  //     case 'G0095': return require('../Guidelines2019JSON/JSON/G0095.json');
  //     case 'G0100': return require('../Guidelines2019JSON/JSON/G0100.json');
  //     case 'G0110': return require('../Guidelines2019JSON/JSON/G0110.json');
  //     case 'G0120': return require('../Guidelines2019JSON/JSON/G0120.json');
  //     case 'G0125': return require('../Guidelines2019JSON/JSON/G0125.json');
  //     case 'G0130': return require('../Guidelines2019JSON/JSON/G0130.json');
  //     case 'G0140': return require('../Guidelines2019JSON/JSON/G0140.json');
  //     case 'G0150': return require('../Guidelines2019JSON/JSON/G0150.json');
  //     case 'G0160': return require('../Guidelines2019JSON/JSON/G0160.json');
  //     case 'G0190': return require('../Guidelines2019JSON/JSON/G0190.json');
  //     case 'G0200': return require('../Guidelines2019JSON/JSON/G0200.json');
  //     case 'G0210': return require('../Guidelines2019JSON/JSON/G0210.json');
  //     case 'G0220': return require('../Guidelines2019JSON/JSON/G0220.json');
  //     case 'G0230': return require('../Guidelines2019JSON/JSON/G0230.json');
  //     case 'G0240': return require('../Guidelines2019JSON/JSON/G0240.json');
  //     case 'G0245': return require('../Guidelines2019JSON/JSON/G0245.json');
  //     case 'G0250': return require('../Guidelines2019JSON/JSON/G0250.json');
  //     case 'G0260': return require('../Guidelines2019JSON/JSON/G0260.json');
  //     case 'G0270': return require('../Guidelines2019JSON/JSON/G0270.json');
  //     case 'G0280': return require('../Guidelines2019JSON/JSON/G0280.json');
  //     case 'G0290': return require('../Guidelines2019JSON/JSON/G0290.json');
  //     case 'G0300': return require('../Guidelines2019JSON/JSON/G0300.json');
  //     case 'G0310': return require('../Guidelines2019JSON/JSON/G0310.json');
  //     case 'G0320': return require('../Guidelines2019JSON/JSON/G0320.json');
  //     case 'G0330': return require('../Guidelines2019JSON/JSON/G0330.json');
  //     case 'G0340': return require('../Guidelines2019JSON/JSON/G0340.json');
  //     case 'G0350': return require('../Guidelines2019JSON/JSON/G0350.json');
  //     case 'G0355': return require('../Guidelines2019JSON/JSON/G0355.json');
  //     case 'G0360': return require('../Guidelines2019JSON/JSON/G0360.json');
  //     case 'G0380': return require('../Guidelines2019JSON/JSON/G0380.json');
  //     case 'G0390': return require('../Guidelines2019JSON/JSON/G0390.json');
  //     case 'G0400': return require('../Guidelines2019JSON/JSON/G0400.json');
  //     case 'G0410': return require('../Guidelines2019JSON/JSON/G0410.json');
  //     case 'G0425': return require('../Guidelines2019JSON/JSON/G0425.json');
  //     case 'G0430': return require('../Guidelines2019JSON/JSON/G0430.json');
  //     case 'G0440': return require('../Guidelines2019JSON/JSON/G0440.json');
  //     case 'G0450': return require('../Guidelines2019JSON/JSON/G0450.json');
  //     case 'G0460': return require('../Guidelines2019JSON/JSON/G0460.json');
  //     case 'G0470': return require('../Guidelines2019JSON/JSON/G0470.json');
  //     case 'G0480': return require('../Guidelines2019JSON/JSON/G0480.json');
  //     case 'G0500': return require('../Guidelines2019JSON/JSON/G0500.json');
  //     case 'G0510': return require('../Guidelines2019JSON/JSON/G0510.json');
  //     case 'G0535': return require('../Guidelines2019JSON/JSON/G0535.json');
  //     case 'G0540': return require('../Guidelines2019JSON/JSON/G0540.json');
  //     case 'G0550': return require('../Guidelines2019JSON/JSON/G0550.json');
  //     case 'G0560': return require('../Guidelines2019JSON/JSON/G0560.json');
  //     case 'G0570': return require('../Guidelines2019JSON/JSON/G0570.json');
  //     case 'G0580': return require('../Guidelines2019JSON/JSON/G0580.json');
  //     case 'G0590': return require('../Guidelines2019JSON/JSON/G0590.json');
  //     case 'G0600': return require('../Guidelines2019JSON/JSON/G0600.json');
  //     case 'G0610': return require('../Guidelines2019JSON/JSON/G0610.json');
  //     case 'G0615': return require('../Guidelines2019JSON/JSON/G0615.json');
  //     case 'G0620': return require('../Guidelines2019JSON/JSON/G0620.json');
  //     case 'G0630': return require('../Guidelines2019JSON/JSON/G0630.json');
  //     case 'G0650': return require('../Guidelines2019JSON/JSON/G0650.json');
  //     case 'G0660': return require('../Guidelines2019JSON/JSON/G0660.json');
  //     case 'G0670': return require('../Guidelines2019JSON/JSON/G0670.json');
  //     case 'G0680': return require('../Guidelines2019JSON/JSON/G0680.json');
  //     case 'G0690': return require('../Guidelines2019JSON/JSON/G0690.json');
  //     case 'G0700': return require('../Guidelines2019JSON/JSON/G0700.json');
  //     case 'G0710': return require('../Guidelines2019JSON/JSON/G0710.json');
  //     case 'G0720': return require('../Guidelines2019JSON/JSON/G0720.json');
  //     case 'G0750': return require('../Guidelines2019JSON/JSON/G0750.json');
  //     case 'GAUTH': return require('../Guidelines2019JSON/JSON/GAUTH.json');
  //     case 'P0010': return require('../Guidelines2019JSON/JSON/P0010.json');
  //     case 'P0020': return require('../Guidelines2019JSON/JSON/P0020.json');
  //     case 'P0030': return require('../Guidelines2019JSON/JSON/P0030.json');
  //     case 'P0040': return require('../Guidelines2019JSON/JSON/P0040.json');
  //     case 'P0050': return require('../Guidelines2019JSON/JSON/P0050.json');
  //     case 'P0060': return require('../Guidelines2019JSON/JSON/P0060.json');
  //     case 'P0070': return require('../Guidelines2019JSON/JSON/P0070.json');
  //     case 'P0080': return require('../Guidelines2019JSON/JSON/P0080.json');
  //     case 'P0090': return require('../Guidelines2019JSON/JSON/P0090.json');
  //     case 'P0100': return require('../Guidelines2019JSON/JSON/P0100.json');
  //     case 'P0110': return require('../Guidelines2019JSON/JSON/P0110.json');
  //     case 'P0120': return require('../Guidelines2019JSON/JSON/P0120.json');
  //     case 'P0130': return require('../Guidelines2019JSON/JSON/P0130.json');
  //     case 'P0140': return require('../Guidelines2019JSON/JSON/P0140.json');
  //     case 'P0150': return require('../Guidelines2019JSON/JSON/P0150.json');
  //     case 'P0160': return require('../Guidelines2019JSON/JSON/P0160.json');
  //     case 'P0170': return require('../Guidelines2019JSON/JSON/P0170.json');
  //     case 'S0020': return require('../Guidelines2019JSON/JSON/S0020.json');
  //     case 'S0030': return require('../Guidelines2019JSON/JSON/S0030.json');
  //     case 'S0040': return require('../Guidelines2019JSON/JSON/S0040.json');
  //     case 'S0100': return require('../Guidelines2019JSON/JSON/S0100.json');
  //   }
  // },

  async getGuidelineJSONForGLID(glid: string): Object {
    var guideline = await this.getGuidelineForGLID(glid);

    if (guideline != null) {
      if (guideline.Year === 'current') {
        if (Platform.OS === 'web') {
          // try downloading from aace bucket first - then fall back to jrcalc bucket if not found.
          try {
            const url = `https://aace.s3.amazonaws.com/${guideline.GLID}.${guideline.publ_major_version_no}.${guideline.publ_minor_version_no}.json`;
            const response = await RemoteDataController.customFetch(url);
            const guidelineJSON = await response.json();

            if (guidelineJSON != null) {
              if (guidelineJSON.guideline) {
                return guidelineJSON.guideline;
              } else {
                return guidelineJSON;
              }
            }
          } catch (error) {
            console.log(guideline.GLID, error);
          }

          try {
            var url = 'https://jrcalc.s3.amazonaws.com/Feed-Sup/';

            console.log(glid);
            if (glid.charAt(0) === 'G' || glid.charAt(0) === 'S') {
              url += 'Guidelines/';
            } else if (glid.charAt(0) === 'P' || glid.charAt(0) === 'D') {
              url += 'Drugs/';
            }

            var filename = glid + '.json';

            url += filename;

            var response = await RemoteDataController.customFetch(url);
            const {guideline} = await response.json();

            return guideline;
          } catch (error) {
            console.log(error);
          }
        } else {
          var guidelineJSON = await DataController.getDataFromFile(
            glid + '.json',
          );

          if (guidelineJSON != null && guidelineJSON !== '') {
            if (guidelineJSON.guideline) {
              return guidelineJSON.guideline;
            } else {
              return guidelineJSON;
            }
          }
        }
      } else {
        return 'Old Guideline';
      }
    }

    return null;
  },

  async getAllGuidelines() {
    // create array of all guidelines
    var allGuidelines = [];

    // get downloaded Guidelines
    var localGuidelines = await DataController.getDataFromFile(
      'guidelinesList.json',
    );

    try {
      if (localGuidelines == null || localGuidelines === '') {
        localGuidelines = [];
      }
    } catch (error) {
      console.log('Error parsing local guidelines: ', error);
    }

    // add all downloaded guidelines guidelines
    for (
      let guidelineIndex = 0;
      guidelineIndex < localGuidelines.length;
      guidelineIndex++
    ) {
      var aGuideline = localGuidelines[guidelineIndex];
      aGuideline.Year = 'current';
      allGuidelines.push(aGuideline);
    }

    // add all 2019 guidelines - which aren't already in allGuidelines (based on GLID)
    for (
      let guidelineIndex = 0;
      guidelineIndex < GLList2019.length;
      guidelineIndex++
    ) {
      var aGuideline = GLList2019[guidelineIndex];
      let matchingGuideline = _.find(allGuidelines, {GLID: aGuideline.GLID});

      if (matchingGuideline == null) {
        aGuideline.Year = '2019';
        allGuidelines.push(aGuideline);
      }
    }

    // add all 2017 guidelines (Sup) - which aren't already in allGuidelines (based on GLID)
    for (
      let guidelineIndex = 0;
      guidelineIndex < GLListSup.length;
      guidelineIndex++
    ) {
      var aGuideline = GLListSup[guidelineIndex];
      let matchingGuideline = _.find(allGuidelines, {GLID: aGuideline.GLID});

      if (matchingGuideline == null) {
        aGuideline.Year = '2017';
        allGuidelines.push(aGuideline);
      }
    }

    // add all 2016 guidelines - which aren't already in allGuidelines (based on GLID)
    for (
      let guidelineIndex = 0;
      guidelineIndex < GLList.length;
      guidelineIndex++
    ) {
      var aGuideline = GLList[guidelineIndex];
      let matchingGuideline = _.find(allGuidelines, {GLID: aGuideline.GLID});

      if (matchingGuideline == null) {
        aGuideline.Year = '2016';
        allGuidelines.push(aGuideline);
      }
    }

    return allGuidelines;
  },

  async getGuidelineForGLID(glid: string) {
    var allGuidelines = await this.getAllGuidelines();

    for (
      let guidelineIndex = 0;
      guidelineIndex < allGuidelines.length;
      guidelineIndex++
    ) {
      var aGuideline = allGuidelines[guidelineIndex];

      if (glid === aGuideline.GLID) {
        return aGuideline;
      }
    }

    return null;
  },

  async getGuidelineNameForGuideline(glid: string) {
    var allGuidelines = await this.getAllGuidelines();

    for (
      let guidelineIndex = 0;
      guidelineIndex < allGuidelines.length;
      guidelineIndex++
    ) {
      var aGuideline = allGuidelines[guidelineIndex];

      if (glid === aGuideline.GLID) {
        return aGuideline.Title;
      }
    }

    return '';
  },

  getGuidelineSectionForGuideline(guideline: Object) {
    if (guideline != null) {
      var sectionNumberString = guideline.Section.replace('Section', '');
      var sectionNumber = parseInt(sectionNumberString);
      return this.getSectionNameFromNumber(sectionNumber);
    }

    return '';
  },

  getSectionNameFromNumber(sectionNumber: number) {
    switch (sectionNumber) {
      case 1:
        return 'General Guidance';
      case 2:
        return 'Resuscitation';
      case 3:
        return 'Medical';
      case 4:
        return 'Trauma';
      case 5:
        return 'Maternity Care';
      case 6:
        return 'Medicines';
      case 7:
        return 'Special Situations';
      // case 8:
      //   return 'Drugs';
      // case 9:
      //   return 'Drugs';

      default:
        return '';
    }
  },

  async getAllPGDAcknowledgements() {
    // create array of all pgd acknowledgements
    var allPGDAcknowledgements = await DataController.getDataFromFile(
      'pgdAcknowledgements.json',
    );
    return allPGDAcknowledgements ?? [];
  },

  async getAllPGDs() {
    // create array of all pgds
    var allPGDs = [];
    var allTrustGuidelines = await DataController.getDataFromFile(
      'trustGuidelinesList.json',
    );

    if (allTrustGuidelines === null || allTrustGuidelines === '') {
      allTrustGuidelines = [];
    }

    for (
      let guidelineIndex = 0;
      guidelineIndex < allTrustGuidelines.length;
      guidelineIndex++
    ) {
      var guideline = allTrustGuidelines[guidelineIndex];

      if (
        guideline.GuidelineType === 'Patient Group Direction' ||
        guideline.GuidelineType === 'Prescription Medicine Authorisation'
      ) {
        allPGDs.push(guideline);
      }
    }

    return allPGDs;
  },

  async getTrustGuidelineJSONForGLID(GLID: string) {
    var fileName = GLID + '.json';

    var trustGuideline = await DataController.getDataFromFile(fileName);

    if (trustGuideline?.length === 0) {
      return null;
    } else {
      return trustGuideline;
    }
  },

  async getExcludedGuidelines() {
    try {
      var excludedGuidelines = await AsyncStorage.getItem('excludedGuidelines');

      if (excludedGuidelines != null) {
        return JSON.parse(excludedGuidelines);
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting excluded guidelines: ' + error);
      return [];
    }
  },
};
