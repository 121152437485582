import DashboardScreen from '../screens/DashboardScreen';
import QuizDashboardScreen from '../screens/QuizDashboardScreen';
import QuizSetListScreen from '../screens/QuizSetListScreen';
import QuizListScreen from '../screens/QuizListScreen';
import VideoListScreen from '../screens/VideoListScreen';
import VideoScreen from '../screens/VideoScreen';
import QuizOverviewScreen from '../screens/QuizOverviewScreen';
import QuizScreen from '../screens/QuizScreen';
import ResultsDashboardScreen from '../screens/ResultsDashboardScreen';
import AllResultsScreen from '../screens/AllResultsScreen';
import QuizTypeResultsScreen from '../screens/QuizTypeResultsScreen';
import QuizSetResultsScreen from '../screens/QuizSetResultsScreen';
import QuizResultsScreen from '../screens/QuizResultsScreen';
import QuizSingleResultScreen from '../screens/QuizSingleResultScreen';
import StandbyDashboardScreen from '../screens/StandbyDashboardScreen';
import StandbyVolumeScreen from '../screens/StandbyVolumeScreen';
import CaseStudiesDashboardScreen from '../screens/CaseStudiesDashboardScreen';
import CaseStudyOverviewScreen from '../screens/CaseStudyOverviewScreen';
import CaseStudiesAndStandbyDashboardScreen from '../screens/CaseStudiesAndStandbyDashboardScreen';
import BooksDashboardScreen from '../screens/BooksDashboardScreen';
import BookContentsScreen from '../screens/BookContentsScreen';
import BookReadingScreen from '../screens/BookReadingScreen';
import ReferenceScreen from '../screens/ReferenceScreen';
import PGDViewerScreen from '../screens/PGDViewerScreen';
import PDFViewerScreen from '../screens/PDFViewerScreen';
import SearchScreen from '../screens/SearchScreen';

import MainMenuScreen from '../screens/MainMenuScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SettingsScreen from '../screens/SettingsScreen';
import SubscriptionScreen from '../screens/SubscriptionScreen';
import HtmlInfoScreen from '../screens/HtmlInfoScreen';
import PlusSuggestedQuizScreen from '../screens/PlusSuggestedQuizScreen';
import PodcastScreen from '../screens/PodcastScreen';
import PodcastListScreen from '../screens/PodcastListScreen';
import PSIRFDashboardScreen from '../screens/PSIRFDashboardScreen';
import PSIRFScreen from '../screens/PSIRFScreen';
import BookBlurbScreen from '../screens/BookBlurbScreen';

export const Tabs = [
  {
    Name: 'Dashboard',
    Screens: {
      Dashboard: DashboardScreen,
      QuizSetList: QuizSetListScreen,
      QuizList: QuizListScreen,
      Video: VideoScreen,
      VideoList: VideoListScreen,
      Podcast: PodcastScreen,
      PodcastList: PodcastListScreen,
      Search: SearchScreen,
      QuizOverview: QuizOverviewScreen,
      StandbyVolume: StandbyVolumeScreen,
      CaseStudyOverview: CaseStudyOverviewScreen,
      Quiz: QuizScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
      PGDViewer: PGDViewerScreen,
      PlusSuggestedQuiz: PlusSuggestedQuizScreen,
      Promotion: QuizDashboardScreen,
      PSIRFDashboard: PSIRFDashboardScreen,
      PSIRF: PSIRFScreen,
    },
  },
  {
    Name: 'Quizzes',
    Screens: {
      QuizDashboard: QuizDashboardScreen,
      QuizSetList: QuizSetListScreen,
      Search: SearchScreen,
      QuizOverview: QuizOverviewScreen,
      Quiz: QuizScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
      PGDViewer: PGDViewerScreen,
    },
  },
  {
    Name: 'Standby CPD',
    Screens: {
      StandbyDashboard: StandbyDashboardScreen,
      StandbyVolume: StandbyVolumeScreen,
      Quiz: QuizScreen,
      Search: SearchScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
      PGDViewer: PGDViewerScreen,
    },
  },
  {
    Name: 'Case Studies',
    Screens: {
      CaseStudiesDashboard: CaseStudiesDashboardScreen,
      CaseStudyOverview: CaseStudyOverviewScreen,
      Quiz: QuizScreen,
      Search: SearchScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
    },
  },
  {
    Name: 'Case Studies and Standby',
    Screens: {
      CaseStudiesAndStandbyDashboard: CaseStudiesAndStandbyDashboardScreen,
      CaseStudiesDashboard: CaseStudiesDashboardScreen,
      CaseStudyOverview: CaseStudyOverviewScreen,
      Search: SearchScreen,
      StandbyDashboard: StandbyDashboardScreen,
      StandbyVolume: StandbyVolumeScreen,
      Quiz: QuizScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
    },
  },
  {
    Name: 'Books',
    Screens: {
      BooksDashboard: BooksDashboardScreen,
      BookContents: BookContentsScreen,
      BookReading: BookReadingScreen,
      Search: SearchScreen,
      BookBlurb: BookBlurbScreen,
      QuizOverview: QuizOverviewScreen,
      Quiz: QuizScreen,
      QuizResults: QuizResultsScreen,
    },
  },
  {
    Name: 'Results',
    Screens: {
      ResultsDashboard: ResultsDashboardScreen,
      AllResults: AllResultsScreen,
      QuizTypeResults: QuizTypeResultsScreen,
      QuizSetResults: QuizSetResultsScreen,
      QuizResults: QuizResultsScreen,
      PDFViewer: PDFViewerScreen,
      QuizSingleResult: QuizSingleResultScreen,
      Reference: ReferenceScreen,
      PGDViewer: PGDViewerScreen,
      Search: SearchScreen,
    },
  },
];

export const MenuScreens = {
  MainMenu: MainMenuScreen,
  Profile: ProfileScreen,
  Settings: SettingsScreen,
  Subscription: SubscriptionScreen,
  HtmlInfo: HtmlInfoScreen,
};

export const ScreensWithTabBarHidden = [
  'QuizOverview',
  'Quiz',
  'QuizResults',
  'StandbyVolume',
  'QuizSingleResult',
  'PDFViewer',
  'BookReading',
];
